import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

interface IAPEmployeeProjectRateData {
  ProjectUuid: string;
  sourceProjectUuid: string;
  roleCode: string;
  employeeUuid: string;
  projectUuid: string;
  ratePerWord: number;
  ratePerArticle: number;
}

const getAPEmployeeProjectRateAPI = (params: any) =>
  apiCall({
    url: Endpoint.APEmployeeProjectRate,
    method: Method.Get,
    params,
  });

const addAPEmployeeProjectRateAPI = (data: IAPEmployeeProjectRateData) =>
  apiCall({
    url: Endpoint.APEmployeeProjectRate,
    method: Method.Post,
    data,
  });
const editAPEmployeeProjectRateAPI = (
  urlSuffix: string,
  data: IAPEmployeeProjectRateData,
) =>
  apiCall({
    url: Endpoint.APEmployeeProjectRate,
    method: Method.Put,
    urlSuffix,
    data,
  });
const deleteAPEmployeeProjectRateAPI = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.APEmployeeProjectRate,
    method: Method.Delete,
    urlSuffix,
  });

export {
  getAPEmployeeProjectRateAPI,
  addAPEmployeeProjectRateAPI,
  editAPEmployeeProjectRateAPI,
  deleteAPEmployeeProjectRateAPI,
};
