import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import l from 'helpers/l';
import { ContentWrapper, EditTable, PageLayout } from 'components';
import { RootState } from '../../store';
import { useLocation, useParams } from 'react-router-dom';
import { GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { actions as reportsSlice } from '../../store/slices/reportSlices/reportsSlice';
import { Button, Grid, Typography } from '@mui/material';
import Goback from '../../components/Goback/Goback';
import { v4 as uuidv4 } from 'uuid';

function ReportWOParametersPage() {
    const dispatch = useDispatch();
    const [sort, setSort] = useState({});
    const location: any = useLocation();
    const executedReport = useSelector((state: RootState) => state.reports.executedReportItems);
    const executedReportMeta = useSelector((state: RootState) => state.reports.executedReportMeta);
    const { reportId } = useParams();

    useEffect(() => {

     dispatch(reportsSlice.setExecutedReport([]));

      const objToSend = {
        urlSuffix: `${reportId}/execute`,
      }

      dispatch(reportsSlice.executeReport(objToSend))
    },[dispatch]);

    const columns = () => {
        let formattedColumns: any[] = [];
        if (executedReport?.length > 0) {
            Object.keys(executedReport[0]).forEach((field: any) => {
                formattedColumns.push(
                    {
                        field: `${field}`,
                        headerName: `${field?.charAt(0)?.toUpperCase() + field?.slice(1)}`,
                        width: 250,
                      renderCell: (params: any) => {
                        return <Typography>{params?.value === null || params?.value === undefined ? ' ' : params?.value}</Typography>;
                      },
                    },
                )
            })
        }
        return formattedColumns;
    }

    const handleSort = useCallback(
        (model: GridSortModel) => {
            const formatValues = model.map((item: GridSortItem) => ({
                orderBy: item.field,
                order: item?.sort?.toUpperCase(),
            }))[0];

            setSort({orderBy: formatValues?.orderBy, order: formatValues?.order});
            const params = {
                orderBy: formatValues?.orderBy,
                order: formatValues?.order,
                urlSuffix: `${reportId}/execute`,
            };
            dispatch(reportsSlice.sendExecutedReportFilters(params));
        },
        [dispatch],
    );
    const handlePage = useCallback(
        (page: number) => {
            const params = {...sort, page: page + 1, urlSuffix: `${reportId}/execute`};
            dispatch(reportsSlice.sendExecutedReportFilters(params));
        },
        [dispatch, sort],
    );

    const handleExportToPDF = useCallback(
        () => {
            if (reportId) {
                dispatch(reportsSlice.exportPDF({urlSuffix:`${reportId}`, data: { parameters: location.state.reportParameters }}));
            }
        },
        [dispatch],
    );
    const handleExportToXLSX = useCallback(
        () => {
            if (reportId) {
                dispatch(reportsSlice.exportXLSX({urlSuffix:`${reportId}`, data: { parameters: location.state.reportParameters }}));
            }
        },
        [dispatch],
    );

    return (
        <PageLayout title={l('PAGES.REPORT_RESULT') + ': ' + location.state.name} fullWidth={true}>
          <Goback text="Go Back" location={-1} />
            {executedReport?.length > 0 ? (
              <Grid container flexDirection='row'>
                <Grid item xs={12} sx={{marginBottom: '19px'}}>
                  <EditTable
                    rows={executedReport}
                    columns={columns()}
                    getRowId={(row) => {
                      const rowObj = { ...row };
                      rowObj['customUuid'] = uuidv4();
                      return rowObj.customUuid;
                    }}
                    onSortModelChange={handleSort}
                    editColumnWidth={0}
                    pagination
                    page={executedReportMeta.currentPage - 1 || 0}
                    rowCount={executedReportMeta?.totalItems}
                    onPageChange={handlePage}
                    pageSize={executedReportMeta?.itemsPerPage}
                    rowsPerPageOptions={[executedReportMeta?.itemsPerPage]}
                    paginationMode="server"
                    sortingMode="server"
                  />
                </Grid>
                <Grid container flexDirection='row' sx={{paddingLeft: '29px'}} spacing={2}>
                  <Grid item>
                    <Button sx={{backgroundColor: '#1e98d7', borderRadius: '5px', width: '140px', height: '40px'}}
                            variant="contained" onClick={() => handleExportToPDF()}>
                      Export to PDF
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button sx={{backgroundColor: '#1e98d7', borderRadius: '5px', width: '140px', height: '40px'}}
                            variant="contained" onClick={() => handleExportToXLSX()}>
                      Export to XLSX
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              ) : (
                <ContentWrapper width='100%' sx={{alignItems: 'center'}}>
                  <Typography fontWeight={900} fontSize={18}>
                    {'No results'}
                  </Typography>
                </ContentWrapper>
              )

            }
        </PageLayout>
    );
}

export default memo(ReportWOParametersPage);
