import {Endpoint, Method} from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getCategoryByUuid = (urlSuffix: any) =>
    apiCall({
        url: Endpoint.CrudReportCategory,
        method: Method.Get,
        urlSuffix,
    });

const runTestQueryApi = (data: any) =>
    apiCall({
        url: Endpoint.RunTestQuery,
        method: Method.Post,
        data,
    });

const saveReportApi = (data: any) =>
    apiCall({
        url: Endpoint.ExecuteReport,
        method: Method.Post,
        data,
    });

const editReportApi = (urlSuffix: any, data: any) =>
    apiCall({
        url: Endpoint.ExecuteReport,
        method: Method.Put,
        urlSuffix,
        data,
    });

const getReportForEditApi= (urlSuffix: any) =>
    apiCall({
        url: Endpoint.ExecuteReport,
        method: Method.Get,
        urlSuffix,
    });

export {
    getCategoryByUuid,
    runTestQueryApi,
    saveReportApi,
    getReportForEditApi,
    editReportApi,
};
