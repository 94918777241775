import { useEffect, useState, memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store/slices/currenciesSlice';
import l from 'helpers/l';
import { RootState } from 'store';
import EditTable from 'components/Table/EditTable';
import PageLayout from 'components/PageLayout/PageLayout';
import { actions as commonActions } from 'store/slices/common';
import { GridRowParams, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { ModalType } from 'enums/ModalEnums';

const CurrenciesPage = () => {
  const dispatch = useDispatch();
  const [sort, setSort] = useState({});
  const loading = useSelector((state: RootState) => state.common.loading);
  const currencyList = useSelector(
    (state: RootState) => state.currencies.items,
  );
  const meta = useSelector((state: RootState) => state.currencies.meta);

  useEffect(() => {
    dispatch(actions.getCurrencyList());
  }, [dispatch]);

  const addEntryHandler = useCallback(() => {
    dispatch(commonActions.showModal({ type: ModalType.CurrenciesAdd }));
  }, [dispatch]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.CurrenciesEdit,
          data: params.id.toString(),
        }),
      );
    },
    [dispatch],
  );

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.CurrenciesDelete,
          data: params.id.toString(),
        }),
      );
    },
    [dispatch],
  );

  const handlePage = useCallback(
    (page: number) => {
      const params = { ...sort, page: page + 1 };
      dispatch(actions.sendCurrencyFilters(params));
    },
    [dispatch, sort],
  );

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: item.field,
        order: item?.sort?.toUpperCase(),
      }))[0];

      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });
      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
      };
      dispatch(actions.sendCurrencyFilters(params));
    },
    [dispatch],
  );

  return (
    <PageLayout title={l('PAGES.CURRENCIES')}>
      <EditTable
        onAdd={addEntryHandler}
        onEdit={editEntryHandler}
        onDelete={deleteEntryHandler}
        rows={currencyList}
        columns={[
          { field: 'code', headerName: l('CODE'), width: 150 },
          {
            field: 'name',
            headerName: l('NAME'),
            width: 200,
          },
        ]}
        loading={loading}
        sortedFieldIndex={1}
        onSortModelChange={handleSort}
        pagination
        page={meta.currentPage !== undefined ? meta.currentPage - 1 : 0}
        rowCount={meta?.totalItems}
        onPageChange={handlePage}
        pageSize={meta?.itemsPerPage}
        rowsPerPageOptions={[meta?.itemsPerPage || 10]}
        paginationMode="server"
        sortingMode="server"
      />
    </PageLayout>
  );
};
export default memo(CurrenciesPage);
