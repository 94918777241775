import { FC, memo, useCallback, useMemo } from 'react';
import { actions as userActions } from 'store/slices/user';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import * as Yup from 'yup';
import EmployeePreferenceForm from 'components/Forms/EmployeePreferenceForm';

const validationSchema = Yup.object().shape({
  employeeRoleUuid: Yup.string(),
  contactPersonUuid: Yup.string(),
  level: Yup.number(),
  description: Yup.string(),
});

const EmployeePreferenceModalEdit: FC<GenericModalProps> = ({
  data,
  ...rest
}) => {
  const dispatch = useDispatch();
  const {
    uuid,
    employeeUuid,
    entry,
    employeeDetails: { firstName, lastName },
  } = data;
  const name = `${firstName} ${lastName}`;

  const defaultValues = useMemo(() => {
    return {
      employeeRoleUuid: entry.employeeRole.uuid,
      contactPersonUuid: entry.contactPerson.uuid,
      level: entry.level,
      description: entry.description,
    };
  }, [entry]);

  const submitHandler = useCallback(
    (values: any) => {
      dispatch(
        userActions.editUserPreferences({
          id: uuid,
          uuid: employeeUuid,
          data: { ...values },
        }),
      );
    },
    [dispatch],
  );

  return data ? (
    <EmployeePreferenceForm
      title={l('EMPLOYEE.PREFERENCES_EDIT')}
      onSubmit={submitHandler}
      initialValues={defaultValues}
      validationSchema={validationSchema}
      employeeName={name}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(EmployeePreferenceModalEdit);
