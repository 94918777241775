import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, PageLayout, TextField } from 'components';
import {
  Box,
  Button,
  FormLabel,
  Grid,
  List,
  ListItem,
  Paper,
  Switch,
  TextareaAutosize,
  Typography,
} from '@mui/material';

import { useFormik } from 'formik';
import InputRowWrapper from '../../../components/InputRowWrapper/InputRowWrapper';
import colors from '../../../styles/colors';
import { actions as reportCategoriesActions } from '../../../store/slices/reportSlices/reportCategoriesSlice';
import { RootState } from '../../../store';
import { SuggestedDropdown } from '../../../components/SuggestedDropdown/SuggestedDropdown';
import { Endpoint } from '../../../enums/APIEndpointEnum';
import { actions as reportParameterActions } from '../../../store/slices/reportSlices/reportParameterSlice';
import AdjustIcon from '@mui/icons-material/Adjust';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment, { isMoment } from 'moment';
import insertTextAtCursor from 'insert-text-at-cursor';
import { actions as reportManagementActions } from '../../../store/slices/reportSlices/reportManagementSlices/reportManagementSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { createMenuItems } from '../../../helpers/dropdown';
import Goback from '../../../components/Goback/Goback';
import EditTable from '../../../components/Table/EditTable';
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@mui/icons-material/Close';
import { GridValueGetterParams } from '@mui/x-data-grid';

const datePickerStyles = {
  display: 'flex',
  backgroundColor: '#f2f2f2',
  borderRadius: '15px',
  padding: '12px',
};

function ReportsManagementAddEditPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTable, setSelectedTable] = useState<string>();
  const reportCategories = useSelector(
    (state: RootState) => state.reportCategories.categories,
  ).map((entry: any) => ({
    value: entry.uuid,
    label: entry.name,
  }));
  const tables = useSelector(
    (state: RootState) => state.reportParameters.tables,
  );
  const columns = useSelector(
    (state: RootState) => state.reportParameters.columns,
  );
  const parameters = useSelector(
    (state: RootState) => state.reportParameters.items,
  );
  const parametersForDropDown = useSelector(
    (state: RootState) => state.reportParameters.items,
  ).map((entry: any) => ({
    value: entry.uuid,
    label: entry.name,
  }));
  const runTestReportResult = useSelector(
    (state: RootState) => state.reportManagement.runTestReportResult,
  );
  const singleQuote = '`';
  const checkResult = runTestReportResult?.result?.length > 0;
  const reportForEdit = useSelector(
    (state: RootState) => state.reportManagement.reportForEdit,
  );
  const locationSelectedRep: any = location.state;
  const hasInitialReportParams =
    reportForEdit.reportParametersDropdowns?.length > 0 &&
    locationSelectedRep.isEdit;

  useEffect(() => {
    dispatch(
      reportManagementActions.setRunTestQueryResult({
        error: '',
        query: '',
        result: [],
      }),
    );
  }, []);
  const formats = [moment.ISO_8601, 'MM/DD/YYYY'];

  const runQueryColumns = () => {
    const formattedColumns: any[] = [];
    if (
      runTestReportResult?.result?.length > 0 &&
      typeof runTestReportResult !== 'string'
    ) {
      Object.keys(runTestReportResult?.result[0]).forEach((field: any) => {
        formattedColumns.push({
          field: `${field}`,
          headerName: `${field.charAt(0).toUpperCase() + field.slice(1)}`,
          width: 250,
          valueGetter: (params: GridValueGetterParams) => {
            return moment(params.row[field], formats, true).isValid()
              ? moment(params.row[field]).format('DD.MM.YYYY')
              : params.row[field];
          },
        });
      });
    }
    return formattedColumns;
  };

  const [addedParameters, setAddedParameters] = useState(
    hasInitialReportParams ? reportForEdit.reportParametersDropdowns : [],
  );

  useEffect(() => {
    dispatch(reportParameterActions.getReportParameters());
    dispatch(reportCategoriesActions.getReportCategories());
    dispatch(reportParameterActions.getReportParametersTables());
  }, [dispatch]);

  useEffect(() => {
    if (selectedTable) {
      dispatch(
        reportParameterActions.getReportParametersColumns({
          urlSuffix: selectedTable,
        }),
      );
    }
  }, [dispatch, selectedTable]);

  useEffect(() => {
    if (locationSelectedRep?.reportUuid) {
      dispatch(
        reportManagementActions.getReportForEdit({
          urlSuffix: locationSelectedRep.reportUuid,
        }),
      );
    }
  }, [dispatch, locationSelectedRep]);

  useEffect(() => {
    if (hasInitialReportParams) {
      setAddedParameters(reportForEdit.reportParametersDropdowns);
    } else {
      setAddedParameters([]);
    }
  }, [reportForEdit, hasInitialReportParams]);

  const initialValues: any = locationSelectedRep.isEdit
    ? reportForEdit
    : {
        isActive: false,
        category: locationSelectedRep?.category,
      };

  const { setValues, ...formik } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      const objToSend = {
        name: values.name,
        folderUuid: values.category || values.categoryUuid,
        isActive: values.isActive,
        query: values.query,
        columnLabels: formatColumnLabels(values),
        assignedParameters: formatAddedParameters(values),
        navigate,
      };

      if (locationSelectedRep.isEdit) {
        const editedObject = {
          urlSuffix: locationSelectedRep.reportUuid,
          data: objToSend,
        };
        dispatch(reportManagementActions.editReport(editedObject));
      } else {
        dispatch(reportManagementActions.saveReport(objToSend));
      }
      dispatch(reportManagementActions.setRunTestQueryResult({}));
    },
  });

  const formatColumnLabels = (formikValues: any) => {
    const arrWithColumnLabels: string[] = [];
    Object.keys(formikValues).map((objKey: any) => {
      if (objKey.includes('column_label')) {
        arrWithColumnLabels.push(formikValues[objKey]);
      }
    });
    return arrWithColumnLabels;
  };

  const handleAddParameter = () => {
    const lastElement = addedParameters.slice(-1)[0];
    if (lastElement) {
      setAddedParameters([
        ...addedParameters,
        {
          index: lastElement.index + 1,
        },
      ]);
    } else {
      setAddedParameters([
        ...addedParameters,
        {
          index: 1,
        },
      ]);
    }
  };

  const handleRemoveParameter = (
    parameter: any,
    parameterCode: any,
    indexWithSign: any,
  ) => {
    Object.keys(formik.values).forEach((property: any) => {
      if (property.includes(indexWithSign)) {
        formik.setFieldValue(property, 'DELETED');
      }
    });

    const newAddedParametersArray = addedParameters.filter((param: any) => {
      return param.index !== parameter.index;
    });

    setAddedParameters(newAddedParametersArray);
  };

  const getSelectedParameter = (index: any) => {
    return parameters.find(
      (parameter: any) =>
        parameter.uuid === formik.values[`reportParameter${index}`],
    );
  };

  const handleDateChange = (value: any, inputValue: any, index: any) => {
    formik.setFieldValue(`${getSelectedParameter(index).code}${index}`, value);
  };

  const handleTargetClick = (stringToPaste: string) => {
    const el: any = document.getElementById('text-autosize');
    insertTextAtCursor(el, stringToPaste);
  };

  const formatAddedParameters = (formikValues: any) => {
    const enteredValues = formikValues;
    const objWithAllParametersAndValues: any = {};

    Object.keys(enteredValues).map((objKey: any) => {
      if (objKey.includes('-param') && enteredValues[objKey] !== 'DELETED') {
        if (isMoment(enteredValues[objKey])) {
          objWithAllParametersAndValues[objKey] =
            enteredValues[objKey].toISOString();
        } else {
          objWithAllParametersAndValues[objKey] = enteredValues[objKey];
        }
      }
    });

    const arrToSend: any = [];

    Object.keys(objWithAllParametersAndValues).forEach((parameterKey: any) => {
      if (parameterKey.includes('reportParameter')) {
        const pramWithIndex = parameterKey.split('-')[1];
        Object.keys(objWithAllParametersAndValues).forEach((valueKey: any) => {
          if (
            valueKey.includes(pramWithIndex) &&
            !valueKey.includes('reportParameter')
          ) {
            if (objWithAllParametersAndValues[valueKey]?._isAMomentObject) {
              arrToSend.push({
                parameterUuid: objWithAllParametersAndValues[parameterKey],
                testValue: objWithAllParametersAndValues[valueKey],
                index: pramWithIndex.split('param')[1],
              });
            } else if (
              typeof objWithAllParametersAndValues[valueKey] === 'object'
            ) {
              arrToSend.push({
                parameterUuid: objWithAllParametersAndValues[parameterKey],
                testValue: objWithAllParametersAndValues[valueKey]?.uuid || '',
                index: pramWithIndex.split('param')[1],
              });
            } else {
              arrToSend.push({
                parameterUuid: objWithAllParametersAndValues[parameterKey],
                testValue: objWithAllParametersAndValues[valueKey],
                index: pramWithIndex.split('param')[1],
              });
            }
          }
        });
      }
    });

    return arrToSend;
  };

  const handleRunTestQuery = () => {
    const enteredValues = formik.values;
    const objWithAllParameterValues: any = {};
    const objToSend: any = {};

    Object.keys(enteredValues).map((objKey: any) => {
      if (!objKey.includes('reportParameter') && objKey.includes('-')) {
        const parameterKey = objKey.split('param').join('');
        if (enteredValues[objKey]?._isAMomentObject) {
          objWithAllParameterValues[parameterKey] =
            enteredValues[objKey].toISOString();
        } else if (typeof enteredValues[objKey] === 'object') {
          objWithAllParameterValues[parameterKey] = enteredValues[objKey]?.uuid;
        } else {
          objWithAllParameterValues[parameterKey] = enteredValues[objKey];
        }
      }
    });

    objToSend.query = formik.values.query;
    objToSend.parameters = objWithAllParameterValues;

    dispatch(reportManagementActions.runTestQuery(objToSend));
  };

  const getProperDefaultField = (index: any) => {
    if (
      getSelectedParameter(index)?.type === 'string' ||
      getSelectedParameter(index)?.type === 'number'
    ) {
      if (
        formik.values[`${getSelectedParameter(index).code}${index}`] ===
        undefined
      ) {
        formik.values[`${getSelectedParameter(index).code}${index}`] = '';
      }
      return (
        <TextField
          name={`${getSelectedParameter(index).code}${index}`}
          type={
            getSelectedParameter(index).type === 'number' ? 'number' : 'text'
          }
          fullWidth
          size="small"
          value={formik.values[`${getSelectedParameter(index).code}${index}`]}
          onChange={formik.handleChange}
          autoComplete="off"
        />
      );
    } else if (
      getSelectedParameter(index)?.type === 'date' ||
      getSelectedParameter(index)?.type === 'datetime'
    ) {
      if (
        formik.values[`${getSelectedParameter(index).code}${index}`] ===
        undefined
      ) {
        formik.values[`${getSelectedParameter(index).code}${index}`] = moment();
      }
      return (
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <KeyboardDatePicker
            name={`${getSelectedParameter(index).code}${index}`}
            autoOk
            showTodayButton
            value={formik.values[`${getSelectedParameter(index).code}${index}`]}
            onChange={(value: any, inputValues: any) =>
              handleDateChange(value, inputValues, index)
            }
            format="DD.MM.YYYY"
            style={datePickerStyles}
          />
        </MuiPickersUtilsProvider>
      );
    } else if (getSelectedParameter(index)?.type === 'reference') {
      if (
        formik.values[`${getSelectedParameter(index).code}${index}`] ===
        undefined
      ) {
        formik.values[`${getSelectedParameter(index).code}${index}`] = '';
      }

      return (
        <SuggestedDropdown
          url={Endpoint.ReportRunQuery}
          reportParameter={{
            parameter: {
              uuid: formik.values[`reportParameter${index}`],
            },
          }}
          defaultValue={
            formik.values[`${getSelectedParameter(index).code}${index}`] || ''
          }
          inputId={`${getSelectedParameter(index).code}${index}`}
          inputValue={
            formik.values[`${getSelectedParameter(index).code}${index}`] || ''
          }
          value={
            formik.values[`${getSelectedParameter(index).code}${index}`] || ''
          }
          dropdownLabel="label"
          dropdownValue="key"
          dataLocation="resultData"
          style={{ display: 'flex', flexDirection: 'row' }}
          formikHook={formik}
        />
      );
    } else if (getSelectedParameter(index)?.code === 'current_employee_id') {
      if (
        formik.values[`${getSelectedParameter(index).code}${index}`] ===
        undefined
      ) {
        formik.values[`${getSelectedParameter(index).code}${index}`] = '';
      }
    }
  };

  return (
    <PageLayout
      title={`Report Management ${locationSelectedRep.isEdit ? 'Edit' : 'Add'}`}
      fullWidth={true}>
      <Form
        contentWrapperSx={{
          boxShadow: '0',
          backgroundColor: colors.mainBackground,
        }}>
        <Grid container flexDirection={'column'} spacing={2}>
          <Grid item key={'descriptionForm'}>
            <Goback text="Go Back" location={-1} />
            <Paper sx={{ padding: '22px 36px 23px', borderRadius: '10px' }}>
              <InputRowWrapper
                label={'Name'}
                labelWidth="175px"
                inputWidth="60%">
                <TextField
                  name="name"
                  fullWidth
                  size="small"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  error={Boolean(formik.errors.name)}
                  helperText={formik.errors.name}
                />
              </InputRowWrapper>
              <InputRowWrapper
                label={'Category'}
                labelWidth="175px"
                inputWidth="40%">
                <TextField
                  select
                  name="category"
                  size="small"
                  value={
                    (locationSelectedRep.isEdit
                      ? formik?.values?.categoryUuid
                      : formik?.values?.category) || ''
                  }
                  onChange={formik.handleChange}
                  autoComplete="off">
                  {createMenuItems(reportCategories)}
                </TextField>
              </InputRowWrapper>
              <InputRowWrapper
                label={'IsActive?'}
                labelWidth="175px"
                inputWidth="60%">
                <Switch
                  name="isActive"
                  size="small"
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                />
              </InputRowWrapper>
            </Paper>
          </Grid>
          <Grid item key={'queryCreator'}>
            <Paper sx={{ padding: '22px 36px 23px', borderRadius: '10px' }}>
              <Grid
                key={'mainContainer'}
                container
                flexDirection={'row'}
                spacing={2}>
                <Grid
                  key={'leftSideContainer'}
                  item
                  flexDirection={'column'}
                  xs={6}>
                  <Grid key={'textareaItem'} item>
                    <TextareaAutosize
                      id={'text-autosize'}
                      name="query"
                      minRows={30}
                      value={formik.values.query}
                      onChange={formik.handleChange}
                      style={{
                        resize: 'none',
                        width: '100%',
                        backgroundColor: '#f2f2f2',
                        fontFamily: 'Courier New',
                        padding: '10px',
                        borderRadius: '10px',
                        border: 'none',
                        fontSize: 'large',
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  key={'rightSideContainer'}
                  container
                  flexDirection={'row'}
                  xs={6}>
                  <Grid key={'tablesItem'} item flexDirection={'column'} xs={6}>
                    <FormLabel sx={{ fontWeight: 900, marginLeft: '45px' }}>
                      {'Tables'}
                    </FormLabel>
                    <Box style={{ maxHeight: '600px', overflow: 'auto' }}>
                      <List>
                        {tables &&
                          tables.map((table: string) => {
                            return (
                              <ListItem sx={{ cursor: 'pointer' }}>
                                <AdjustIcon
                                  onClick={() => {
                                    handleTargetClick(
                                      `${singleQuote}${table}${singleQuote}`,
                                    );
                                  }}
                                />
                                <Typography
                                  onClick={() => {
                                    setSelectedTable(table);
                                  }}
                                  color={
                                    selectedTable === table ? 'primary' : ''
                                  }
                                  sx={{
                                    fontWeight:
                                      selectedTable === table ? 900 : 500,
                                    marginLeft: '6px',
                                  }}>
                                  {table}
                                </Typography>
                              </ListItem>
                            );
                          })}
                      </List>
                    </Box>
                  </Grid>
                  <Grid key={'columnItem'} item flexDirection={'column'} xs={6}>
                    <FormLabel sx={{ fontWeight: 900, marginLeft: '45px' }}>
                      {columns.length > 0 ? 'Fields' : ''}
                    </FormLabel>
                    <Box style={{ maxHeight: '600px', overflow: 'auto' }}>
                      <List>
                        {columns.length > 0 &&
                          columns.map((column: string) => {
                            return (
                              <ListItem sx={{ cursor: 'pointer' }}>
                                <AdjustIcon
                                  onClick={() => {
                                    const stringToPaste = `${singleQuote}${selectedTable}${singleQuote}.${singleQuote}${column}${singleQuote}`;
                                    handleTargetClick(stringToPaste);
                                  }}
                                />
                                <Typography sx={{ marginLeft: '6px' }}>
                                  {column}
                                </Typography>
                              </ListItem>
                            );
                          })}
                      </List>
                    </Box>
                  </Grid>
                </Grid>
                <Grid key={'parametersItem'} item width={'87%'}>
                  <FormLabel sx={{ fontWeight: 900, marginRight: '15px' }}>
                    {'Parameters'}
                  </FormLabel>
                  {addedParameters?.map((parameter: any, index: number) => {
                    const indexOfField = addedParameters[index].index;
                    const indexWithSign = `-param${addedParameters[index].index}`;
                    const isIndexZero = index === 0;

                    return (
                      <Grid
                        container
                        flexDirection={'row'}
                        key={parameter.index}>
                        <Grid
                          item
                          flexDirection={'row'}
                          width={'20%'}
                          marginRight={'3%'}
                          sx={{
                            minWidth: '170px',
                          }}>
                          {isIndexZero && <FormLabel>{'Parameters'}</FormLabel>}
                          <Grid alignItems={'center'}>
                            <TextField
                              select
                              name={`reportParameter${indexWithSign}`}
                              size="small"
                              value={
                                formik.values[
                                  `reportParameter${indexWithSign}`
                                ] || ''
                              }
                              onChange={formik.handleChange}
                              autoComplete="off"
                              sx={{ maxWidth: '140px' }}>
                              {createMenuItems(parametersForDropDown)}
                            </TextField>
                            <Button
                              onClick={() => {
                                handleRemoveParameter(
                                  parameter,
                                  `${
                                    getSelectedParameter(indexWithSign)?.code
                                  }`,
                                  indexWithSign,
                                );
                              }}
                              children={<CloseIcon />}
                              color={'inherit'}
                              style={{
                                maxWidth: '30px',
                                maxHeight: '30px',
                                minWidth: '30px',
                                minHeight: '30px',
                                marginTop: '10px',
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          flexDirection={'column'}
                          width={'30%'}
                          marginRight={'3%'}>
                          {isIndexZero && <FormLabel>{'Code'}</FormLabel>}
                          {formik.values[`reportParameter${indexWithSign}`] && (
                            <Box>
                              {getSelectedParameter(indexWithSign)?.code && (
                                <ListItem
                                  sx={{
                                    cursor: 'pointer',
                                    paddingLeft: '0px',
                                  }}>
                                  <Typography
                                    sx={{
                                      fontWeight: 900,
                                      marginRight: '6px',
                                      wordBreak: 'break-all',
                                    }}>
                                    {`{{${
                                      getSelectedParameter(indexWithSign)?.code
                                    }-${indexOfField}}}`}
                                  </Typography>
                                  <AdjustIcon
                                    onClick={() => {
                                      handleTargetClick(
                                        `{{${
                                          getSelectedParameter(indexWithSign)
                                            ?.code
                                        }-${indexOfField}}}`,
                                      );
                                    }}
                                  />
                                </ListItem>
                              )}
                            </Box>
                          )}
                        </Grid>
                        <Grid item flexDirection={'column'} width={'30%'}>
                          {isIndexZero && <FormLabel>{'Default'}</FormLabel>}
                          {formik.values[`reportParameter${indexWithSign}`] &&
                            getProperDefaultField(indexWithSign)}
                        </Grid>
                      </Grid>
                    );
                  })}
                  <FormLabel
                    sx={{ color: '#02a7f0', cursor: 'pointer' }}
                    onClick={handleAddParameter}>
                    {'Add parameter'}
                  </FormLabel>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item key={'runQuery'}>
            <Paper sx={{ padding: '22px 36px 23px', borderRadius: '10px' }}>
              <Grid
                container
                flexDirection={'column'}
                alignItems={'flex-start'}
                spacing={1}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    size={'large'}
                    onClick={handleRunTestQuery}>
                    Run Query
                  </Button>
                </Grid>
                <Grid item width={'100%'}>
                  {runTestReportResult?.error !== '' && (
                    <Typography>{runTestReportResult?.error}</Typography>
                  )}
                  <TextareaAutosize
                    id={'text-result-query'}
                    readOnly
                    value={
                      typeof runTestReportResult === 'string'
                        ? runTestReportResult
                        : runTestReportResult?.query
                    }
                    minRows={1}
                    style={{
                      resize: 'none',
                      width: '100%',
                      backgroundColor: '#f2f2f2',
                      fontFamily: 'Courier New',
                      padding: '10px',
                      borderRadius: '10px',
                      border: 'none',
                    }}
                  />
                  {runTestReportResult?.result?.length > 0 &&
                    typeof runTestReportResult !== 'string' && (
                      <EditTable
                        getRowId={(row) => {
                          const rowObj = { ...row };
                          rowObj['customUuid'] = uuidv4();
                          return rowObj.customUuid;
                        }}
                        columns={runQueryColumns()}
                        rows={runTestReportResult.result}
                        hideFooterPagination
                        hideFooter
                      />
                    )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item key={'column labels'}>
            {checkResult && (
              <Paper
                sx={{
                  padding: '22px 36px 23px',
                  borderRadius: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <FormLabel sx={{ fontWeight: 900 }}>
                  {'Column Labels'}
                </FormLabel>
                {Object.keys(runTestReportResult?.result[0] || []).map(
                  (key: any, index: any) => {
                    const hasColumnLabel = formik.values?.columnLabels;

                    return (
                      <InputRowWrapper
                        label={`Column ${index + 1} Label`}
                        maxWidth={'30%'}
                        labelWidth="175px">
                        <TextField
                          name={`column_label_${key}`}
                          size="small"
                          defaultValue={
                            hasColumnLabel
                              ? formik.values?.columnLabels[index]
                              : key
                          }
                          value={formik.values[`column_label_${key}`]}
                          onChange={formik.handleChange}
                          autoComplete="off"
                        />
                      </InputRowWrapper>
                    );
                  },
                )}
              </Paper>
            )}
            <Grid
              container
              flexDirection={'row'}
              spacing={2}
              marginTop={'10px'}>
              {/*{checkResult && (*/}{' '}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size={'large'}
                  onClick={formik.submitForm}>
                  Save
                </Button>
              </Grid>{' '}
              {/*)}*/}
              <Grid item>
                <Button
                  variant="outlined"
                  color="inherit"
                  size={'large'}
                  onClick={() => {
                    navigate(-1);
                  }}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </PageLayout>
  );
}

export default memo(ReportsManagementAddEditPage);
