import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

export interface IClientRateData {
  languageUuid: string;
  sourceLanguageUuid: string;
  contactPersonUuid: string;
  ratePerWord: number;
  ratePerArticle: number;
}

const getClientsRateAPI = () =>
  apiCall({
    url: Endpoint.ClientRate,
    method: Method.Get,
  });
const getClientsRateFiltersAPI = (params: any) =>
  apiCall({
    url: Endpoint.ClientRate,
    method: Method.Get,
    params,
  });

const addClientsRateAPI = (data: IClientRateData) =>
  apiCall({
    url: Endpoint.ClientRate,
    method: Method.Post,
    data,
  });
const editClientsRateAPI = (urlSuffix: string, data: IClientRateData) =>
  apiCall({
    url: Endpoint.ClientRate,
    method: Method.Put,
    urlSuffix,
    data,
  });
const deleteClientsRateAPI = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.ClientRate,
    method: Method.Delete,
    urlSuffix,
  });

export {
  getClientsRateAPI,
  addClientsRateAPI,
  editClientsRateAPI,
  deleteClientsRateAPI,
  getClientsRateFiltersAPI,
};
