import { memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLayout } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { actions as ordersActions } from 'store/slices/orderEntriesSlice';
import l from 'helpers/l';
import OrderEntriesForm from 'components/Forms/OrderEntriesForm';

function OrderEntriesEditPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const order = useParams();

  const orderEntries = useSelector(
    (state: RootState) => state.orderEntries?.items,
  );
  const [exactOrder] = orderEntries.filter((c: any) => c?.uuid === order.id);

  const initialValues = {
    client: exactOrder?.contactPerson?.client?.id,
    contactPersonUuid: exactOrder?.contactPerson?.uuid,
    contactPersonName: exactOrder?.contactPerson?.nick,
    orderDate: exactOrder?.orderedAt,
    dueDate: exactOrder?.dueDatetime,
    dateCritical: exactOrder?.dateCritical,
    orderTypeId: exactOrder?.orderType?.id,
    isCorrection: exactOrder?.isCorrection,
    isRatePerArticleClient: exactOrder?.isRatePerArticleClient,
    isRatePerArticleWriter: exactOrder?.isRatePerArticleWriter,
    isNoCharge: exactOrder?.isNoCharge,
    comments: exactOrder?.comments,
  };
  const handleSubmit = (values: any) => {
    const data = {
      ...values,
      uuid: order.id,
      navigate,
    };
    dispatch(ordersActions.editEntries({ ...data, navigate }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <PageLayout title={l('EDIT_ORDER')} fullWidth={true}>
      <OrderEntriesForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        initialValues={initialValues}
      />
    </PageLayout>
  );
}

export default memo(OrderEntriesEditPage);
