import { FC, memo, useCallback } from 'react';
import { actions as employeeStatusesActions } from 'store/slices/employeeStatusesSlice';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import EmployeeStatusesForm from 'components/Forms/EmployeeStatusesForm';
import { EmployeeStatusesAddEntry } from 'types/employeeStatusesTypes';

const EmployeeStatusesAddModal: FC<GenericModalProps> = (props) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: EmployeeStatusesAddEntry) =>
      dispatch(employeeStatusesActions.addEmployeeStatuses(values)),
    [dispatch],
  );

  return (
    <EmployeeStatusesForm
      title={l('ADD_NEW_EMPLOYEE_STATUS')}
      onSubmit={submitHandler}
      {...props}
    />
  );
};

export default memo(EmployeeStatusesAddModal);
