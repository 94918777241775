import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CurrencyEntry,
  Currency,

} from 'types/currencyTypes';

const initialState: Currency = {
  items: [],
  meta: {},
};

export const currencies = createSlice({
  name: 'currencies',
  initialState,
  reducers: {
    getCurrencyList: (state) => state,
    setCurrencyList: (state, action: PayloadAction<Currency>) => {
      state.items = action.payload.items;
      state.meta = action.payload.meta;
    },
    sendCurrencyFilters: (state) => state,
    addCurrency: (state) => state,
    addCurrencySuccess: (state, action: PayloadAction<CurrencyEntry>) => {
      state.items = [...state.items, action.payload];
    },
    editCurrency: (state) => state,
    editCurrencySuccess: (state, action: PayloadAction<CurrencyEntry>) => {
      const id = action.payload.uuid;
      const findIndex = state.items.findIndex((lang) => lang.uuid === id);
      const copyState = state.items.slice();
      copyState.splice(findIndex, 1, action.payload);
      state.items = copyState;
    },

    deleteCurrency: (state) =>
      state,
    deleteCurrencySuccess: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.items = state.items.filter((currency) => currency.uuid !== id);
    },
  },
});
export const actions: any = currencies.actions;
export default currencies.reducer;
