import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/checklistsSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  addChecklistsApi,
  getChecklistsApi,
  editChecklistsApi,
  deleteChecklistsApi,
  sendChecklistsFiltersApi,
} from 'store/api/checklistsAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { GenericIdNameType } from 'types/genericTypes';
import { APIResult } from 'types/APITypes';
import {genericErrorMessageHelper} from "../../helpers/messageHelpers";

function* sendChecklistsFilters(action: PayloadAction<string>) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(sendChecklistsFiltersApi, params);

  if (result?.data) {
    yield put(actions.setChecklists(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* getChecklists() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any[]> = yield call(getChecklistsApi);
  if (result?.data) {
    yield put(actions.setChecklists(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addChecklists(action: PayloadAction<any>) {
  const data = action.payload;
  const { result, error }: APIResult<any> = yield call(addChecklistsApi, data);
  if (result?.data) {
    const { result }: APIResult<any[]> = yield call(getChecklistsApi);
    if (result?.data) {
      yield put(actions.setChecklists(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.name} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.name} already exists`,
        severity: 'error',
      }),
    );
  }
}

function* deleteChecklists(action: PayloadAction<GenericIdNameType>) {
  const { id, name } = action.payload;
  const { result, error }: APIResult<GenericIdNameType> = yield call(
    deleteChecklistsApi,
    id! as string,
  );
  if (error?.status === 400) {
    yield put(
        commonActions.snackBar({
          open: true,
          message: 'This item can not be deleted due to existing relations',
          severity: 'error',
        }),
    );
  } else if (error) {
    yield put(
        commonActions.snackBar({
          open: true,
          message: genericErrorMessageHelper(error),
          severity: 'error',
        }),
    );
  }else if (result?.status === 200) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getChecklistsApi,
    );
    if (result?.data) {
      yield put(actions.setChecklists(result.data));
    }

    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
  }
  yield put(commonActions.hideModal());
}

function* editChecklists(action: PayloadAction<any>) {
  const { result, error }: APIResult<any> = yield call(
    editChecklistsApi,
    action.payload,
  );
  if (result?.status === 200) {
    const { result }: APIResult<any[]> = yield call(getChecklistsApi);
    if (result?.data) {
      yield put(actions.setChecklists(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${action.payload.newName}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Checklist name is taken!',
        severity: 'error',
      }),
    );
  }
}

export default function* ChecklistsWatcher() {
  yield takeLatest(actions.sendChecklistsFilters.type, sendChecklistsFilters);
  yield takeLatest(actions.getChecklists.type, getChecklists);
  yield takeLatest(actions.addChecklists.type, addChecklists);
  yield takeLatest(actions.deleteChecklists.type, deleteChecklists);
  yield takeLatest(actions.editChecklists.type, editChecklists);
}
