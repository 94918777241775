import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from 'components/PageLayout/PageLayout';
import l from 'helpers/l';
import { RootState } from 'store';
import {
  actions as orderLineActions,
  actions as orderLinesActions,
} from 'store/slices/orderLinesSlice';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentWrapper, EmployeeDetail, Goback, Spinner } from 'components';
import WriterForm from 'components/Forms/WriterForm';
import { getDateColor } from 'config/utilsFunctions';
import { numberOfDays } from 'constants/globalVariables';
import moment from 'moment';
import { actions as commonActions } from '../../store/slices/common';
import { ModalType } from '../../enums/ModalEnums';
import { put } from 'redux-saga/effects';
import { genericErrorMessageHelper } from '../../helpers/messageHelpers';

const WriterDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  useSelector((state: RootState) => state.common.loading);
  const checkList = useSelector(
    (state: RootState) => state?.orderLines?.pmCheckList,
  );

  const [loading, setLoading] = useState<boolean>(true);

  const checkListCount = `Writer Checklist ${checkList.checked}/${checkList.total}`;

  const orderLineStatuses = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  );

  //  const orderLineDetails = useSelector(
  //    (state: RootState) => state.orderLines?.writerPage,
  //  ).find((line: any) => line.uuid === params.id);

  const orderLineDetails = useSelector(
    (state: RootState) => state.orderLines?.item,
  );

  const editWriterCheckList = useCallback(() => {
    dispatch(
      commonActions.showModal({
        type: ModalType.PmCheckList,
        widht: '500px',
        data: { orderLineUuid: params?.id, role: 'writer' },
      }),
    );
  }, [dispatch]);

  const initialValues = {
    orderLineStatusUuid:
      orderLineDetails?.lastStatusLogEntry?.orderLineStatus?.uuid,
    statusDescription: orderLineDetails?.lastStatusLogEntry?.description,
    writerArticleLink: orderLineDetails?.writerArticleLink,
  };

  const handleSubmit = (values: any) => {
    const data = {
      ...values,
      urlSuffix: params.id,
      navigate,
    };

    dispatch(orderLinesActions.editOrderLinesWriterPage(data));
  };

  useEffect(() => {
    const paramsOrder = {
      //  order: 'ASC',
      orderLineUuid: params.id,
    };
    //const paramsOrder = {
    //  order: 'ASC',
    //};
    //dispatch(orderLinesActions.getOrderLinesWriterPage(paramsOrder));
    dispatch(orderLinesActions.getOrderLine(paramsOrder));
    dispatch(orderLineActions.getWriterCheckList({ orderLineUuid: params.id }));
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, []);

  if (loading) {
    return <Spinner title="Please wait..." />;
  }

  return (
    <PageLayout
      title={`${l('PAGES.WRITER_SCREEN')}: Order ${
        orderLineDetails?.order?.id
      }, Line ${orderLineDetails?.lineNumber}`}
      fullWidth>
      <Box
        style={{ width: '100%' }}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Box>
          <Goback text="back to list" location={'/writer-screen'} />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => editWriterCheckList()}>
            {checkListCount}
          </Button>
        </Box>
      </Box>
      <ContentWrapper mb="20px">
        <Box minWidth={600}>
          <Box mb="20px">
            <Grid
              container
              spacing={4}
              wrap="wrap"
              sx={{ marginBottom: '20px' }}>
              <Grid item>
                <EmployeeDetail
                  title="Contact Person"
                  text={orderLineDetails?.order?.contactPerson?.nick}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Order #"
                  text={orderLineDetails?.order?.id}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Line #"
                  text={orderLineDetails?.lineNumber}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Order Date"
                  text={moment(orderLineDetails?.order?.orderedAt).format(
                    'DD.MM.YYYY',
                  )}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Article Type"
                  text={orderLineDetails?.articleType?.name}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Source Language"
                  text={orderLineDetails?.sourceLanguage?.name}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Language"
                  text={orderLineDetails?.language?.name}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Quantity"
                  text={orderLineDetails?.quantity}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Current Status"
                  text={
                    orderLineDetails?.lastStatusLogEntry?.orderLineStatus?.name
                  }
                  color={
                    orderLineDetails?.lastStatusLogEntry?.orderLineStatus?.color
                  }
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Status Description"
                  text={orderLineDetails?.lastStatusLogEntry?.description}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} wrap="wrap">
              <Grid item>
                <EmployeeDetail
                  title="Project Manager"
                  text={
                    orderLineDetails?.pmEmployee !== null
                      ? orderLineDetails?.pmEmployee?.firstName +
                        ' ' +
                        orderLineDetails?.pmEmployee?.lastName
                      : ''
                  }
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Assistant Project Manager"
                  text={
                    orderLineDetails?.assistantProjectManager !== null
                      ? orderLineDetails?.assistantProjectManager?.firstName +
                        ' ' +
                        orderLineDetails?.assistantProjectManager?.lastName
                      : ''
                  }
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Editor"
                  text={
                    orderLineDetails?.editor !== null
                      ? orderLineDetails?.editor?.firstName +
                        ' ' +
                        orderLineDetails?.editor?.lastName
                      : ''
                  }
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Writer"
                  text={
                    orderLineDetails?.writer !== null
                      ? orderLineDetails?.writer?.firstName +
                        ' ' +
                        orderLineDetails?.writer?.lastName
                      : ''
                  }
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Meta Description"
                  type={'check'}
                  text={orderLineDetails?.hasMetaDescription}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Meta Title"
                  type={'check'}
                  text={orderLineDetails?.hasMetaTitle}
                />
              </Grid>
              <Grid item>
                <Grid container flexDirection={'column'}>
                  <Grid item>
                    <Typography variant="subtitle2">Writer Due Date</Typography>
                    <Typography
                      sx={{ fontWeight: 600 }}
                      color={getDateColor(
                        orderLineDetails?.writerDueDateTime,
                        numberOfDays,
                      )}>
                      {orderLineDetails?.writerDueDateTime !== null
                        ? moment(orderLineDetails?.writerDueDateTime).format(
                            'DD.MM.YYYY',
                          )
                        : ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ContentWrapper>
      <WriterForm
        onSubmit={handleSubmit}
        onCancel={() => navigate(-1)}
        orderLineDetails={orderLineDetails}
        initialValues={initialValues}
      />
    </PageLayout>
  );
};

export default memo(WriterDetailPage);
