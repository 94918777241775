import { FC, memo, useCallback } from 'react';
import { actions as articleTypesActions } from 'store/slices/articleTypesSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import ArticleTypesForm from 'components/Forms/ArticleTypesForm';
import { RootState } from 'store';
import { ArticleTypesActionEntry } from 'types/articleTypes';

const ArticleTypesEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state: RootState) =>
    data
      ? state.articleTypes.articleTypes.find((entry) => entry?.name === data)
      : null,
  );

  const submitHandler = useCallback(
    (values: ArticleTypesActionEntry) =>
      dispatch(articleTypesActions.editArticleTypes({ ...values })),
    [dispatch, entry?.name],
  );

  return entry ? (
    <ArticleTypesForm
      title={l('EDIT_ARTICLE_TYPE')}
      onSubmit={submitHandler}
      initialValues={entry}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(ArticleTypesEditModal);
