import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/rolesSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  getRolesApi,
  addRolesApi,
  editRolesApi,
  deleteRolesApi,
} from 'store/api/rolesAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { Role } from 'interfaces/roles';
import { APIResult } from 'types/APITypes';
import { RoleType } from 'types/roleTypes';
import { GenericIdNameType } from 'types/genericTypes';

function* getRoles() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<RoleType[]> = yield call(getRolesApi);
  if (result?.data?.length) {
    yield put(actions.setRoles(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addRoles(action: PayloadAction<Role['entry']>) {
  const data = action.payload;
  const { result, error }: APIResult<RoleType> = yield call(addRolesApi, data);
  if (result?.status === 201) {
    const { result }: APIResult<RoleType[]> = yield call(getRolesApi);
    if (result?.data?.length) {
      yield put(actions.setRoles(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.name} to the list of roles`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.name} already exists`,
        severity: 'error',
      }),
    );
  }
}

function* deleteRoles(action: PayloadAction<Role['entry']>) {
  const { code, name } = action.payload;
  const { result }: APIResult<GenericIdNameType> = yield call(
    deleteRolesApi,
    code,
  );
  if (result?.status === 200) {
    const { result }: APIResult<RoleType[]> = yield call(getRolesApi);
    if (result?.data) {
      yield put(actions.setRoles(result.data));
    }
    yield put(commonActions.hideModal());
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
  }
}

function* editRoles(action: PayloadAction<Role['entry']>) {
  const data = action.payload;

  const { result, error }: APIResult<RoleType> = yield call(editRolesApi, data);
  if (result?.status === 200) {
    const { result }: APIResult<RoleType[]> = yield call(getRolesApi);
    if (result?.data?.length) {
      yield put(actions.setRoles(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${data.name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Speaker name already exist!',
        severity: 'error',
      }),
    );
  }
}

export default function* RolesWatcher() {
  yield takeLatest(actions.getRoles.type, getRoles);
  yield takeLatest(actions.addRoles.type, addRoles);
  yield takeLatest(actions.deleteRoles.type, deleteRoles);
  yield takeLatest(actions.editRoles.type, editRoles);
}
