import { FC, memo, useCallback } from 'react';
import { actions as questionsActions } from 'store/slices/questionsSlice';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import QuestionsForm from 'components/Forms/QuestionsFrom';
import { QuestionEntry } from 'types/questionsTypes';

const QuestionsAddModal: FC<GenericModalProps> = (props) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: QuestionEntry) => dispatch(questionsActions.addQuestion(values)),
    [dispatch],
  );

  return (
    <QuestionsForm
      title={l('ADD_NEW_QUESTION')}
      onSubmit={submitHandler}
      {...props}
    />
  );
};

export default memo(QuestionsAddModal);
