import { FC, memo, useCallback } from 'react';
import { actions as targetAudienceActions } from 'store/slices/targetAudienceSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import EmployeeStatusesForm from 'components/Forms/EmployeeStatusesForm';
import { RootState } from 'store';
import { GenericIdNameType } from 'types/genericTypes';

const TargetAudienceEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state: RootState) =>
    data
      ? state.targetAudience.targetAudience.find(
          (entry: any) => entry?.name === data,
        )
      : null,
  );

  const submitHandler = useCallback(
    (values: GenericIdNameType) => {
      dispatch(targetAudienceActions.editTargetAudience({ ...values }));
    },
    [dispatch, entry?.name],
  );

  return entry ? (
    <EmployeeStatusesForm
      title="Target Audience Edit"
      onSubmit={submitHandler}
      initialValues={entry}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(TargetAudienceEditModal);
