import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  employees: [],
  teamLeads: [],
  employeesPm: [],
  employeesByRole: [],
  tableData: {},
  employeeDetails: {},
};

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    getEmployeesList: (state) => state,
    getTeamLeadsList: (state) => state,
    getEmployeesPM: (state) => state,
    getEmployee: (state, action: any) =>
      state.employees.find((emp: any) => emp.id === action.payload),
    setEmployeesList: (state, action: any) => {
      return { ...state, employees: action.payload };
    },
    setTeamLeadsList: (state, action: any) => {
      return { ...state, teamLeads: action.payload };
    },
    getEmployeesByRole: (state) => state,
    setEmployeesListByRole: (state, action: any) => {
      return { ...state, employeesByRole: action.payload };
    },
    setEmployeesListPM: (state, action: PayloadAction<any>) => {
      state.employeesPm = action.payload;
      //  return { ...state, employeesPM: action?.payload };
    },
    setEmployeeTableData: (state, action: any) => {
      return { ...state, tableData: action.payload };
    },
    addEmployee: (state) => state,
    addEmployeeSuccess: (state, action: any) => {
      return { ...state, employeeDetails: action.payload };
    },
    editEmployee: (state) => state,
    editEmployeeSuccess: (state, action: any) => {
      return { ...state, employeeDetails: action.payload };
    },
    deleteEmployee: (state) => state,
    deleteEmployeeSuccess: (state, action) => {
      return {
        ...state,
        employees: state.employees.filter(
          (emp: any) => emp.uuid !== action.payload,
        ),
      };
    },
    sendFilters: (state) => state,
    createUpdateUser: (state, action: any) => {
      return {
        ...state,
        employeeDetails: { ...state.employeeDetails, user: action.payload },
      };
    },
    getDetailsPage: (state) => state,
    setDetailsPage: (state, action: PayloadAction<any>) => {
      state.employeeDetails = { ...action.payload };
    },
  },
});
export const actions: any = employeesSlice.actions;
export default employeesSlice.reducer;
