import { FC, memo, useCallback } from 'react';
import { actions as currenciesActions } from 'store/slices/currenciesSlice';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import { GenericCodeNameType } from 'types/genericTypes';
import CurrenciesForm from 'components/Forms/CurrenciesForm';

const CurrenciesAddModal: FC<GenericModalProps> = (props) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: GenericCodeNameType) =>
      dispatch(currenciesActions.addCurrency(values)),
    [dispatch],
  );

  return (
    <CurrenciesForm
      title={l('ADD_CURRENCY')}
      onSubmit={submitHandler}
      {...props}
    />
  );
};

export default memo(CurrenciesAddModal);
