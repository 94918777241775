import { FC, memo, useCallback } from 'react';
import { actions as languageActions } from 'store/slices/languagesSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import GenericCodeNameForm from 'components/Forms/GenericCodeNameForm';
import { GenericCodeNameType } from 'types/genericTypes';

const LanguagesEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state) =>
    data ? state.languages.items.find((entry) => entry?.uuid === data) : null,
  );

    console.log('entry edit0', entry);

  const submitHandler = useCallback(
    (values: GenericCodeNameType) =>
      dispatch(languageActions.editLanguage({ uuid: entry?.uuid, ...values })),
    [dispatch, entry?.uuid],
  );

  return entry ? (
    <GenericCodeNameForm
      title={l('EDIT_LANGUAGE')}
      nameLabel={l('LANGUAGE')}
      onSubmit={submitHandler}
      initialValues={entry}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(LanguagesEditModal);
