import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getOrderLinesPMAPI = (params: any) => {
  return apiCall({
    url: Endpoint.OrderLinesPM,
    method: Method.Get,
    params: { ...params, statusUuids: params?.statusUuids ? [params.statusUuids].toString() : [].toString() },
  });
}
const getOrderLinesSuggestionWritersAPI = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.Suggestion,
    method: Method.Get,
    urlSuffix,
  });
const getOrderLinesSuggestionEditorsAPI = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.Suggestion,
    method: Method.Get,
    urlSuffix,
  });
const editOrderLinesPMAPI = (urlSuffix: string, data: any) =>
  apiCall({
    url: Endpoint.OrderLinesPM,
    method: Method.Put,
    urlSuffix,
    data,
  });
const editOrderLinesEMployeeDatesPMAPI = (urlSuffix: string, data: any) =>
  apiCall({
    url: Endpoint.OrderLinesPM,
    method: Method.Put,
    urlSuffix,
    data,
  });

export {
  getOrderLinesPMAPI,
  getOrderLinesSuggestionWritersAPI,
  getOrderLinesSuggestionEditorsAPI,
  editOrderLinesPMAPI,
  editOrderLinesEMployeeDatesPMAPI,
};
