import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    items: [],
    tables: [],
    columns: [],
};

export const reportParameterSlice = createSlice({
    name: 'reportParameters',
    initialState,
    reducers: {
        getReportParameters: (state) => state,
        setReportParameters: (state, action: PayloadAction<any>) => {
            state.items = action.payload;
        },
        postReportParameter: (state) => state,
        getReportParametersTables: (state) => state,
        setReportParametersTables: (state, action: PayloadAction<any>) => {
            state.tables = action.payload;
        },
        getReportParametersColumns: (state) => state,
        setReportParametersColumns: (state, action: PayloadAction<any>) => {
            state.columns = action.payload;
        },
        deleteReportParameter: (state) => state,
        editReportParameter: (state) => state,
        sendReportParameterFilters:  (state) => state,
    },
});
export const actions: any = reportParameterSlice.actions;
export default reportParameterSlice.reducer;
