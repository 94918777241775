export enum ModalType {
  LanguageAdd = 'LanguageAdd',
  LanguageEdit = 'LanguagesEdit',
  LanguageDelete = 'LanguageDelete',
  PermissionsEdit = 'PermissionsEdit',
  CurrenciesAdd = 'CurrenciesAdd',
  CurrenciesEdit = 'CurrenciesEdit',
  CurrenciesDelete = 'CurrenciesDelete',
  CountriesAdd = 'CountriesAdd',
  CountriesEdit = 'CountriesEdit',
  CountriesDelete = 'CountriesDelete',
  EmployeeStatusesAdd = 'EmployeeStatusesAdd',
  EmployeeStatusesEdit = 'EmployeeStatusesEdit',
  EmployeeStatusesDelete = 'EmployeeStatusesDelete',
  EmployeePreferenceAdd = 'EmployeePreferenceAdd',
  EmployeePreferenceEdit = 'EmployeePreferenceEdit',
  EmployeePreferenceDelete = 'EmployeePreferenceDelete',
  RoleEdit = 'RoleEdit',
  RoleAdd = 'RoleAdd',
  RoleDelete = 'RoleDelete',
  EmployeesCreate = 'EmployeesCreate',
  EmployeesDelete = 'EmployeesDelete',
  ExpertiseAdd = 'ExpertiseAdd',
  ExpertiseEdit = 'ExpertiseEdit',
  ExpertiseDelete = 'ExpertiseDelete',
  QuestionsEdit = 'QuestionsEdit',
  QuestionsAdd = 'QuestionsAdd',
  QuestionsDelete = 'QuestionsDelete',
  VoiceEdit = 'VoiceEdit',
  VoiceAdd = 'VoiceAdd',
  VoiceDelete = 'VoiceDelete',
  PaymentEdit = 'PaymentEdit',
  PaymentAdd = 'PaymentAdd',
  PaymentDelete = 'PaymentDelete',
  TargetAudienceAdd = 'TargetAudienceAdd',
  TargetAudienceEdit = 'TargetAudienceEdit',
  TargetAudienceDelete = 'TargetAudienceDelete',
  ProjectsAdd = 'ProjectsAdd',
  ProjectsEdit = 'ProjectsEdit',
  ProjectsDelete = 'ProjectsDelete',
  PmCheckList = 'PmCheckList',
  ArticleTypesEdit = 'ArticleTypesEdit',
  ArticleTypesAdd = 'ArticleTypesAdd',
  ArticleTypesDelete = 'ArticleTypesDelete',
  OrderTypesEdit = 'OrderTypesEdit',
  OrderTypesAdd = 'OrderTypesAdd',
  OrderTypesDelete = 'OrderTypesDelete',
  OrderEntriesDelete = 'OrderEntriesDelete',
  OrderEntryLineDeleteModal = 'OrderEntryLineDeleteModal',
  ChecklistsEdit = 'ChecklistsEdit',
  ChecklistsAdd = 'ChecklistsAdd',
  ChecklistsDelete = 'ChecklistsDelete',
  ChecklistsRolesEdit = 'ChecklistsRolesEdit',
  ChecklistsRolesAdd = 'ChecklistsRolesAdd',
  ChecklistsRolesDelete = 'ChecklistsRolesDelete',
  ChecklistsContactPersonsEdit = 'ChecklistsContactPersonsEdit',
  ChecklistsContactPersonsAdd = 'ChecklistsContactPersonsAdd',
  ChecklistsContactPersonsDelete = 'ChecklistsContactPersonsDelete',
  ClientsDelete = 'ClientsDelete',
  EmployeeRolesAdd = 'EmployeeRolesAdd',
  EmployeeRolesEdit = 'EmployeeRolesEdit',
  EmployeeRolesDelete = 'EmployeeRolesDelete',
  SpeakersEdit = 'SpeakersEdit',
  SpeakersAdd = 'SpeakersAdd',
  SpeakersDelete = 'SpeakersDelete',
  ReplacementsAdd = 'ReplacementsAdd',
  ReplacementsDelete = 'ReplacementsDelete',
  UserLanguageAdd = 'UserLanguageAdd',
  UserLanguageDelete = 'UserLanguageDelete',
  UserLanguageEdit = 'UserLanguageEdit',
  UserExpertiseAdd = 'UserExpertiseAdd',
  UserExpertiseDelete = 'UserExpertiseDelete',
  UserExpertiseEdit = 'UserExpertiseEdit',
  UserQuestionAdd = 'UserQuestionAdd',
  UserQuestionDelete = 'UserQuestionDelete',
  UserQuestionEdit = 'UserQuestionEdit',
  OrderLineStatusesAdd = 'OrderLineStatusesAdd',
  OrderLineStatusesDelete = 'OrderLineStatusesDelete',
  OrderLineStatusesEdit = 'OrderLineStatusesEdit',
  ContactPersonDelete = 'ContactPersonDelete',
  StatusHistoryModal = 'StatusHistoryModal',
  AssignAssistantPMModal = 'AssignAssistantPMModal',
  WriterModal = 'WriterModal',
  EditorModal = 'EditorModal',
  OrderDeliveryEditModal = 'OrderDeliveryEditModal',
  DuplicateOrderLineModal = 'DuplicateOrderLineModal',
  ReportParametersModal = 'ReportParametersModal',
  ReportParametersDeleteModal = 'ReportParametersDeleteModal',
  ReportParametersEditModal = 'ReportParametersEditModal',
  ReportCategoryAddModal = 'ReportCategoryAddModal',
  ReportCategoryDeleteModal = 'ReportCategoryDeleteModal',
  LanguageRatesAddModal = 'LanguageRatesAddModal',
  LanguageRatesEditModal = 'LanguageRatesEditModal',
  LanguageRatesDeleteModal = 'LanguageRatesDeleteModal',
  ProjectsRatesAddModal = 'ProjectsRatesAddModal',
  ProjectsRatesEditModal = 'ProjectsRatesEditModal',
  ProjectsRatesDeleteModal = 'ProjectsRatesDeleteModal',
  ClientsRatesAddModal = 'ClientsRatesAddModal',
  ClientsRatesEditModal = 'ClientsRatesEditModal',
  ClientsRatesDeleteModal = 'ClientsRatesDeleteModal',
  APLanguageRateAddModal = 'APLanguageRateAddModal',
  APLanguageRateEditModal = 'APLanguageRateEditModal',
  APLanguageRateDeleteModal = 'APLanguageRateDeleteModal',
  ChangeUserNameModal = 'ChangeUserNameModal',
  APEmployeeLanguageRateAddModal = 'APEmployeeLanguageRateAddModal',
  APEmployeeLanguageRateEditModal = 'APEmployeeLanguageRateEditModal',
  APEmployeeLanguageRateDeleteModal = 'APEmployeeLanguageRateDeleteModal',
  APEmployeeProjectRateAddModal = 'APEmployeeProjectRateAddModal',
  APEmployeeProjectRateEditModal = 'APEmployeeProjectRateEditModal',
  APEmployeeProjectRateDeleteModal = 'APEmployeeProjectRateDeleteModal',
}
