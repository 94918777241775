import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    reports: [],
    reportByUuid: {},
    executedReportItems: [],
    executedReport: null,
    executedReportMeta: {},
    previousExecutionsOfReport: [],
    previousExecutionsOfReportMeta: {},
    runReportsFields: [],
    runReportsQueryDropdown: [],
    pdfFile: '',
    xlsxFile: '',
};

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        getReports: (state) => state,
        setReports: (state, action: PayloadAction<any>) => {
            state.reports = action.payload;
        },
        getReportByUuid: (state) => state,
        setReportsByUuid: (state, action: PayloadAction<any>) => {
            state.reportByUuid = action.payload;
        },
        executeReport: (state) => state,
        sendExecutedReportFilters: (state) => state,
        setExecutedReport: (state, action: PayloadAction<any>) => {
            state.executedReportItems = action.payload.data;
            state.executedReportMeta = action.payload.meta;
        },
        setExecutedReportDataWithParams: (state, action: PayloadAction<any>) => {
            state.executedReport = action.payload;
        },
        setFilePDF: (state, action: PayloadAction<any>) => {
            state.pdfFile = action.payload;
        },
        setFileXLSX: (state, action: PayloadAction<any>) => {
            state.xlsxFile = action.payload;
        },
        exportPDF: (state) => state,
        exportXLSX: (state) => state,

        // run report
        previousExecutionsOfReport: (state) => state,
        setPreviousExecutionsOfReport: (state, action: PayloadAction<any>) => {
            state.previousExecutionsOfReport = action.payload.items;
            state.previousExecutionsOfReportMeta = action.payload.meta;
        },
        sendPreviousExecutionsOfReportFilters: (state) => state,

        getRunReportsFields: (state) => state,
        setRunReportsFields: (state, action: PayloadAction<any>) => {
            state.runReportsFields = action.payload;
        },

        runReport: (state) => state,
        setRunReport: (state) => state,

        runReportQuery: (state) => state,

        setRunReportQuery: (state, action: PayloadAction<any>) =>{
            state.runReportsQueryDropdown = action.payload
         },

        // report results
        saveReport: (state) => state,

        deleteReportExecution: (state) => state,
    },
});
export const actions: any = reportsSlice.actions;
export default reportsSlice.reducer;
