import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditTable, Form, Goback, PageLayout, TextField } from 'components';
import { RootState } from '../../store';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { actions as reportsSlice } from '../../store/slices/reportSlices/reportsSlice';
import { Grid, Typography } from '@mui/material';
import InputRowWrapper from '../../components/InputRowWrapper/InputRowWrapper';
import { useFormik } from 'formik';
import { GridRowParams, GridSortItem, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { SuggestedDropdown } from '../../components/SuggestedDropdown/SuggestedDropdown';
import { Endpoint } from '../../enums/APIEndpointEnum';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import * as Yup from 'yup';

const datePickerStyles = {
  display: 'flex',
  backgroundColor: '#f2f2f2',
  borderRadius: '15px',
  padding: '12px',
};

function RunReportsPage() {
  const dispatch = useDispatch();
   const location: any = useLocation();
  const navigate = useNavigate();
  const { reportUuid } = useParams();
  const previousExecutionsOfReport = useSelector((state: RootState) => state.reports.previousExecutionsOfReport);
  const previousExecutionsOfReportMeta = useSelector((state: RootState) => state.reports.previousExecutionsOfReportMeta);
  const report = useSelector((state: RootState) => state.reports.runReportsFields); // current report

  const [selectedDate, setSelectedDate] = useState<any>();
  const [dateInfoUuid, setDateInfoUuid] = useState();
  const [sort, setSort] = useState({});

  const rows = () => {
    const rowsArraysOfObj: any = [];
    let obj: any = {};
    previousExecutionsOfReport.forEach((row: any) => {
      row.parameterValues.forEach((paramObj: any) => {
        obj[`${paramObj.reportParameter.parameter.code}`] = paramObj.value;
        //used for delete
        obj['row_uuid'] = row.uuid;
        obj[`${paramObj.reportParameter.parameter.code}_uuid`] = paramObj.reportParameter.uuid;
      });
      rowsArraysOfObj.push(obj);
      obj = {};
    });
    return rowsArraysOfObj;
  };

  const columns = () => {
    const iterateRows: any = rows();
    return iterateRows.map((row: any) => {
      return Object.keys(row).map((column: any) => {
        const headerName = column?.split('_').join(' ');
        const capitalizedHeaderName = headerName?.charAt(0).toUpperCase() + headerName?.slice(1);
        return {
          field: `${column}`,
          headerName: `${capitalizedHeaderName}`,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value;
          },
          width: 250,
          hide: !!column.includes('_uuid'),
        };
      });
    });
  };

  const initialValues: any = {};

  const validationSchema = () => {
    const validationObj: any = {};
    report?.reportParameters?.forEach((reportParam: any) => {
      if (reportParam.parameter.type !== 'date' && reportParam.parameter.type !== 'datetime' && reportParam.parameter.code !== 'current_employee_id') {
        validationObj[reportParam.parameter.code] = Yup.object().required('Required');
      }
    });

    return Yup.object().shape(validationObj);
  };

  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema: validationSchema(),
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values: any) => {

      const arrayToSend = Object.values(values);

      if (report?.reportParameters?.find((param: any) => param?.parameter?.code === 'current_employee_id')) {
        const currentEmpUuid = report?.reportParameters?.find((param: any) => param?.parameter?.code === 'current_employee_id')?.uuid;
        arrayToSend.push(
          {
            value: '',
            reportParameterUuid: currentEmpUuid || '',
          },
        );
      }

      const params = {
        parameters: arrayToSend.filter((param: any) =>
          report.reportParameters.find((p: any) => param?.reportParameterUuid === p.uuid),
        ),
      };

      const objToSend = {
        urlSuffix: `${report.uuid}/execute`,
        reportParameters: params,
      };
      const reportToSend = {
        ...report,
        parameters: params.parameters,
      };

      dispatch(reportsSlice.setExecutedReportDataWithParams(reportToSend))
      dispatch(reportsSlice.executeReport(objToSend));
      navigate(`/report_results/${report.uuid}`, { state: reportToSend });
    },
  });

  const handlePage = useCallback(
    (page: number) => {

      let arrayToSend = [];
      if (report?.reportParameters?.find((param: any) => param?.parameter?.code === 'current_employee_id')) {
        const currentEmpUuid = report?.reportParameters?.find((param: any) => param?.parameter?.code === 'current_employee_id')?.uuid;
        arrayToSend.push(
          {
            value: '',
            reportParameterUuid: currentEmpUuid || '',
          },
        );
      }

      const params = { ...sort, urlSuffix: `${report.uuid}`, page: page + 1,  parameters: arrayToSend };

      dispatch(reportsSlice.sendPreviousExecutionsOfReportFilters(params));
    },
    [dispatch, sort],
  );

  /*const Fields = () => {
    if (report?.reportParameters) {
      /!*return
      );*!/
    }
    return null;
  };*/

  const handleExecuteReportFromTable = useCallback(
    (param: GridRowParams) => {

      const formattedReportParams = Object.keys(param.row).map((parameter: any) => {
        if (parameter !== 'uuid') {
          return {
            reportParameterUuid: param.row[`${parameter}_uuid`],
            value: param.row[parameter],
            name: parameter,
          };
        }
      });

      const resultParams = formattedReportParams.filter(element => {
        return element !== undefined && element.reportParameterUuid !== undefined;
      });

      const reportObj = {
        ...report,
        parameters: resultParams,
      };

      navigate(`/report_results/${report.uuid}`, { state: reportObj });
    },
    [dispatch],
  );

  useEffect(() => {
    const urlSuffix = {
      urlSuffix: reportUuid,
    };
    location.state = null
    dispatch(reportsSlice.getRunReportsFields(urlSuffix));
    dispatch(reportsSlice.previousExecutionsOfReport(urlSuffix));
    dispatch(reportsSlice.setExecutedReport([]))
  }, []);

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(reportsSlice.deleteReportExecution({
        urlSuffix: params.row.row_uuid,
        tableUrlSuffix: reportUuid,
      }));
    },
    [dispatch],
  );

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: item.field,
        order: item?.sort?.toUpperCase(),
      }))[0];

      setSort({ orderBy: formatValues?.orderBy, order: formatValues?.order });
      const params = {
        orderBy: formatValues?.orderBy,
        order: formatValues?.order,
        urlSuffix: `${reportUuid}`,
      };
      dispatch(reportsSlice.sendPreviousExecutionsOfReportFilters(params));
    },
    [dispatch],
  );

  return (
    <PageLayout title={'Run Report' + ': ' + report?.name || ''} fullWidth={true}>
      <Goback text='Go Back' location={-1} />
      <Grid container flexDirection='row'>
        <Grid item xs={12} sx={{ marginBottom: '19px' }}>
          <Form
            onSubmit={formik.submitForm}
            btnSubmitLabel={'Execute'}>
            <Typography fontWeight={900} fontSize={18} marginBottom='22px'>
              {'Parameter Values'}
            </Typography>
            {/*fields*/}
            <Grid flexDirection='column'>
              {  report?.reportParameters && (
                report?.reportParameters.map((reportParam: any, index: any) => {
                  if (reportParam.parameter.type === 'string' || reportParam.parameter.type === 'number') {
                    return (
                      <Grid item key={reportParam.parameter.uuid + index}>
                        <InputRowWrapper
                          label={reportParam.parameter.name}
                          labelWidth='175px'
                          inputWidth='60%'>
                          <TextField
                            type={reportParam.parameter.type === 'number' ? 'number' : 'text'}
                            id={reportParam.parameter.uuid}
                            name={reportParam.parameter.code}
                            size='small'
                            defaultValue={reportParam.testValue}
                            value={formik.values[reportParam.parameter.code]?.value}
                            onChange={(e) => {
                              formik.setFieldValue(reportParam.parameter.code, {
                                value: e.target.value,
                                reportParameterUuid: reportParam.uuid,
                                parameterOptions: {
                                  parameterType: reportParam.parameter.type,
                                  parameterName: reportParam.parameter.name,
                                },
                              });
                            }
                            }
                            error={Boolean(formik.errors[reportParam.parameter.code])}
                            helperText={formik.errors[reportParam.parameter.code]}
                          />
                        </InputRowWrapper>
                      </Grid>
                    );
                  } else if (reportParam.parameter.type === 'date' || reportParam.parameter.type === 'datetime') {

                    if (!selectedDate) {
                      setSelectedDate(moment(reportParam.testValue));
                    }
                    if (!dateInfoUuid) {
                      setDateInfoUuid(reportParam.uuid);
                    }

                    if(!formik.values[reportParam.uuid]?.value){
                      formik.setFieldValue(reportParam.uuid, {
                        value: moment(reportParam.testValue).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
                        label: moment(reportParam.testValue).format('DD.MM.YYYY'),
                        reportParameterUuid: reportParam.uuid,
                        parameterOptions: {
                          parameterType: reportParam.parameter.type,
                          parameterName: reportParam.parameter.name,
                        },
                      });
                    }

                    return (
                      <Grid item key={reportParam.parameter.uuid + index}>
                        <InputRowWrapper
                          label={reportParam.parameter.name}
                          marginBottom={'2rem'}
                          labelWidth='175px'
                          inputWidth='60%'>

                          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
                            <KeyboardDatePicker
                              autoOk
                              showTodayButton
                              name={reportParam.uuid}
                              defaultValue={moment(reportParam.testValue)}
                              value={formik.values[reportParam.uuid]?.value}
                              inputValue={formik.values[reportParam.uuid]?.label || moment(reportParam.testValue).format('DD.MM.YYYY')}
                              onChange={(value: any, inputValues: any) => {
                                formik.setFieldValue(reportParam.uuid, {
                                  value: moment(value).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
                                  label: inputValues,
                                  reportParameterUuid: reportParam.uuid,
                                  parameterOptions: {
                                    parameterType: reportParam.parameter.type,
                                    parameterName: reportParam.parameter.name,
                                  },
                                });
                              }}
                              format='DD.MM.YYYY'
                              style={datePickerStyles}
                            />
                          </MuiPickersUtilsProvider>

                        </InputRowWrapper>
                      </Grid>
                    );
                  } else if (reportParam.parameter.type === 'reference') {
                    return (
                      <Grid item key={reportParam.parameter.uuid + index}>
                        <InputRowWrapper
                          label={reportParam.parameter.name}
                          labelWidth='175px'
                          inputWidth='60%'>
                          <SuggestedDropdown
                            url={Endpoint.ReportRunQuery}
                            reportParameter={reportParam}
                            inputId={reportParam.parameter.code}
                            dropdownLabel='label'
                            dropdownValue='key'
                            dataLocation='resultData'
                            style={{ display: 'flex', flexDirection: 'row' }}
                            formikHook={formik}
                            error={Boolean(formik.errors[reportParam.parameter.code])}
                            helperText={formik.errors[reportParam.parameter.code]}
                          />
                        </InputRowWrapper>
                      </Grid>
                    );
                  } else if (reportParam.parameter.type === '') {
                    return;
                  }
                },
              ))}
            </Grid>
          </Form>
        </Grid>
        {previousExecutionsOfReport?.length > 0 ?
          (<ContentWrapper width='100%'>
            <Grid item xs={12} sx={{ marginBottom: '19px' }}>
              <Typography fontWeight={900} fontSize={18} marginBottom='22px'>
                {'Saved executions'}
              </Typography>
              <EditTable
                rows={rows()}
                columns={columns()[0]}
                getRowId={(row) => {
                  return row[Object.keys(row)[0]] || '';
                }}
                onExecute={handleExecuteReportFromTable}
                onDelete={deleteEntryHandler}
                editColumnWidth={0}
                onSortModelChange={handleSort}
                pagination
                page={previousExecutionsOfReportMeta?.currentPage - 1 || 0}
                rowCount={previousExecutionsOfReportMeta?.totalItems}
                onPageChange={handlePage}
                pageSize={previousExecutionsOfReportMeta?.itemsPerPage}
                rowsPerPageOptions={[previousExecutionsOfReportMeta?.itemsPerPage]}
                paginationMode='server'
                sortingMode='server'
              />
            </Grid>
          </ContentWrapper>) : (
            <ContentWrapper width='100%' sx={{ alignItems: 'center' }}>
              <Typography fontWeight={900} fontSize={18}>
                {'No saved executions to show'}
              </Typography>
            </ContentWrapper>
          )
        }
      </Grid>
    </PageLayout>
  );
}

export default memo(RunReportsPage);
