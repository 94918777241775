import { FC, memo, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormLabel } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { createMenuItems } from 'helpers/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { actions as articleTypesActions } from 'store/slices/articleTypesSlice';
import { actions as rolesActions } from 'store/slices/user';
import { actions as checklistsActions } from 'store/slices/checklistsSlice';
import { actions as contactPersonsActions } from 'store/slices/contactPersonsSlice';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';
import { Endpoint } from 'enums/APIEndpointEnum';

const defaultValues: any = {
  roleCode: '',
  checklistId: '',
  articleTypeUuid: '',
  contactPersonUuid: '',
};

const validationSchema = Yup.object().shape({
  articleTypeUuid: Yup.string().required('Required').nullable(),
  roleCode: Yup.string().required('Required').nullable(),
  checklistId: Yup.string().required('Required').nullable(),
  contactPersonUuid: Yup.string().required('Required').nullable(),
});

type ChecklistsContactPersonsFormProps = {
  initialValues?: any;
  title: string;
  onSubmit: (values: any) => void;
} & any;

const ChecklistsContactPersonsForm: FC<ChecklistsContactPersonsFormProps> = ({
  onClose,
  initialValues = defaultValues,
  title,
  onSubmit,
}) => {
  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit({
        articleTypeUuid: values.articleTypeUuid,
        roleCode: values.roleCode,
        checklistId: values.checklistId,
        contactPersonUuid: values.contactPersonUuid,
      });
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(articleTypesActions.getArticleTypes());
    dispatch(rolesActions.getRoles());
    dispatch(checklistsActions.getChecklists());
    dispatch(contactPersonsActions.getContactPersonsList());
  }, [dispatch]);

  const articleTypeDropdown = useSelector(
    (state: RootState) => state.articleTypes.articleTypes,
  ).map((articleTypes) => ({
    label: articleTypes.name,
    value: articleTypes.uuid,
  }));

  const contactPersonDropdown = useSelector(
    (state: RootState) => state.contactPersons.items,
  ).map((items) => ({
    label: items.nick,
    value: items.uuid,
  }));

  const contactPerson = useSelector(
    (state: RootState) => state.contactPersons.items,
  ).find((element) => element.uuid === formik?.values?.contactPersonUuid);

  const rolesDropdown = useSelector((state: RootState) => state.user.roles).map(
    (roles) => ({
      label: roles.name,
      value: roles.code,
    }),
  );

  const checklistsDropdown = useSelector(
    (state: RootState) => state.checklists.items,
  ).map((items: { name: any; id: any }) => ({
    label: items.name,
    value: items.id,
  }));

  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <FormLabel>Article Type</FormLabel>
      <TextField
        select
        fullWidth
        name="articleTypeUuid"
        size="small"
        value={formik.values.articleTypeUuid}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.articleTypeUuid)}
        helperText={formik.errors.articleTypeUuid}
        pressEnter={formik.submitForm}>
        {createMenuItems(articleTypeDropdown)}
      </TextField>
      <FormLabel>Contact Person</FormLabel>
      <SuggestedDropdown
        url={Endpoint.ContactPersons}
        inputId="contactPersonUuid"
        initialData={contactPersonDropdown}
        inputValue={{
          label: contactPerson?.nick || '',
          value: formik?.values?.contactPersonUuid || '',
        }}
        dropdownLabel="nick"
        dropdownValue="uuid"
        dataLocation="resultDataItems"
        orderBy="nick"
        order="ASC"
        error={Boolean(formik?.errors?.contactPersonUuid)}
        helperText={formik.errors.contactPersonUuid}
        style={{ display: 'flex', flexDirection: 'row' }}
        formikHook={formik}
      />
      <FormLabel>Role</FormLabel>
      <TextField
        select
        name="roleCode"
        sx={{ width: '12rem', display: 'flex' }}
        size="small"
        value={formik.values.roleCode}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.roleCode)}
        helperText={formik.errors.roleCode}
        pressEnter={formik.submitForm}>
        {createMenuItems(rolesDropdown)}
      </TextField>
      <FormLabel>Checklist</FormLabel>
      <SuggestedDropdown
        url={Endpoint.Checklists}
        inputId="checklistId"
        initialData={checklistsDropdown}
        inputValue={{
          label: formik?.values?.checklistName || '',
          value: formik?.values?.checklistId || '',
        }}
        dropdownLabel="name"
        dropdownValue="id"
        dataLocation="resultDataItems"
        orderBy="name"
        order="ASC"
        error={Boolean(formik?.errors?.checklistId)}
        helperText={formik.errors.checklistId}
        style={{ display: 'flex', flexDirection: 'row' }}
        formikHook={formik}
      />
    </SimpleFormModal>
  );
};

export default memo(ChecklistsContactPersonsForm);
