import { FC, memo} from 'react';
import { actions } from 'store/slices/checklistsContactPersonsSlice';
import { useDispatch} from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { Typography } from '@mui/material';

const ChecklistsContactPersonsDeleteModal: FC<GenericModalProps> = ({
  data,
  onClose,
}) => {
  const dispatch = useDispatch();
  const confirmHandler = () => {
    dispatch(
      actions.deleteChecklistsContactPersons({
        id: data,
      }),
    );
  };

  return (
    <ConfirmationModal onCancel={onClose} onConfirm={confirmHandler}>
      <Typography>Are you sure you want to delete this item ?</Typography>
    </ConfirmationModal>
  );
};

export default memo(ChecklistsContactPersonsDeleteModal);
