import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getOrderLineStatusLogApi = (params: string) =>
  apiCall({
    url: Endpoint.OrderLineStatusLog,
    method: Method.Get,
    params,
  });

export { getOrderLineStatusLogApi };
