import useCheckPermissions from 'hooks/useCheckPermissions';
import { FC, PropsWithChildren } from 'react';

const RouteGuard: FC<PropsWithChildren<any>> = ({
  allow,
  deny,
  children,
}): JSX.Element => {
  const hasPermissions = useCheckPermissions(allow, deny);
  if (hasPermissions) {
    return <>{children}</>;
  }

  return <></>;
};

export default RouteGuard;
