import { put, call, takeLatest } from 'redux-saga/effects';
import { actions as commonActions } from '../slices/common';
import { actions as countryActions } from '../slices/countriesSlice';
import { Countries, CountryEntry} from '../../types/countriesType';
import { APIResult } from '../../types/APITypes';
import * as api from 'store/api/countriesAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { genericErrorMessageHelper } from '../../helpers/messageHelpers';
import { sendCountriesFiltersApi } from '../api/countriesAPI';

function* sendCountriesFilters(action: PayloadAction<string>) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(sendCountriesFiltersApi, params);

  if (result?.data) {
    yield put(countryActions.setCountryList(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* getCountryList() {
  yield put(commonActions.setLoading(true));

  const { result, error }: APIResult<any> = yield call(api.getCountries);

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else {
    if (result?.data) {
      yield put(countryActions.setCountryList(result?.data));
    }
  }

  yield put(commonActions.setLoading(false));
}

function* addCountry(action: PayloadAction<any>) {
  const { error }: APIResult<CountryEntry> = yield call(
    api.addCountry,
    action.payload,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<Countries> = yield call(api.getCountries);
    if (result?.data) {
      yield put(countryActions.setCountryList(result?.data));
    }
    yield put(commonActions.hideModal());
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added country to the list`,
        severity: 'success',
      }),
    );
  }

  yield put(commonActions.setLoading(false));
}

function* editCountry(action: PayloadAction<CountryEntry>) {
  const { result, error }: APIResult<CountryEntry> = yield call(
    api.editCountry,
    action.payload,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else if (result?.data) {
    const { result }: APIResult<Countries> = yield call(api.getCountries);
    if (result?.data) {
      yield put(countryActions.setCountryList(result?.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited country in the list`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }

  yield put(commonActions.setLoading(false));
}

function* deleteCountry(action: PayloadAction<string>) {
  const data = action.payload;
  const { result, error }: APIResult<never> = yield call(
    api.deleteCountries,
    data,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else if (result?.status === 200) {
    const { result }: APIResult<Countries> = yield call(api.getCountries);
    if (result?.data) {
      yield put(countryActions.setCountryList(result?.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted country from the list`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }

  yield put(commonActions.setLoading(false));
}

export default function* countriesWatcher() {
  yield takeLatest(countryActions.getCountryList.type, getCountryList);
  yield takeLatest(
    countryActions.sendCountryFilters.type,
    sendCountriesFilters,
  );
  yield takeLatest(countryActions.addCountry.type, addCountry);
  yield takeLatest(countryActions.editCountry.type, editCountry);
  yield takeLatest(countryActions.deleteCountry.type, deleteCountry);
}
