import { useNavigate } from 'react-router-dom';
import { PageLayout} from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { actions as clientsActions } from 'store/slices/clientsSlice';
import ClientsForm from 'components/Forms/ClientsForm';

const ClientsAddPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useSelector(
    (state: RootState) => state.timeZones.items,
  );
  useSelector(
    (state: RootState) => state.currencies.items,
  );

  const handleSubmit = (values: any) => {
    const data = {
      ...values,
    };
    dispatch(clientsActions.addClients({ ...data, navigate }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <PageLayout title="Add client" fullWidth={true}>
      <ClientsForm onSubmit={handleSubmit} onCancel={handleCancel} />
    </PageLayout>
  );
};
export default ClientsAddPage;
