import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PmCheckListTypes } from '../../types/PmCheckListTypes';
import { PaginationMeta } from 'types/genericTypes';
export interface OrderLines {
  items: any[];
  item: any;
  writers: any[];
  writerPage: any[];
  writerMeta: PaginationMeta;
  tableMeta: PaginationMeta;
  editors: any[];
  editorPage: any[];
  editorMeta: PaginationMeta;
  pmCheckList: PmCheckListTypes;
}

const initialState: OrderLines = {
  items: [],
  item: {},
  editors: [],
  writers: [],
  // this is for writer screen
  writerPage: [],
  writerMeta: {},
  tableMeta: {},
  // this is for editor screen
  editorPage: [],
  editorMeta: {},
  pmCheckList: {
    checked: 0,
    total: 0,
    items: [],
  },
};

export const orderLinesSlice = createSlice({
  name: 'orderLines',
  initialState,
  reducers: {
    getOrderLines: (state) => state,
    setOrderLines: (state, action: PayloadAction<any>) => {
      state.items = action.payload.items;
      state.tableMeta = action.payload.meta;
    },
    sendOrderLinesFilters:  (state) => state,
    getPmCheckList: (state) => state,
    setPmCheckList: (state, action: PayloadAction<any>) => {
      state.pmCheckList = action.payload;
    },
    getWriterCheckList: (state) => state,
    setWriterCheckList: (state, action: PayloadAction<any>) => {
      state.pmCheckList = action.payload;
    },
    getEditorCheckList: (state) => state,
    setEditorCheckList: (state, action: PayloadAction<any>) => {
      state.pmCheckList = action.payload;
    },
    postWriterCheckList: (state) => state,
    postEditorCheckList: (state) => state,

    postPmChecklist: (state) => state,
    getOrderLinesByOrder: (state) => state,

    getOrderLine: (state) => state,
    setOrderLine: (state, action: PayloadAction<any>) => {
      state.item = action.payload;
    },

    setOrderLineEmpty: (state) => {
      state.item = {};
    },
    getOrderLinesWriters: (state) => state,
    setOrderLinesWriters: (state, action: PayloadAction<any>) => {
      state.writers = action.payload;
    },
    getOrderLinesEditors: (state) => state,
    setOrderLinesEditors: (state, action: PayloadAction<any>) => {
      state.editors = action.payload;
    },
    addLine: (state) => state,
    duplicateOrderLine: (state) => state,
    editLine: (state) => state,
    deleteLine: (state) => state,
    //Writer page for order lines
    getOrderLinesWriterPage: (state) => state,
    setOrderLinesWriterPage: (state, action: any) => {
      state.writerPage = action.payload.items;
      state.writerMeta = action.payload.meta;
    },
    editOrderLinesWriterPage: (state) => state,
    //Editor page for order lines
    getOrderLinesEditorPage: (state) => state,
    setOrderLinesEditorPage: (state, action: any) => {
      state.editorPage = action.payload.items;
      state.editorMeta = action.payload.meta;
    },
    editOrderLinesEditorPage: (state) => state,
    orderLineDuplicateFrom: (state) => state,
  },
});
export const actions: any = orderLinesSlice.actions;
export default orderLinesSlice.reducer;
