import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

interface IAPLanguageRateAPIData {
  languageUuid: string;
  sourceLanguageUuid: string;
  roleCode: string;
  ratePerWord: number;
  ratePerArticle: number;
}

const getAPLanguageRateAPI = (params: any) =>
  apiCall({
    url: Endpoint.APLanguageRate,
    method: Method.Get,
    params,
  });

const addAPLanguageRateAPI = (data: IAPLanguageRateAPIData) =>
  apiCall({
    url: Endpoint.APLanguageRate,
    method: Method.Post,
    data,
  });
const editAPLanguageRateAPI = (
  urlSuffix: string,
  data: IAPLanguageRateAPIData,
) =>
  apiCall({
    url: Endpoint.APLanguageRate,
    method: Method.Put,
    urlSuffix,
    data,
  });
const deleteAPLanguageRateAPI = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.APLanguageRate,
    method: Method.Delete,
    urlSuffix,
  });

export {
  getAPLanguageRateAPI,
  addAPLanguageRateAPI,
  editAPLanguageRateAPI,
  deleteAPLanguageRateAPI,
};
