import { FC, memo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Form } from 'components';
import colors from 'styles/colors';
import { makeStyles } from '@mui/styles';
import { actions as orderLineStatusesActions } from 'store/slices/orderLineStatusesSlice';
import FormInfoRow from 'components/FormInfoRow/FormInfoRow';
import { Box, FormLabel, TextField } from '@mui/material';
import { createMenuItems } from 'helpers/dropdown';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  formRoot: {
    '& .boxRow': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
      '& .MuiSvgIcon-root': {
        fill: '#02A7F0',
      },
      '& label.MuiFormLabel-root': {
        width: 300,
        minWidth: 300,
      },
      '& .MuiFormControl-root, & .MuiAutocomplete-root': {
        height: 'unset',
        width: '40%',
        minWidth: '350px',
        '&.number': {
          width: '20%',
          minWidth: '200px',
        },
      },
      '& .MuiAutocomplete-root .MuiFormControl-root': {
        width: '100%',
      },
    },
  },
}));

const defaultValues = {
  orderLineStatusUuid: '',
  statusDescription: '',
  writerArticleLink: '',
};

interface ProjectManagerFormProps {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  initialValues?: any;
  orderLineDetails?: any;
}

const WriterForm: FC<ProjectManagerFormProps> = ({
  onCancel,
  initialValues = defaultValues,
  onSubmit,
  orderLineDetails,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const orderLineStatusesDropdown = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  ).map((entry: any) => ({ label: entry.name, value: entry.uuid }));

  const orderLineStatuses = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  );

  const [pmCheckUuid, setpmCheckUuid] = useState<string>('');
  const [inProgressUuid, setInProgressUuid] = useState<string>('');
  const [forEditUuid, setForEditUuid] = useState<string>('');
  const [pmApproved, setpmApproved] = useState<string>('');

  const [needDescriptionUuid, setNeedDescriptionUuid] = useState<string>('');

  const validationSchema = Yup.object().shape({
    orderLineStatusUuid: Yup.string().required('This field is required!'),
    statusDescription: Yup.string().when('orderLineStatusUuid', {
      is: (orderLineStatusUuid: string) =>
        orderLineStatusUuid === needDescriptionUuid,
      then: Yup.string().required('This field is required!').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    /*writerArticleLink: Yup.string().when('orderLineStatusUuid', {
      is: (orderLineStatusUuid: string) =>
        orderLineStatusUuid === pmCheckUuid ||
        orderLineStatusUuid === inProgressUuid ||
        orderLineStatusUuid === forEditUuid ||
        orderLineStatusUuid === pmApproved,
      then: Yup.string().required('This field is required!').nullable(),
      otherwise: Yup.string().nullable(),
    }), // Writer Article Link should be mandatory when you try to set the status for all those: pm_check, in_progress, for_edit, pm_approved*/
  });

  const { setValues, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit({
        ...values,
      });
    },
  });

  useEffect(() => {
    const pmCheckStatusValue = orderLineStatuses.find(
      (status: any) => status.code === 'pm_check',
    );
    const inProgressValue = orderLineStatuses.find(
      (status: any) => status.code === 'in_progress',
    );
    const forEditValue = orderLineStatuses.find(
      (status: any) => status.code === 'for_edit',
    );
    const pmApprovedValue = orderLineStatuses.find(
      (status: any) => status.code === 'pm_approved',
    );

    const currentStatus = orderLineStatuses.find(
      (status: any) => status.uuid === formik?.values?.orderLineStatusUuid,
    );
    if (currentStatus?.needsDescription) {
      setNeedDescriptionUuid(currentStatus?.uuid);
    }

    setpmCheckUuid(pmCheckStatusValue?.uuid);
    setInProgressUuid(inProgressValue?.uuid);
    setForEditUuid(forEditValue?.uuid);
    setpmApproved(pmApprovedValue?.uuid);
  }, [formik]);

  useEffect(() => {
    dispatch(orderLineStatusesActions.getOrderLineStatuses());
  }, [dispatch]);

  return (
    <Form
      onSubmit={formik.submitForm}
      onCancel={onCancel}
      btnCancelLabel="Cancel"
      btnSubmitLabel="Save"
      className={classes.formRoot}
      sx={{ padding: '32px', backgroundColor: '#fff' }}>
      <FormInfoRow
        rowTitle={'Client task Instruction link'}
        rowValueText={orderLineDetails?.clientInstructionsUrl}
        url={orderLineDetails?.clientInstructionsUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Client Additional Note'}
        rowValueText={orderLineDetails?.instructions}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Client General Notes Link'}
        rowValueText={orderLineDetails?.order?.contactPerson?.notes}
        url={orderLineDetails?.order?.contactPerson?.notes}
        color={colors.black}
      />
      {/* TODO 3 basic form */}
      <Box className="boxRow">
        <FormLabel>Status</FormLabel>
        <TextField
          select
          name="orderLineStatusUuid"
          size="small"
          value={formik?.values?.orderLineStatusUuid}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.orderLineStatusUuid)}
          helperText={formik.errors.orderLineStatusUuid}
          //pressEnter={formik.submitForm}
        >
          {createMenuItems(orderLineStatusesDropdown)}
        </TextField>
      </Box>

      <Box className="boxRow">
        <FormLabel>Status Description</FormLabel>
        <TextField
          name="statusDescription"
          size="small"
          value={formik?.values?.statusDescription}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.statusDescription)}
          helperText={formik.errors.statusDescription}
        />
      </Box>

      <FormInfoRow
        rowTitle={'Writer Article Link'}
        rowValueText={orderLineDetails?.writerArticleLink}
        url={orderLineDetails?.writerArticleLink}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'PM Revision Comments'}
        rowValueText={orderLineDetails?.pmRevisionComments}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Article Topic'}
        rowValueText={orderLineDetails?.articleTopic}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Article Title'}
        rowValueText={orderLineDetails?.articleTitle}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Keywords'}
        rowValueText={orderLineDetails?.keywords}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Expertise 1'}
        rowValueText={orderLineDetails?.expertise1?.name}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Expertise 2'}
        rowValueText={orderLineDetails?.expertise2?.name}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Expertise 3'}
        rowValueText={orderLineDetails?.expertise3?.name}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Speaker Perspective'}
        rowValueText={orderLineDetails?.speaker?.name}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Tone of Voice'}
        rowValueText={orderLineDetails?.toneOfVoice?.name}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Target Audience'}
        rowValueText={orderLineDetails?.targetAudience?.name}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Outline Link'}
        rowValueText={orderLineDetails?.outlineUrl}
        url={orderLineDetails?.outlineUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Example Articles Link'}
        rowValueText={orderLineDetails?.exampleArticlesUrl}
        url={orderLineDetails?.exampleArticlesUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Internal Links'}
        rowValueText={orderLineDetails?.internalUrl}
        url={orderLineDetails?.internalUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'External Links'}
        rowValueText={orderLineDetails?.externalUrl}
        url={orderLineDetails?.externalUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Anchor Text Link'}
        rowValueText={orderLineDetails?.anchorTextUrl}
        url={orderLineDetails?.anchorTextUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Anchor Text'}
        rowValueText={orderLineDetails?.anchorText}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Hosting Site Link'}
        rowValueText={orderLineDetails?.hostingSiteUrl}
        url={orderLineDetails?.hostingSiteUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Meta title'}
        rowValueText={orderLineDetails?.hasMetaTitle}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Meta description'}
        rowValueText={orderLineDetails?.hasMetaDescription}
        //rowValueText={orderLineDetails?.hasMetaDescription}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Images'}
        rowValueText={orderLineDetails?.hasImages}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Images Link'}
        rowValueText={orderLineDetails?.imagesUrl}
        url={orderLineDetails?.imagesUrl}
        color={colors.black}
      />
    </Form>
  );
};

export default memo(WriterForm);
