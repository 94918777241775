import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as orderLinesActions } from 'store/slices/orderLinesSlice';
import PageLayout from 'components/PageLayout/PageLayout';
import EditTable from 'components/Table/EditTable';
import l from 'helpers/l';
import {
  GridRenderCellParams,
  GridRowParams,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { RootState } from 'store';
import moment from 'moment';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getDateColor } from 'config/utilsFunctions';
import { numberOfDays } from 'constants/globalVariables';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  mainWrapper: {
    '& .MuiBadge-dot': {
      display: 'none',
    },
    '& .hasUnreadMessages.MuiDataGrid-row': {
      '& .MuiBadge-dot': {
        display: 'block',
      },
    },
  },
}));

const WriterPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state: RootState) => state.common.loading);

  const { writerPage, writerMeta } = useSelector(
    (state: RootState) => state.orderLines,
  );

  const { orderLinesWithUnreadMessages } = useSelector(
    (state: RootState) => state?.messages,
  );

  const [dropdownValues] = useState({});
  const [sort, setSort] = useState({});

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      navigate(`/writer-detail-screen/${params?.row?.uuid}`);
    },
    [dispatch],
  );
  const handleGoToMessages = useCallback(
    (params: GridRowParams) => {
      navigate(`/messages`, { state: params.id });
    },
    [navigate],
  );

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: item.field,
        order: item?.sort?.toUpperCase(),
      }))[0];
      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });
      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
        limit: 50,
        ...dropdownValues,
      };

      dispatch(orderLinesActions.getOrderLinesWriterPage(params));
    },
    [dispatch, dropdownValues],
  );

  const handlePage = useCallback(
    (page: number) => {
      const params = { ...dropdownValues, ...sort, page: page + 1, limit: 50 };

      dispatch(orderLinesActions.getOrderLinesWriterPage(params));
    },
    [dispatch, dropdownValues, sort],
  );

  const handleClassName = useCallback(
    (params: GridRowParams) => {
      let className = '';

      orderLinesWithUnreadMessages?.map((item) => {
        if (item === params.row.uuid) {
          className = 'hasUnreadMessages';
        }
      });

      return className;
    },
    [orderLinesWithUnreadMessages],
  );

  useEffect(() => {
    const params = {
      order: 'DESC',
      limit: 50,
    };
    dispatch(orderLinesActions.getOrderLinesWriterPage(params));
  }, [dispatch]);

  return (
    <PageLayout
      title={l('PAGES.WRITER_WORK_SCREEN')}
      fullWidth
      className={classes.mainWrapper}>
      <EditTable
        getRowId={(row) => row.uuid}
        columns={[
          {
            field: 'contactPerson',
            headerName: 'Contact Person',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.order?.contactPerson?.nick,
          },
          {
            field: 'orderId',
            headerName: 'Order #',
            width: 100,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.order?.id,
          },
          {
            field: 'orderLine',
            headerName: 'Line #',
            width: 100,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.lineNumber,
          },
          {
            field: 'orderedAt',
            headerName: 'Order Date',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row?.order?.orderedAt).format('DD.MM.YYYY. HH:mm'),
          },
          {
            field: 'status',
            headerName: 'Status',
            width: 180,
            renderCell: (params: GridRenderCellParams) => (
              <Typography
                color={params.row?.lastStatusLogEntry?.orderLineStatus?.color}
                fontWeight={600}>
                {params.row?.lastStatusLogEntry?.orderLineStatus?.name}
              </Typography>
            ),
          },
          {
            field: 'statusDate',
            headerName: 'Status Date',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row?.order?.createdAt).format('DD.MM.YYYY. HH:mm'),
          },
          {
            field: 'writerDueDate',
            headerName: 'Writer Due Date',
            width: 180,
            renderCell: (params: GridRenderCellParams) => (
              <Typography
                color={getDateColor(
                  params.row?.writerDueDateTime,
                  numberOfDays,
                )}
                fontWeight={600}>
                {params.row?.writerDueDateTime !== null
                  ? moment(params.row?.writerDueDateTime).format('DD.MM.YYYY')
                  : ''}
              </Typography>
            ),
          },
          {
            field: 'dateCritical',
            headerName: 'Due Time Critical',
            width: 180,
          },
          {
            field: 'sourceLanguage',
            headerName: 'Source Language',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.sourceLanguage?.name,
          },
          {
            field: 'language',
            headerName: 'Language',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.language?.name,
          },
          {
            field: 'articleType',
            headerName: 'Article Type',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.articleType?.name,
          },
          {
            field: 'quantity',
            headerName: 'Quantity',
            width: 180,
          },
          {
            field: 'projectManager',
            headerName: 'Project Manager',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.pmEmployee?.firstName +
              ' ' +
              params.row?.pmEmployee?.lastName,
          },
          {
            field: 'writerDueDateChanges',
            headerName: 'Writer Due Date Changes',
            width: 180,
          },
          {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row?.updatedAt).format('DD.MM.YYYY. HH:mm'),
          },
          {
            field: 'writer',
            headerName: 'Writer',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.writer !== null
                ? params.row?.writer?.firstName +
                  ' ' +
                  params.row?.writer?.lastName
                : '',
          },
        ]}
        rows={writerPage}
        loading={isLoading}
        onEdit={editEntryHandler}
        onMessage={handleGoToMessages}
        getRowClassName={handleClassName}
        sortedFieldIndex={14}
        onSortModelChange={handleSort}
        sortingOrderDirection="desc"
        pagination
        // @ts-ignore
        page={writerMeta?.currentPage - 1 || 0}
        rowCount={writerMeta?.totalItems}
        onPageChange={handlePage}
        pageSize={50}
        rowsPerPageOptions={[50]}
        paginationMode="server"
        sortingMode="server"
      />
    </PageLayout>
  );
};

export default memo(WriterPage);
