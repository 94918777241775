import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import { actions } from 'store/slices/common';
import { actions as projectManagerActions } from 'store/slices/projectManagerSlice';
import { actions as employeesActions } from 'store/slices/employeeListSlice';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import EditTable from 'components/Table/EditTable';
import { GridRenderCellParams} from '@mui/x-data-grid';
import AdjustIcon from '@mui/icons-material/Adjust';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Box } from '@mui/material';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';
import { Endpoint } from 'enums/APIEndpointEnum';
import { useFormik } from 'formik';

const defaultValues: any = {
  editorUuid: '',
};

const EditorModal: FC<GenericModalProps> = ({ data }) => {
  const dispatch = useDispatch();

  const editorsData = useSelector(
    (state: RootState) => state?.pmOrderLines?.editors,
  );

  const closeHandler = useCallback(
    () => dispatch(actions.hideModal()),
    [dispatch],
  );

  const submitHandler = useCallback(
    (values: any) => {
      const dataForm = {
        editorUuid: values,
        urlSuffix: `${data.uuid}/employees-and-dates`,
      };

      dispatch(
        projectManagerActions.editProjectEMployeeDatesOrderLines({
          ...dataForm,
        }),
      );
    },
    [dispatch],
  );

  const { setValues, ...formik } = useFormik({
    initialValues: defaultValues,
    validateOnChange: false,
    onSubmit: (values: any) => {
      submitHandler(values);
    },
  });

  useEffect(() => {
    const dataForm = {
      urlSuffix: `${data?.uuid}/editors`,
    };
    const params: any = {
      urlSuffix: 'editor',
    }
    dispatch(projectManagerActions.getSuggestionEditors(dataForm));
    dispatch(employeesActions.getEmployeesByRole(params));
  }, [dispatch]);

  return (
    <SimpleFormModal
      onClose={closeHandler}
      title={'Set Editor'}
      style={{ minWidth: '1000px' }}>
      <Box display="flex" alignItems="center" width="50%" mb="10px">
        <span
          style={{ marginRight: '15px' }}
          onClick={() => submitHandler(formik.values.editorUuid)}>
          <AdjustIcon />
        </span>
        <SuggestedDropdown
          url={Endpoint.EmployeeReplacementsRoles}
          urlSuffix="editor"
          inputId="editorUuid"
          //  initialData={writersDropdown}
          inputValue={formik?.values.editorUuid}
          dropdownLabel={['firstName', 'lastName']}
          dropdownValue="uuid"
          dataLocation="resultDataItems"
          //  error={Boolean(formik.errors.editorUuid)}
          //  helperText={formik.errors.editorUuid}
          formikHook={formik}
          style={{ width: '100%', height: '55px' }}
        />
      </Box>
      <EditTable
        rows={editorsData}
        getRowId={(row) => row.editorUuid}
        columns={[
          {
            field: 'assign',
            headerName: 'Assign',
            width: 120,
            renderCell: (params: GridRenderCellParams) => (
              <span
                onClick={() => submitHandler(params?.row.editorUuid)}
                style={{ cursor: 'pointer' }}>
                <AdjustIcon />
              </span>
            ),
          },
          {
            field: 'editorName',
            headerName: 'Editor Name',
            width: 180,
          },
          {
            field: 'expertises',
            headerName: 'Expertise',
            width: 180,
          },
          {
            field: 'level',
            headerName: 'Level',
            width: 120,
          },
          {
            field: 'availability',
            headerName: 'Availability',
            width: 120,
          },
          {
            field: 'payment',
            headerName: 'Payment',
            width: 150,
          },
        ]}
        editColumnWidth={5}
        hideFooter
      />
    </SimpleFormModal>
  );
};

export default EditorModal;
