import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { GenericModalProps } from 'types/genericTypes';
import { actions as contactPersonsActions } from 'store/slices/contactPersonsSlice';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { Typography } from '@mui/material';

const ContactPersonDeleteModal: FC<GenericModalProps> = ({ data, onClose }) => {
  const dispatch = useDispatch();
  const entry = useSelector((state: RootState) =>
    data
      ? state.contactPersons.items.find((entry) => entry?.nick === data)
      : null,
  );

  const confirmHandler = useCallback(() => {
    if (entry?.nick) {
      dispatch(contactPersonsActions.deleteContactPerson(entry));
    }
  }, [dispatch, entry?.name]);

  return entry ? (
    <ConfirmationModal onCancel={onClose} onConfirm={confirmHandler}>
      <Typography>
        Are you sure you want to delete <strong>{entry?.nick} </strong>
        from the table ?
      </Typography>
    </ConfirmationModal>
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default ContactPersonDeleteModal;
