import {call, put, takeLatest} from 'redux-saga/effects';
import {actions as commonActions} from 'store/slices/common';

import {APIResult} from 'types/APITypes';
import {
    deleteReportParametersApi,
    editReportParametersApi,
    getReportParametersApi,
    getReportParametersColumnsApi,
    getReportParametersTablesApi,
    postReportParametersApi,
    sendReportParametersFiltersApi,
} from "../../api/reportsAPI";
import {actions as reportParameterSlice} from "../../slices/reportSlices/reportParameterSlice";
import {PayloadAction} from "@reduxjs/toolkit";
import {genericErrorMessageHelper} from "../../../helpers/messageHelpers";

function* getReportParameters() {
    yield put(commonActions.setLoading(true));
    const {result}: APIResult<any> = yield call(getReportParametersApi);
    if (result?.data) {
        yield put(reportParameterSlice.setReportParameters(result?.data));
    }
    yield put(commonActions.setLoading(false));
}

function* getReportParametersTables() {
    yield put(commonActions.setLoading(true));
    const {result}: APIResult<any> = yield call(getReportParametersTablesApi);
    if (result?.data) {
        yield put(reportParameterSlice.setReportParametersTables(result?.data));
    }
    yield put(commonActions.setLoading(false));
}

function* getReportParametersColumns(action: any) {
    const urlSuffix = {table: action.payload.urlSuffix};
    yield put(commonActions.setLoading(true));
    const {result}: APIResult<any> = yield call(getReportParametersColumnsApi, urlSuffix);
    if (result?.data) {
        yield put(reportParameterSlice.setReportParametersColumns(result?.data));
    }
    yield put(commonActions.setLoading(false));
}

function* postReportParameter(action: PayloadAction<any>) {
    const data = action.payload;

    const { result, error }: APIResult<any> = yield call(
        postReportParametersApi,
        data,
    );

    if (result?.status === 201) {
        const { result }: APIResult<any> = yield call(
            getReportParametersApi,
        );
        if (result?.data?.length) {
            yield put(reportParameterSlice.setReportParameters(result?.data));
        }
        yield put(
            commonActions.snackBar({
                open: true,
                message: `You have successfully added parameter to reports`,
                severity: 'success',
            }),
        );
    }
    if (error?.status === 400) {
        yield put(
            commonActions.snackBar({
                open: true,
                message: genericErrorMessageHelper(error),
                severity: 'error',
            }),
        );
    }
    yield put(commonActions.hideModal());
}
function* deleteReportParameter(action: any) {
    const urlSuffix = action.payload;
    yield put(commonActions.setLoading(true));
    const { result, error }: APIResult<any> = yield call(
        deleteReportParametersApi,
        urlSuffix,
    );
    if (error) {
        yield put(
            commonActions.snackBar({
                open: true,
                message: genericErrorMessageHelper(error),
                severity: 'error',
            }),
        );
    } else if (result?.status === 200) {
        const { result }: APIResult<any> = yield call(
            getReportParametersApi,
        );
        if (result?.data?.length) {
            yield put(reportParameterSlice.setReportParameters(result?.data));
        }
        yield put(
            commonActions.snackBar({
                open: true,
                message: 'You have successfully removed report parameter',
                severity: 'success',
            }),
        );
    }
    yield put(commonActions.setLoading(false));
    yield put(commonActions.hideModal());
}

function* editReportParameter(action: any) {
    const data = action.payload;
    yield put(commonActions.setLoading(true));
    const { error }: APIResult<any> = yield call(
        editReportParametersApi,
        data.uuid,
        data,
    );
    if (error) {
        yield put(
            commonActions.snackBar({
                open: true,
                message: genericErrorMessageHelper(error),
                severity: 'error',
            }),
        );
    } else {
        const { result }: APIResult<any> = yield call(
            getReportParametersApi,
        );
        if (result?.data?.length) {
            yield put(reportParameterSlice.setReportParameters(result?.data));
        }
        yield put(
            commonActions.snackBar({
                open: true,
                message: 'You have successfully edited report parameter',
                severity: 'success',
            }),
        );
    }
    yield put(commonActions.setLoading(false));
    yield put(commonActions.hideModal());
}

function* sendReportParameterFilters(action: any) {
    const urlSuffix = action.payload.orderUuid;
    const params = action.payload;
    delete params.orderUuid;

    yield put(commonActions.setLoading(true));

    const { result, error }: APIResult<any> = yield call(
        sendReportParametersFiltersApi,
        urlSuffix,
        params,
    );

    if (error) {
        yield put(
            commonActions.snackBar({
                open: true,
                message: genericErrorMessageHelper(error),
                severity: 'error',
            }),
        );
    } else {
        if (result?.data) {
            yield put(reportParameterSlice.setReportParameters(result?.data));
        }
    }
    yield put(commonActions.setLoading(false));
}

export default function* ReportParametersWatcher() {
    yield takeLatest(reportParameterSlice.getReportParameters.type, getReportParameters);
    yield takeLatest(reportParameterSlice.getReportParametersTables.type, getReportParametersTables);
    yield takeLatest(reportParameterSlice.getReportParametersColumns.type, getReportParametersColumns);
    yield takeLatest(reportParameterSlice.postReportParameter.type, postReportParameter);
    yield takeLatest(reportParameterSlice.deleteReportParameter.type, deleteReportParameter);
    yield takeLatest(reportParameterSlice.editReportParameter.type, editReportParameter);
    yield takeLatest(reportParameterSlice.sendReportParameterFilters.type, sendReportParameterFilters);
}
