import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from '../slices/contactPersonsSlice';
import { actions as commonActions } from '../slices/common';
import {
  getContactPersonsApi,
  AddContactPersonsApi,
  editContactPersonApi,
  deleteContactPersonApi,
  sendContactPersonApi,
} from 'store/api/contactPersonsAPI';
import { genericErrorMessageHelper } from 'helpers/messageHelpers';
import { APIResult } from 'types/APITypes';
import { PayloadAction } from '@reduxjs/toolkit';

function* getContactPersons() {
  yield put(commonActions.setLoading(true));
  const { result } = yield call(getContactPersonsApi);
  if (result?.data) {
    yield put(actions.setContactPersonsList(result.data));
    yield put(actions.setContactPersonsTableData(result.data?.meta));
  }
  yield put(commonActions.setLoading(false));
}

function* addContactPersons(action: any) {
  const { data, navigate } = action.payload;
  const { error }: APIResult<any> = yield call(
    AddContactPersonsApi,
    data,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getContactPersonsApi);
    if (result?.data) {
      yield put(actions.setContactPersonsList(result?.data));
      navigate(`/contact-persons`);
    }

    yield put(
      commonActions.snackBar({
        open: true,
        message: `Added new Contact Person- ${data.firstName} ${data.lastName}`,
        severity: 'success',
      }),
    );
  }
}

function* deleteContactPersons(action: PayloadAction<any>) {
  const { uuid, nick } = action.payload;
  const { result, error }: APIResult<any> = yield call(
    deleteContactPersonApi,
    uuid,
  );
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'This item can not be deleted due to existing relations',
        severity: 'error',
      }),
    );
  } else if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  }
  if (result?.status === 200) {
    const { result }: APIResult<any> = yield call(getContactPersonsApi);
    if (result?.data) {
      yield put(actions.setContactPersonsList(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${nick} from the list`,
        severity: 'info',
      }),
    );
  }
  yield put(commonActions.hideModal());
}

function* editContactPersons(action: PayloadAction<any>) {
  const { uuid, data, navigate } = action.payload;
  const { result, error }: APIResult<any> = yield call(
    editContactPersonApi,
    uuid,
    data,
  );
  if (result?.status === 200) {
    const { result }: APIResult<any> = yield call(getContactPersonsApi);
    if (result?.data) {
      yield put(actions.setContactPersonsList(result.data));
      navigate(`/contact-persons`);
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${data.nick}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'This item can not be edited due to existing relations',
        severity: 'error',
      }),
    );
  }
}

function* sendContactPersonsFilters(action: any) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(sendContactPersonApi, params);

  if (result?.data) {
    yield put(actions.setContactPersonsList(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

export default function* ContactPersonsWatcher() {
  yield takeLatest(actions.getContactPersonsList.type, getContactPersons);
  yield takeLatest(actions.addContactPersons.type, addContactPersons);
  yield takeLatest(actions.deleteContactPerson.type, deleteContactPersons);
  yield takeLatest(actions.editContactPerson.type, editContactPersons);
  yield takeLatest(
    actions.sendContactPersonsFilters.type,
    sendContactPersonsFilters,
  );
}
