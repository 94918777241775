import React, { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as commonActions } from 'store/slices/common';
import PageLayout from 'components/PageLayout/PageLayout';
import EditTable from 'components/Table/EditTable';
import l from 'helpers/l';
import { GridRowParams } from '@mui/x-data-grid';
import { ModalType } from 'enums/ModalEnums';
import { RootState } from 'store';
import { actions } from 'store/slices/articleTypesSlice';

const ArticleTypes: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const articleTypes = useSelector(
    (state: RootState) => state.articleTypes.articleTypes,
  );

  const addEntryHandler = useCallback(() => {
    dispatch(commonActions.showModal({ type: ModalType.ArticleTypesAdd }));
  }, [dispatch]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.ArticleTypesEdit,
          data: params.row.name.toString(),
        }),
      );
    },
    [dispatch],
  );

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.ArticleTypesDelete,
          data: params.row.name.toString(),
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(actions.getArticleTypes());
  }, [dispatch]);

  return (
    <PageLayout title={l('PAGES.ARTICLE_TYPES')}>
      <EditTable
        getRowId={(row) => row.uuid}
        columns={[
          { field: 'id', headerName: 'Id', width: 350 },
          {
            field: 'name',
            headerName: l('NAME'),
            width: 300,
          },
        ]}
        rows={articleTypes}
        loading={isLoading}
        onAdd={addEntryHandler}
        onEdit={editEntryHandler}
        onDelete={deleteEntryHandler}
        hideFooterPagination
        hideFooter
      />
    </PageLayout>
  );
};

export default memo(ArticleTypes);
