import React, {FC, memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Button, FormControl, IconButton, MenuItem, Typography} from '@mui/material';
import {Clear as ClearIcon} from '@mui/icons-material';
import EditTable from 'components/Table/EditTable';
import PageLayout from '../../components/PageLayout/PageLayout';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from 'store/slices/user';
import {PermissionEntry} from 'types/permissionTypes';
import {actions as commonActions} from 'store/slices/common';
import {actions as reportCategoriesActions} from 'store/slices/reportSlices/reportCategoriesSlice';
import {RootState} from 'store';
import {GridColDef, GridRenderCellParams, GridRowHeightParams, GridRowParams} from '@mui/x-data-grid';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import styles from '../Roles/RolesStyles';
import l from 'helpers/l';
import {ModalType} from 'enums/ModalEnums';
import {Roles} from 'types/userRoleTypes';
import {TextField} from 'components';
import {Link} from "react-router-dom";
import colors from "../../styles/colors";

const initialValues: any = {
    name: '',
    roles: [],
};
const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
});

const ReportCategoriesPage: FC = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: RootState) => state.common.loading);
    const availableRoles = useSelector((state: RootState) => state.user.roles);
    const reportCategories = useSelector((state: RootState) => state.reportCategories.categories);

    const classes = styles();
    const [selectedValue, setSelectedValue] = useState('');

    const onDropdownSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        setSelectedValue(e.target.value);
    };

    const addEntryHandler = useCallback(() => {
        dispatch(commonActions.showModal({type: ModalType.ReportCategoryAddModal}));
    }, [dispatch]);

    const editEntryHandler = useCallback(
        (params: GridRowParams) => {
            dispatch(
                commonActions.showModal({
                    type: ModalType.ReportCategoryAddModal,
                    data: params.row,
                }),
            );
        },
        [dispatch],
    );

    const deleteEntryHandler = useCallback(
        (params: GridRowParams) => {
            dispatch(
                commonActions.showModal({
                    type: ModalType.ReportCategoryDeleteModal,
                    data: params,
                }),
            );
        },
        [dispatch],
    );

    const handleAssignRole = useCallback(
        (params: GridRenderCellParams) => {
            dispatch(
                reportCategoriesActions.assignRoleToReportCategory({
                    roleCode: selectedValue,
                    folderUuid: params.row.uuid,
                }),
            );
            dispatch(actions.getRoles());
        },
        [selectedValue],
    );

    const handleUnassignRole = useCallback(
        (params: GridRenderCellParams, roleCode: any) => {
            dispatch(
                reportCategoriesActions.unassignFromPostReportCategory({
                    roleCode: roleCode,
                    folderUuid: params.row.uuid,
                }),
            );
            dispatch(actions.getRoles());
        },
        [],
    );

    useEffect(() => {
        setTimeout(() => {
            dispatch(actions.getRoles());
            dispatch(reportCategoriesActions.getReportCategories());
        });
    }, [dispatch, handleAssignRole, handleUnassignRole]);

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'name',
                headerName: 'Category',
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <Link
                            to={`/report_management_by_category/${params.row.uuid}`}
                            style={{textDecoration: 'none'}}>
                            <Typography color={colors.info} fontWeight={600}>
                                {params.value}
                            </Typography>
                        </Link>
                    )
                },
            },
            {
                headerName: 'Roles',
                align: 'left',
                field: 'roles',
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                    const assignedRoles = params.row.roles;

                    const assignedRolesCodes = assignedRoles?.map(
                        (el: PermissionEntry) => el.code,
                    );
                    const rolesOptions = availableRoles?.filter(
                        (option: PermissionEntry) =>
                            !assignedRolesCodes?.includes(option.code),
                    );

                    return (
                        <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                            {assignedRoles?.map((el: any) => {
                                return (
                                    <Box
                                        key={el.code}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        className={classes.PermissionBox}>
                                        <Box>{`${el.name}`}</Box>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleUnassignRole(params, el.code)}>
                                            <ClearIcon/>
                                        </IconButton>
                                    </Box>
                                );
                            })}
                            {rolesOptions.length ? (
                                <FormControl className={classes.DropDown}>
                                    <TextField
                                        select
                                        size="small"
                                        name="dropdownSelect"
                                        sx={{
                                            width: '150px',
                                        }}
                                        className={classes.Test}
                                        onChange={(e: any) => onDropdownSelectChange(e)}>
                                        <MenuItem style={{display: 'none'}}/>
                                        {rolesOptions.map((el: PermissionEntry) => {
                                            return (
                                                <MenuItem key={el.code} value={el.code}>
                                                    {`${el.name}`}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                    <Box>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleAssignRole(params)}>
                                            Assign
                                        </Button>
                                    </Box>
                                </FormControl>
                            ) : (
                                ''
                            )}
                        </Box>
                    );
                },
            },
        ],
        [editEntryHandler, reportCategories, handleAssignRole, handleUnassignRole],
    );
    const handleGetRowHeight = React.useCallback(
        ({model}: GridRowHeightParams) => {
            if ((model.permissions?.length as number) > 1) {
                const availableWidth = window.innerWidth - 200 - 200 - 70;
                const averageItemWidth = 180;
                const lines = Math.ceil(
                    ((model.permissions.length + 1) * averageItemWidth) / availableWidth,
                );
                return lines * 95;
            } else return 100;
        },
        [],
    );

    return (
        <PageLayout title={l('PAGES.REPORTS_CATEGORIES')} fullWidth={true}>
            <EditTable
                rows={reportCategories}
                columns={columns}
                loading={isLoading}
                onEdit={editEntryHandler}
                onAdd={addEntryHandler}
                onDelete={deleteEntryHandler}
                getRowHeight={handleGetRowHeight}
                getRowId={(row) => row.uuid}
                hideFooterPagination
                hideFooter
            />
        </PageLayout>
    );
};

export default memo(ReportCategoriesPage);
