import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actions as authActions } from 'store/slices/auth';
import { Box } from '@mui/material';
import { Header, SideNavigation, Notifications } from 'components';
import styles from 'styles/main';
import AppRoutes from 'AppRoutes';
import { RootState } from 'store';
import ModalContainer from './components/Modals/ModalContainer';
import { actions as pmOrderActions } from './store/slices/projectManagerSlice';

const authPaths = [
  '/login',
  '/forgotten-password',
  '/reset-password',
  '/set-password',
];

const pmLocationPaths = ['pm-work-screen', 'messages'];
function App() {
  const { root } = styles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [sideNavigationShown, setSideNavigationShown] = useState(false);
  const logged = useSelector((state: RootState) => Boolean(state.auth.token));
  const [height, setHeight] = useState<string>('unset');

  useEffect(() => {
    dispatch(authActions.authMe());
    if (location.pathname === '/messages') {
      setHeight('calc(100vh - 90px)');
    }
    if (location.pathname === '/') {
      setSideNavigationShown(true);
    }

    if (
      !location.pathname.includes('pm-work-screen') &&
      !location.pathname.includes('messages')
    ) {
      localStorage.removeItem('filterValues');
      dispatch(
        pmOrderActions.setProjectOrderLinesFilters({
          articleTypeUuid: '',
          assistantPmUuid: '',
          daysOverdue: 'All',
          editorUuid: '',
          keyword: '',
          languageUuid: '',
          pmDueDateFrom: '',
          pmDueDateTo: '',
          sourceLanguageUuid: '',
          statusUuids: null,
          writerUuid: '',
        }),
      );
    }
  }, [location]);

  useEffect(() => {
    if (!authPaths.includes(location.pathname) && !logged) navigate('/login');
  }, [location.pathname, logged, navigate]);

  return (
    <Box className={root + ' appMainWrapper'}>
      <Notifications />
      <Header
        sideNavigationStateHandler={setSideNavigationShown}
        sideNavigationState={sideNavigationShown}
      />
      <SideNavigation
        sideNavigationStateHandler={setSideNavigationShown}
        sideNavigationState={sideNavigationShown}
      />
      <Box className="appRoutesWrapper" height={height}>
        <AppRoutes />
      </Box>
      <ModalContainer />
    </Box>
  );
}

export default App;
