import { PageLayout } from 'components';
import { ContactPersonsForm } from 'components/Forms/ContactPersonsForm';
import { actions as contactPersonActions } from 'store/slices/contactPersonsSlice';
import * as Yup from 'yup';
import l from 'helpers/l';
import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions as employeeCurrenciesActions } from '../../../store/slices/currenciesSlice';

export const ContactPersonsAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = (values: any) => {
    const data = { ...values };
    dispatch(contactPersonActions.addContactPersons({ data, navigate }));
  };

  const cancelHandler = () => {
    navigate('/contact-persons');
  };

  useEffect(() => {
    dispatch(employeeCurrenciesActions.getCurrencyList());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    clientUuid: Yup.string().required('Required'),
    nick: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email adress').nullable(),
    phone: Yup.string().nullable(),
    notes: Yup.string().nullable(),
    accountManagerUuid: Yup.string().nullable(),
    currencyUuid: Yup.string().nullable(),
  });

  return (
    <PageLayout title={`${l('PAGES.ADD_CONTACT_PERSON')}`} fullWidth={true}>
      <ContactPersonsForm
        onSubmit={submitHandler}
        onCancel={cancelHandler}
        validationSchema={validationSchema}
      />
    </PageLayout>
  );
};

export default memo(ContactPersonsAdd);
