import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/orderTypesSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  addOrderTypesApi,
  deleteOrderTypesApi,
  editOrderTypesApi,
  getOrderTypesApi,
} from 'store/api/orderTypesAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { OrderTypesActionEntry } from 'types/orderTypes';
import { GenericIdNameType } from 'types/genericTypes';
import { APIResult } from 'types/APITypes';

function* getOrderTypes() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<GenericIdNameType[]> = yield call(
    getOrderTypesApi,
  );
  if (result?.data?.length) {
    yield put(actions.setOrderTypes(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addOrderTypes(action: PayloadAction<OrderTypesActionEntry>) {
  const data = action.payload;
  const { result, error }: APIResult<OrderTypesActionEntry> = yield call(
    addOrderTypesApi,
    data,
  );
  if (result?.status === 201) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getOrderTypesApi,
    );
    if (result?.data?.length) {
      yield put(actions.setOrderTypes(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.name} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.name} already exists`,
        severity: 'error',
      }),
    );
  }
}

function* deleteOrderTypes(action: PayloadAction<GenericIdNameType>) {
  const { id, name } = action.payload;
  const { result }: APIResult<GenericIdNameType> = yield call(
    deleteOrderTypesApi,
    id! as string,
  );
  if (result?.status === 200) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getOrderTypesApi,
    );
    if (result?.data?.length) {
      yield put(actions.setOrderTypes(result.data));
    }
    yield put(commonActions.hideModal());
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
  }
}

function* editOrderTypes(action: PayloadAction<GenericIdNameType>) {
  const { name, id } = action.payload;

  const { result, error }: APIResult<GenericIdNameType> = yield call(
    editOrderTypesApi,
    { id, name },
  );
  if (result?.status === 200) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getOrderTypesApi,
    );
    if (result?.data?.length) {
      yield put(actions.setOrderTypes(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Employee status name is taken',
        severity: 'error',
      }),
    );
  }
}

export default function* orderTypesWatcher() {
  yield takeLatest(actions.getOrderTypes.type, getOrderTypes);
  yield takeLatest(actions.addOrderTypes.type, addOrderTypes);
  yield takeLatest(actions.deleteOrderTypes.type, deleteOrderTypes);
  yield takeLatest(actions.editOrderTypes.type, editOrderTypes);
}
