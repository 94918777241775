import { FC, memo, useCallback } from 'react';
import { actions as apEmployeeProjectRateActions } from 'store/slices/apEmployeeProjectRateSlice';
import { actions } from 'store/slices/common';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import APEmployeeProjectRatesForm from 'components/Forms/APEmployeeProjectRatesForm';

const APEmployeeProjectRateEditModal: FC<GenericModalProps> = ({ data }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state) =>
    data?.id
      ? state.apEmployeeProjectRate.items.find(
          (entry) => entry?.uuid === data?.id,
        )
      : null,
  );

  console.log('entry', entry);

  const initialValues = {
    sourceLanguage: entry?.sourceLanguage?.name,
    sourceLanguageUuid: entry?.sourceLanguage?.uuid,
    language: entry?.language?.name,
    languageUuid: entry?.language?.uuid,
    roleCode: entry?.employeeRole?.role?.code,
    employeeUuid: entry?.employeeRole?.employee?.uuid,
    employee:
      entry?.employeeRole?.employee?.firstName +
      ' ' +
      entry?.employeeRole?.employee?.lastName,
    projectUuid: entry?.project?.uuid,
    project: entry?.project?.name,
    ratePerWord: entry?.ratePerWord,
    ratePerArticle: entry?.ratePerArticle,
  };

  const submitHandler = useCallback(
    (values: any) =>
      dispatch(
        apEmployeeProjectRateActions.editAPEmployeeProjectRate({
          urlSuffix: entry?.uuid,
          filters: data?.filters,
          ...values,
        }),
      ),
    [dispatch, entry?.uuid, data],
  );

  const closeHandler = useCallback(
    () => dispatch(actions.hideModal()),
    [dispatch],
  );

  console.log('initialValues', initialValues);

  return (
    <APEmployeeProjectRatesForm
      onSubmit={submitHandler}
      title="Edit AP Employee Project Rate"
      onClose={closeHandler}
      initialValues={initialValues}
    />
  );
};

export default memo(APEmployeeProjectRateEditModal);
