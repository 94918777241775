import { FC, memo} from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormLabel, Typography } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps } from 'types/genericTypes';
import { Endpoint } from 'enums/APIEndpointEnum';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';

const validationSchema = Yup.object().shape({
  expertiseId: Yup.string().required('Required'),
  level: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

const defaultValues = {
  expertiseId: '',
  level: '',
  description: '',
};

type EmployeeLanguageFormProps = {
  initialValues?: any;
  title: string;
  employeeName?: string;
  employeeUuid?: string;
  onSubmit: (values: any) => void;
  values?: any;
  unassignedExpertiseValues?: any;
  levelValues?: any;
} & GenericModalProps;

const EmployeeViewExpertiseForm: FC<EmployeeLanguageFormProps> = ({
  onClose,
  initialValues = defaultValues,
  employeeName,
  employeeUuid,
  title,
  onSubmit,
  unassignedExpertiseValues,
  levelValues,
}) => {
  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit({
        values,
      });
    },
  });

  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <FormLabel>Employee</FormLabel>
      <Box sx={{ marginBottom: '30px' }}>
        <Typography fontWeight={600}>{employeeName}</Typography>
      </Box>
      <FormLabel>Expertise</FormLabel>
      {unassignedExpertiseValues ? (
        <SuggestedDropdown
          url={Endpoint.UserNotAssignedExpertise}
          urlSuffix={employeeUuid}
          inputId="expertiseId"
          initialData={unassignedExpertiseValues}
          dropdownLabel="name"
          dropdownValue="id"
          dataLocation="resultData"
          error={Boolean(formik.errors.expertiseId)}
          helperText={formik.errors.expertiseId}
          formikHook={formik}
        />
      ) : (
        <Typography sx={{ marginBottom: '20px', fontWeight: 700 }}>
          {initialValues?.expertise.name}
        </Typography>
      )}
      <FormLabel>Level</FormLabel>
      <TextField
        select
        name="level"
        sx={{ width: '6rem', display: 'flex' }}
        size="small"
        value={formik.values.level}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.level)}
        helperText={formik.errors.level}
        pressEnter={formik.submitForm}>
        {levelValues}
      </TextField>
      <FormLabel>Description</FormLabel>
      <TextField
  multiline
  rows={6}
  name='description'
  sx={{ display: 'flex', height: 'max-content', width: '100%' }}
  size='small'
  value={formik.values.description}
  error={Boolean(formik.errors.description)}
  helperText={formik.errors.description}
  onChange={formik.handleChange}
  autoComplete='off'
  pressEnter={formik.submitForm}/>
    </SimpleFormModal>
  );
};

export default memo(EmployeeViewExpertiseForm);
