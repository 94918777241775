import { FC, memo, useCallback } from 'react';
import { actions as projectRateActions } from 'store/slices/projectsRateSlice';
import { useDispatch } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import { actions } from 'store/slices/common';
import ARProjectRateForm from 'components/Forms/ARProjectRateForm';

const ProjectsRatesAddModal: FC<GenericModalProps> = ({ data }) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: any) =>
      dispatch(
        projectRateActions.addProjectsRate({
          ...values,
          filters: data?.filters,
        }),
      ),
    [dispatch],
  );

  const closeHandler = useCallback(
    () => dispatch(actions.hideModal()),
    [dispatch],
  );

  return (
    <ARProjectRateForm
      onSubmit={submitHandler}
      title="Add Project Rate"
      onClose={closeHandler}
      initialValues={data?.filters}
    />
  );
};

export default memo(ProjectsRatesAddModal);
