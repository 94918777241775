import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderLineStatusTransition } from 'interfaces/orderLineStatusTransitions';

export type OrderLineStatusTransitionState = {
  assigned: OrderLineStatusTransition['assignedStatuses'] | [];
  notAssigned: OrderLineStatusTransition['notAssignedStatuses'] | [];
};

const initialState: OrderLineStatusTransitionState = {
  assigned: [],
  notAssigned: [],
};

export const orderLineStatusTransitionSlice = createSlice({
  name: 'orderLineStatusTransitions',
  initialState,
  reducers: {
    getAssignedStatusTransitions: (state) =>
      state,
    getNotAssignedStatusTransitions: (state) =>
      state,
    setAssignedStatusTransitions: (
      state,
      action: PayloadAction<OrderLineStatusTransition['assignedStatuses']>,
    ) => {
      state.assigned = action.payload;
    },
    setNotAssignedStatusTransitions: (
      state,
      action: PayloadAction<OrderLineStatusTransition['notAssignedStatuses']>,
    ) => {
      state.notAssigned = action.payload;
    },
    addStatusTransitions: (state) => state,
    deleteStatusTransitions: (state) => state,
  },
});
export const actions: any = orderLineStatusTransitionSlice.actions;
export default orderLineStatusTransitionSlice.reducer;
