import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import PageLayout from 'components/PageLayout/PageLayout';
import l from 'helpers/l';
import EditTable from 'components/Table/EditTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { actions } from 'store/slices/checklistsSlice';
import { ModalType } from 'enums/ModalEnums';
import { actions as commonActions } from 'store/slices/common';
import { GridRowParams, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { Filters, TextField } from 'components';
import { FormLabel, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { formatFilters } from 'helpers/filters';
import { isEmpty } from 'lodash';

const initialValues = {
  keyword: '',
};

const Checklists: FC = () => {
  const dispatch = useDispatch();
  const [sort, setSort] = useState({});
  const [dropdownValues, setDropdownValues] = useState({});
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const { items, meta } = useSelector((state: RootState) => state.checklists);

  const { setValues, ...formik } = useFormik({
    initialValues,
    onSubmit: (values: any) => {
      const formatValues = formatFilters(values);
      setDropdownValues(() => {
        return { ...formatValues };
      });
      if (!isEmpty(formatValues)) {
        const params = { ...formatValues, ...sort };
        dispatch(actions.sendChecklistsFilters(params));
      } else {
        dispatch(actions.sendChecklistsFilters(sort));
      }
    },
  });

  const addEntryHandler = useCallback(() => {
    dispatch(commonActions.showModal({ type: ModalType.ChecklistsAdd }));
  }, [dispatch]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.ChecklistsEdit,
          data: params.id.toString(),
        }),
      );
    },
    [dispatch],
  );

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.ChecklistsDelete,
          data: params.id.toString(),
        }),
      );
    },
    [dispatch],
  );

  const handlePage = useCallback(
    (page: number) => {
      const params = { ...dropdownValues, ...sort, page: page + 1 };
      dispatch(actions.sendChecklistsFilters(params));
    },
    [dispatch, sort, dropdownValues],
  );

  const onReset = useCallback(() => {
    setValues(initialValues);
    setDropdownValues({});
    dispatch(actions.sendChecklistsFilters(sort));
  }, [dispatch, setValues, sort]);

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: item.field,
        order: item?.sort?.toUpperCase(),
      }))[0];
      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });
      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
        ...dropdownValues,
      };

      dispatch(actions.sendChecklistsFilters(params));
    },
    [dispatch, dropdownValues],
  );

  useEffect(() => {
    dispatch(actions.getChecklists());
  }, [dispatch]);
  return (
    <PageLayout title={l('PAGES.CHECKLISTS')}>
      <Filters handleFilters={formik.submitForm} onReset={onReset}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <FormLabel>Keyword</FormLabel>
            <TextField
              autoHeight
              fullWidth
              name="keyword"
              size="small"
              value={formik.values.keyword}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.keyword)}
            />
          </Grid>
        </Grid>
      </Filters>
      <EditTable
        getRowId={(row) => row.id}
        loading={isLoading}
        columns={[
          { field: 'id', headerName: 'Id' },
          {
            field: 'name',
            headerName: l('NAME'),
            width: 300,
          },
          {
            field: 'weight',
            headerName: 'Weight',
            width: 150,
          },
        ]}
        rows={items}
        onAdd={addEntryHandler}
        onEdit={editEntryHandler}
        onDelete={deleteEntryHandler}
        sortedFieldIndex={1}
        onSortModelChange={handleSort}
        pagination
        page={meta.currentPage - 1 || 0}
        rowCount={meta?.totalItems}
        onPageChange={handlePage}
        pageSize={meta?.itemsPerPage}
        rowsPerPageOptions={[meta?.itemsPerPage]}
        paginationMode="server"
        sortingMode="server"
      />
    </PageLayout>
  );
};

export default memo(Checklists);
