import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/projectManagerSlice';
import { actions as orderLinesActions } from 'store/slices/orderLinesSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  getOrderLinesPMAPI,
  getOrderLinesSuggestionWritersAPI,
  getOrderLinesSuggestionEditorsAPI,
  editOrderLinesPMAPI,
  editOrderLinesEMployeeDatesPMAPI,
} from 'store/api/projectManagerAPI';

function* getProjectOrderLines(action: any) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));
  const { result } = yield call(getOrderLinesPMAPI, params);
  if (result?.data) {
    yield put(actions.setProjectOrderLines(result.data));
  }
  yield put(commonActions.setLoading(false));
}
function* getSuggestionWriters(action: any) {
  const { urlSuffix } = action.payload;

  yield put(commonActions.setLoading(true));
  const { result } = yield call(getOrderLinesSuggestionWritersAPI, urlSuffix);
  if (result?.data) {
    yield put(actions.setSuggestionWriters(result.data));
  }
  yield put(commonActions.setLoading(false));
}
function* getSuggestionEditors(action: any) {
  const { urlSuffix } = action.payload;

  yield put(commonActions.setLoading(true));
  const { result, error } = yield call(
    getOrderLinesSuggestionEditorsAPI,
    urlSuffix,
  );
  if (result?.data) {
    yield put(actions.setSuggestionEditors(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* editProjectOrderLines(action: any) {
  const data = action.payload;
  const navigate = action.payload.navigate;
  const urlSuffix = action.payload.urlSuffix;

  yield put(commonActions.setLoading(true));
  const { result, error } = yield call(editOrderLinesPMAPI, urlSuffix, data);
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  }
  if (result?.data) {
    //yield put(actions.setProjectOrderLines(result.data));
    navigate('/pm-work-screen');
  }
  yield put(commonActions.setLoading(false));
}
function* editProjectEMployeeDatesOrderLines(action: any) {
  const data = action.payload;
  const urlSuffix = action.payload.urlSuffix;
  delete data.urlSuffix;
  yield put(commonActions.setLoading(true));
  const { result } = yield call(
    editOrderLinesEMployeeDatesPMAPI,
    urlSuffix,
    data,
  );

  if (result?.data) {
    //yield put(actions.setProjectOrderLines(result.data)); // here is problem maybe when change one item
    yield put(orderLinesActions.setOrderLine(result.data));
    yield put(commonActions.hideModal());
  }
  yield put(commonActions.setLoading(false));
}

function* sendProjectOrderLinesFilters(action: any) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(getOrderLinesPMAPI, params);

  if (result?.data) {
    yield put(actions.setProjectOrderLines(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

export default function* ProjectWatcher() {
  yield takeLatest(actions.getProjectOrderLines.type, getProjectOrderLines);
  yield takeLatest(actions.getSuggestionWriters.type, getSuggestionWriters);
  yield takeLatest(actions.getSuggestionEditors.type, getSuggestionEditors);
  yield takeLatest(actions.editProjectOrderLines.type, editProjectOrderLines);
  yield takeLatest(
    actions.editProjectEMployeeDatesOrderLines.type,
    editProjectEMployeeDatesOrderLines,
  );
  yield takeLatest(
    actions.sendProjectOrderLinesFilters.type,
    sendProjectOrderLinesFilters,
  );
}
