import { GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import EditTable from 'components/Table/EditTable';
import { Box } from '@mui/material';
import { ModalType } from 'enums/ModalEnums';
import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { actions as commonActions } from 'store/slices/common';
import { actions as userActions } from 'store/slices/user';

interface EmployeeViewLanguagesProps {
  employeeData: any;
}

const EmployeeViewLanguages: FC<EmployeeViewLanguagesProps> = ({
  employeeData,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const { userLanguages } = useSelector((state: RootState) => state.user);
  const { employeeDetails } = useSelector(
    (state: RootState) => state.employees,
  );
  const name = `${employeeDetails.firstName} ${employeeDetails.lastName}`;

  const addEntryHandler = useCallback(() => {
    dispatch(
      commonActions.showModal({
        type: ModalType.UserLanguageAdd,
        data: { id: employeeDetails.uuid, name } as any,
      }),
    );
  }, [dispatch, employeeDetails.uuid, name]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.UserLanguageEdit,
          data: {
            rowId: params.id.toString(),
            employeeId: employeeDetails.uuid,
            name,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(userActions.getUserLanguages(employeeData.uuid));
  }, [dispatch, employeeData.uuid]);

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.UserLanguageDelete,
          data: params.row as any,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Box style={{ margin: '-1px -10px' }}>
      {!isLoading && (
        <EditTable
          loading={isLoading}
          rows={userLanguages}
          sortedFieldIndex={0}
          columns={[
            {
              field: 'language',
              headerName: 'Language',
              width: 150,
              valueGetter: (params: GridValueGetterParams) =>
                params.row.language.name,
            },
            {
              field: 'level',
              headerName: 'Level',
              width: 150,
            },
            {
              field: 'editorLevel',
              headerName: 'Editor Level',
              width: 150,
            },
          ]}
          onEdit={editEntryHandler}
          onAdd={addEntryHandler}
          onDelete={deleteEntryHandler}
          hideFooterPagination
          hideFooter
        />
      )}
    </Box>
  );
};

export default EmployeeViewLanguages;
