import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationMeta } from 'types/genericTypes';

interface IClientsRate {
  items: any[];
  meta: PaginationMeta;
}
const initialState: IClientsRate = {
  items: [],
  meta: {},
};

export const clientsRateSlice = createSlice({
  name: 'clientsRate',
  initialState,
  reducers: {
    getClientsRates: (state) => state,
    getClientsRatesWithFilters: (state) => state,
    setClientsRates: (state, action: PayloadAction<any>) => {
      state.items = action?.payload?.items;
      state.meta = action.payload.meta;
    },
    addClientsRate: (state) => state,
    editClientsRate: (state) => state,
    deleteClientsRate: (state) => state,
  },
});
export const actions: any = clientsRateSlice.actions;
export default clientsRateSlice.reducer;
