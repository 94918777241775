import {memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import l from 'helpers/l';
import {PageLayout} from 'components';
import {FormLabel, Grid, IconButton, Typography} from "@mui/material";
import {actions as reportsSlice} from "../../store/slices/reportSlices/reportsSlice";
import {RootState} from "../../store";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import {useNavigate} from "react-router-dom";

function ReportPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const reports = useSelector(
        (state: RootState) => state.reports.reports,
    )
    const [selectedCategory, setSelectedCategory] = useState(reports[0] || {name: "", uuid: ""});
    useEffect(() => {
      // navigate(`${`/messages`}`);
        dispatch(reportsSlice.getReports())
    }, [dispatch])

    useEffect(() => {
        if (reports) {
            setSelectedCategory(reports[0])
        }
    }, [reports])

    const handleExecutionOfReport = (report: any) => {
        if (report.reportParameters && report.reportParameters.length > 0) {
            const objWithParameters: any = {
                parameters: [],
            };

            report.reportParameters.forEach((parameter: any) => {
                const objToPush = {
                    reportParameterUuid: parameter.uuid,
                    value: parameter.testValue,
                }

                objWithParameters.parameters.push(objToPush);
            })

            const urlSuffix = {
                urlSuffix: `${report.uuid}`,
            }

            dispatch(reportsSlice.previousExecutionsOfReport(urlSuffix))
            navigate(`/run_report/${report.uuid}`)
        } else if (report.uuid) {
            navigate(`/report_result_w_o_parameters/${encodeURIComponent(report.uuid) || ''}`, {state: report})
        }
    }

    return (
        <PageLayout title={l('PAGES.REPORTS')} fullWidth={true}>
            <Grid key={'reportPageContainer'} container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                <Grid item xs={3}>
                    <Grid key={'left'}
                          sx={{
                              backgroundColor: '#fff',
                              borderRadius: 3,
                              display: 'flex',
                              flexDirection: 'column',
                              padding: '13px 20px',
                          }}
                          container>
                        <Grid key={'titleLeft'} sx={{display: 'flex', justifyContent: 'flex-start', marginLeft: 3}}
                              item>
                            <FormLabel sx={{fontWeight: 900}}>{'Categories'}</FormLabel>
                        </Grid>
                        <Grid key={'contentsLeft'} container
                              sx={{display: 'flex', flexDirection: 'column', marginLeft: 6}}>
                            {
                                reports?.map((category: any) => {
                                    return (
                                        <Grid item key={category.uuid}>
                                            <Typography key={category.uuid} onClick={() => {
                                                setSelectedCategory(category)
                                            }} color={selectedCategory?.uuid === category.uuid ? 'primary' : ''}
                                                        sx={{
                                                            fontWeight: selectedCategory?.uuid === category.uuid ? 900 : 500,
                                                            marginTop: "8px",
                                                            cursor: 'pointer',
                                                        }}>
                                                {category.name}
                                            </Typography>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    <Grid key={'right'}
                          sx={{
                              backgroundColor: '#fff',
                              borderRadius: 3,
                              display: 'flex',
                              flexDirection: 'column',
                              padding: '13px 20px',
                          }}
                          container>
                        <Grid key={'titleRight'} sx={{display: 'flex', justifyContent: 'flex-start', marginLeft: 3}}
                              item>
                            <FormLabel sx={{fontWeight: 900}}>{'Reports'}</FormLabel>
                        </Grid>
                        <Grid key={'contentsRight'} container
                              sx={{display: 'flex', flexDirection: 'column', marginLeft: 1}}>
                            {selectedCategory?.reports?.map((report: any) => {
                                return (
                                    <Grid container sx={{borderTop: '1px solid #d7d7d7'}} key={report.uuid}>
                                        <Grid item>
                                            <IconButton children={<FlashOnIcon
                                                color={report?.reportParameters.length > 0 ? "primary" : 'inherit'}/>}
                                                        onClick={() => handleExecutionOfReport(report)}/>
                                        </Grid>
                                        <Grid sx={{display: 'flex', alignItems: 'center'}} item>
                                            <Typography>
                                                {report.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </PageLayout>
    );
}

export default memo(ReportPage);
