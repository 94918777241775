import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/speakersSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  addSpeakersApi,
  deleteSpeakersApi,
  editSpeakersApi,
  getSpeakersApi,
} from 'store/api/speakersAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { GenericIdNameType } from 'types/genericTypes';
import { APIResult } from 'types/APITypes';

function* getSpeakers() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<GenericIdNameType[]> = yield call(getSpeakersApi);
  if (result?.data?.length) {
    yield put(actions.setSpeakers(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addSpeakers(action: PayloadAction<GenericIdNameType>) {
  const data = action.payload;
  const { result, error }: APIResult<GenericIdNameType> = yield call(
    addSpeakersApi,
    data,
  );
  if (result?.status === 201) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getSpeakersApi,
    );
    if (result?.data?.length) {
      yield put(actions.setSpeakers(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.name} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.name} already exists`,
        severity: 'error',
      }),
    );
  }
}

function* deleteSpeakers(action: PayloadAction<GenericIdNameType>) {
  const { id, name } = action.payload;
  const { result }: APIResult<GenericIdNameType> = yield call(
    deleteSpeakersApi,
    id! as string,
  );
  if (result?.status === 200) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getSpeakersApi,
    );
    if (result?.data?.length) {
      yield put(actions.setSpeakers(result.data));
    }
    yield put(commonActions.hideModal());
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
  }
}

function* editSpeakers(action: PayloadAction<GenericIdNameType>) {
  const name = action.payload;

  const { result, error }: APIResult<GenericIdNameType> = yield call(
    editSpeakersApi,
    name,
  );
  if (result?.status === 200) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getSpeakersApi,
    );
    if (result?.data?.length) {
      yield put(actions.setSpeakers(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${name.name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Speaker name already exist!',
        severity: 'error',
      }),
    );
  }
}

export default function* speakersWatcher() {
  yield takeLatest(actions.getSpeakers.type, getSpeakers);
  yield takeLatest(actions.addSpeakers.type, addSpeakers);
  yield takeLatest(actions.deleteSpeakers.type, deleteSpeakers);
  yield takeLatest(actions.editSpeakers.type, editSpeakers);
}
