import { FC, memo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormLabel } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { LanguageEntry } from 'types/languageTypes';
import { GenericModalProps } from 'types/genericTypes';
import { PermissionEntry } from 'types/permissionTypes';
import l from 'helpers/l';

const defaultValues: PermissionEntry = {
  code: '',
  name: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

type PermissionsFormProps = {
  initialValues?: LanguageEntry;
  title: string;
  onSubmit: (values: LanguageEntry) => void;
} & GenericModalProps;

const PermissionsForm: FC<PermissionsFormProps> = ({
  onClose,
  initialValues = defaultValues,
  title,
  onSubmit,
}) => {
  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: PermissionEntry) => {
      onSubmit(values);
    },
  });

  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <FormLabel>{l('CODE')}</FormLabel>
      <Box
        sx={{ fontFamily: 'Poppins', fontWeight: 600, marginBottom: '30px' }}>
        {formik.values.code}
      </Box>
      <FormLabel>Name</FormLabel>
      <TextField
        name="name"
        fullWidth
        size="small"
        value={formik.values.name}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.name)}
        helperText={formik.errors.name}
        pressEnter={formik.submitForm}
      />
    </SimpleFormModal>
  );
};

export default memo(PermissionsForm);
