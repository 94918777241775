import { FC, memo, useCallback } from 'react';
import { actions } from 'store/slices/checklistsSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import ChecklistsForm from 'components/Forms/ChecklistForm';
import { RootState } from 'store';

const ChecklistsEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state: RootState) =>
    data
      ? state.checklists.items.find((entry: any) => entry?.id === Number(data))
      : null,
  );

  const submitHandler = useCallback(
    (values: any) =>
      dispatch(
        actions.editChecklists({
          newName: values.name,
          newWeight: values.weight,
          id: entry?.id,
        }),
      ),
    [dispatch, entry?.name],
  );

  return entry ? (
    <ChecklistsForm
      title={l('EDIT_CHECKLIST')}
      onSubmit={submitHandler}
      initialValues={entry}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(ChecklistsEditModal);
