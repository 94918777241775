import { FC, memo, useCallback } from 'react';
import { actions as clientsRateActions } from 'store/slices/clientsRateSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { Typography } from '@mui/material';

const ClientsRateDeleteModal: FC<GenericModalProps> = ({ data, onClose }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state) =>
    state.languagesRate.items.find((item) => item.uuid === data.uuid),
  );

  const confirmHandler = useCallback(
    () =>
      dispatch(
        clientsRateActions.deleteClientsRate({
          urlSuffix: data,
        }),
      ),
    [dispatch, entry],
  );

  return (
    <ConfirmationModal onCancel={onClose} onConfirm={confirmHandler}>
      <Typography>
        Are you sure you want to delete this item from the table ?
      </Typography>
    </ConfirmationModal>
  );
};

export default memo(ClientsRateDeleteModal);
