import React, {FC, memo, useCallback, useEffect} from 'react';
import PageLayout from 'components/PageLayout/PageLayout';
import l from 'helpers/l';
import EditTable from 'components/Table/EditTable';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'store';
import {OrderLineStatusType} from 'types/orderLineStatusesTypes';
import {actions as orderLineStatusesActions} from 'store/slices/orderLineStatusesSlice';
import {ModalType} from 'enums/ModalEnums';
import {actions as commonActions} from 'store/slices/common';
import {GridRenderCellParams, GridRowParams} from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import {ColorButton} from 'material-ui-color';
import {useNavigate} from "react-router-dom";

const OrderLineStatuses: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const navigate = useNavigate();
  const orderlineStatusesValues: OrderLineStatusType[] = useSelector(
    (state: RootState) => state.orderLineStatuses.orderLineStatuses,
  );

  const addEntryHandler = useCallback(() => {
    dispatch(
      commonActions.showModal({
        type: ModalType.OrderLineStatusesAdd,
        data: '',
      }),
    );
  }, [dispatch]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.OrderLineStatusesEdit,
          data: params.row.uuid.toString(),
        }),
      );
    },
    [dispatch],
  );
    const settingsHandler = useCallback(
        (params: GridRowParams) => {
            const rowUuid = params.row.uuid
            if(rowUuid){
                navigate('/order-line-statuses-assignment', {state: rowUuid} )
            }
        },
        [dispatch],
    );

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.OrderLineStatusesDelete,
          data: params.row.name.toString(),
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(orderLineStatusesActions.getOrderLineStatusesManage());
  }, [dispatch]);

  return (
    <PageLayout fullWidth={true} title={l('PAGES.ORDER_LINE_STATUSES')}>
      <EditTable
        getRowId={(row) => row.uuid}
        loading={isLoading}
        pageSize={100}
        columns={[
          {
            field: 'name',
            headerName: l('ORDER_LINE_STATUSES.NAME'),
            width: 250,
          },
          {
            field: 'isFixed',
            headerName: l('ORDER_LINE_STATUSES.IS_FIXED'),
            width: 100,
            renderCell: (params: GridRenderCellParams) =>
              params.row.isFixed && <CheckIcon />,
          },
          {
            field: 'needsDescription',
            headerName: l('ORDER_LINE_STATUSES.NEEDS_DESCRIPTION'),
            width: 200,
            renderCell: (params: GridRenderCellParams) =>
              params.row.needsDescription && <CheckIcon />,
          },
          {
            field: 'isActive',
            headerName: l('ORDER_LINE_STATUSES.IS_ACTIVE'),
            width: 100,
            renderCell: (params: GridRenderCellParams) =>
              params.row.isActive && <CheckIcon />,
          },
          {
            field: 'isSendMail',
            headerName: l('ORDER_LINE_STATUSES.IS_SEND_MAIL'),
            width: 150,
            renderCell: (params: GridRenderCellParams) =>
              params.row.isSendMail && <CheckIcon />,
          },
          {
            field: 'isSendNotification',
            headerName: l('ORDER_LINE_STATUSES.IS_SEND_NOTIFICATION'),
            width: 200,
            renderCell: (params: GridRenderCellParams) =>
              params.row.isSendNotification && <CheckIcon />,
          },
          {
            field: 'color',
            headerName: l('ORDER_LINE_STATUSES.COLOR'),
            width: 100,
            renderCell: (params: GridRenderCellParams) =>
              params.row.color && <ColorButton color={params.row.color} />,
          },
        ]}
        rows={orderlineStatusesValues}
        onAdd={addEntryHandler}
        onEdit={editEntryHandler}
        onDelete={deleteEntryHandler}
        settingsButton={settingsHandler}
        hideFooterPagination
        hideFooter
      />
    </PageLayout>
  );
};

export default memo(OrderLineStatuses);
