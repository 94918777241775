import { FC, memo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormLabel, Switch } from '@mui/material';
import { Form, TextField } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/index';
import { actions as clientsActions } from 'store/slices/clientsSlice';
import { actions as orderTypesActions } from 'store/slices/orderTypesSlice';
import { actions as languageActions } from 'store/slices/languagesSlice';
import {
  actions as employeesActions,
  actions as employeeActions,
} from 'store/slices/employeeListSlice';
import { actions as projectActions } from 'store/slices/projectsSlice';
import { actions as orderLineStatusesActions } from 'store/slices/orderLineStatusesSlice';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { createMenuItems } from 'helpers/dropdown';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';
import { Endpoint } from 'enums/APIEndpointEnum';
import { getFullName } from 'helpers/text';
import InputRowWrapper from 'components/InputRowWrapper/InputRowWrapper';
import l from 'helpers/l';
import { useParams } from 'react-router-dom';
import { actions as ordersActions } from '../../store/slices/orderEntriesSlice';

const defaultValues: any = {
  clientReference: '',
  orderLineStatusUuid: '',
  articleTypeUuid: '',
  sourceLanguageUuid: '',
  languageUuid: '',
  quantity: '',
  dueDate: '',
  dateCritical: 'must',
  pmEmployeeUuid: '',
  pmDueDate: '',
  clientInstructionsUrl: '',
  instructions: '',
  isNoEdit: false,
  projectUuid: '',
};

const validationSchema = Yup.object().shape({
  clientReference: Yup.string().nullable(),
  orderLineStatusUuid: Yup.string(),
  articleTypeUuid: Yup.string().required('This field is required!'),
  languageUuid: Yup.string()
    .required('This field is required!')
    .notOneOf(
      [Yup.ref('sourceLanguage')],
      'Language must not have the same value as Source Language',
    ),
  quantity: Yup.number().integer().required('This field is required!'),
  dueDate: Yup.date(),
  dateCritical: Yup.string(),
  pmEmployeeUuid: Yup.string(),
  pmDueDate: Yup.date(),
  clientInstructionsUrl: Yup.string().nullable(),
  instructions: Yup.string().when('clientInstructionsUrl', {
    is: (clientInstructionsUrl: any) =>
      clientInstructionsUrl === '' || !clientInstructionsUrl,
    then: Yup.string().required(
      'One of those: Client task Instruction link or Client Additional Note are required!',
    ),
    otherwise: Yup.string().nullable(),
  }),
  isNoEdit: Yup.bool(),
  projectUuid: Yup.string(),
});

type OrderLinesFormProps = {
  initialValues?: any;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  articleTypesDropdown?: {}[];
  type?: 'add' | 'edit' | 'duplicate';
};

const OrderLinesForm: FC<OrderLinesFormProps> = ({
  initialValues = defaultValues,
  onSubmit,
  onCancel,
  articleTypesDropdown,
  type,
}) => {
  const dispatch = useDispatch();
  const contactPersonUuid = useSelector(
    (state: RootState) => state?.orderEntries?.orderEntry?.contactPerson?.uuid,
  );

  const { id } = useParams();
  const orderEntry = useSelector(
    (state: RootState) => state?.orderEntries?.orderEntry,
  );

  const [dueDateFormat, setDueDateFormat] = useState(
    initialValues?.dueDate
      ? moment(initialValues?.dueDate).format('DD.MM.YYYY')
      : type === 'add'
      ? moment().format('DD.MM.YYYY')
      : moment(orderEntry?.dueDatetime).format('DD.MM.YYYY'),
  );

  const [pmMaxDate, setPmMaxDate] = useState();

  const handleDueDateChange = (value: any, inputValue: any) => {
    setDueDateFormat(inputValue);
    setPmMaxDate(value);
  };

  const [pmDueDateFormat, setPnDueDateFormat] = useState(
    initialValues?.pmDueDate
      ? moment(initialValues?.pmDueDate).format('DD.MM.YYYY')
      : type === 'add'
      ? moment().format('DD.MM.YYYY')
      : moment(orderEntry?.dueDatetime).format('DD.MM.YYYY'), // on add new order line get default order entry due date for this PM due date
  );

  const handlePmDueDateChange = (value: any, inputValue: any) => {
    setPnDueDateFormat(inputValue);
  };

  const languagesDropdown = useSelector(
    (state: RootState) => state?.languages?.items,
  ).map((entry) => ({ label: entry.name, value: entry.uuid }));

  const projectManagersDropdown = useSelector(
    (state: RootState) => state?.employees?.employeesPm,
  ).map((entry: any) => ({ label: getFullName(entry), value: entry.uuid }));

  const projectsDropdown = useSelector(
    (state: RootState) => state?.projects?.items,
  ).map((entry: any) => ({ label: entry.name, value: entry.uuid }));

  const orderLineStatusesDropdown = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  ).map((entry: any) => ({ label: entry.name, value: entry.uuid }));

  const assistantPMDropdown = useSelector(
    (state: RootState) => state?.employees?.employeesByRole,
  ).map((entry: any) => ({
    label: entry?.firstName + ' ' + entry.lastName,
    value: entry?.uuid,
  }));

  const dateFormatter = (string: any) => {
    const splitDate = string.split('.');
    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  };

  const { setValues, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit({
        ...values,
        dueDatetime: setTime(new Date(dateFormatter(dueDateFormat))),
        pmDueDateTime: setTime(new Date(dateFormatter(pmDueDateFormat))),
        projectUuid: values?.projectUuid || null,
        articleTypeUuid: values?.articleTypeUuid || null,
        sourceLanguageUuid: values?.sourceLanguageUuid || null,
        languageUuid: values?.languageUuid || null,
        pmEmployeeUuid: values?.pmEmployeeUuid || null,
        assistantProjectManagerUuid:
          values?.assistantProjectManagerUuid || null,
        writerArticleLink: values?.writerArticleLink || '',
      });
    },
  });

  const setTime = (date: any) => {
    const split = String(date).split(' ');
    split[4] = '23:59:00';

    return split.join(' ');
  };

  const dueDateCriticalValues = createMenuItems([
    { label: 'must', value: 'must' },
    { label: 'critical', value: 'critical' },
    { label: 'non-critical', value: 'nonCritical' },
  ]);

  const datePickerStyles = {
    width: '100%',
    display: 'flex',
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    padding: '12px',
  };

  useEffect(() => {
    const data = {
      uuid: id,
    };
    dispatch(ordersActions.getOrderEntryItem({ data }));
    dispatch(clientsActions.getClients());
    dispatch(orderTypesActions.getOrderTypes());
    dispatch(languageActions.getLanguages());
    dispatch(employeeActions.getEmployeesPM());
    dispatch(orderLineStatusesActions.getOrderLineStatusesManage());
    dispatch(
      employeesActions.getEmployeesByRole({
        urlSuffix: 'assistant_pm',
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const objToSend: any = {
      contactPersonUuid,
    };
    dispatch(projectActions.getProjectsByContactPerson(objToSend));
  }, [dispatch, contactPersonUuid]);

  useEffect(() => {
    if (type === 'add') {
      initialValues.dateCritical = 'must';
      // @ts-ignore
      initialValues.articleTypeUuid = articleTypesDropdown?.[0]?.value || '';
    }
    if (type === 'edit' || type === 'duplicate') {
      setDueDateFormat(moment(initialValues?.dueDate).format('DD.MM.YYYY'));
      setPnDueDateFormat(moment(initialValues?.pmDueDate).format('DD.MM.YYYY'));
    }
  }, [initialValues, articleTypesDropdown]);

  const minimalOrderDueDate = new Date();
  const minimalPmDueDate = new Date();

  return (
    <Form
      onSubmit={formik.submitForm}
      onCancel={onCancel}
      btnCancelLabel="Cancel"
      btnSubmitLabel="Save"
      sx={{ padding: '32px' }}>
      {type === 'edit' && (
        <InputRowWrapper
          label={l('NEW_STATUS')}
          labelWidth="320px"
          inputWidth="70%">
          <TextField
            select
            name="orderLineStatusUuid"
            size="small"
            value={formik?.values?.orderLineStatusUuid || ''}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.orderLineStatusUuid)}
            helperText={formik.errors.orderLineStatusUuid}
            pressEnter={formik.submitForm}>
            {createMenuItems(orderLineStatusesDropdown) || []}
          </TextField>
        </InputRowWrapper>
      )}
      <InputRowWrapper
        label={l('CLIENT_REF')}
        labelWidth="320px"
        inputWidth="70%">
        <TextField
          name="clientReference"
          size="small"
          value={formik?.values?.clientReference || ''}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.clientReference)}
          helperText={formik.errors.clientReference}
          pressEnter={formik.submitForm}
        />
      </InputRowWrapper>
      <InputRowWrapper
        label={l('ARTICLE_TYPE')}
        labelWidth="320px"
        inputWidth="35%">
        <TextField
          select
          name="articleTypeUuid"
          size="small"
          value={formik?.values?.articleTypeUuid || ''}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.articleType)}
          helperText={formik.errors.articleTypeUuid}
          pressEnter={formik.submitForm}>
          {/* @ts-ignore*/}
          {createMenuItems(articleTypesDropdown) || []}
        </TextField>
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="70%"></InputRowWrapper>
      <InputRowWrapper
        label={l('SOURCE_LANGUAGE')}
        labelWidth="320px"
        inputWidth="45%">
        <SuggestedDropdown
          url={Endpoint.Languages}
          inputId="sourceLanguageUuid"
          initialData={languagesDropdown}
          inputValue={
            initialValues?.sourceLanguageUuid
              ? {
                  label: initialValues.sourceLanguage,
                  value: initialValues.sourceLanguageUuid,
                }
              : ''
          }
          dropdownLabel="name"
          dropdownValue="uuid"
          dataLocation="resultDataItems"
          orderBy="code"
          order="ASC"
          formikHook={formik}
          style={{ display: 'flex', flexDirection: 'row' }}
        />
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="70%"></InputRowWrapper>
      <InputRowWrapper
        label={l('LANGUAGE')}
        labelWidth="320px"
        inputWidth="45%">
        <SuggestedDropdown
          url={Endpoint.Languages}
          inputId="languageUuid"
          error={Boolean(formik.errors.languageUuid)}
          helperText={formik.errors.languageUuid}
          initialData={languagesDropdown}
          inputValue={
            initialValues?.language
              ? {
                  label: initialValues?.language,
                  value: initialValues?.languageUuid,
                }
              : ''
          }
          dropdownLabel="name"
          dropdownValue="uuid"
          dataLocation="resultDataItems"
          orderBy="code"
          order="ASC"
          formikHook={formik}
          style={{ display: 'flex', flexDirection: 'row' }}
        />
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="70%"></InputRowWrapper>
      <InputRowWrapper
        label={l('QUANTITY')}
        labelWidth="320px"
        inputWidth="25%">
        <TextField
          type="number"
          name="quantity"
          size="small"
          value={formik?.values?.quantity || ''}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.quantity)}
          helperText={formik.errors.quantity}
          pressEnter={formik.submitForm}
        />
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="100%"></InputRowWrapper>
      <InputRowWrapper
        label={l('ORDER_DUE_DATE')}
        labelWidth="320px"
        inputWidth="35%">
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <KeyboardDatePicker
            autoOk
            value={dueDateFormat}
            inputValue={dueDateFormat}
            onChange={handleDueDateChange}
            format="DD.MM.YYYY"
            style={datePickerStyles}
            minDate={minimalOrderDueDate}
            disablePast={type === 'add'}
          />
        </MuiPickersUtilsProvider>
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="100%"></InputRowWrapper>
      <InputRowWrapper
        label={l('ORDER_DATE_CRITICAL')}
        labelWidth="320px"
        inputWidth="35%">
        <TextField
          select
          name="dateCritical"
          fullWidth
          size="small"
          value={formik?.values?.dateCritical || ''}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.dateCritical)}
          helperText={formik.errors.dateCritical}>
          {dueDateCriticalValues || []}
        </TextField>
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="100%"></InputRowWrapper>
      <InputRowWrapper
        label={l('PROJECT_MANAGER')}
        labelWidth="320px"
        inputWidth="70%">
        <TextField
          select
          name="pmEmployeeUuid"
          size="small"
          value={formik?.values?.pmEmployeeUuid || ''}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.pmEmployeeUuid)}
          helperText={formik.errors.pmEmployeeUuid}
          pressEnter={formik.submitForm}>
          {projectManagersDropdown.length
            ? createMenuItems(projectManagersDropdown)
            : []}
        </TextField>
      </InputRowWrapper>{' '}
      <InputRowWrapper labelWidth="250px" inputWidth="100%"></InputRowWrapper>
      <InputRowWrapper
        label={l('PROJECT_MANAGER_DUE_DATE')}
        labelWidth="320px"
        inputWidth="35%">
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <KeyboardDatePicker
            autoOk
            value={pmDueDateFormat}
            inputValue={pmDueDateFormat}
            onChange={handlePmDueDateChange}
            format="DD.MM.YYYY"
            style={datePickerStyles}
            minDate={minimalPmDueDate}
            maxDate={pmMaxDate}
            disablePast={type === 'add'}
          />
        </MuiPickersUtilsProvider>
      </InputRowWrapper>{' '}
      <InputRowWrapper labelWidth="250px" inputWidth="100%"></InputRowWrapper>
      <InputRowWrapper
        label={l('CLIENT_INSTRUCTIONS_URL')}
        labelWidth="320px"
        inputWidth="70%">
        <TextField
          type="text"
          name="clientInstructionsUrl"
          size="small"
          value={formik?.values?.clientInstructionsUrl || ''}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.clientInstructionsUrl)}
          helperText={formik.errors.clientInstructionsUrl}
          pressEnter={formik.submitForm}
        />
      </InputRowWrapper>
      <Box height="150px">
        <InputRowWrapper
          label={l('CLIENT_ADDITIONAL_NOTE')}
          labelWidth="320px"
          inputWidth="70%">
          <TextField
            type="text"
            name="instructions"
            size="small"
            multiline
            rows={5}
            value={formik?.values?.instructions || ''}
            onChange={formik.handleChange}
            autoComplete="off"
            error={Boolean(formik.errors.instructions)}
            helperText={formik.errors.instructions}
          />
        </InputRowWrapper>
      </Box>
      <Box height="150px">
        <FormLabel></FormLabel>
        <InputRowWrapper
          label={'Assistant PM'}
          labelWidth="320px"
          inputWidth="70%">
          <SuggestedDropdown
            url={Endpoint.AssistantPm}
            inputId="assistantProjectManagerUuid"
            initialData={assistantPMDropdown}
            inputValue={
              initialValues?.assistantProjectManager &&
              initialValues?.assistantProjectManagerUuid
                ? {
                    label: initialValues?.assistantProjectManager,
                    value: initialValues?.assistantProjectManagerUuid,
                  }
                : ''
            }
            dropdownLabel={['firstName', 'lastName']}
            dropdownValue="uuid"
            dataLocation="resultDataItems"
            error={Boolean(formik.errors.assistantProjectManagerUuid)}
            helperText={formik.errors.assistantProjectManagerUuid}
            formikHook={formik}
          />
        </InputRowWrapper>
      </Box>
      <InputRowWrapper label={l('NO_EDIT')} labelWidth="320px" inputWidth="25%">
        <Switch
          id="isNoEdit"
          value={formik?.values?.isNoEdit}
          defaultChecked={initialValues?.isNoEdit}
          size="small"
          onChange={formik.handleChange}
        />
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="100%"></InputRowWrapper>
      <InputRowWrapper label={l('PROJECT')} labelWidth="320px" inputWidth="70%">
        <TextField
          select
          name="projectUuid"
          size="small"
          value={formik?.values?.projectUuid || ''}
          onChange={formik.handleChange}
          autoComplete="off">
          {createMenuItems(projectsDropdown) || []}
        </TextField>
      </InputRowWrapper>
    </Form>
  );
};

export default memo(OrderLinesForm);
