import React, { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as commonActions } from 'store/slices/common';
import PageLayout from 'components/PageLayout/PageLayout';
import EditTable from 'components/Table/EditTable';
import l from 'helpers/l';
import { GridRowParams } from '@mui/x-data-grid';
import { ModalType } from 'enums/ModalEnums';
import { RootState } from 'store';
import { actions } from 'store/slices/paymentSlice';

const Payment: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const payment = useSelector((state: RootState) => state.payment.payment);

  const addEntryHandler = useCallback(() => {
    dispatch(commonActions.showModal({ type: ModalType.PaymentAdd }));
  }, [dispatch]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.PaymentEdit,
          data: params.row.name.toString(),
        }),
      );
    },
    [dispatch],
  );

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.PaymentDelete,
          data: params.row.name.toString(),
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(actions.getPayment());
  }, [dispatch]);

  return (
    <PageLayout title={l('PAGES.PAYMENT_METHODS')}>
      <EditTable
        getRowId={(row) => row.id}
        columns={[
          { field: 'id', headerName: 'Id' },
          {
            field: 'name',
            headerName: l('NAME'),
            width: 300,
          },
        ]}
        rows={payment}
        loading={isLoading}
        onAdd={addEntryHandler}
        onEdit={editEntryHandler}
        onDelete={deleteEntryHandler}
        hideFooterPagination
        hideFooter
      />
    </PageLayout>
  );
};

export default memo(Payment);
