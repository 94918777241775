import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  targetAudience: [],
};

export const targetAudienceSlice = createSlice({
  name: 'targetAudience',
  initialState,
  reducers: {
    getTargetAudience: (state) => state,
    setTargetAudience: (state, action: any) => {
      state.targetAudience = action.payload;
    },
    addTargetAudience: (state) => state,
    deleteTargetAudience: (state) => state,
    editTargetAudience: (state) => state,
  },
});
export const actions: any = targetAudienceSlice.actions;
export default targetAudienceSlice.reducer;
