import { FC, memo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormLabel } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps } from 'types/genericTypes';
import l from 'helpers/l';
import { QuestionEntry } from 'types/questionsTypes';

const defaultValues: QuestionEntry = {
  id: 0,
  name: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

type QuestionsFormProps = {
  initialValues?: QuestionEntry;
  title: string;
  onSubmit: (values: QuestionEntry) => void;
} & GenericModalProps;

const EmployeeStatusesForm: FC<QuestionsFormProps> = ({
  onClose,
  initialValues = defaultValues,
  title,
  onSubmit,
}) => {
  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: QuestionEntry) => {
      onSubmit(values);
    },
  });

  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <FormLabel>{l('QUESTION')}</FormLabel>
      <TextField
        name="name"
        fullWidth
        size="small"
        value={formik.values.name}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.name)}
        helperText={formik.errors.name}
        pressEnter={formik.submitForm}
      />
    </SimpleFormModal>
  );
};

export default memo(EmployeeStatusesForm);
