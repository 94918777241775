import { FC, memo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormLabel, Typography } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps } from 'types/genericTypes';
import { useDispatch, useSelector } from 'hooks/redux';
import MomentUtils from '@date-io/moment';
import { actions as userActions } from '../../store/slices/user';
import { RootState } from 'store';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { Endpoint } from 'enums/APIEndpointEnum';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';

const validationSchema = Yup.object().shape({
  roleCode: Yup.string().required('Required'),
  replaceeUuid: Yup.string().required('Required'),
});

const defaultValues = {
  roleCode: '',
  replaceeUuid: '',
};

type EmployeeReplacementsFormProps = {
  initialValues?: any;
  title: string;
  employeeName?: string;
  employeeUuid?: string;
  onSubmit: (values: any) => void;
  values?: any;
  rolesValues?: any;
} & GenericModalProps;

const EmployeeViewRolesForm: FC<EmployeeReplacementsFormProps> = ({
  onClose,
  initialValues = defaultValues,
  employeeName,
  title,
  onSubmit,
  rolesValues,
}) => {
  const [fromSelectedDate, setFromSelectedDate] = useState(moment());
  const [from, setFrom] = useState(moment().format('DD.MM.YYYY'));
  const [toSelectedDate, setToSelectedDate] = useState(moment());
  const [to, setTo] = useState(moment().format('DD.MM.YYYY'));
  // const [roleCode, setRoleCode] = useState('');
  const dispatch = useDispatch();

  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit({
        replacerUuid: values?.replaceeUuid,
        replaceeUuid: chosenUuid?.uuid,
        dateFrom: dateFormatter(fromSelectedDate),
        dateTo: dateFormatter(toSelectedDate),
      });
    },
  });

  const replacementsByRoles = useSelector(
    (state: RootState) => state.user?.replacementsByRole,
  );

  const chosenEmployee = useSelector(
    (state: RootState) => state.employees?.employeeDetails,
  );

  const userRoles = useSelector((state: RootState) => state.user.userRoles);

  const chosenUuid = userRoles?.find(
    (role: any) => role.role.code === formik?.values?.roleCode,
  );

  const returnReplacementsDropdown = (replacementsRoles: any) => {
    if (replacementsRoles?.items) {
      let roles: any = [];

      replacementsRoles?.items?.map((item: any) => {
        const rolesUuid = item.roles.filter((role: any) => {
          if (role.role.code === formik.values.roleCode) {
            return role.uuid;
          }
        });

        roles.push({
          label: `${item.firstName} ${item.lastName}`,
          value: rolesUuid[0]?.uuid,
        });
      });
      return roles;
    }
  };

  let replacementDropdownRoles =
    returnReplacementsDropdown(replacementsByRoles);

  const dateFormatter = (string: any) => {
    return moment(string).format('YYYY-MM-DDTHH:mm:ss.sssZ');
  };

  const handleFromChange = (value: any, inputValue: any) => {
    setFromSelectedDate(value);
    setFrom(inputValue);
  };

  const handleToChange = (value: any, inputValue: any) => {
    setToSelectedDate(value);
    setTo(inputValue);
  };

  useEffect(() => {
    if (formik.values.roleCode !== '') {
      const data = {
        roleCode: formik?.values?.roleCode,
        choosenEmployee: chosenEmployee,
      };
      dispatch(userActions.getReplacementsByRoles({ ...data }));
    }
  }, [dispatch, formik.values.roleCode]);

  const datePickerStyles = {
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    padding: '12px',
  };

  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <FormLabel>Employee</FormLabel>
      <Box sx={{ marginBottom: '30px' }}>
        <Typography fontWeight={600}>{employeeName}</Typography>
      </Box>
      <FormLabel>Role</FormLabel>
      <TextField
        select
        name="roleCode"
        sx={{ width: '12rem', display: 'flex' }}
        size="small"
        value={formik.values.roleCode}
        error={Boolean(formik.errors.roleCode)}
        helperText={formik.errors.roleCode}
        onChange={formik.handleChange}
        autoComplete="off"
        defaultValue="Super User"
        pressEnter={formik.submitForm}>
        {rolesValues}
      </TextField>

      {formik.values.roleCode !== '' && (
        <>
          <FormLabel>Replacing Employee</FormLabel>
          <SuggestedDropdown
            url={Endpoint.EmployeeReplacementsRoles}
            urlSuffix={formik.values.roleCode}
            excludeEmployeeUuid={chosenEmployee?.uuid}
            inputId="replaceeUuid"
            initialData={replacementDropdownRoles}
            //dropdownLabel='name'
            dropdownLabel={['firstName', 'lastName']}
            error={Boolean(formik.errors.replaceeUuid)}
            helperText={formik.errors.replaceeUuid}
            dropdownValue="uuid"
            dataLocation="resultDataItems"
            formikHook={formik}
          />
        </>
      )}
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ width: '12rem', marginRight: '2rem' }}>
          <FormLabel sx={{ display: 'flex' }}>From</FormLabel>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <KeyboardDatePicker
              autoOk={true}
              showTodayButton={true}
              value={fromSelectedDate}
              inputValue={from}
              onChange={handleFromChange}
              format="DD.MM.YYYY"
              style={datePickerStyles}
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box sx={{ width: '12rem' }}>
          <FormLabel sx={{ display: 'flex' }}>To</FormLabel>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <KeyboardDatePicker
              autoOk={true}
              showTodayButton={true}
              value={toSelectedDate}
              inputValue={to}
              onChange={handleToChange}
              format="DD.MM.YYYY"
              style={datePickerStyles}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Box>
    </SimpleFormModal>
  );
};

export default memo(EmployeeViewRolesForm);
