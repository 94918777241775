import { Box, Breakpoint } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import styles from './InputWrapperStyles';

type InputWrapper = {
  maxWidth?: string | Breakpoint | undefined;
};

const InputWrapper: FC<PropsWithChildren<InputWrapper>> = ({
  children,
  maxWidth = '500px',
  ...props
}) => {
  const classes = styles();

  return (
    <Box className={classes.InputWrapper} maxWidth={maxWidth} {...props}>
      {children}
    </Box>
  );
};

export default InputWrapper;
