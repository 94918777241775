import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/employeeListSlice';
import { actions as commonActions } from '../slices/common';
import {
    addEmployeeApi,
    getEmployeesApi,
    getEmployeesRoleApi,
    sendFiltersApi,
    EditEmployeeApi,
    deleteEmployeeApi,
    getDetailsPageApi,
    getEmployeesPMApi, getTeamLeadsApi,
} from 'store/api/employeesApi';
import { PayloadAction } from '@reduxjs/toolkit';
import { EmployeeResult } from 'types/employeeTypes';
import { employeeFilter } from 'types/employeeTypes';
import { employeeMessagessHelper } from 'helpers/messageHelpers';
import { APIResult } from 'types/APITypes';

function* getEmployees() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<EmployeeResult> = yield call(getEmployeesApi);
  if (result?.data) {
    yield put(actions.setEmployeesList(result?.data?.items));
    yield put(actions.setEmployeeTableData(result?.data?.meta));
  }
  yield put(commonActions.setLoading(false));
}

function* getTeamLeads() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any> = yield call(getTeamLeadsApi);
  if (result?.data) {
    yield put(actions.setTeamLeadsList(result?.data?.items));
  }
  yield put(commonActions.setLoading(false));
}
function* getEmployeesPM() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<EmployeeResult> = yield call(getEmployeesPMApi);
  if (result?.data) {
    yield put(actions.setEmployeesListPM(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* getEmployeesByRole(action: any) {
  const urlSuffix = action.payload.urlSuffix;
  yield put(commonActions.setLoading(true));

  const { result }: APIResult<EmployeeResult> = yield call(
    getEmployeesRoleApi,
    urlSuffix,
  );
  if (result?.data) {
    yield put(actions.setEmployeesListByRole(result?.data?.items));
  }
  yield put(commonActions.setLoading(false));
}

function* sendFilters(action: PayloadAction<employeeFilter>) {
  const params = action.payload;
  yield put(commonActions.setLoading(true));
  const { result } = yield call(sendFiltersApi, params);
  if (result?.data) {
    yield put(actions.setEmployeesList(result.data?.items));
    yield put(actions.setEmployeeTableData(result.data?.meta));
  }
  yield put(commonActions.setLoading(false));
}

function* addEmployee(action: any) {
  const { data, navigate } = action.payload;
  const { result, error } = yield call(addEmployeeApi, data);
  if (result?.data) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `Added new employee - ${data.firstName} ${data.lastName}`,
        severity: 'success',
      }),
    );
    yield put(actions.addEmployeeSuccess(result.data));
    yield put(actions.getEmployeesList());
    navigate(`/employees/${result.data.uuid}`);
  }
  if (error?.message) {
    yield call(employeeMessagessHelper, error.message);
  }
}

function* editEmployee(action: any) {
  const { data, navigate } = action.payload;
  const urlSuffix = data.uuid;
  const { result, error } = yield call(EditEmployeeApi, data, urlSuffix);
  if (result?.data) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `Succesfully edited ${data.firstName} ${data.lastName}`,
        severity: 'success',
      }),
    );
    yield put(actions.editEmployeeSuccess(result.data));
    navigate(`/employees/${result.data.uuid}`);
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `The user name should be 30 characters max`,
        severity: 'error',
      }),
    );
  } else if (error?.message) {
    yield call(employeeMessagessHelper, error.message);
  }
}

function* deleteEmployee(action: any) {
  const { id: urlSuffix, navigate } = action.payload;
  const { result, error } = yield call(deleteEmployeeApi, urlSuffix);
  if (result?.status === 200) {
    yield put(actions.deleteEmployeeSuccess(urlSuffix));
    yield put(
      commonActions.snackBar({
        open: true,
        message: `Succesfully deleted employee`,
        severity: 'success',
      }),
    );
    navigate && navigate('/employees');
  }
  if (error?.message) {
    yield call(employeeMessagessHelper, error.message);
  }
  yield put(commonActions.hideModal());
}

function* getDetailsPage(action: any) {
  const urlSuffix = action.payload;
  yield put(commonActions.setLoading(true));
  const { result } = yield call(getDetailsPageApi, urlSuffix);
  if (result?.data) {
    yield put(actions.setDetailsPage(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

export default function* employeesListWatcher() {
  yield takeLatest(actions.getEmployeesList.type, getEmployees);
  yield takeLatest(actions.getTeamLeadsList.type, getTeamLeads);
  yield takeLatest(actions.getEmployeesPM.type, getEmployeesPM);
  yield takeLatest(actions.getEmployeesByRole.type, getEmployeesByRole);
  yield takeLatest(actions.sendFilters.type, sendFilters);
  yield takeLatest(actions.addEmployee.type, addEmployee);
  yield takeLatest(actions.editEmployee.type, editEmployee);
  yield takeLatest(actions.deleteEmployee.type, deleteEmployee);
  yield takeLatest(actions.getDetailsPage.type, getDetailsPage);
}
