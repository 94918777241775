import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getLanguageRateAPI = (params: any) =>
  apiCall({
    url: Endpoint.LanguageRate,
    method: Method.Get,
    params,
  });

const addLanguageRateAPI = (data: any) =>
  apiCall({
    url: Endpoint.LanguageRate,
    method: Method.Post,
    data,
  });
const editLanguageRateAPI = (urlSuffix: string, data: any) =>
  apiCall({
    url: Endpoint.LanguageRate,
    method: Method.Put,
    urlSuffix,
    data,
  });
const deleteLanguageRateAPI = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.LanguageRate,
    method: Method.Delete,
    urlSuffix,
  });

export {
  getLanguageRateAPI,
  addLanguageRateAPI,
  editLanguageRateAPI,
  deleteLanguageRateAPI,
};
