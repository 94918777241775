import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { ExpertiseActionsEntry } from 'types/expertiseTypes';
import { GenericIdNameType } from 'types/genericTypes';
import apiCall from './apiCall';

export const sendExpertiseFiltersApi = (params: any) =>
    apiCall({
        url: Endpoint.Expertise,
        method: Method.Get,
        params,
    });

const getExpertiseApi = () =>
  apiCall({
    url: Endpoint.Expertise,
    method: Method.Get,
  });

const addExpertiseApi = (data: ExpertiseActionsEntry) =>
  apiCall({
    url: Endpoint.Expertise,
    method: Method.Post,
    data,
  });

const deleteExpertiseApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.Expertise,
    method: Method.Delete,
    urlSuffix,
  });

const editExpertiseApi = ({ id, ...data }: GenericIdNameType) => {
  return apiCall({
    url: Endpoint.Expertise,
    urlSuffix: id.toString(),
    method: Method.Put,
    data,
  });
};

export {
  getExpertiseApi,
  addExpertiseApi,
  editExpertiseApi,
  deleteExpertiseApi,
};
