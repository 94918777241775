import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { GenericIdNameType } from 'types/genericTypes';
import apiCall from './apiCall';

const getPaymentApi = () =>
  apiCall({
    url: Endpoint.Payment,
    method: Method.Get,
  });

const addPaymentApi = (data: GenericIdNameType) =>
  apiCall({
    url: Endpoint.Payment,
    method: Method.Post,
    data,
  });

const deletePaymentApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.Payment,
    method: Method.Delete,
    urlSuffix,
  });

const editPaymentApi = (data: GenericIdNameType) => {
  return apiCall({
    url: Endpoint.Payment,
    method: Method.Put,
    data,
  });
};

export { getPaymentApi, addPaymentApi, deletePaymentApi, editPaymentApi };
