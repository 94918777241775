import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role, RolesState } from 'interfaces/roles';
import { RoleType } from 'types/roleTypes';

const initialState: RolesState = {
  roles: [],
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    getRoles: (state) => state,
    setRoles: (state, action: PayloadAction<RoleType[]>) => {
      state.roles = action.payload;
    },
    addRoles: (state) => state,
    editRoles: (state) => state,
    deleteRoles: (state) => state,
  },
});
export const actions: any = rolesSlice.actions;
export default rolesSlice.reducer;
