import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from 'components/PageLayout/PageLayout';
import EditTable from 'components/Table/EditTable';
import l from 'helpers/l';
import {
  GridRowParams,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { RootState } from 'store';
import { actions as languageActions } from 'store/slices/languagesSlice';
import { actions as languageRateActions } from 'store/slices/languagesRateSlice';
import { actions as commonActions } from 'store/slices/common';
import { ModalType } from 'enums/ModalEnums';
import { Filters } from 'components';
import { Box, FormLabel } from '@mui/material';
import { useFormik } from 'formik';
import { formatFilters } from 'helpers/filters';
import { isEmpty } from 'lodash';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';
import { Endpoint } from 'enums/APIEndpointEnum';
import { AR_LR_MAPPINGS, findAndMap } from 'config/utilsFunctions';

const initialValues: any = {
  sourceLanguageUuid: '',
  languageUuid: '',
};

const LanguageRatesPage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.common.loading);

  const { items, meta } = useSelector(
    (state: RootState) => state.languagesRate,
  );

  const languagesDropdown = useSelector(
    (state: RootState) => state?.languages?.items,
  ).map((entry) => ({ label: entry.name, value: entry.uuid }));

  const [filters, setFilters] = useState({});
  const [dropdownValues, setDropdownValues] = useState({});
  const [sort, setSort] = useState({});
  const [resetFilter, setResetFilter] = useState<boolean>(false);

  const addEntryHandler = useCallback(() => {
    dispatch(
      commonActions.showModal({
        type: ModalType.LanguageRatesAddModal,
        data: { filters: filters },
      }),
    );
  }, [dispatch, filters]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.LanguageRatesEditModal,
          data: { id: params.id.toString(), filters: dropdownValues },
        }),
      );
    },
    [dispatch, dropdownValues],
  );

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.LanguageRatesDeleteModal,
          data: params.id.toString(),
        }),
      );
    },
    [dispatch],
  );

  const { setValues, ...formik } = useFormik({
    initialValues,
    validateOnChange: false,
    onSubmit: (values: any) => {
      const dropdowns = {
        languagesDropdown,
      };

      const filters = findAndMap(values, dropdowns, AR_LR_MAPPINGS);
      setFilters(filters);

      const formatValues = formatFilters(values);
      setDropdownValues(() => {
        return { ...formatValues };
      });
      if (!isEmpty(formatValues)) {
        const params = { ...formatValues, ...sort };

        dispatch(languageRateActions.getLanguageRates(params));
      } else {
        dispatch(languageRateActions.getLanguageRates(sort));
      }
    },
  });

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: item.field,
        order: item?.sort?.toUpperCase(),
      }))[0];
      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });
      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
        ...dropdownValues,
      };

      dispatch(languageRateActions.getLanguageRates(params));
    },
    [dispatch, dropdownValues],
  );

  const onReset = useCallback(() => {
    setResetFilter(true);
    setValues(initialValues);
    setDropdownValues({});
    setFilters({});
    dispatch(languageRateActions.getLanguageRates(sort));
  }, [dispatch, setValues, sort]);

  const handlePage = useCallback(
    (page: number) => {
      const params = { ...dropdownValues, ...sort, page: page + 1 };

      dispatch(languageRateActions.getLanguageRates(params));
    },
    [dispatch, dropdownValues, sort],
  );

  useEffect(() => {
    setResetFilter(false);
  }, [resetFilter]);

  useEffect(() => {
    dispatch(languageRateActions.getLanguageRates(''));
    dispatch(languageActions.getLanguages());
  }, [dispatch]);

  return (
    <PageLayout title={l('PAGES.AR_LANGUAGE_RATES')} fullWidth>
      <Filters handleFilters={formik.submitForm} onReset={onReset}>
        <Box minWidth="150px">
          <FormLabel>{l('SOURCE_LANGUAGE')}</FormLabel>
          <SuggestedDropdown
            url={Endpoint.Languages}
            inputId="sourceLanguageUuid"
            initialData={languagesDropdown}
            dropdownLabel="name"
            dropdownValue="uuid"
            dataLocation="resultDataItems"
            orderBy="code"
            order="ASC"
            filter
            resetFilter={resetFilter}
            formikHook={formik}
            style={{ display: 'flex', flexDirection: 'row' }}
          />
        </Box>
        <Box minWidth="150px" m="0 15px">
          <FormLabel>{l('LANGUAGE')}</FormLabel>
          <SuggestedDropdown
            url={Endpoint.Languages}
            inputId="languageUuid"
            error={Boolean(formik.errors.languageUuid)}
            helperText={formik.errors.languageUuid}
            initialData={languagesDropdown}
            dropdownLabel="name"
            dropdownValue="uuid"
            dataLocation="resultDataItems"
            orderBy="code"
            order="ASC"
            filter
            resetFilter={resetFilter}
            formikHook={formik}
            style={{ display: 'flex', flexDirection: 'row' }}
          />
        </Box>
      </Filters>

      <EditTable
        // getRowId={(row) => row.uuid}
        columns={[
          {
            field: 'sourceLanguage',
            headerName: 'Source Language',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.sourceLanguage?.name,
          },
          {
            field: 'language',
            headerName: 'Language',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.language?.name,
          },
          {
            field: 'currency',
            headerName: 'Currency',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.currency?.code + ' ' + params.row?.currency?.name,
          },
          {
            field: 'ratePerWord',
            headerName: 'Rate per Word',
            width: 180,
          },
          {
            field: 'ratePerArticle',
            headerName: 'Rate per Article',
            width: 180,
          },
        ]}
        rows={items}
        loading={isLoading}
        onAdd={addEntryHandler}
        onEdit={editEntryHandler}
        onDelete={deleteEntryHandler}
        sortedFieldIndex={0}
        onSortModelChange={handleSort}
        sortingOrderDirection="desc"
        pagination
        // @ts-ignore
        page={meta?.currentPage - 1 || 0}
        rowCount={meta?.totalItems}
        onPageChange={handlePage}
        pageSize={meta?.itemsPerPage}
        rowsPerPageOptions={[meta.itemsPerPage || 10]}
        paginationMode="server"
        sortingMode="server"
      />
    </PageLayout>
  );
};

export default memo(LanguageRatesPage);
