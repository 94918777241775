import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getChecklistsApi = () =>
  apiCall({
    url: Endpoint.Checklists,
    method: Method.Get,
  });

const sendChecklistsFiltersApi = (params: string) =>
  apiCall({
    url: Endpoint.Checklists,
    method: Method.Get,
    params,
  });

const addChecklistsApi = (data: any) =>
  apiCall({
    url: Endpoint.Checklists,
    method: Method.Post,
    data,
  });

const deleteChecklistsApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.Checklists,
    method: Method.Delete,
    urlSuffix,
  });

const editChecklistsApi = (data: any) => {
  return apiCall({
    url: Endpoint.Checklists,
    method: Method.Put,
    data,
  });
};

export {
  sendChecklistsFiltersApi,
  getChecklistsApi,
  addChecklistsApi,
  editChecklistsApi,
  deleteChecklistsApi,
};
