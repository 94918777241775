import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMessages } from 'types/messagesTypes';

const initialState: IMessages = {
  items: [],
  meta: {},
  hasAnyUnseenMessages: false,
  orderLinesWithUnreadMessages: [],
  currentConversation: [],
  unseenMessagesMarkSeen: false,
  clickedMessages: [],
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    getAllMessageConversations: (state) => state,
    setMessagesConversations: (state, action: PayloadAction<any>) => {
      state.items = action.payload.items;
      state.meta = action.payload.meta;
    },
    getAllMessagesInConversation: (state) => state,
    setAllMessagesInConversation: (state, action: PayloadAction<any>) => {
      state.currentConversation = action.payload;
    },
    sendNewMessage: (state) => state,
    unseenMessagesMarkSeen: (state) => state,
    setUnseenMessagesMarkSeen: (state) => {
      state.unseenMessagesMarkSeen = !state.unseenMessagesMarkSeen;
    },
    hasAnyUnseenMessages: (state) => state,
    setHasAnyUnseenMessages: (state, action: PayloadAction<any>) => {
        state.hasAnyUnseenMessages = action.payload;
    },
    hasUnseenMessagesForGivenOrderLine: (state) =>
    state,
    hasUnseenMessagesOnOrderLines: (state) => state,
    setOrderLinesWithUnreadMessages: (state, action: PayloadAction<any>) => {
      state.orderLinesWithUnreadMessages = action.payload
    },
    setClickedMessages: (state, action: PayloadAction<any>) => {
      if (action.payload.length === 0) {
        state.clickedMessages = initialState.clickedMessages;
      } else {
        state.clickedMessages = [...state.clickedMessages, action.payload];
      }
    },
  },
});
export const actions: any = messagesSlice.actions;
export default messagesSlice.reducer;
