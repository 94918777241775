import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { OrderTypesActionEntry } from 'types/orderTypes';
import { GenericIdNameType } from 'types/genericTypes';
import apiCall from './apiCall';

const getOrderTypesApi = () =>
  apiCall({
    url: Endpoint.OrderTypes,
    method: Method.Get,
  });

const addOrderTypesApi = (data: OrderTypesActionEntry) =>
  apiCall({
    url: Endpoint.OrderTypes,
    method: Method.Post,
    data,
  });

const deleteOrderTypesApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.OrderTypes,
    method: Method.Delete,
    urlSuffix,
  });

const editOrderTypesApi = ({ id, ...data }: GenericIdNameType) => {
  return apiCall({
    url: Endpoint.OrderTypes,
    urlSuffix: id.toString(),
    method: Method.Put,
    data,
  });
};

export {
  getOrderTypesApi,
  addOrderTypesApi,
  editOrderTypesApi,
  deleteOrderTypesApi,
};
