import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { ISendNewMessage } from 'types/messagesTypes';
import apiCall from './apiCall';

// conversations to populate table
const getAllMessageConversationsAPI = (params: any) =>
  apiCall({
    url: Endpoint.MessagingThreads,
    method: Method.Get,
    params,
  }); // done by the BE docs

// right panel with messages in one conversation
const getAllMessagesInConversationAPI = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.MessagingMessages,
    method: Method.Get,
    urlSuffix,
  }); // done by the BE docs
const sendNewMessageAPI = (data: ISendNewMessage) =>
  apiCall({
    url: Endpoint.Messaging,
    method: Method.Post,
    data,
  }); // done
const unseenMessagesMarkSeenAPI = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.UnseenMessagesMarkSeen,
    method: Method.Get,
    urlSuffix, // mark messages in thread as seen for specific order line
  }); // done

const hasAnyUnseenMessagesAPI = () =>
  apiCall({
    url: Endpoint.UnseenMessagesHasUnseenMessages,
    method: Method.Get, // check if employee has any unseen messages
  }); // done
const hasUnseenMessagesForGivenOrderLineAPI = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.UnseenMessagesHasUnseenMessages,
    method: Method.Get,
    urlSuffix, // check if employee has any unseen messages for given order line
  }); // done

const hasUnseenMessagesOnOrderLinesAPI = () =>
  apiCall({
    url: Endpoint.UnseenMessagesUnseenOrderLines,
    method: Method.Get,
  }); // done

export {
  getAllMessageConversationsAPI,
  getAllMessagesInConversationAPI,
  sendNewMessageAPI,
  unseenMessagesMarkSeenAPI,
  hasAnyUnseenMessagesAPI,
  hasUnseenMessagesForGivenOrderLineAPI,
  hasUnseenMessagesOnOrderLinesAPI,
};
