import { makeStyles } from '@material-ui/core/styles';
export const styles = makeStyles((theme) => {
  return {
    EmailTemplateBox: {
      fontFamily: theme.typography.fontFamily,
      backgroundColor: 'white',
      padding: '2rem',
      borderRadius: '15px',
    },
    Lables: {
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'column',
    },
    NonEditable: {
      padding: '7px 0',
      fontWeight: 700,
    },
    TextEditor: {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    TextArea: {
      display: 'flex',
      resize: 'none',
      padding: '1rem',
      borderRadius: '15px',
      backgroundColor: '#f2f2f2',
      width: '100%',
      minHeight: '300px',
      outline: 'none',
      '&:hover': {
        backgroundColor: '#e1e1e1',
      },
    },
    ButtonBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '2rem',
      marginTop: '1rem',
    },
  };
});
