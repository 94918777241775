import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as commonActions } from 'store/slices/common';
import { actions as orderActions } from 'store/slices/orderEntriesSlice';
import { actions as orderLinesDeliveryActions } from 'store/slices/orderLinesDeliverySlice';
import { actions as orderLineStatusesActions } from 'store/slices/orderLineStatusesSlice';
import { actions as languageActions } from 'store/slices/languagesSlice';
import { actions as clientActions } from 'store/slices/clientsSlice';
import { actions as projectActions } from 'store/slices/projectsSlice';
import {
  GridRenderCellParams,
  GridRowParams,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import l from 'helpers/l';
import { EditTable, Filters, PageLayout, Spinner, TextField } from 'components';
import { FormLabel, Grid, IconButton, Stack, Typography } from '@mui/material';
import { RootState } from 'store';
import { useFormik } from 'formik';
import { formatFilters, formatSortOrderBy } from 'helpers/filters';
import { actions as articleTypesActions } from 'store/slices/articleTypesSlice';
import { isEmpty } from 'lodash';
import { ModalType } from 'enums/ModalEnums';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Clear as ClearIcon } from '@mui/icons-material';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { getDateColor } from '../../config/utilsFunctions';
import { numberOfDays } from '../../constants/globalVariables';
import HistoryIcon from '@mui/icons-material/History';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';
import { Endpoint } from 'enums/APIEndpointEnum';
import MultiSelectDropdown from 'components/MultiSelectDropdown/MultiSelectDropdown';
import { useNavigate } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    mainWrapper: {
      '& .MuiBadge-dot': {
        display: 'none',
      },
      '& .hasUnreadMessages.MuiDataGrid-row': {
        '& .MuiBadge-dot': {
          display: 'block',
        },
      },
    },
  }),
);

const OrderDeliveryPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state: RootState) => state.common.loading);
  const { items, meta } = useSelector(
    (state: RootState) => state.orderLinesDelivery,
  );

  const { orderLinesWithUnreadMessages } = useSelector(
    (state: RootState) => state?.messages,
  );

  const [loadingSpinner, setLoadingSpinner] = useState(true); // for showing loader

  const [resetFilter, setResetFilter] = useState<boolean>(false);

  const [firstLoading, setFirstLoading] = useState(true);

  // orderLinesDelivery
  const [orderDateFromFormat, setOrderDateFromFormat] = useState<any>(null);
  const [orderDateToFormat, setOrderDateToFormat] = useState<any>(null);
  const [dropdownValues, setDropdownValues] = useState<any>({});
  const [sort, setSort] = useState({});

  const articleTypesDropdown = useSelector(
    (state: RootState) => state.articleTypes.articleTypes,
  ).map((articleTypes) => ({
    label: articleTypes.name,
    value: articleTypes.uuid,
  }));

  const contactPersonDropdown = useSelector(
    (state: RootState) => state.projects?.contactPersons?.items,
  )?.map(({ nick, uuid }: any) => ({ label: nick, value: uuid }));

  const languagesDropdown = useSelector(
    (state: RootState) => state.languages.items,
  )?.map(({ name, uuid }) => ({ label: name, value: uuid }));

  const clientsDropdown = useSelector(
    (state: RootState) => state.clients.items,
  ).map((cli) => ({ label: cli.name, value: cli.uuid }));

  const orderLineStatusesDropdown = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  ).map((entry: any) => ({ label: entry.name, value: entry.uuid }));

  const orderLineStatusesInitialData = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  ).filter((entry: any) => {
    return (
      //  entry.name === 'PM' &&
      entry.code === 'pm_approved'
    );
  });

  const orderLineStatusesInitialDataUuids = orderLineStatusesInitialData.map(
    (item: any) => item.uuid,
  );

  const initialValues: any = {
    keyword: '',
    orderDateFrom: '',
    orderDateTo: '',
    articleTypeUuid: '',
    clientUuid: '',
    sourceLanguageUuid: '',
    languageUuid: '',
    statusUuids: orderLineStatusesInitialDataUuids,
    contactPersonUuid: '',
  };

  const { setValues, ...formik } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      const formatValues = formatFilters(values);
      const objectForDropdownValues: any = {};
      let orderDateFrom = null;
      let orderDateTo = null;
      let statusUuids = [];
      if (orderDateFromFormat !== null) {
        orderDateFrom = moment(
          new Date(dateFormatter(orderDateFromFormat)),
        ).format('YYYY-MM-DDTHH:mm:ss.sssZ');
        objectForDropdownValues.statusDateFrom = orderDateFrom;
      }
      if (orderDateToFormat !== null) {
        orderDateTo = moment(new Date(dateFormatter(orderDateToFormat))).format(
          'YYYY-MM-DDTHH:mm:ss.sssZ',
        );
        objectForDropdownValues.statusDateTo = orderDateTo;
      }
      if (formik?.values?.statusUuids.length !== 0) {
        statusUuids = formik?.values?.statusUuids;
        objectForDropdownValues.statusUuids = statusUuids;
      }
      setDropdownValues({
        ...formatValues,
        ...objectForDropdownValues,
      });
      if (!isEmpty(formatValues)) {
        const params = {
          limit: 50,
          ...formatValues,
          ...sort,
          statusUuids,
        };
        if (orderDateFrom) {
          params.statusDateFrom = orderDateFrom;
        }
        if (orderDateTo) {
          params.statusDateTo = orderDateTo;
        }
        dispatch(
          orderLinesDeliveryActions.sendOrderLinesDeliveryFilters(params),
        );
      } else {
        dispatch(orderLinesDeliveryActions.sendOrderLinesDeliveryFilters(sort));
      }
    },
  });

  const dateFormatter = (string: any) => {
    if (string !== null) {
      const splitDate = string.split('.');
      return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
    } else return '';
  };

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.OrderDeliveryEditModal,
          data: params.row,
        }),
      );
    },
    [dispatch],
  );

  const handleGoToMessages = useCallback(
    (params: GridRowParams) => {
      navigate(`/messages`, { state: params.id });
    },
    [navigate],
  );

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: formatSortOrderBy(item.field),
        order: item?.sort?.toUpperCase(),
      }))[0];
      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });
      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
        limit: 50,
        ...dropdownValues,
      };
      dispatch(orderLinesDeliveryActions.sendOrderLinesDeliveryFilters(params));
    },
    [dispatch, dropdownValues],
  );

  const handlePage = useCallback(
    (page: number) => {
      const params = { limit: 50, ...dropdownValues, ...sort, page: page + 1 };
      dispatch(orderLinesDeliveryActions.sendOrderLinesDeliveryFilters(params));
    },
    [dispatch, dropdownValues, sort],
  );
  //   const onReset = useCallback(() => {
  //   }, [dispatch, setValues, sort]);

  const onReset = () => {
    setResetFilter(true);
    setValues(formik.initialValues);
    setOrderDateToFormat(null);
    setOrderDateFromFormat(null);
    setDropdownValues({ statusUuids: orderLineStatusesInitialDataUuids });
    dispatch(
      orderLinesDeliveryActions.sendOrderLinesDeliveryFilters({
        ...sort,
        statusUuids: orderLineStatusesInitialDataUuids,
      }),
    );
  };

  const handleClassName = useCallback(
    (params: GridRowParams) => {
      let className = '';

      orderLinesWithUnreadMessages?.map((item) => {
        if (item === params.row.uuid) {
          className = 'hasUnreadMessages';
        }
      });

      return className;
    },
    [orderLinesWithUnreadMessages],
  );

  useEffect(() => {
    dispatch(orderActions.getOrderEntries({}));
    // dispatch(orderLinesDeliveryActions.getOrderLinesDelivery(params));
    dispatch(articleTypesActions.getArticleTypes());
    dispatch(clientActions.getClients());
    dispatch(projectActions.getContactPersons());
    dispatch(languageActions.getLanguages());
    dispatch(orderLineStatusesActions.getOrderLineStatusesManage());
  }, [dispatch]);

  useEffect(() => {
    if (firstLoading && orderLineStatusesInitialDataUuids.length !== 0) {
      const params = {
        limit: 50,
        statusUuids: orderLineStatusesInitialDataUuids,
      };

      dispatch(orderLinesDeliveryActions.sendOrderLinesDeliveryFilters(params));
      setTimeout(() => {
        setFirstLoading(false);
      }, 800);
    }
    setDropdownValues({
      statusUuids: orderLineStatusesInitialDataUuids,
    }); // have default initial status value in this state for handle page & handleSort functions
  }, [formik.values.statusUuids]);

  useEffect(() => {
    setTimeout(() => {
      setLoadingSpinner(false);
    }, 1000);
  }, []);

  useEffect(() => {
    setResetFilter(false);
  }, [resetFilter]);

  const handleOrderDateChange = (value: any, inputValue: any) => {
    //setOrderDate(value);
    setOrderDateFromFormat(() => inputValue);
  };
  const handleOrderDateToChange = (value: any, inputValue: any) => {
    //setOrderDate(value);
    setOrderDateToFormat(() => inputValue);
  };

  const calHistoryOfStatussesModal = useCallback(
    (data) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.StatusHistoryModal,
          data,
        }),
      );
    },
    [dispatch],
  );

  const datePickerStyles = {
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    padding: '9px',
  };

  if (loadingSpinner) {
    return <Spinner title="Please wait..." />;
  }
  return (
    <PageLayout
      title={l('PAGES.ORDER_DELIVERY_SCREEN')}
      fullWidth={true}
      className={classes.mainWrapper}>
      <Filters handleFilters={formik.submitForm} onReset={onReset}>
        <Grid
          container
          spacing={1}
          columns={{ xs: 1, md: 20 }}
          alignItems="center">
          <Grid item xs={2} md={2}>
            <FormLabel>{l('KEYWORD')}</FormLabel>
            <TextField
              autoHeight
              fullWidth
              name="keyword"
              size="small"
              value={formik.values.keyword}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.keyword)}
            />
          </Grid>
          <Grid
            item
            md={2}
            sx={{
              paddingBottom: 4,
            }}>
            <FormLabel>{l('STATUS_DATE_FROM')}</FormLabel>
            <Stack direction={'row'}>
              <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
                <KeyboardDatePicker
                  autoOk={true}
                  showTodayButton={true}
                  value={orderDateFromFormat}
                  name="orderDateFromFormat"
                  inputValue={orderDateFromFormat}
                  onChange={handleOrderDateChange}
                  format="DD.MM.YYYY"
                  style={{
                    ...datePickerStyles,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  error={Boolean(formik.errors.orderDateFrom)}
                  helperText={formik.errors.orderDateFrom}
                />
              </MuiPickersUtilsProvider>
              <IconButton onClick={() => setOrderDateFromFormat(null)}>
                <ClearIcon fontSize={'small'} />
              </IconButton>
            </Stack>
          </Grid>
          <Grid
            item
            md={2}
            sx={{
              paddingBottom: 4,
            }}>
            <FormLabel>{l('STATUS_DATE_TO')}</FormLabel>
            <Stack direction={'row'}>
              <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
                <KeyboardDatePicker
                  autoOk={true}
                  showTodayButton={true}
                  name="orderDateToFormat"
                  value={orderDateToFormat}
                  inputValue={orderDateToFormat}
                  onChange={handleOrderDateToChange}
                  format="DD.MM.YYYY"
                  style={{
                    ...datePickerStyles,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  error={Boolean(formik.errors.orderDateTo)}
                  helperText={formik.errors.orderDateTo}
                />
              </MuiPickersUtilsProvider>
              <IconButton onClick={() => setOrderDateToFormat(null)}>
                <ClearIcon fontSize={'small'} />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={2} md={2}>
            <FormLabel>{l('COMMON.STATUS')}</FormLabel>
            <MultiSelectDropdown
              initialData={orderLineStatusesDropdown}
              inputId="statusUuids"
              name="statusUuids"
              defaultValue={formik?.values?.statusUuids}
              inputValue={formik?.values?.statusUuids}
              formikHook={formik}
            />
          </Grid>
          <Grid item xs={2} md={2}>
            <FormLabel>{l('ARTICLE_TYPE')}</FormLabel>
            <SuggestedDropdown
              url={Endpoint.ArticleTypes}
              inputId="articleTypeUuid"
              initialData={languagesDropdown}
              value={formik.values.articleTypeUuid}
              dropdownLabel="name"
              dropdownValue="uuid"
              dataLocation="resultData"
              formikHook={formik}
              filter
              resetFilter={resetFilter}
            />
          </Grid>
          <Grid item xs={2} md={3}>
            <FormLabel>{l('SOURCE_LANGUAGE')}</FormLabel>
            <SuggestedDropdown
              url={Endpoint.Languages}
              inputId="sourceLanguageUuid"
              initialData={languagesDropdown}
              value={formik.values.sourceLanguageUuid}
              dropdownLabel="name"
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              formikHook={formik}
              // style={{ width: '100%' }}
              filter
              resetFilter={resetFilter}
            />
          </Grid>
          <Grid item xs={2} md={2}>
            <FormLabel>{l('LANGUAGE')}</FormLabel>
            <SuggestedDropdown
              url={Endpoint.Languages}
              inputId="languageUuid"
              initialData={languagesDropdown}
              inputValue={formik?.values.languageUuid}
              dropdownLabel="name"
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              formikHook={formik}
              style={{ width: '100%' }}
              filter
              resetFilter={resetFilter}
            />
          </Grid>
          <Grid item xs={2} md={2}>
            <FormLabel>{l('COMMON.CLIENT')}</FormLabel>
            <SuggestedDropdown
              url={Endpoint.Clients}
              inputId="clientUuid"
              initialData={clientsDropdown}
              inputValue={formik?.values.clientUuid}
              dropdownLabel="internalName"
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              error={Boolean(formik.errors.clientUuid)}
              helperText={formik.errors.clientUuid}
              formikHook={formik}
              style={{ width: '100%' }}
              filter
              resetFilter={resetFilter}
            />
          </Grid>
          <Grid item xs={2} md={2}>
            <FormLabel>{l('CONTACT_PERSON')}</FormLabel>
            <SuggestedDropdown
              url={Endpoint.ContactPersons}
              inputId="contactPersonUuid"
              initialData={contactPersonDropdown}
              inputValue={formik?.values.contactPersonUuid}
              dropdownLabel={'nick'}
              //  dropdownLabel={['firstName', 'lastName']}
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              error={Boolean(formik.errors.contactPersonUuid)}
              helperText={formik.errors.contactPersonUuid}
              formikHook={formik}
              style={{ width: '100%' }}
              filter
              resetFilter={resetFilter}
            />
          </Grid>
        </Grid>
      </Filters>
      <EditTable
        getRowId={(row) => row.uuid}
        columns={[
          {
            field: 'client',
            headerName: 'Client',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
              params.row.order.contactPerson.client.internalName,
          },
          {
            field: 'contactPerson',
            headerName: 'Contact Person',
            valueGetter: (params: GridValueGetterParams) => {
              return params.row.order.contactPerson.nick || '';
            },
            width: 200,
          },
          {
            field: 'order',
            headerName: 'Order',
            width: 100,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.order?.id,
          },
          {
            field: 'orderLine',
            headerName: 'Line #',
            width: 100,
            valueGetter: (params: GridValueGetterParams) => {
              return params.row?.lineNumber;
            },
          },
          {
            field: 'status',
            headerName: 'Status',
            width: 180,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  color={params.row?.lastStatusLogEntry?.orderLineStatus?.color}
                  fontWeight={600}>
                  {params.row?.lastStatusLogEntry?.orderLineStatus?.name}
                  <span
                    onClick={() => {
                      calHistoryOfStatussesModal({
                        uuid: params.row?.uuid,
                        orderNumber: params.row?.order?.id,
                        lineNumber: params?.row?.lineNumber,
                      });
                    }}>
                    <HistoryIcon
                      fontSize="medium"
                      sx={{ color: '#aaa', marginLeft: '10px' }}
                    />
                  </span>
                </Typography>
              );
            },
          },
          {
            field: 'statusDate',
            headerName: 'Status Date',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row?.lastStatusLogEntry?.createdAt).format(
                'DD.MM.YYYY. HH:mm',
              ),
          },
          {
            field: 'sourceLanguage',
            headerName: 'Source Language',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.sourceLanguage?.name,
          },
          {
            field: 'language',
            headerName: 'Language',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.language?.name,
          },
          {
            field: 'articleType',
            headerName: 'Article Type',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.articleType?.name,
          },
          // TODO TOPIC
          {
            field: 'articleTopic',
            headerName: 'articleTopic',
            width: 180,
          },
          {
            field: 'quantity',
            headerName: 'Quantity',
            width: 180,
          },
          {
            field: 'pmEmployee',
            headerName: 'Project Manager',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.pmEmployee?.firstName,
          },
          {
            field: 'orderDate',
            headerName: 'Order Date',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row?.order?.orderedAt).format('DD.MM.YYYY hh:mm'),
          },
          {
            field: 'dueDatetime',
            headerName: 'Line Due Date',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row?.dueDatetime).format('DD.MM.YYYY hh:mm'),
          },
          {
            field: 'dateCritical',
            headerName: 'Due Date Critical',
            width: 180,
          },
          {
            field: 'pmDueDateTime',
            headerName: 'PM Due Date',
            width: 180,
            renderCell: (params: GridRenderCellParams) => (
              <Typography
                color={getDateColor(params.row?.pmDueDateTime, numberOfDays)}
                fontWeight={600}>
                {moment(params.row?.pmDueDateTime).format('DD.MM.YYYY. HH:mm')}
              </Typography>
            ),
          },
          {
            field: 'writerLevel',
            headerName: 'Writer Level',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.order?.contactPerson?.client?.writerLevel,
          },

          {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row.updatedAt).format('DD.MM.YYYY hh:mm'),
          },
        ]}
        rows={items}
        loading={loading}
        onEdit={editEntryHandler}
        onMessage={handleGoToMessages}
        getRowClassName={handleClassName}
        editColumnWidth={130}
        sortedFieldIndex={17}
        sortingOrderDirection="desc"
        onSortModelChange={handleSort}
        pagination
        page={meta.currentPage! - 1 || 0}
        rowCount={meta?.totalItems}
        onPageChange={handlePage}
        pageSize={50}
        rowsPerPageOptions={[50]}
        paginationMode="server"
        sortingMode="server"
      />
    </PageLayout>
  );
};

export default memo(OrderDeliveryPage);
