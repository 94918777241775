import { FC, memo, useCallback } from 'react';
import { actions as rolesActions } from 'store/slices/user';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import GenericCodeNameForm from 'components/Forms/GenericCodeNameForm';
import { GenericCodeNameType } from 'types/genericTypes';

const RoleAddModal: FC<GenericModalProps> = (props) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: GenericCodeNameType) => {
      dispatch(rolesActions.addRole(values));
    },
    [dispatch],
  );

  return (
    <GenericCodeNameForm
      title={l('ADD_NEW_ROLE')}
      nameLabel={l('NAME')}
      onSubmit={submitHandler}
      {...props}
    />
  );
};

export default memo(RoleAddModal);
