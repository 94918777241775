import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { styles } from './ErrorHandlerStyles';

interface Props {
  statusCode?: number | string;
}

const ErrorHandler: FC<Props> = ({ statusCode = 403 }) => {
  const { root, box } = styles();
  if (statusCode === 403)
    return (
      <Box className={root}>
        <Box className={box}>
          <Typography variant="h2">Loading...</Typography>
        </Box>
      </Box>
    );
  else
    return (
      <Box className={root}>
        <Box className={box}>
          <Typography variant="h2">Error code: {statusCode ?? '400'}</Typography>
          <Typography paragraph>
            {statusCode === 401 && 'Invalid authentication token'}
            {statusCode === 403 && 'Access denied'}
            {statusCode === 404 && "Page doesn't exist"}
          </Typography>
        </Box>
      </Box>
    );
};

export default ErrorHandler;
