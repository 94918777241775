import { FC, memo, useCallback, useEffect } from 'react';
import { actions as userActions } from 'store/slices/user';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import RolesForm from 'components/Forms/EmployeeViewRolesForm';
import { RootState } from 'store';
import { createMenuItems } from 'helpers/dropdown';

const EmployeeViewRoleAddModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();
  const userRoles = useSelector((state: RootState) => state.user.userRoles).map(
    (role: any) => role.role.name,
  );
  const rolesDropdown = useSelector((state: RootState) => state.user.roles)
    .map(({ name, code }) => {
      return { label: name, value: code };
    })
    .filter((role) => {
      if (!userRoles.includes(role.label)) return role;
      return null;
    });
  const values = createMenuItems(rolesDropdown);

  useEffect(() => {
    dispatch(userActions.getRoles());
  }, [dispatch]);

  const submitHandler = useCallback(
    (values: any) => {
      dispatch(
        userActions.assignRole({
          ...values,
          employeeUuid: data.id,
        }),
      );
    },
    [dispatch, data.id],
  );

  return data ? (
    <RolesForm
      title={'Add Role'}
      employeeName={data.name}
      onSubmit={submitHandler}
      type="add"
      values={values}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(EmployeeViewRoleAddModal);
