import React, {FC, memo, Suspense, useEffect} from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import {useDispatch, useSelector} from 'react-redux';
import {actions as rolesActions} from 'store/slices/rolesSlice';
import {RootState} from 'store';
import {actions as orderLineStatusesActions} from 'store/slices/orderLineStatusesSlice';
import l from 'helpers/l';
import {Goback, Spinner} from 'components';
import {useLocation} from "react-router-dom";
import {Grid} from "@mui/material";
import styles from "./stylesAssign";

const CanSeeCell = React.lazy(() => import('./OrderLineStatusRoleCanSeeCell'));
const CanSetCell = React.lazy(() => import('./OrderLineStatusRoleCanSetCell'));
const CanChangeCell = React.lazy(
  () => import('./OrderLineStatusTransitionsCell'),
);

const OrderLineStatusRoles: FC = () => {
  styles();
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const availableOrderLineStatuses = useSelector(
    (state: RootState) => state.orderLineStatuses.orderLineStatuses,
  );

  const {state} = useLocation();
  const statusUuid = state;
  const selectedOrderLineStatus = availableOrderLineStatuses.find((item: any) => item.uuid === statusUuid)

  useEffect(() => {
    dispatch(orderLineStatusesActions.getOrderLineStatusesManage());
    dispatch(rolesActions.getRoles());
  }, [dispatch]);

  const pageTitle = l('PAGES.ORDER_LINE_STATUSES_ASSIGN') + ': ' + selectedOrderLineStatus?.name || ''

  return (
    <PageLayout title={pageTitle} fullWidth={true}>
      {isLoading && (
        <Spinner title={''}/>
      )}
      <Goback text="back to list" location={'/order-line-statuses'} />
      <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          columnSpacing={2}
      >
        <Grid container item>
          <Suspense fallback={<Spinner title="" />}>
            <CanSeeCell {...selectedOrderLineStatus} />
          </Suspense>
        </Grid>

        <Grid  container item>
          <Suspense fallback={<Spinner title="" />}>
            <CanSetCell {...selectedOrderLineStatus} />
          </Suspense>
        </Grid>
        <Grid container item>
          <Suspense fallback={<Spinner title="" />}>
            <CanChangeCell {...selectedOrderLineStatus} />
          </Suspense>
        </Grid>
      </Grid>
      {/*<OrderRolesDataGrid
        rows={availableOrderLineStatuses}
        columns={columns}
        getRowId={(row) => row.uuid}
        // loading={isLoading}
        pageSize={availableOrderLineStatuses.length || 100}
        hideFooter={true}
      />*/}
    </PageLayout>
  );
};

export default memo(OrderLineStatusRoles);
