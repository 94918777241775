import { MenuItem } from '@mui/material';

interface Dropdown {
  label: string;
  value?: string | number;
}

export const createMenuItems = (data: Dropdown[]) => {
  return data.map((item) => {
    return (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    );
  });
};
