import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/experiseSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  addExpertiseApi,
  deleteExpertiseApi,
  editExpertiseApi,
  getExpertiseApi, sendExpertiseFiltersApi,
} from 'store/api/expertiseAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import {ExpertiseActionsEntry, ExpertiseResult} from 'types/expertiseTypes';
import { GenericIdNameType } from 'types/genericTypes';
import { APIResult } from 'types/APITypes';
import {genericErrorMessageHelper} from "../../helpers/messageHelpers";

function* getExpertise() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<ExpertiseResult> = yield call(
    getExpertiseApi,
  );

  if (result?.data) {
    yield put(actions.setExpertise(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* sendExpertiseFilters(action: PayloadAction<string>) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(sendExpertiseFiltersApi, params);

  if (result?.data) {
    yield put(actions.setExpertise(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addExpertise(action: PayloadAction<any>) {
  const data = action.payload;
  const clonedData = {...data};
  delete data.meta;

  const { result, error }: APIResult<ExpertiseActionsEntry> = yield call(
    addExpertiseApi,
    data,
  );
  if (result?.status === 201) {
    const pageParams = {page: clonedData.meta.currentPage}
    const { result } = yield call(sendExpertiseFiltersApi, pageParams);

    if (result?.data) {
      yield put(actions.setExpertise(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.name} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.name} already exists`,
        severity: 'error',
      }),
    );
  }
}

function* deleteExpertise(action: PayloadAction<GenericIdNameType>) {
  const { id, name } = action.payload;
  const { result, error }: APIResult<GenericIdNameType> = yield call(
    deleteExpertiseApi,
    id! as string,
  );
  if (error?.status === 400) {
    yield put(
        commonActions.snackBar({
          open: true,
          message: 'This item can not be deleted due to existing relations',
          severity: 'error',
        }),
    );
  } else if (error) {
    yield put(
        commonActions.snackBar({
          open: true,
          message: genericErrorMessageHelper(error),
          severity: 'error',
        }),
    );
  }
  if (result?.status === 200) {
    const { result }: APIResult<ExpertiseResult> = yield call(
      getExpertiseApi,
    );
    if (result?.data) {
      yield put(actions.setExpertise(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
  }

  yield put(commonActions.hideModal());
}

function* editExpertise(action: PayloadAction<GenericIdNameType>) {
  const { name, id } = action.payload;

  const { result, error }: APIResult<GenericIdNameType> = yield call(
    editExpertiseApi,
    { id, name },
  );
  if (result?.status === 200) {
    const { result }: APIResult<ExpertiseResult> = yield call(
      getExpertiseApi,
    );
    if (result?.data) {
      yield put(actions.setExpertise(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Expertise name is taken!',
        severity: 'error',
      }),
    );
  }
}

export default function* expertiseWatcher() {
  yield takeLatest(actions.getExpertise.type, getExpertise);
  yield takeLatest(actions.sendExpertiseFilters.type, sendExpertiseFilters);
  yield takeLatest(actions.addExpertise.type, addExpertise);
  yield takeLatest(actions.deleteExpertise.type, deleteExpertise);
  yield takeLatest(actions.editExpertise.type, editExpertise);
}
