import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationMeta } from 'types/genericTypes';

export interface ContactPersons {
  items: any[];
  meta: PaginationMeta;
}

const initialState: ContactPersons = {
  items: [],
  meta: {},
};

export const contactPersonsSlice = createSlice({
  name: 'contactPersons',
  initialState,
  reducers: {
    getContactPersonsList: (state) => state,
    setContactPersonsList: (state, action: any) => {
      state.items = action.payload.items;
      state.meta = action.payload.meta;
    },
    editContactPerson: (state) => state,
    deleteContactPerson: (state) => state,
    sendContactPersonsFilters: (state) => state,
    setContactPersonsTableData: (state, action: PayloadAction<any>) => {
      return { ...state, tableData: action.payload };
    },
    addContactPersons: (state) => state,
  },
});
export const actions: any = contactPersonsSlice.actions;
export default contactPersonsSlice.reducer;
