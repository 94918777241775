import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContentWrapper, EditTable, PageLayout } from 'components';
import { RootState } from '../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { actions as reportsSlice } from '../../store/slices/reportSlices/reportsSlice';
import { Button, Grid, Paper, Typography } from '@mui/material';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

function ReportResultsPage() {
    const dispatch = useDispatch();
    const { reportUuid } = useParams();
    const navigate = useNavigate();
    const executedReportResults = useSelector((state: RootState) => state.reports.executedReportItems);
    const executedReportMeta = useSelector((state: RootState) => state.reports.executedReportMeta);
    const report = useSelector((state: RootState) => state.reports.executedReport);

    const columns = () => {
        let formattedColumns: any[] = [];
        if (executedReportResults.length > 0) {

            Object.keys(executedReportResults[0]).forEach((field: any) => {
                formattedColumns.push(
                  {
                      field: `${field}`,
                      headerName: `${field.charAt(0).toUpperCase() + field.slice(1)}`,
                      width: 250,
                  },
                )
            })
        }
        return formattedColumns;
    }

    const handleExportToPDF = useCallback(
      async () => {
          if (report.uuid) {
              await dispatch(reportsSlice.exportPDF({urlSuffix: `${report.uuid}`, data: { parameters: report.parameters } }))
          }
      },
      [dispatch],
    );

    const handleExportToXLSX = useCallback(
      async () => {
          if (report.uuid) {
              dispatch(reportsSlice.exportXLSX({urlSuffix: `${report.uuid}`, data: { parameters: report.parameters } }))
          }
      },
      [dispatch],
    );

    const handleNavigateOnClick = () => {
        navigate(`/run_report/${reportUuid}`)
    }

    const handleSaveReport = () => {
        const objWithParameters: any = {
            parameters: [],
        };

        report.parameters.forEach((parameter: any) => {
            const objToPush = {
                reportParameterUuid: parameter.reportParameterUuid,
                value: parameter.value,
            }

            objWithParameters.parameters.push(objToPush);
        })
        const objToSend = {
            reportUuid: report.uuid,
            parameters: objWithParameters.parameters,
        }
        dispatch(reportsSlice.saveReport(objToSend));
    }

    const handlePage = useCallback(
      (page: number) => {
          let arrayToSend: any[] = [];
          if (report?.parameters) {
              report?.parameters?.forEach((param: any) => {
                  arrayToSend.push(
                    {
                        value: param.value || '',
                        reportParameterUuid: param.reportParameterUuid || '',
                    },
                  );
              });

          }

          const params = { page: page + 1, urlSuffix: `${report?.uuid}/execute`, parameters: arrayToSend};
          dispatch(reportsSlice.sendExecutedReportFilters(params));
      },
      [dispatch, report],
    );

    useEffect(() => {
      if(!report) navigate(`/run_report/${reportUuid}`)
    },[report])

    return (
      <PageLayout title={'Report results' + ': ' + report?.name} fullWidth={true}>
          <Grid container flexDirection='row'>
              <Grid item xs={12} sx={{marginBottom: '19px'}}>
                  <Paper>
                      <Grid item padding={'11px 22px 22px'}>
                          <Typography fontWeight={900} fontSize={18} marginBottom='22px'>
                              {'Parameter Values'}
                          </Typography>
                          <Grid flexDirection={'row'} container spacing={6}>
                              {
                                  report?.parameters?.map((param: any) => {
                                      return (
                                        <Grid item key={param.reportParameterUuid}>
                                            <Typography fontSize={13} marginBottom='5px'>
                                                {moment(param.value, moment.ISO_8601, true).isValid() && !param.parameterOptions?.parameterName || param.parameterOptions?.parameterName === '' ? 'Date' : param.parameterOptions?.parameterName ? param.parameterOptions?.parameterName : param.name}
                                            </Typography>
                                            <Typography fontWeight={900} fontSize={13} marginBottom='22px'>
                                                {moment(param.value, moment.ISO_8601, true).isValid() ? moment(param.value).format('DD.MM.YYYY') : param.value}
                                            </Typography>
                                        </Grid>
                                      )
                                  })
                              }
                              <Grid item alignItems={'flex-end'} sx={{marginLeft: 'auto'}}>
                                  <Button variant={'outlined'} color={'inherit'}
                                          sx={{borderRadius: '5px', width: '140px', height: '40px'}}
                                          onClick={handleNavigateOnClick}>
                                      Change
                                  </Button>
                              </Grid>
                          </Grid>
                      </Grid>
                  </Paper>
              </Grid>
              {executedReportResults?.length > 0 ?
                (<ContentWrapper width='100%'>
                    <Grid item xs={12} sx={{marginBottom: '19px'}}>
                        <EditTable
                          rows={executedReportResults.length > 0 ? executedReportResults : []}
                          columns={executedReportResults.length > 0 ? columns() : []}
                          getRowId={(row) => {
                              const rowObj = { ...row };
                              rowObj['customUuid'] = uuidv4();
                              return rowObj.customUuid;
                          }}
                          //hideFooter
                          editColumnWidth={0}
                          sortingOrderDirection="desc"
                          pagination
                          page={executedReportMeta?.currentPage - 1 || 0}
                          rowCount={executedReportMeta?.totalItems}
                          onPageChange={handlePage}
                          pageSize={executedReportMeta?.itemsPerPage}
                          rowsPerPageOptions={[executedReportMeta.itemsPerPage || 10]}
                          paginationMode="server"
                          sortingMode="server"
                        />
                    </Grid>
                    <Grid container flexDirection='row' sx={{paddingLeft: '29px'}} spacing={2}>
                        <Grid item>
                            <Button
                              sx={{
                                  backgroundColor: '#1e98d7',
                                  borderRadius: '5px',
                                  width: '140px',
                                  height: '40px',
                              }}
                              variant="contained"
                              onClick={handleSaveReport}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                              sx={{
                                  backgroundColor: '#1e98d7',
                                  borderRadius: '5px',
                                  width: '140px',
                                  height: '40px',
                              }}
                              variant="contained" onClick={() => handleExportToPDF()}>
                                Export to PDF
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                              sx={{
                                  backgroundColor: '#1e98d7',
                                  borderRadius: '5px',
                                  width: '140px',
                                  height: '40px',
                              }}
                              variant="contained" onClick={() => handleExportToXLSX()}>
                                Export to XLSX
                            </Button>
                        </Grid>
                    </Grid>
                </ContentWrapper>) : (
                  <ContentWrapper width='100%' sx={{alignItems: 'center'}}>
                      <Typography fontWeight={900} fontSize={18}>
                          {'No results'}
                      </Typography>
                  </ContentWrapper>)
              }
          </Grid>

      </PageLayout>
    );
}

export default memo(ReportResultsPage);
