import { FC, memo } from 'react';
import { useDispatch } from 'hooks/redux';
import { actions as userActions } from 'store/slices/user';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import GenericNameForm from 'components/Forms/GenericNameForm';

const EmployeesCreateModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();
  const {
    user: { uuid: urlSuffix, username },
    uuid,
    firstName,
    lastName,
  } = data;

  const submitHandler = ({ name }: { name: string }) => {
    const data = { username: name, urlSuffix, id: uuid };
    dispatch(userActions.editEmployeeUsername(data));
  };
  const initialValues = {
    name: username,
  };
  return (
    <GenericNameForm
      title={'Employee Change Username'}
      formEntity={l('PAGES.EMPLOYEE')}
      formEntityText={`${firstName} ${lastName}`}
      inputFieldLabel={l('USERNAME')}
      onSubmit={submitHandler}
      initialValues={initialValues}
      {...rest}
    />
  );
};

export default memo(EmployeesCreateModal);
