import { FC, memo, useCallback } from 'react';
import { actions as orderLineStatusesActions } from 'store/slices/orderLineStatusesSlice';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import OrderLineStatusesForm from 'components/Forms/OrderLineStatusesForm';
import { OrderLineStatusActionEntry } from 'types/orderLineStatusesTypes';

const OrderTypeAddModal: FC<GenericModalProps> = (props) => {
  const dispatch = useDispatch();

  const defaultValues: OrderLineStatusActionEntry = {
    name: '',
    needsDescription: false,
    isActive: false,
    isSendMail: false,
    isSendNotification: false,
    color: '#000000',
  };

  const submitHandler = useCallback(
    (values: OrderLineStatusActionEntry) => {
      dispatch(orderLineStatusesActions.addOrderLineStatuses(values));
    },
    [dispatch],
  );

  return (
    <OrderLineStatusesForm
      onSubmit={submitHandler}
      initialValues={defaultValues}
      title={l('ORDER_LINE_STATUSES.MODAL_ADD_TITLE')}
      {...props}
    />
  );
};

export default memo(OrderTypeAddModal);
