import { FC, memo, useCallback } from 'react';
import { actions as expertiseActions } from 'store/slices/experiseSlice';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import EmployeeStatusesForm from 'components/Forms/EmployeeStatusesForm';
import { ExpertiseActionsEntry } from 'types/expertiseTypes';
import {useSelector} from "react-redux";
import {RootState} from "../../../store";

const ExpertiseAddModal: FC<GenericModalProps> = (props) => {
  const dispatch = useDispatch();
  const {meta} = useSelector(
      (state: RootState) => state.expertise,
  );
  const submitHandler = useCallback(
    (values: ExpertiseActionsEntry) =>{
      const newValue = {
        ...values,
        meta: meta,
      }
      dispatch(expertiseActions.addExpertise(newValue));
    },[dispatch]);

  return (
    <EmployeeStatusesForm
      title={l('ADD_NEW_EXPERTISE')}
      onSubmit={submitHandler}
      {...props}
    />
  );
};

export default memo(ExpertiseAddModal);
