import { FC, memo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormLabel, Typography } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps, GenericNameType } from 'types/genericTypes';

const defaultValues: GenericNameType = {
  name: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

type GenericNameProps = {
  title: string;
  inputFieldLabel: string;
  formEntity: string;
  formEntityText: string;
  initialValues?: GenericNameType;
  onSubmit: (values: GenericNameType) => void;
} & GenericModalProps;

const GenericNameForm: FC<GenericNameProps> = ({
  onClose,
  inputFieldLabel = '',
  formEntity = '',
  formEntityText = '',
  title,
  initialValues = defaultValues,
  onSubmit,
}) => {
  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: GenericNameType) => {
      onSubmit(values);
    },
  });

  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <FormLabel>{formEntity}</FormLabel>
      <Box mb="30px">
        <Typography fontWeight={600}>{formEntityText}</Typography>
      </Box>
      <FormLabel>{inputFieldLabel}</FormLabel>
      <TextField
        name="name"
        fullWidth
        size="small"
        value={formik.values.name}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.name)}
        helperText={formik.errors.name}
        pressEnter={formik.submitForm}
      />
    </SimpleFormModal>
  );
};

export default memo(GenericNameForm);
