import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageLayout } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { actions as orderLineActions } from 'store/slices/orderLinesSlice';
import l from 'helpers/l';
import OrderLinesForm from 'components/Forms/OrderLinesForm';
import { RootState } from 'store';
import { actions as articleTypesActions } from 'store/slices/articleTypesSlice';

export type OrderLineAddPageProps = {
  type: 'add' | 'duplicate';
};

const OrderLineAddPage: FC<OrderLineAddPageProps> = ({ type }) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const articleTypesDropdown = useSelector(
    (state: RootState) => state?.articleTypes?.articleTypes,
  ).map((entry) => ({ label: entry.name, value: entry.uuid }));

  const orderLineDetails = useSelector(
    (state: RootState) => state?.orderLines?.item,
  );

  const handleSubmit = ({ dueDate, pmDueDate, ...values }: any) => {
    const data = {
      orderUuid: id,
      ...values,
    };

    if (type === 'duplicate') {
      dispatch(orderLineActions.duplicateOrderLine({ ...data, navigate }));
    } else {
      dispatch(orderLineActions.addLine({ ...data, navigate }));
      if (type === 'add') {
        dispatch(orderLineActions.setOrderLineEmpty());
      }
    }
  };

  useEffect(() => {
    dispatch(articleTypesActions.getArticleTypes());
    if (!location?.state?.hasLocation) {
      if (type === 'add') {
        dispatch(orderLineActions.setOrderLineEmpty());
      }
    }
  }, [dispatch, location]);

  const handleCancel = () => {
    dispatch(orderLineActions.setOrderLineEmpty());
    navigate(-1);
  };

  const [initials, setInitials] = useState(null);

  useEffect(() => {
    if (Object.keys(orderLineDetails).length > 0) {
      setInitials({
        orderLineStatusUuid:
          orderLineDetails?.lastStatusLogEntry?.orderLineStatus?.uuid,
        clientReference: orderLineDetails?.clientReference,
        orderLineUuid: orderLineDetails?.order?.uuid,
        uuid: orderLineDetails?.uuid,
        articleTypeUuid: orderLineDetails?.articleType?.uuid,
        articleType: orderLineDetails?.articleType?.name,
        sourceLanguageUuid: orderLineDetails?.sourceLanguage?.uuid,
        sourceLanguage: orderLineDetails?.sourceLanguage?.name,
        languageUuid: orderLineDetails?.language?.uuid,
        language: orderLineDetails?.language?.name,
        quantity: orderLineDetails?.quantity,
        dueDate: orderLineDetails?.dueDatetime,
        dateCritical: orderLineDetails?.dateCritical,
        pmEmployeeUuid: orderLineDetails?.pmEmployee?.uuid,
        pmEmployee: orderLineDetails?.pmEmployee?.name,
        pmDueDate: orderLineDetails?.pmDueDateTime,
        clientInstructionsUrl: orderLineDetails?.clientInstructionsUrl,
        instructions: orderLineDetails?.instructions,
        isNoEdit: orderLineDetails?.isNoEdit,
        projectUuid: orderLineDetails?.project?.uuid,
        project: orderLineDetails?.project?.name,
      });
    }
  }, [orderLineDetails]);

  return (
    <PageLayout title={l('PAGES.ORDER_LINE_ADD')} fullWidth={true}>
      {type === 'duplicate' && initials && (
        <OrderLinesForm
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          articleTypesDropdown={articleTypesDropdown}
          initialValues={initials}
          type={type}
        />
      )}
      {type === 'add' && (
        <OrderLinesForm
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          articleTypesDropdown={articleTypesDropdown}
          type={type}
        />
      )}
    </PageLayout>
  );
};

export default memo(OrderLineAddPage);
