import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getChecklistsContactPersonsApi = () =>
  apiCall({
    url: Endpoint.ChecklistsContactPersons,
    method: Method.Get,
  });

const sendChecklistsContactPersonsFiltersApi = (params: string) =>
  apiCall({
    url: Endpoint.ChecklistsContactPersons,
    method: Method.Get,
    params,
  });

const addChecklistsContactPersonsApi = (data: any) =>
  apiCall({
    url: Endpoint.ChecklistsContactPersons,
    method: Method.Post,
    data,
  });

const deleteChecklistsContactPersonsApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.ChecklistsContactPersons,
    method: Method.Delete,
    urlSuffix,
  });

const editChecklistsContactPersonsApi = (data: any) => {
  return apiCall({
    url: Endpoint.ChecklistsContactPersons,
    method: Method.Put,
    data,
  });
};

export {
  sendChecklistsContactPersonsFiltersApi,
  getChecklistsContactPersonsApi,
  addChecklistsContactPersonsApi,
  editChecklistsContactPersonsApi,
  deleteChecklistsContactPersonsApi,
};
