import { FC } from 'react';
import { Badge, IconButton } from '@mui/material';
import {
  DataGridProps,
  GridActionsCellItem,
  GridEnrichedColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import {
  AddCircle as AddCircleIcon,
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  EmailOutlined,
  Preview as PreviewIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import Table from './Table';
import { useDispatch } from 'react-redux';
import * as styles from '../Header/HeaderStyles';

type EditFnType = (params: GridRowParams) => void;
type AddFnType = (data: any) => void;

type EditTableProps = DataGridProps & {
  onDelete?: EditFnType;
  onEdit?: EditFnType;
  onView?: EditFnType;
  onExecute?: EditFnType;
  onAdd?: AddFnType;
  onCopy?: AddFnType;
  onMessage?: AddFnType;
  settingsButton?: EditFnType;
  editColumnWidth?: number;
  sortedFieldIndex?: number;
  sortingOrderDirection?: 'asc' | 'desc';
  unread?: boolean;
};

const EditTable: FC<EditTableProps> = ({
  onDelete,
  onEdit,
  onAdd,
  onView,
  onCopy,
  onMessage,
  onExecute,
  settingsButton,
  editColumnWidth,
  columns = [],
  sortedFieldIndex = 0,
  sortingOrderDirection = 'asc',
  unread,
  ...rest
}) => {
  useDispatch();
  const editColumn: GridEnrichedColDef = {
    field: 'icons',
    type: 'actions',
    ...(editColumnWidth && { width: editColumnWidth }),
    ...(onEdit || onDelete || onView || onCopy || settingsButton || onExecute
      ? {
          getActions: (params: GridRowParams) =>
            [
              onMessage && (
                //@ts-ignore
                <GridActionsCellItem
                  showInMenu={false}
                  icon={
                    <Badge
                      variant={'dot'}
                      sx={styles.NotificationStyles}
                      component={() => <EmailOutlined sx={{ color: '#aaa' }} />}
                    />
                  }
                  onClick={() => onMessage(params)}
                  label="Message"
                />
              ),
              onView && (
                //@ts-ignore
                <GridActionsCellItem
                  showInMenu={false}
                  icon={<PreviewIcon color="secondary" />}
                  label="Preview"
                  onClick={() => onView(params)}
                />
              ),
              onEdit && (
                //@ts-ignore
                <GridActionsCellItem
                  showInMenu={false}
                  icon={<EditIcon color="secondary" />}
                  onClick={() => onEdit(params)}
                  label="Edit"
                />
              ),
              settingsButton && (
                //@ts-ignore
                <GridActionsCellItem
                  showInMenu={false}
                  icon={<SettingsIcon color="secondary" />}
                  onClick={() => settingsButton(params)}
                  label="Settings"
                />
              ),
              onCopy && (
                //@ts-ignore
                <GridActionsCellItem
                  showInMenu={false}
                  icon={<ContentCopyIcon color="secondary" />}
                  onClick={() => onCopy(params)}
                  label="Copy"
                />
              ),
              onExecute && (
                //@ts-ignore
                <GridActionsCellItem
                  icon={
                    <FlashOnIcon
                      color={
                        params?.row?.reportParameters?.length > 0
                          ? 'primary'
                          : 'secondary'
                      }
                    />
                  }
                  onClick={() => {
                    onExecute(params);
                  }}
                  label="Execute"
                  showInMenu={false}
                />
              ),
              !params?.row?.isFixed && onDelete && (
                //@ts-ignore
                <GridActionsCellItem
                  showInMenu={false}
                  icon={<DeleteIcon color="secondary" />}
                  label="Delete"
                  onClick={() => onDelete(params)}
                />
              ),
            ].filter(Boolean) as JSX.Element[],
        }
      : {
          getActions: () => [],
        }),
    ...(onAdd && {
      renderHeader: () => (
        <IconButton onClick={onAdd} component="button">
          <AddCircleIcon color="secondary" />
        </IconButton>
      ),
    }),
    headerAlign: 'left',
    align: 'left',
  };

  return (
    <Table
      columns={[editColumn, ...columns]}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: columns[sortedFieldIndex].field,
              sort: sortingOrderDirection,
            },
          ],
        },
      }}
      {...rest}
    />
  );
};

export default EditTable;
