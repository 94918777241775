import { FC, memo, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormLabel, Typography } from '@mui/material';
import { TextField } from 'components';
import l from 'helpers/l';
import InputWrapper from 'components/containers/InputWrapper/InputWrapper';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps } from 'types/genericTypes';
import { actions as userActions } from 'store/slices/user';
import { actions as contactActions } from 'store/slices/contactPersonsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { createMenuItems } from 'helpers/dropdown';
import { Box } from '@mui/system';
import { Endpoint } from '../../enums/APIEndpointEnum';
import { SuggestedDropdown } from '../SuggestedDropdown/SuggestedDropdown';

interface EmployeePreferenceEntry {
  employeeRoleUuid: string;
  contactPersonUuid: string;
  level: number;
  description: string;
}

const defaultValues: EmployeePreferenceEntry = {
  employeeRoleUuid: '',
  contactPersonUuid: '',
  level: 1,
  description: '',
};

const defaultValidationSchema = Yup.object().shape({
  employeeRoleUuid: Yup.string().required('Required'),
  contactPersonUuid: Yup.string().required('Required'),
  level: Yup.number().required('Required'),
  description: Yup.string().required('Required'),
});

type Dropdown = {
  label: string;
  value: any;
};

type EmployeePreferenceFormProps = {
  initialValues: EmployeePreferenceEntry | any;
  validationSchema?: any;
  title: string;
  employeeName: string;
  onSubmit: (values: EmployeePreferenceEntry) => void;
} & GenericModalProps;

const EmployeePreferenceForm: FC<EmployeePreferenceFormProps> = ({
  onClose,
  initialValues = defaultValues,
  validationSchema = defaultValidationSchema,
  title,
  employeeName,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit({
        ...values,
      });
    },
  });

  const contactPersons = useSelector(
    (state: RootState) => state.contactPersons.items,
  ).map(({ nick, uuid }) => ({
    label: nick,
    value: uuid,
  }));

  const rolesDropdown: Dropdown[] = useSelector(
    (state: RootState) => state.user.userRoles,
  ).map((role: any) => ({ label: role.role.code, value: role.uuid }));

  const levels = [...Array(11).keys()]
    .filter((x: number) => x !== 0)
    .map((x) => ({
      label: x.toString(),
      value: x,
    }));

  const employeeRoleDropdown = useMemo(() => {
    return createMenuItems(rolesDropdown);
  }, [rolesDropdown]);

  const levelsDropdown = useMemo(() => {
    return createMenuItems(levels);
  }, [levels]);

  useEffect(() => {
    dispatch(userActions.getRoles());
    dispatch(contactActions.getContactPersonsList());
  }, [dispatch]);

  return (
    <SimpleFormModal
      onSubmit={formik.submitForm}
      onClose={onClose}
      title={title}>
      <FormLabel>{l('EMPLOYEE.EMPLOYEE')}</FormLabel>
      <Box sx={{ marginBottom: '30px' }}>
        <Typography fontWeight={700}>{employeeName}</Typography>
      </Box>
      <InputWrapper maxWidth="100%">
        <FormLabel>{l('ROLE')}</FormLabel>
        <TextField
          select
          name="employeeRoleUuid"
          sx={{ width: '12rem', display: 'flex' }}
          size="small"
          value={formik.values.employeeRoleUuid}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.employeeRoleUuid)}
          helperText={formik.errors.employeeRoleUuid}
          pressEnter={formik.submitForm}>
          {employeeRoleDropdown}
        </TextField>
      </InputWrapper>
      <InputWrapper maxWidth="100%">
        <FormLabel>{l('CONTACT_PERSON')}</FormLabel>
        <SuggestedDropdown
          url={Endpoint.ContactPersons}
          inputId="contactPersonUuid"
          initialData={contactPersons}
          inputValue={{
            label: formik?.values?.contactPerson?.nick,
            value: formik?.values?.contactPerson?.uuid,
          }}
          style={{ width: '12rem', display: 'flex' }}
          dropdownLabel="nick"
          dropdownValue="uuid"
          dataLocation="resultDataItems"
          error={Boolean(formik.errors.contactPersonUuid)}
          helperText={formik.errors.contactPersonUuid}
          formikHook={formik}
        />
      </InputWrapper>
      <InputWrapper maxWidth="100%">
        <FormLabel>{l('LEVEL')}</FormLabel>
        <TextField
          select
          name="level"
          sx={{ width: '12rem', display: 'flex' }}
          size="small"
          value={formik.values.level}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.level)}
          helperText={formik.errors.level}
          pressEnter={formik.submitForm}>
          {levelsDropdown}
        </TextField>
      </InputWrapper>
      <InputWrapper maxWidth="100%">
        <FormLabel>{l('COMMON.DESCRIPTION')}</FormLabel>
        <TextField
          name="description"
          size="small"
          value={formik.values.description}
          onChange={formik.handleChange}
          autoComplete="on"
          error={Boolean(formik.errors.description)}
          helperText={formik.errors.description}
          pressEnter={formik.submitForm}
        />
      </InputWrapper>
    </SimpleFormModal>
  );
};

export default memo(EmployeePreferenceForm);
