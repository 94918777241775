import {memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import l from 'helpers/l';
import {EditTable, PageLayout} from 'components';
import {actions as reportParameterSlice} from "../../store/slices/reportSlices/reportParameterSlice";
import {RootState} from "../../store";
import {actions as commonActions} from "../../store/slices/common";
import {ModalType} from "../../enums/ModalEnums";
import {GridRowParams, GridSortItem, GridSortModel} from "@mui/x-data-grid";

function ReportParametersPage() {
    const dispatch = useDispatch();
    const { items } = useSelector((state: RootState) => state.reportParameters);

    useEffect(() => {
        dispatch(reportParameterSlice.getReportParameters());
    }, [dispatch]);

    const addEntryHandler = useCallback(() => {
        dispatch(
            commonActions.showModal({
                type: ModalType.ReportParametersModal,
            }),
        );
    }, [dispatch]);

    const deleteEntryHandler = useCallback(
        (params: GridRowParams) => {
            dispatch(
                commonActions.showModal({
                    type: ModalType.ReportParametersDeleteModal,
                    data: params.row as any,
                }),
            );
        },
        [dispatch])

    const editEntryHandler = useCallback(
        (params: GridRowParams) => {
            dispatch(
                commonActions.showModal({
                    type: ModalType.ReportParametersEditModal,
                    data: { data: params.row } as any,
                }),
            );
        },
        [dispatch],
    );

    const handleSort = useCallback(
        (model: GridSortModel) => {
            const formatValues = model.map((item: GridSortItem) => ({
                orderBy: item.field,
                order: item?.sort?.toUpperCase(),
            }))[0];

            const params = {
                orderBy: formatValues.orderBy,
                order: formatValues.order,
            };
            dispatch(reportParameterSlice.sendReportParameterFilters(params));
        },
        [dispatch],
    );

    return (
        <PageLayout title={l('PAGES.REPORT_PARAMETERS')} fullWidth={true}>
            <EditTable
                rows={items}
                columns={[
                    {
                        field: 'code',
                        headerName: 'Code',
                        width: 250,
                    },
                    {
                        field: 'name',
                        headerName: 'Name',
                        width: 250,
                    },
                    {
                        field: 'type',
                        headerName: 'Type',
                        width: 150,
                    },
                    {
                        field: 'referenceTable',
                        headerName: 'Reference Table',
                        width: 150,
                    },
                    {
                        field: 'key',
                        headerName: 'Key',
                        width: 150,
                    },
                    {
                        field: 'label',
                        headerName: 'Label',
                        width: 150,
                    },
                ]}
                onAdd={addEntryHandler}
                onDelete={deleteEntryHandler}
                onEdit={editEntryHandler}
                sortedFieldIndex={1}
                editColumnWidth={130}
                onSortModelChange={handleSort}
                sortingOrderDirection="desc"
                sortingMode="server"
                hideFooter
            />
        </PageLayout>
    );
}

export default memo(ReportParametersPage);
