import { employeeFilter } from 'types/employeeTypes';

export const formatFilters = (
  data: employeeFilter | any,
): employeeFilter | any => {
  const { keyword, statusUuids, ...rest } = data; //added status field because of pm status dropdown with multiple select
  const formatValues = Object.fromEntries(
    Object.entries(rest)
      .filter(([, value]) => value !== 'All')
      .map(([key, value]) => {
        if (typeof value === 'number') return [key, value];
        if (key === 'receivedByUuid') return [key, value === null ? '' : value];
        return [
          key,
          //  @ts-ignore
          value?.charAt(0).toLowerCase() + value?.substring(1) || value,
        ];
      }),
  );
  return { ...formatValues, ...(keyword && { keyword }) };
};

export const formatSortOrderBy = (value: string): string => {
  if (value === 'userstatus') {
    return 'userStatus';
  } else if (value === 'user') return 'username';
  return value;
};

export const datePickerStyles = {
  display: 'flex',
  backgroundColor: '#f2f2f2',
  borderRadius: '15px',
  padding: '12px',
};

export const isObjectWithValueAndLabel = (value) => {
  return (
    typeof value === 'object' &&
    value !== null &&
    value.hasOwnProperty('value') &&
    value.hasOwnProperty('label')
  );
};
