import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderLineStatusRoleType } from 'types/orderLineStatusRolesTypes';
import { OrderLineStatusRole } from 'interfaces/orderLineStatusRole';

export interface OrderLineStatusRolesState<T> {
  orderLineStatusRoles: T[] | [];
}

const initialState: OrderLineStatusRolesState<OrderLineStatusRoleType> = {
  orderLineStatusRoles: [],
};

export const orderLineStatusRolesSlice = createSlice({
  name: 'orderLineStatusRoles',
  initialState,
  reducers: {
    getOrderLineStatusRoles: (state) => state,
    setOrderLineStatusRoles: (
      state,
      action: PayloadAction<OrderLineStatusRole['role'][]>,
    ) => {
      state.orderLineStatusRoles = action.payload;
    },
    addOrderLineStatusRoles: (
      state,

    ) => state,
    deleteOrderLineStatusRoles: (
      state,

    ) => state,
    editOrderLineStatusRoles: (
      state,

    ) => state,
  },
});
export const actions: any = orderLineStatusRolesSlice.actions;
export default orderLineStatusRolesSlice.reducer;
