import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { EmployeeForm, PageLayout } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { actions as employeeActions } from 'store/slices/employeeListSlice';
import { RootState } from 'store';
import { useEffect, useState } from 'react';

const EmployeeEditPage = () => {
  const defaultValues: any = {
    firstName: '',
    lastName: '',
    email: '',
    statusId: 0,
    languageUuid: '',
    timezoneUuid: '',
    strikes: 0,
    countryOfOriginUuid: '',
    countryOfResidenceUuid: '',
    availableHours: 0,
    currencyUuid: '',
    hourlyRate: 0,
    linkedinUrl: '',
    cvUrl: '',
    paymentMethodId: 0,
    passportNumber: '',
    isExpert: false,
    isFirstInterview: false,
    isNewEmployee: false,
    isSeniorEmployee: false,
    isSignedContract: false,
    qa: '',
    test: '',
    paymentDetails: '',
    referredByUuid: '',
    teamLeadUuid: '',
  };

  const defaultValidation = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email adress').required('Required'),
    statusId: Yup.string().required('Required'),
    languageUuid: Yup.string().nullable(),
    timezoneUuid: Yup.string(),
    strikes: Yup.number(),
    countryOfOriginUuid: Yup.string().nullable(),
    countryOfResidenceUuid: Yup.string().nullable(),
    availableHours: Yup.number(),
    currencyUuid: Yup.string(),
    hourlyRate: Yup.number(),
    linkedinUrl: Yup.string(),
    cvUrl: Yup.string(),
    paymentMethodId: Yup.number(),
    passportNumber: Yup.string(),
    isExpert: Yup.bool(),
    isFirstInterview: Yup.bool(),
    isNewEmployee: Yup.bool(),
    isSeniorEmployee: Yup.bool(),
    isSignedContract: Yup.bool(),
    test: Yup.string(),
    paymentDetails: Yup.string(),
    referredByUuid: Yup.string().nullable(),
    teamLeadUuid: Yup.string().nullable(),
  });

  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(employeeActions.getEmployeesList());
    dispatch(employeeActions.getDetailsPage(id));
  }, [id]);

  const employee = useSelector(
    (state: RootState) => state.employees.employeeDetails,
  );

  const [initialValues, setInitialValues] = useState<any>({ ...defaultValues });

  useEffect(() => {
    if (employee.uuid) {
      setInitialValues({
        ...employee,
        statusId: employee?.status?.id || 0,
        languageUuid: employee?.language?.uuid || '',
        timezoneUuid: employee?.timezone?.uuid || '',
        currencyUuid: employee?.currency?.uuid || '',
        paymentMethodId: employee?.paymentMethod?.id || 0,
        countryOfOriginUuid: employee?.countryOfOrigin?.uuid || '',
        countryOfResidenceUuid: employee?.countryOfResidence?.uuid || '',
      });
    } else {
      dispatch(employeeActions.getEmployeesList());
      dispatch(employeeActions.getDetailsPage(id));
    }
  }, [dispatch, employee.uuid]);

  const handleSubmit = (values: any) => {
    const data = {
      ...initialValues,
      ...values,
    };
    dispatch(employeeActions.editEmployee({ id, data, navigate }));
  };

  const handleCancel = () => navigate(-1);

  return (
    <PageLayout
      style={{ margin: '0 auto' }}
      title="Edit employee"
      fullWidth={true}>
      {initialValues.uuid && (
        <EmployeeForm
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          initialValues={initialValues}
          validationSchema={defaultValidation}
        />
      )}
    </PageLayout>
  );
};
export default EmployeeEditPage;
