import { FC, PropsWithChildren } from 'react';
import { Box, Paper } from '@mui/material';
import styles from './ContentWrapperStyles';

type ContentWrapperProps = any;

const ContentWrapper: FC<PropsWithChildren<ContentWrapperProps>> = ({
  children,
  className,
  sx,
  ...props
}) => {
  styles();
  return (
    <Box {...props}>
      <Paper
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          borderRadius: '20px',
          padding: '1rem',
          ...sx,
        }}>
        {children}
      </Paper>
    </Box>
  );
};
export default ContentWrapper;
