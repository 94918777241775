import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CommonSliceType,
  ShowModalActionType,
  SnackBarType,
} from '../../types/commonSliceTypes';

const initialState: CommonSliceType = {
  loading: false,
  snackBar: {
    open: false,
    message: '',
    severity: undefined,
  },
  modal: {
    type: null,
    data: null,
  },
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
    snackBar: (state, action: PayloadAction<SnackBarType>) => ({
      ...state,
      snackBar: action.payload,
    }),
    showModal: (state, action: PayloadAction<ShowModalActionType>) => ({
      ...state,
      modal: action.payload,
    }),
    hideModal: (state) => ({ ...state, modal: { ...initialState.modal } }),
  },
});
export const actions: any = commonSlice.actions;
export default commonSlice.reducer;
