import { FC, memo, useCallback } from 'react';
import { actions as currenciesActions } from 'store/slices/currenciesSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import { GenericCodeNameType } from 'types/genericTypes';
import CurrenciesForm from 'components/Forms/CurrenciesForm';

const LanguagesEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state) =>
    data ? state.currencies.items.find((entry) => entry?.uuid === data) : null,
  );

  const submitHandler = useCallback(
    (values: GenericCodeNameType) =>
      entry?.uuid
        ? dispatch(
            currenciesActions.editCurrency({ uuid: entry?.uuid, ...values }),
          )
        : console.log('@TODO - Show error'),
    [dispatch, entry?.uuid],
  );

  return entry ? (
    <CurrenciesForm
      title={l('EDIT_CURRENCY')}
      onSubmit={submitHandler}
      initialValues={entry}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(LanguagesEditModal);
