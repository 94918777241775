import { FC, memo, useCallback} from 'react';
import { actions as userActions } from 'store/slices/user';
import { useDispatch} from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import UserQuestionAddForm from 'components/Forms/UserQuestionAddForm';

const UserQuestionEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const submitHandler = useCallback(
    (values: any) => {
      dispatch(
        userActions.editUserQuestions({
          ...values.values,
        }),
      );
    },
    [dispatch],
  );

  const initialValues = {
    questionId: data.data.question.id,
    ...data.data,
  };

  return (
    <UserQuestionAddForm
      title="Employee Question Edit"
      employeeName={data.name}
      onSubmit={submitHandler}
      initialValues={initialValues}
      {...rest}
    />
  );
};

export default memo(UserQuestionEditModal);
