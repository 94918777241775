import { FC, memo, useCallback } from 'react';
import { actions } from 'store/slices/checklistsContactPersonsSlice';
import { useDispatch } from 'hooks/redux';
import ChecklistsContactPersonsForm from 'components/Forms/ChecklistsContactPersonsForm';
import l from 'helpers/l';

const ChecklistsContactPersonsAddModal: FC<any> = ({ data, ...rest }) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: any) => dispatch(actions.addChecklistsContactPersons(values)),
    [dispatch],
  );

  const initialValues = {
    articleTypeUuid: data.articleTypeUuid,
    roleCode: data.roleCode,
    contactPersonUuid: data.contactPersonUuid,
    contactPersonNick: data.contactPersonNick,
  };

  return (
    <ChecklistsContactPersonsForm
      title={l('ADD_CHECKLIST_CONTACT_PERSONS')}
      onSubmit={submitHandler}
      initialValues={initialValues}
      {...rest}
    />
  );
};

export default memo(ChecklistsContactPersonsAddModal);
