import { GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import EditTable from 'components/Table/EditTable';
import { Box } from '@mui/material';
import { ModalType } from 'enums/ModalEnums';
import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { actions as commonActions } from 'store/slices/common';
import { actions as userActions } from 'store/slices/user';
import moment from 'moment';

interface EmployeeViewReplacementsProps {
  employeeData: any;
}

const EmployeeViewReplacements: FC<EmployeeViewReplacementsProps> = ({
  employeeData,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const { employeeDetails } = useSelector(
    (state: RootState) => state.employees,
  );

  const replacements = useSelector(
    (state: RootState) => state.user.replacements,
  );

  const name = `${employeeDetails.firstName} ${employeeDetails.lastName}`;

  const addEntryHandler = useCallback(() => {
    dispatch(
      commonActions.showModal({
        type: ModalType.ReplacementsAdd,
        data: { id: employeeDetails.uuid, name } as any,
      }),
    );
  }, [dispatch, employeeDetails.uuid, name]);

  useEffect(() => {
    dispatch(userActions.getUserRoles(employeeData.uuid));
    dispatch(userActions.getReplacements(employeeData.uuid));
  }, [dispatch, employeeData.uuid]);

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.ReplacementsDelete,
          data: params.row as any,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Box style={{ margin: '-1px -10px' }}>
      {!isLoading && (
        <EditTable
          loading={isLoading}
          rows={replacements}
          sortedFieldIndex={0}
          columns={[
            {
              field: 'role',
              headerName: 'Role',
              width: 150,
              valueGetter: (params: GridValueGetterParams) =>
                params.row.replacee.role.name,
            },
            {
              field: 'replacingEmployee',
              headerName: 'Replacing Employee',
              width: 200,
              valueGetter: (params: GridValueGetterParams) =>
                `${params.row.replacer.firstName} ${params.row.replacer.lastName}`,
            },
            {
              field: 'dateFrom',
              headerName: 'From',
              width: 150,
              valueGetter: (params: GridValueGetterParams) =>
                moment(params.row.dateFrom).format('DD.MM.YYYY'),
            },
            {
              field: 'dateTo',
              headerName: 'To',
              width: 150,
              valueGetter: (params: GridValueGetterParams) =>
                moment(params.row.dateTo).format('DD.MM.YYYY'),
            },
          ]}
          onAdd={addEntryHandler}
          onDelete={deleteEntryHandler}
          hideFooterPagination
          hideFooter
        />
      )}
    </Box>
  );
};

export default EmployeeViewReplacements;
