import { styled } from '@mui/system';

// export const useStyles = makeStyles(() => ({
export const MainWrapperDiv = styled('div')({
  //   mainBox: {
  display: 'flex',
  height: 'calc(100% - 62px)',
  backgroundColor: '#fff',
  justifyContent: 'space-between',
  padding: '15px',
  flexGrow: 3,
  '& .MuiDataGrid-row': {
    '&.selectedRow': {
      backgroundColor: '#ccc',
      '& span > svg': {
        display: 'none',
      },
      '& p': {
        fontWeight: '400 !important',
      },
      '& .MuiDataGrid-cell': {
        fontWeight: '400 !important',
      },
    },
    '&:hover': {
      cursor: 'pointer',
    },
    '&.hasUnreadMessages': {
      '& p': {
        fontWeight: 600,
      },
      '& .MuiDataGrid-cell': {
        fontWeight: 600,
      },
    },
    '&.removeUnreadMessages': {
      '& span > svg': {
        display: 'none',
      },
      '& p': {
        fontWeight: '400 !important',
      },
      '& .MuiDataGrid-cell': {
        fontWeight: '400 !important',
      },
    },
  },
  '& .currentConversation': {
    '& .MuiAccordionSummary-root': {
      minHeight: '35px',
    },
    '& .MuiAccordionSummary-content': {
      margin: '8px 0',
    },
    '& .MuiAccordionDetails-root': {
      padding: '12px',
    },
    backgroundColor: '#f2f2f2',
    padding: '10px',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& .propValueRow ': {
      '& p, & label ': {
        fontSize: '13px',
      },
    },
    //   '& .topBoxes': {
    //     marginBottom: '10px',
    //   },
    '& .leftTopBox': {
      backgroundColor: '#fff',
      width: '49%',
      padding: '0 10px',
    },
    '& .rightTopBox': {
      backgroundColor: '#fff',
      width: '49%',
      padding: '0 10px',
    },
  },
  '& .messages': {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 3,
    marginTop: '10px',
    overflowY: 'auto',
    '& .messageBox': {
      '& .topPart': {
        marginBottom: '7px',
        display: 'flex',
        justifyContent: 'space-between',
        '& > div': {
          display: 'flex',
          alignItems: 'center',
        },
        '& p': {
          fontSize: '13px',
        },
      },
      padding: '10px',
      backgroundColor: '#fff',
      width: '75%',
      margin: '0 0 10px',
      '&.my': {
        alignSelf: 'flex-end',
      },
      '& .reply': {
        backgroundColor: '#f2f2f2',
        margin: '0 24px 7px 34px',
        padding: '5px',
        '& p': {
          fontSize: '13px',
        },
        '& > p': {
          marginTop: '5px',
          marginLeft: '34px',
        },
      },
      '& .body': {
        '& p': {
          fontSize: '13px',
          marginLeft: '34px',
          marginRight: '20px',
        },
      },
    },
  },
  '& .writeMessageBox': {
    '& .sendMessageButton': {
      backgroundColor: '#1e98d7',
      color: '#fff',
      '&.Mui-disabled': {
        backgroundColor: '#1e98d799',
        '&:hover': {
          cursor: 'no-drop',
        },
      },
      '&:hover': {
        backgroundColor: '#1e98d7cc',
      },
    },
    backgroundColor: '#fff',
    padding: '10px',
    width: '100%',
    '& p': {
      fontSize: '13px',
    },
    '& .MuiFormControl-root.MuiFormControl-fullWidth': {
      height: 'unset',
    },
    '& .notifyWrapper': {
      overflowX: 'auto',
      marginBottom: '10px',
      '& .personWrapper': {
        marginRight: '10px',
        '& > div': {
          display: 'flex',
          alignItems: 'center',
          '& p': {
            whiteSpace: 'nowrap',
          },
          '& .MuiCheckbox-root': {
            padding: '3px',
          },
        },
        '& > p': {
          textAlign: 'center',
          fontSize: '11px',
          whiteSpace: 'nowrap',
        },
      },
    },
  },
});
