import { FC, memo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormLabel } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps } from 'types/genericTypes';
import l from 'helpers/l';
import { GenericCodeNameType } from 'types/genericTypes';

const defaultValues: GenericCodeNameType = {
  code: '',
  name: '',
};

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
});

type GenericCodeNameFormProps = {
  initialValues?: GenericCodeNameType;
  title: string;
  codeLabel?: string;
  nameLabel?: string;

  onSubmit: (values: GenericCodeNameType) => void;
} & GenericModalProps;

const GenericCodeNameForm: FC<GenericCodeNameFormProps> = ({
  onClose,
  initialValues = defaultValues,
  title,
  onSubmit,
  codeLabel = l('CODE'),
  nameLabel = l('NAME'),
}) => {
  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: GenericCodeNameType) => {
      onSubmit(values);
    },
  });

  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <FormLabel>{codeLabel}</FormLabel>
      <TextField
        sx={{ width: '12rem', display: 'flex' }}
        name="code"
        size="small"
        value={formik.values.code}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.code)}
        helperText={formik.errors.code}
        pressEnter={formik.submitForm}
      />

      <FormLabel>{nameLabel}</FormLabel>
      <TextField
        name="name"
        fullWidth
        size="small"
        value={formik.values.name}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.name)}
        helperText={formik.errors.name}
        pressEnter={formik.submitForm}
      />
    </SimpleFormModal>
  );
};

export default memo(GenericCodeNameForm);
