import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/languagesSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  getLanguagesApi,
  addLanguageApi,
  deleteLanguageApi,
  editLanguageApi,
} from 'store/api/languagesAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  LanguageDeleteEntry,
  LanguageEntry,
  Languages,
} from 'types/languageTypes';
import { APIResult } from 'types/APITypes';
import { actions as languageActions } from '../slices/languagesSlice';
import { sendLanguagesFiltersApi } from '../api/languagesAPI';
import { genericErrorMessageHelper } from '../../helpers/messageHelpers';

function* sendLanguageFilters(action: PayloadAction<string>) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(sendLanguagesFiltersApi, params);

  if (result?.data) {
    yield put(languageActions.setLanguages(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* getLanguages() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<Languages> = yield call(getLanguagesApi);
  if (result?.data) {
    yield put(actions.setLanguages(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addLanguage(action: PayloadAction<LanguageEntry>) {
  const { error }: APIResult<LanguageEntry> = yield call(
    addLanguageApi,
    action.payload,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<Languages> = yield call(getLanguagesApi);
    if (result?.data) {
      yield put(languageActions.setLanguages(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${action.payload.code} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
}

function* deleteLanguage(action: PayloadAction<LanguageDeleteEntry>) {
  const { id, name } = action.payload;
  const { error } = yield call(deleteLanguageApi, id!);
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${name} already exists`,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<Languages> = yield call(getLanguagesApi);
    if (result?.data) {
      yield put(languageActions.setLanguages(result.data));
    }
    yield put(commonActions.hideModal());
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
  }
}

function* editLanguage(action: PayloadAction<LanguageEntry>) {
  const { uuid, code, name } = action.payload;
  const data = { newCode: code, newName: name, uuid };
  const { result, error }: APIResult<LanguageEntry> = yield call(
    editLanguageApi,
    data,
  );
  if (result?.status === 200) {
    yield put(actions.editLanguageSuccess(result.data));
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Language name is taken',
        severity: 'error',
      }),
    );
  }
}

export default function* languagesWatcher() {
  yield takeLatest(actions.getLanguages.type, getLanguages);
  yield takeLatest(actions.addLanguage.type, addLanguage);
  yield takeLatest(actions.sendLanguageFilters.type, sendLanguageFilters);
  yield takeLatest(actions.deleteLanguage.type, deleteLanguage);
  yield takeLatest(actions.editLanguage.type, editLanguage);
}
