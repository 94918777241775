import { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import { RootState } from '../../../store';
import { actions as orderLineActions } from '../../../store/slices/orderLinesSlice';
import PmCheckListForm from '../../Forms/PmCheckListForm';
import {
  PmCheckListItemTypes,
  PmCheckListTypes,
} from '../../../types/PmCheckListTypes';

const PmCheckListModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const checkList = useSelector(
    (state: RootState) => state.orderLines.pmCheckList,
  );

  useEffect(() => {
    if (data.role === 'editor') {
      dispatch(
        orderLineActions.getEditorCheckList({
          orderLineUuid: data.orderLineUuid,
        }),
      );
    } else if (data.role === 'writer') {
      dispatch(
        orderLineActions.getWriterCheckList({
          orderLineUuid: data.orderLineUuid,
        }),
      );
    } else if (data.role === 'pm') {
      dispatch(
        orderLineActions.getPmCheckList({ orderLineUuid: data.orderLineUuid }),
      );
    }
  }, [dispatch]);

  const submitHandler = useCallback(
    (values: PmCheckListTypes) => {
      const checkedItems = values.items
        .filter((item: PmCheckListItemTypes) => item.isChecked)
        .map((item: any) => item.id);
      const postObj = {
        orderLineUuid: data.orderLineUuid,
        checklistItemIds: checkedItems,
      };

      if (data.role === 'editor') {
        dispatch(orderLineActions.postEditorCheckList(postObj));
      } else if (data.role === 'writer') {
        dispatch(orderLineActions.postWriterCheckList(postObj));
      } else if (data.role === 'pm') {
        dispatch(orderLineActions.postPmChecklist(postObj));
      }
    },
    [dispatch],
  );

  return (
    <PmCheckListForm
      title={l('CHECKLIST')}
      initialValues={checkList}
      onSubmit={submitHandler}
      {...rest}
    />
  );
};

export default memo(PmCheckListModal);
