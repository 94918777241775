import { FC, memo, useCallback } from 'react';
import { actions as languageRateActions } from 'store/slices/languagesRateSlice';
import { actions } from 'store/slices/common';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ARLanguageRateForm from 'components/Forms/ARLanguageRateForm';

const LanguagesEditModal: FC<GenericModalProps> = ({ data }) => {
  const dispatch = useDispatch();

  const filters = data?.filters || {};

  const entry = useSelector((state) =>
    data?.id
      ? state.languagesRate.items.find((entry) => entry?.uuid === data?.id)
      : null,
  );

  const initialValues = {
    sourceLanguage: entry?.sourceLanguage?.name,
    sourceLanguageUuid: entry?.sourceLanguage?.uuid,
    language: entry?.language?.name,
    languageUuid: entry?.language?.uuid,
    currencyUuid: entry?.currency?.uuid,
    ratePerWord: entry?.ratePerWord,
    ratePerArticle: entry?.ratePerArticle,
  };

  const submitHandler = useCallback(
    (values: any) => {
      dispatch(
        languageRateActions.editLanguageRate({
          urlSuffix: entry?.uuid,
          filters: filters,
          ...values,
        }),
      );
    },
    [dispatch, entry?.uuid, filters],
  );

  const closeHandler = useCallback(
    () => dispatch(actions.hideModal()),
    [dispatch],
  );

  return (
    <ARLanguageRateForm
      onSubmit={submitHandler}
      title="Add Language Rate"
      onClose={closeHandler}
      initialValues={initialValues}
    />
  );
};

export default memo(LanguagesEditModal);
