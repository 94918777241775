import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/orderLineStatusRolesSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  addOrderLineStatusRolesApi,
  editOrderLineStatusRolesApi,
  getOrderLineStatusRolesApi,
  deleteOrderLineStatusRolesApi,
} from 'store/api/orderLineStatusRolesAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { OrderLineStatusRole } from 'interfaces/orderLineStatusRole';
import { GenericIdNameType } from 'types/genericTypes';
import { genericErrorMessageHelper } from '../../helpers/messageHelpers';
import { APIResult } from 'types/APITypes';

function* getOrderLineStatusRoles() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<OrderLineStatusRole['role'][]> = yield call(
    getOrderLineStatusRolesApi,
  );
  if (result?.data?.length) {
    yield put(actions.setOrderLineStatusRoles(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addOrderLineStatusRoles(
  action: PayloadAction<OrderLineStatusRole['entry']>,
) {
  const data = action.payload;
  const { result, error }: APIResult<OrderLineStatusRole['role']> = yield call(
    addOrderLineStatusRolesApi,
    data,
  );
  if (result?.status === 201) {
    const { result }: APIResult<OrderLineStatusRole['role'][]> = yield call(
      getOrderLineStatusRolesApi,
    );
    if (result?.data?.length) {
      yield put(actions.setOrderLineStatusRoles(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.roleCode} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.roleCode} already exists`,
        severity: 'error',
      }),
    );
  }
}

function* deleteOrderLineStatusRoles(
  action: PayloadAction<OrderLineStatusRole['role']>,
) {
  const { uuid, role } = action.payload;
  const { result, error }: APIResult<GenericIdNameType> = yield call(
    deleteOrderLineStatusRolesApi,
    uuid,
  );
  if (error?.status === 500) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'This item can not be deleted due to existing relations',
        severity: 'error',
      }),
    );
  } else if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  }
  if (result?.status === 200) {
    const { result }: APIResult<OrderLineStatusRole['role'][]> = yield call(
      getOrderLineStatusRolesApi,
    );
    if (result?.data?.length) {
      yield put(actions.setOrderLineStatusRoles(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${role.name} from the list`,
        severity: 'info',
      }),
    );
  }
  yield put(commonActions.hideModal());
}

function* editOrderLineStatusRoles(
  action: PayloadAction<OrderLineStatusRole['role']>,
) {
  const data = action.payload;
  const { result, error }: APIResult<OrderLineStatusRole['role']> = yield call(
    editOrderLineStatusRolesApi,
    data,
  );
  if (result?.status === 200) {
    const { result }: APIResult<OrderLineStatusRole['role'][]> = yield call(
      getOrderLineStatusRolesApi,
    );
    if (result?.data?.length) {
      yield put(actions.setOrderLineStatusRoles(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${data.orderLineStatus.name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Order line status name is taken',
        severity: 'error',
      }),
    );
  }
}

export default function* OrderLineStatusRolesWatcher() {
  yield takeLatest(
    actions.getOrderLineStatusRoles.type,
    getOrderLineStatusRoles,
  );
  yield takeLatest(
    actions.addOrderLineStatusRoles.type,
    addOrderLineStatusRoles,
  );
  yield takeLatest(
    actions.deleteOrderLineStatusRoles.type,
    deleteOrderLineStatusRoles,
  );
  yield takeLatest(
    actions.editOrderLineStatusRoles.type,
    editOrderLineStatusRoles,
  );
}
