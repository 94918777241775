import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationMeta } from 'types/genericTypes';

export interface PMFilters {
  articleTypeUuid: string;
  assistantPmUuid: string;
  daysOverdue: string;
  editorUuid: string;
  keyword: string;
  languageUuid: string;
  pmDueDateFrom: string;
  pmDueDateTo: string;
  sourceLanguageUuid: string;
  statusUuids: string[];
  writerUuid: string;
  contactPersonUuid: string;
}
export interface ProjectManager {
  items: any[];
  writers: any[];
  editors: any[];
  filters: PMFilters;
  filterDropdownValues: {};
  sort: any;
  meta: PaginationMeta;
}

const initialState: ProjectManager = {
  items: [],
  writers: [],
  editors: [],
  filters: {
    articleTypeUuid: '',
    assistantPmUuid: '',
    daysOverdue: 'All',
    editorUuid: '',
    keyword: '',
    languageUuid: '',
    pmDueDateFrom: '',
    pmDueDateTo: '',
    sourceLanguageUuid: '',
    statusUuids: null,
    writerUuid: '',
    contactPersonUuid: '',
  },
  filterDropdownValues: {},
  sort: {},
  meta: {},
};

export const projectManagerSlice = createSlice({
  name: 'pmOrderLines',
  initialState,
  reducers: {
    getProjectOrderLines: (state) => state,
    setProjectOrderLines: (state, action: PayloadAction<any>) => {
      state.items = action?.payload?.items;
      state.meta = action?.payload?.meta;
    },
    getSuggestionWriters: (state) => state,
    getSuggestionEditors: (state) => state,
    setSuggestionWriters: (state, action: PayloadAction<any>) => {
      state.writers = action?.payload;
    },
    setSuggestionEditors: (state, action: PayloadAction<any>) => {
      state.editors = action?.payload;
    },
    editProjectOrderLines: (state) => state,
    editProjectEMployeeDatesOrderLines: (state) => state,

    sendProjectOrderLinesFilters: (state) => state,
    setProjectOrderLinesFilters: (state, action: any) => {
      state.filters = action?.payload;
    },

    setFiletDropdownValues: (state, action: any) => {
      state.filterDropdownValues = action?.payload;
    },

    setFilterParams: (state, action: any) => {
      localStorage.setItem('filterValues', JSON.stringify(action?.payload));
    },

    setSortParams: (state, action: any) => {
      state.sort = action?.payload;
    },
  },
});
export const actions: any = projectManagerSlice.actions;
export default projectManagerSlice.reducer;
