import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/orderLineStatusLogSlice';
import { actions as commonActions } from 'store/slices/common';
import { getOrderLineStatusLogApi } from 'store/api/orderLineStatusLogAPI';
import { OrderLineStatusRole } from 'interfaces/orderLineStatusRole';
import { APIResult } from 'types/APITypes';

function* getOrderLineStatusLog(action: any) {
  const params = action.payload;
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<OrderLineStatusRole['role'][]> = yield call(
    getOrderLineStatusLogApi,
    params,
  );
  if (result?.data?.length) {
    yield put(actions.setOrderLineStatusLog(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

export default function* OrderLineStatusLogWatcher() {
  yield takeLatest(actions.getOrderLineStatusLog.type, getOrderLineStatusLog);
}
