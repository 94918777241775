import { FC, PropsWithChildren } from 'react';
import { Box, FormLabel, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CheckIcon from '@mui/icons-material/Check';

interface FormInfoRowProps {
  rowTitle: string;
  rowValueText: string | boolean;
  url?: string;
  color?: string;
  newTab?: boolean;
  style?: any;
}

const FormInfoRow: FC<PropsWithChildren<FormInfoRowProps>> = ({
  rowTitle,
  rowValueText,
  url,
  color,
  style,
}) => {
  const renderIcon = (rowValueText: boolean) => {
    if (rowValueText) {
      return <CheckIcon />;
    }
    return '';
  };
  return (
    <Box className="boxRow" style={style}>
      <FormLabel>{rowTitle}:</FormLabel>
      {url ? (
        <a
          href={url}
          target="_blank"
          style={{
            textDecoration: 'none',
            fontSize: '1rem',
            fontFamily: 'Poppins, sans-serif',
            lineHeight: 1.5,
            color: '#000',
            fontWeight: 600,
            wordBreak: 'break-all',
          }}>
          {` ${rowValueText}`}
          <OpenInNewIcon sx={{ fontSize: '15px', marginLeft: '10px' }} />
        </a>
      ) : (
        <Typography color={color || ''} fontWeight={600}>
          {typeof rowValueText === 'string'
            ? ` ${rowValueText}`
            :  renderIcon(rowValueText)}
        </Typography>
      )}
    </Box>
  );
};
export default FormInfoRow;
