import AuthContainer from 'components/AuthContainer/AuthContainer';
import { useDispatch } from 'react-redux';
import { actions as authActions } from 'store/slices/auth';
import { Box, FormLabel } from '@mui/material';
import { TextField } from 'components';
import { ForgottenPasswordProps } from 'types/authTypes';
import l from 'helpers/l';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const initialValues: ForgottenPasswordProps = {
  usernameOrEmail: '',
};

const validationSchema = Yup.object().shape({
  usernameOrEmail: Yup.string()
    .min(3, 'Field must be at least 3 characters')
    .required('Required'),
});

const link = { url: '/login', text: 'Login' };

const Login = () => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: ForgottenPasswordProps) => {
      dispatch(authActions.requestNewPassword(values));
    },
  });
  return (
    <AuthContainer
      title={l('UI_LABELS.FORGOTTEN_PASSWORD')}
      link={link}
      buttonText={l('UI_LABELS.SET')}
      onSubmit={formik.submitForm}>
      <Box mb="10px">
        <FormLabel>{l('USERNAMEMAIL')}</FormLabel>
        <TextField
          name="usernameOrEmail"
          fullWidth
          size="small"
          value={formik.values.usernameOrEmail}
          onChange={formik.handleChange}
          autoComplete={'off'}
          error={Boolean(formik.errors.usernameOrEmail)}
          helperText={formik.errors.usernameOrEmail}
          pressEnter={formik.submitForm}
        />
      </Box>
    </AuthContainer>
  );
};
export default Login;
