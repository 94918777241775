import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

interface IAPEmployeeLanguageRateData {
  languageUuid: string;
  sourceLanguageUuid: string;
  roleCode: string;
  employeeUuid: string;
  ratePerWord: number;
  ratePerArticle: number;
}

const getAPEmployeeLanguageRateAPI = (params: any) =>
  apiCall({
    url: Endpoint.APEmployeeLanguageRate,
    method: Method.Get,
    params,
  });

const addAPEmployeeLanguageRateAPI = (data: IAPEmployeeLanguageRateData) =>
  apiCall({
    url: Endpoint.APEmployeeLanguageRate,
    method: Method.Post,
    data,
  });
const editAPEmployeeLanguageRateAPI = (
  urlSuffix: string,
  data: IAPEmployeeLanguageRateData,
) =>
  apiCall({
    url: Endpoint.APEmployeeLanguageRate,
    method: Method.Put,
    urlSuffix,
    data,
  });
const deleteAPEmployeeLanguageRateAPI = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.APEmployeeLanguageRate,
    method: Method.Delete,
    urlSuffix,
  });

export {
  getAPEmployeeLanguageRateAPI,
  addAPEmployeeLanguageRateAPI,
  editAPEmployeeLanguageRateAPI,
  deleteAPEmployeeLanguageRateAPI,
};
