import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TimeZoneEntry } from 'types/TimeZoneTypes';

const initialState: TimeZoneEntry = {
  items: [],
  meta: {},
};

export const timeZonesSlice = createSlice({
  name: 'timeZones',
  initialState,
  reducers: {
    getTimeZones: (state) => state,
    setTimeZones: (state, action: PayloadAction<TimeZoneEntry>) => {
      state.items = action.payload.items;
      state.meta = action.payload.meta;
    },
    sendTimeZoneFilters: (state) => state,
  },
});

export const actions: any = timeZonesSlice.actions;
export default timeZonesSlice.reducer;
