import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationMeta } from 'types/genericTypes';

interface IAPLanguagesRate {
  items: any[];
  meta: PaginationMeta;
}
const initialState: IAPLanguagesRate = {
  items: [],
  meta: {},
};

export const apLanguageRateSlice = createSlice({
  name: 'apLanguageRate',
  initialState,
  reducers: {
    getAPLanguageRates: (state) => state,
    setAPLanguageRates: (state, action: PayloadAction<any>) => {
      state.items = action?.payload?.items;
      state.meta = action.payload.meta;
    },
    addAPLanguageRate: (state) => state,
    editAPLanguageRate: (state) => state,
    deleteAPLanguageRate: (state) => state,
  },
});
export const actions: any = apLanguageRateSlice.actions;
export default apLanguageRateSlice.reducer;
