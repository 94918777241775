import { FC, memo } from 'react';
import {
  OrderLineStatusActionEntry,
  OrderLineStatusType,
} from 'types/orderLineStatusesTypes';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormLabel, Switch } from '@mui/material';
import { TextField } from 'components';
import l from 'helpers/l';
import InputWrapper from 'components/containers/InputWrapper/InputWrapper';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { Color, ColorPicker } from 'material-ui-color';
import { GenericModalProps } from 'types/genericTypes';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  needsDescription: Yup.string().required('Required'),
  isActive: Yup.boolean().required('Required'),
  isSendMail: Yup.boolean().required('Required'),
  isSendNotification: Yup.boolean().required('Required'),
  color: Yup.string().required('Required'),
});

type OrderLineStatusesFormProps = {
  initialValues: OrderLineStatusType | OrderLineStatusActionEntry;
  title: string;
  onSubmit: (values: OrderLineStatusActionEntry) => void;
} & GenericModalProps;

const OrderLineStatusesForm: FC<OrderLineStatusesFormProps> = ({
  onClose,
  initialValues,
  title,
  onSubmit,
}) => {
  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: OrderLineStatusActionEntry) => {
      onSubmit({
        ...values,
      });
    },
  });

  return (
    <SimpleFormModal
      onSubmit={formik.submitForm}
      onClose={onClose}
      title={title}>
      <InputWrapper maxWidth="100%">
        <FormLabel>{l('ORDER_LINE_STATUSES.NAME')}</FormLabel>
        <TextField
          name="name"
          size="small"
          value={formik.values.name}
          onChange={formik.handleChange}
          autoComplete="on"
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name}
          pressEnter={formik.submitForm}
        />
      </InputWrapper>
      <InputWrapper maxWidth="100%">
        <FormLabel>{l('ORDER_LINE_STATUSES.IS_ACTIVE')}</FormLabel>
        <Switch
          id="isActive"
          checked={formik.values.isActive}
          size="small"
          onChange={formik.handleChange}
        />
      </InputWrapper>
      <InputWrapper maxWidth="100%">
        <FormLabel>{l('ORDER_LINE_STATUSES.NEEDS_DESCRIPTION')}</FormLabel>
        <Switch
          id="needsDescription"
          checked={formik.values.needsDescription}
          size="small"
          onChange={formik.handleChange}
        />
      </InputWrapper>
      <InputWrapper maxWidth="100%">
        <FormLabel>{l('ORDER_LINE_STATUSES.IS_SEND_MAIL')}</FormLabel>
        <Switch
          id="isSendMail"
          checked={formik.values.isSendMail}
          size="small"
          onChange={formik.handleChange}
        />
      </InputWrapper>
      <InputWrapper>
        <FormLabel>{l('ORDER_LINE_STATUSES.IS_SEND_NOTIFICATION')}</FormLabel>
        <Switch
          id="isSendNotification"
          checked={formik.values.isSendNotification}
          size="small"
          onChange={formik.handleChange}
        />
      </InputWrapper>
      <InputWrapper maxWidth="100%">
        <FormLabel>{l('ORDER_LINE_STATUSES.COLOR')}</FormLabel>
        <ColorPicker
          defaultValue={'#000000'}
          value={formik.values.color}
          disableTextfield={false}
          hideTextfield={false}
          deferred={true}
          disableAlpha={true}
          disablePlainColor={false}
          onChange={(c: Color) => {
            const format = `#${c.hex}`;
            formik.setFieldValue('color', format);
          }}
          hslGradient={false}
        />
      </InputWrapper>
    </SimpleFormModal>
  );
};

export default memo(OrderLineStatusesForm);
