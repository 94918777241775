import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/employeeStatusesSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  addEmployeeStatusesApi,
  deleteEmployeeStatusesApi,
  editEmployeeStatusesApi,
  getEmployeeStatusesApi,
} from 'store/api/employeeStatusesAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { EmployeeStatusesAddEntry } from 'types/employeeStatusesTypes';
import { GenericIdNameType } from 'types/genericTypes';
import { APIResult } from 'types/APITypes';
import { genericErrorMessageHelper } from '../../helpers/messageHelpers';

function* getEmployeeStatuses() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<GenericIdNameType[]> = yield call(
    getEmployeeStatusesApi,
  );
  if (result?.data?.length) {
    yield put(actions.setEmployeeStatuses(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addEmployeeStatuses(action: PayloadAction<EmployeeStatusesAddEntry>) {
  const data = action.payload;
  const { error }: APIResult<EmployeeStatusesAddEntry> = yield call(
    addEmployeeStatusesApi,
    data,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.name} already exists`,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getEmployeeStatusesApi,
    );
    if (result?.data) {
      yield put(actions.setEmployeeStatuses(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.name} to the list`,
        severity: 'success',
      }),
    );
  }
  yield put(commonActions.hideModal());
}

function* deleteEmployeeStatuses(action: PayloadAction<GenericIdNameType>) {
  const { id, name } = action.payload;
  const { result, error }: APIResult<GenericIdNameType> = yield call(
    deleteEmployeeStatusesApi,
    id! as string,
  );
  if (error?.status === 500) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'This item can not be deleted due to existing relations',
        severity: 'error',
      }),
    );
  } else if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  }
  if (result?.status === 200) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getEmployeeStatusesApi,
    );
    if (result?.data?.length) {
      yield put(actions.setEmployeeStatuses(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
  }
  yield put(commonActions.hideModal());
}

function* editEmployeeStatuses(
  action: PayloadAction<EmployeeStatusesAddEntry>,
) {
  const name = action.payload;

  const { result, error }: APIResult<EmployeeStatusesAddEntry> = yield call(
    editEmployeeStatusesApi,
    name,
  );
  if (result?.status === 200) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getEmployeeStatusesApi,
    );
    if (result?.data?.length) {
      yield put(actions.setEmployeeStatuses(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${name.name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Employee status name is taken',
        severity: 'error',
      }),
    );
  }
}

export default function* employeeStatusesWatcher() {
  yield takeLatest(actions.getEmployeeStatuses.type, getEmployeeStatuses);
  yield takeLatest(actions.addEmployeeStatuses.type, addEmployeeStatuses);
  yield takeLatest(actions.deleteEmployeeStatuses.type, deleteEmployeeStatuses);
  yield takeLatest(actions.editEmployeeStatuses.type, editEmployeeStatuses);
}
