import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/checklistsContactPersonsSlice';
import { actions as commonActions } from 'store/slices/common';
import {
    addChecklistsContactPersonsApi,
    getChecklistsContactPersonsApi,
    editChecklistsContactPersonsApi,
    deleteChecklistsContactPersonsApi,
    sendChecklistsContactPersonsFiltersApi,
} from 'store/api/checklistsContactPersonsAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { GenericIdNameType } from 'types/genericTypes';
import { APIResult } from 'types/APITypes';

function* sendChecklistsContactPersonsFilters(action: PayloadAction<string>) {
    const params = action.payload;

    yield put(commonActions.setLoading(true));

    const { result } = yield call(sendChecklistsContactPersonsFiltersApi, params);

    if (result?.data) {
        yield put(actions.setChecklistsContactPersons(result?.data));
    }
    yield put(commonActions.setLoading(false));
}

function* getChecklistsContactPersons() {
    yield put(commonActions.setLoading(true));
    const { result }: APIResult<any[]> = yield call(getChecklistsContactPersonsApi);
    if (result?.data) {
        yield put(actions.setChecklistsContactPersons(result.data));
    }
    yield put(commonActions.setLoading(false));
}

function* addChecklistsContactPersons(action: PayloadAction<any>) {
    const data = action.payload;
    const { result, error }: APIResult<any> = yield call(
        addChecklistsContactPersonsApi,
        data,
    );
    if (result?.data) {
        // const { result }: APIResult<any[]> = yield call(getChecklistsContactPersonsApi);
        // if (result?.data) {
        //   yield put(actions.setChecklistsContactPersons(result.data));
        // }
        yield put(
            commonActions.snackBar({
                open: true,
                message: `You have successfully added to the list`,
                severity: 'success',
            }),
        );
        yield put(commonActions.hideModal());
    }
    if (error?.status === 400) {
        yield put(
            commonActions.snackBar({
                open: true,
                message: `Item already exists`,
                severity: 'error',
            }),
        );
    }
}

function* deleteChecklistsContactPersons(action: PayloadAction<GenericIdNameType>) {
    const { id} = action.payload;
    const { result, error }: APIResult<GenericIdNameType> = yield call(
        deleteChecklistsContactPersonsApi,
        id! as string,
    );
    if (result?.status === 200) {
        const { result }: APIResult<GenericIdNameType[]> = yield call(
            getChecklistsContactPersonsApi,
        );
        if (result?.data) {
            yield put(actions.setChecklistsContactPersons(result.data));
        }
        yield put(
            commonActions.snackBar({
                open: true,
                message: `You have successfully deleted the item from the list`,
                severity: 'info',
            }),
        );
    } else if (error) {
        yield put(
            commonActions.snackBar({
                open: true,
                message: error.message,
                severity: 'error',
            }),
        );
    }
    yield put(commonActions.hideModal());
}

function* editChecklistsContactPersons(action: PayloadAction<any>) {
    const { result, error }: APIResult<any> = yield call(
        editChecklistsContactPersonsApi,
        action.payload,
    );
    if (result?.status === 200) {
        const { result }: APIResult<any[]> = yield call(getChecklistsContactPersonsApi);
        if (result?.data) {
            yield put(actions.setChecklistsContactPersons(result.data));
        }
        yield put(
            commonActions.snackBar({
                open: true,
                message: `You have successfully edited the item`,
                severity: 'info',
            }),
        );
        yield put(commonActions.hideModal());
    }
    if (error?.status === 400) {
        yield put(
            commonActions.snackBar({
                open: true,
                message: 'The entry already exists!',
                severity: 'error',
            }),
        );
    }
}

export default function* ChecklistsContactPersonsWatcher() {
    yield takeLatest(
        actions.sendChecklistsContactPersonsFilters.type,
        sendChecklistsContactPersonsFilters,
    );
    yield takeLatest(actions.getChecklistsContactPersons.type, getChecklistsContactPersons);
    yield takeLatest(actions.addChecklistsContactPersons.type, addChecklistsContactPersons);
    yield takeLatest(actions.deleteChecklistsContactPersons.type, deleteChecklistsContactPersons);
    yield takeLatest(actions.editChecklistsContactPersons.type, editChecklistsContactPersons);
}
