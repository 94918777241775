import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'hooks/redux';
import { useDispatch } from 'react-redux';
import { actions } from 'store/slices/user';
import { actions as commonActions } from 'store/slices/common';
import { RootState } from 'store';
import l from 'helpers/l';
import EditTable from 'components/Table/EditTable';
import PageLayout from 'components/PageLayout/PageLayout';
import { GridRowParams } from '@mui/x-data-grid';
import { ModalType } from 'enums/ModalEnums';

const PermissionsPage: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const permissions = useSelector((state: RootState) => state.user.permissions);

  useEffect(() => {
    dispatch(actions.getPermissions());
  }, [dispatch]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.PermissionsEdit,
          data: params.id.toString(),
        }),
      );
    },
    [dispatch],
  );

  return (
    <PageLayout title={l('PAGES.PERMISSIONS')}>
      <EditTable
        onEdit={editEntryHandler}
        loading={isLoading}
        getRowId={(row) => row.code}
        rows={permissions}
        columns={[
          {
            field: 'code',
            headerName: 'Code',
            width: 200,
          },
          {
            field: 'name',
            headerName: 'Name',
            width: 200,
          },
        ]}
        hideFooter
        hideFooterPagination
        rowsPerPageOptions={[100]}
        pageSize={100}
      />
    </PageLayout>
  );
};

export default PermissionsPage;
