import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { OrderLineStatusRole } from 'interfaces/orderLineStatusRole';
import apiCall from './apiCall';

const getOrderLineStatusRolesApi = () =>
  apiCall({
    url: Endpoint.OrderLineStatusRoles,
    method: Method.Get,
  });

const addOrderLineStatusRolesApi = (data: OrderLineStatusRole['entry']) =>
  apiCall({
    url: Endpoint.OrderLineStatusRoles,
    method: Method.Post,
    data,
  });

const deleteOrderLineStatusRolesApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.OrderLineStatusRoles,
    method: Method.Delete,
    urlSuffix,
  });

const editOrderLineStatusRolesApi = (data: OrderLineStatusRole['role']) => {
  return apiCall({
    url: Endpoint.OrderLineStatusRoles,
    urlSuffix: data.uuid,
    method: Method.Put,
    data,
  });
};

export {
  getOrderLineStatusRolesApi,
  addOrderLineStatusRolesApi,
  editOrderLineStatusRolesApi,
  deleteOrderLineStatusRolesApi,
};
