import { ChangeEvent, FC, memo, useCallback } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormLabel } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps } from 'types/genericTypes';
import l from 'helpers/l';
import { GenericCodeNameType } from 'types/genericTypes';

const defaultValues: GenericCodeNameType = {
  code: '',
  name: '',
};

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required('Required')
    .min(3)
    .max(3)
    .matches(/^[A-Z\s]+$/, l('ERROR_MESSAGES.ONLY_LETTERS')),
  name: Yup.string().required('Required'),
});

type CurrenciesFormProps = {
  initialValues?: GenericCodeNameType;
  title: string;
  codeLabel?: string;
  nameLabel?: string;
  onSubmit: (values: GenericCodeNameType) => void;
} & GenericModalProps;

const CurrenciesForm: FC<CurrenciesFormProps> = ({
  onClose,
  initialValues = defaultValues,
  title,
  onSubmit,
  codeLabel = l('CODE'),
  nameLabel = l('NAME'),
}) => {
  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit,
  });

  const codeChangeHandler = useCallback(
    (event: ChangeEvent<any>) => {
      const value = event.target.value as string;
      formik.setFieldValue('code', value.toUpperCase());
    },
    [formik],
  );

  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <Box>
        <FormLabel>{codeLabel}</FormLabel>
        <Box>
          <TextField
            sx={{
              width: 80,
              '& .MuiFormHelperText-root': {
                whiteSpace: 'nowrap',
              },
            }}
            inputProps={{ maxLength: 3 }}
            name="code"
            size="small"
            value={formik.values.code}
            onChange={codeChangeHandler}
            autoComplete="off"
            error={Boolean(formik.errors.code)}
            helperText={formik.errors.code}
            pressEnter={formik.submitForm}
          />
        </Box>
      </Box>
      <Box>
        <FormLabel>{nameLabel}</FormLabel>
        <Box>
          <TextField
            name="name"
            fullWidth
            size="small"
            value={formik.values.name}
            onChange={formik.handleChange}
            autoComplete="off"
            error={Boolean(formik.errors.name)}
            helperText={formik.errors.name}
            pressEnter={formik.submitForm}
          />
        </Box>
      </Box>
    </SimpleFormModal>
  );
};

export default memo(CurrenciesForm);
