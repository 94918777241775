import { FC, useCallback } from 'react';
import { useDispatch } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import { actions } from 'store/slices/common';
import { actions as orderLinesActions } from 'store/slices/orderLinesSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, FormLabel } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  orderId: Yup.number().required('This field is required!'),
  lineNumber: Yup.number().required('This field is required!'),
});

const defaultValues: any = {
  orderId: '',
  lineNumber: '',
};

const DuplicateOrderLineModal: FC<GenericModalProps> = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeHandler = useCallback(
    () => dispatch(actions.hideModal()),
    [dispatch],
  );

  const { setValues, ...formik } = useFormik({
    initialValues: defaultValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      submitHandler(values);
      //  onSubmit({
      //    ...values,
      //  });
    },
  });

  const submitHandler = useCallback(
    (values: any) => {
      const dataAction = {
        params: {
          ...values,
          contactPersonUuid: data.contactPersonUuid,
        },
        orderId: data.orderId,
        navigate,
        isCorrection: data?.isCorrection,
      };

      dispatch(orderLinesActions.orderLineDuplicateFrom(dataAction));
    },
    [dispatch],
  );

  return (
    <SimpleFormModal
      onClose={closeHandler}
      onSubmit={formik.submitForm}
      //  onSubmit={submitHandler}
      btnSubmitLabel="Duplicate"
      title={'Duplicate from'}
      style={{ width: '900px' }}>
      <Box className="boxRow" sx={{ width: '50%' }}>
        <FormLabel>Order Id</FormLabel>
        <TextField
          type="number"
          name="orderId"
          size="small"
          className="number"
          fullWidth
          value={formik.values?.orderId}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.orderId)}
          helperText={formik.errors.orderId}
          pressEnter={formik.submitForm}
        />
      </Box>
      <Box className="boxRow" sx={{ width: '50%' }}>
        <FormLabel>Line number</FormLabel>
        <TextField
          type="number"
          name="lineNumber"
          size="small"
          className="number"
          fullWidth
          value={formik.values?.lineNumber}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.lineNumber)}
          helperText={formik.errors.lineNumber}
          pressEnter={formik.submitForm}
        />
      </Box>
    </SimpleFormModal>
  );
};

export default DuplicateOrderLineModal;
