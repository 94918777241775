import { FC } from 'react';
import { Typography, CircularProgress, Box } from '@mui/material';
import styles from './SpinnerStyles';

interface SpinnerProps {
  title: string;
}

const Spinner: FC<SpinnerProps> = ({ title }) => {
  const { Spinner } = styles();
  return (
    <Box className={Spinner}>
      <Box mb="10px">
        <CircularProgress />
      </Box>
      <Typography variant="h5" color="primary">
        {title}
      </Typography>
    </Box>
  );
};
export default Spinner;
