import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

const DataGridStyled = styled(DataGrid)(() => ({
  '&.MuiDataGrid-root': {
    backgroundColor: '#fff',
    borderRadius: '15px',
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal',
    },
    // fix for remove scroll for one item in the table
    //'& .MuiDataGrid-row': {
    //  maxHeight: 'none !important',
    //},
  },
  '& .MuiDataGrid-main': {
    borderRadius: '15px',
  },
  '.MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within':
    {
      outline: 'none',
    },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#f7f7f7',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
  },
  '& .MuiDataGrid-columnHeader': {
    paddingLeft: '5px',
    '&:first-of-type': {
      paddingLeft: 0,
    },
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
}));

const Table: FC<DataGridProps> = (props) => (
  <DataGridStyled
    autoHeight
    disableColumnMenu
    disableSelectionOnClick
    getRowId={(row) => row.uuid}
    sortingOrder={['asc', 'desc']}
    initialState={{
      sorting: {
        sortModel: [{ field: props.columns[0].field, sort: 'asc' }],
      },
    }}
    {...props}
  />
);

export default Table;
