import {FC, memo, useCallback} from 'react';
import {useDispatch} from 'hooks/redux';
import {GenericModalProps} from 'types/genericTypes';
import ReportParametersForm from "../../Forms/reports/ReportParametersForm";
import {actions as reportParameterSlice} from "../../../store/slices/reportSlices/reportParameterSlice";

const ReportParametersEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
    const dispatch = useDispatch();

    const submitHandler = useCallback(
        (values: any) => {
            const objToSend = {
                ...values,
            }
            const checkType = values.type !== 'reference';
            objToSend.code = values.name.toLowerCase().split(' ').join('_');

            if(checkType){
                objToSend.referenceTable = undefined
                objToSend.key = undefined
                objToSend.label = undefined
            }

            dispatch(
                reportParameterSlice.editReportParameter(objToSend),
            );
        },
        [dispatch],
    );

    const initialValues = {
        ...data.data,
    };

    return (
        <ReportParametersForm
            title="Report Parameter Edit"
            onSubmit={submitHandler}
            initialValues={initialValues}
            {...rest}
        />
    );
};

export default memo(ReportParametersEditModal);
