import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GenericIdNameType } from 'types/genericTypes';
export interface Speakers {
  speakers: GenericIdNameType[];
}

const initialState: Speakers = {
  speakers: [],
};

export const speakersSlice = createSlice({
  name: 'speakers',
  initialState,
  reducers: {
    getSpeakers: (state) => state,
    setSpeakers: (state, action: PayloadAction<GenericIdNameType[]>) => {
      state.speakers = action.payload;
    },
    addSpeakers: (state) => state,
    deleteSpeakers: (state) => state,
    editSpeakers: (state) => state,
  },
});
export const actions: any = speakersSlice.actions;
export default speakersSlice.reducer;
