import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as authActions } from 'store/slices/auth';
import { actions as commonActions } from 'store/slices/common';
import { AppBar, Badge, Box, Icon, IconButton, Toolbar } from '@mui/material';
import { actions as messagesActions } from 'store/slices/messagesSlice';
import {
  EmailOutlined,
  Logout,
  MenuRounded as MenuIcon,
  MoreOutlined as MoreIcon,
} from '@mui/icons-material';
import { NavLink, useLocation } from 'react-router-dom';
import * as styles from './HeaderStyles';
import HeaderMobileMenu from './HeaderMobileMenu';
import Logo from 'assets/CaptainWords_Logo.svg';
import { RootState } from 'store';
import l from 'helpers/l';
import { actions } from '../../store/slices/user';

interface HeaderProps {
  sideNavigationStateHandler: (state: boolean) => void;
  sideNavigationState: boolean;
}

const Header: FC<HeaderProps> = ({
  sideNavigationState,
  sideNavigationStateHandler,
}) => {
  const dispatch = useDispatch();

  const firstName = useSelector(
    (state: RootState) => state.auth?.user?.employee?.firstName,
  );
  const lastName = useSelector(
    (state: RootState) => state.auth?.user?.employee?.lastName,
  );

  const { hasAnyUnseenMessages } = useSelector(
    (state: RootState) => state.messages,
  );
  const { unseenMessagesMarkSeen } = useSelector(
    (state: RootState) => state.messages,
  );

  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const logged = useSelector((state: RootState) => Boolean(state.auth.token));

  Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(authActions.logout());
    dispatch(
      commonActions.snackBar({
        open: true,
        message: l('AUTH.LOGOUT'),
        severity: 'info',
      }),
    );
  };

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';

  useEffect(() => {
    sideNavigationStateHandler(false);
  }, [location.pathname, sideNavigationStateHandler]);

  const permission = useSelector((state: RootState) => state.auth.permissions);

  const hasChatPermissions = permission?.includes('chat');

  useEffect(() => {
    if (firstName && hasChatPermissions) {
      dispatch(messagesActions.hasAnyUnseenMessages());
      dispatch(messagesActions.hasUnseenMessagesOnOrderLines());
    }
  }, [
    dispatch,
    firstName,
    location,
    hasAnyUnseenMessages,
    unseenMessagesMarkSeen,
  ]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(actions.getPermissions());
    });
  }, [dispatch]);

  return (
    <Box sx={styles.BoxStyles}>
      <AppBar position="static" sx={styles.AppBarStyles} elevation={0}>
        <Toolbar>
          {logged ? (
            <>
              <IconButton
                onClick={() => sideNavigationStateHandler(!sideNavigationState)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}>
                <MenuIcon sx={{ color: '#565656' }} />
              </IconButton>

              <Icon
                sx={{
                  width: '140px',
                  height: '60px',
                  display: { xs: 'none', sm: 'block' },
                }}>
                <img
                  src={Logo}
                  alt="captain_words_logo"
                  height="100%"
                  width="100%"
                />
              </Icon>

              {/*<Search sx={{ color: '#AAAAAA' }}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                />
              </Search>*/}
              <Box sx={{ flexGrow: 1 }} />
              <Box
                typography="body1"
                sx={{
                  color: 'black',
                  fontSize: { xs: '12px', sm: '14px', md: '16px' },
                  fontWeight: 600,
                  padding: '0 0.5rem 0 0.5rem',
                  textAlign: 'center',
                }}>
                {firstName ? `${firstName} ${lastName}` : ''}
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                {/* <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit">
                  <Badge>
                    <SettingsOutlined sx={{ color: '#565656' }} />
                  </Badge>
                </IconButton> */}
                {hasChatPermissions ? (
                  <NavLink to={'/messages'}>
                    <IconButton
                      size="large"
                      aria-label="show new notifications"
                      color="inherit">
                      {hasAnyUnseenMessages ? (
                        <Badge variant={'dot'} sx={styles.NotificationStyles}>
                          <EmailOutlined sx={{ color: '#565656' }} />
                        </Badge>
                      ) : (
                        <EmailOutlined sx={{ color: '#565656' }} />
                      )}
                    </IconButton>
                  </NavLink>
                ) : (
                  <></>
                )}

                <IconButton
                  sx={{ color: '#565656', borderRadius: '4px' }}
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleLogout}
                  color="inherit">
                  <Logout sx={{ mr: 1.5 }} />
                  <Box typography="body1">Log Out123</Box>
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit">
                  <MoreIcon sx={{ color: '#565656' }} />
                </IconButton>
              </Box>
            </>
          ) : (
            <Icon
              sx={{
                width: '140px',
                height: '60px',
                display: { sm: 'block' },
              }}>
              <img
                src={Logo}
                alt="captain_words_logo"
                height="100%"
                width="100%"
              />
            </Icon>
          )}
        </Toolbar>
      </AppBar>
      <HeaderMobileMenu
        id={mobileMenuId}
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        isMobileMenuOpen={isMobileMenuOpen}
        handleMobileMenuClose={handleMobileMenuClose}
        handleProfileMenuOpen={handleProfileMenuOpen}
      />
      {/* <HeaderMenu
        id={menuId}
        anchorEl={anchorEl}
        isMenuOpen={isMenuOpen}
        handleMenuClose={handleMenuClose}
      /> */}
    </Box>
  );
};

export default Header;
