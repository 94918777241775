import { Endpoint, Method } from 'enums/APIEndpointEnum';
import {
  OrderLineStatusActionEdit,
  OrderLineStatusActionEntry,
} from 'types/orderLineStatusesTypes';
import apiCall from './apiCall';

const getOrderLineStatusesApi = () =>
  apiCall({
    url: Endpoint.OrderLineStatuses,
    method: Method.Get,
  });

const getOrderLineStatusesManageApi = () =>
  apiCall({
    url: Endpoint.OrderLineStatusesManage,
    method: Method.Get,
  });

const addOrderLineStatusesApi = (data: OrderLineStatusActionEntry) =>
  apiCall({
    url: Endpoint.OrderLineStatuses,
    method: Method.Post,
    data,
  });

const deleteOrderLineStatusesApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.OrderLineStatuses,
    method: Method.Delete,
    urlSuffix,
  });

const editOrderLineStatusesApi = ({
  uuid,
  data,
}: OrderLineStatusActionEdit) => {
  return apiCall({
    url: Endpoint.OrderLineStatuses,
    urlSuffix: uuid,
    method: Method.Put,
    data,
  });
};

export {
  getOrderLineStatusesApi,
  getOrderLineStatusesManageApi,
  addOrderLineStatusesApi,
  editOrderLineStatusesApi,
  deleteOrderLineStatusesApi,
};
