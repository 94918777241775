import { memo, useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ContentWrapper,
  EditTable,
  EmployeeDetail,
  Goback,
  PageLayout,
} from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ordersActions } from 'store/slices/orderEntriesSlice';
import { actions as orderLinesActions } from 'store/slices/orderLinesSlice';
import l from 'helpers/l';
import { RootState } from 'store';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { getFullName } from 'helpers/text';
import moment from 'moment';
import { ModalType } from 'enums/ModalEnums';
import { actions as commonActions } from 'store/slices/common';
import {
  GridRenderCellParams,
  GridRowParams,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { Check as CheckIcon } from '@mui/icons-material';
import colors from 'styles/colors';
import { getDateColor } from 'config/utilsFunctions';
import { numberOfDays } from 'constants/globalVariables';
import HistoryIcon from '@mui/icons-material/History';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  mainWrapper: {
    '& .MuiBadge-dot': {
      display: 'none',
    },
    '& .hasUnreadMessages.MuiDataGrid-row': {
      '& .MuiBadge-dot': {
        display: 'block',
      },
    },
  },
}));

function OrderDetailsPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location: any = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [sort, setSort] = useState({});

  const { orderLinesWithUnreadMessages } = useSelector(
    (state: RootState) => state?.messages,
  );
  const orderData: any = useSelector(
    (state: RootState) => state?.orderEntries?.orderEntry,
  );

  const contactPersonUuid = useSelector(
    (state: RootState) => state?.orderEntries?.orderEntry?.contactPerson?.uuid,
  );

  const orderLines: any = useSelector(
    (state: RootState) => state?.orderLines?.items,
  );

  const meta: any = useSelector(
    (state: RootState) => state?.orderLines.tableMeta,
  );

  const handlePage = useCallback(
    (page: number) => {
      const params = { ...sort, orderUuid: id, page: page + 1 };
      dispatch(orderLinesActions.sendOrderLinesFilters(params));
    },
    [dispatch, sort],
  );

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: item.field,
        order: item?.sort?.toUpperCase(),
      }))[0];

      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });
      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
        orderUuid: id,
      };
      dispatch(orderLinesActions.sendOrderLinesFilters(params));
    },
    [dispatch],
  );

  const editEntryHandler = useCallback(
    () => navigate(`/edit-order/${orderData?.uuid}`),
    [navigate, orderData?.uuid],
  );

  const handleGoToMessages = useCallback(
    (params: GridRowParams) => {
      navigate('/messages', { state: params.id });
    },
    [navigate],
  );

  const deleteEntryHandler = useCallback(() => {
    dispatch(
      commonActions.showModal({
        type: ModalType.OrderEntriesDelete,
        data: { orderEntries: orderData?.uuid } as any,
      }),
    );
  }, [dispatch, orderData?.uuid]);

  const cancelAllLinesHandler = useCallback(() => {
    dispatch(
      commonActions.showModal({
        type: ModalType.OrderEntriesDelete,
        data: { orderEntries: id, modalType: 'cancelModal' } as any,
      }),
    );
  }, [dispatch, orderData?.uuid]);

  const addEntryHandler = useCallback(() => {
    navigate(`/add-order-line/${orderData?.uuid}`);
  }, [navigate, orderData?.uuid]);

  const editEntryHandlerOrderLine = useCallback(
    (params: GridRowParams) => {
      navigate(`/edit-order-line/${orderData?.uuid}/${params?.row?.uuid}`);
    },
    [navigate, orderData?.uuid],
  );

  const copyEntryHandler = useCallback(
    (params: GridRowParams) => {
      const objToSend = {
        ...params.row,
        isCorrection: orderData?.isCorrection,
        hasLocation: true,
      };
      dispatch(
        orderLinesActions.getOrderLine({ orderLineUuid: params.row.uuid }),
      );

      navigate(`/duplicate-order-line/${orderData?.uuid}`, {
        state: objToSend,
      });
    },
    [navigate, orderData?.uuid],
  );

  useEffect(() => {
    location.state = null;
  }, []);

  const duplicateHandlerOrderLine = () => {
    dispatch(
      commonActions.showModal({
        type: ModalType.DuplicateOrderLineModal,
        data: {
          contactPersonUuid,
          orderId: id,
          isCorrection: orderData?.isCorrection,
        } as any,
      }),
    );
  };

  const deleteEntryHandlerOrderLine = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.OrderEntryLineDeleteModal,
          data: { orderLineEntry: params.row.uuid, orderEntry: id } as any,
        }),
      );
    },
    [dispatch],
  );

  const calHistoryOfStatussesModal = useCallback(
    (data) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.StatusHistoryModal,
          data,
        }),
      );
    },
    [dispatch],
  );

  const handleClassName = useCallback(
    (params: GridRowParams) => {
      let className = '';

      orderLinesWithUnreadMessages?.map((item) => {
        if (item === params.row.uuid) {
          className = 'hasUnreadMessages';
        }
      });

      return className;
    },
    [orderLinesWithUnreadMessages],
  );

  useEffect(() => {
    const data = {
      uuid: id,
    };
    dispatch(ordersActions.getOrderEntryItem({ data }));
    const orderData = {
      orderUuid: id,
    };

    dispatch(orderLinesActions.getOrderLinesByOrder(orderData));
  }, [dispatch]);

  if (!orderData) {
    return (
      <PageLayout title="Order" fullWidth={true}>
        <Goback text="back to list" location={'/orders-entries'} />
        <div>Order not found</div>
      </PageLayout>
    );
  }
  return (
    <PageLayout
      title={`${l('PAGES.ORDER')} #${orderData?.id}`}
      fullWidth
      className={classes.mainWrapper}>
      <Goback text="back to list" location={'/orders-entries'} />
      <ContentWrapper mb="20px">
        <Box minWidth={600}>
          <Box mb="20px">
            <Grid container spacing={4} wrap="wrap">
              <Grid item>
                <EmployeeDetail
                  title="Client"
                  text={orderData?.contactPerson?.client?.internalName}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Contact Person"
                  text={orderData?.contactPerson?.nick}
                  //  text={getFullName(orderData?.contactPerson)}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  type="internalUrl"
                  title="Received by"
                  text={getFullName(orderData?.receivedBy)}
                  link={`/employees/${orderData?.receivedBy?.uuid}`}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Order Date"
                  text={moment(orderData?.orderedAt).format('DD.MM.YYYY')}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Created at"
                  text={moment(orderData?.createdAt).format('DD.MM.YYYY HH:mm')}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Updated at"
                  text={moment(orderData?.updatedAt).format('DD.MM.YYYY HH:mm')}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Due Date/Hour"
                  text={moment(orderData?.dueDatetime).format(
                    'DD.MM.YYYY HH:mm',
                  )}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Date Critical"
                  text={orderData?.dateCritical}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Correction"
                  type="check"
                  text={orderData?.isCorrection ? '1' : ''}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Rate per Article  Client"
                  type="check"
                  text={orderData?.isRatePerArticleClient ? '1' : ''}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Rate per Article Writer"
                  type="check"
                  text={orderData?.isRatePerArticleWriter ? '1' : ''}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="No Charge"
                  type="check"
                  text={orderData?.isNoCharge ? '1' : ''}
                />
              </Grid>
              <Grid item>
                <EmployeeDetail title="Comments" text={orderData?.comments} />
              </Grid>
              <Grid item>
                <EmployeeDetail
                  title="Client General Notes"
                  text={orderData?.clientGeneralNotesUrl}
                />
              </Grid>
            </Grid>
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              marginTop="20px">
              <Button variant="contained" onClick={editEntryHandler}>
                Edit
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={cancelAllLinesHandler}>
                Cancel all lines
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={deleteEntryHandler}>
                Delete
              </Button>
            </Stack>
          </Box>
        </Box>
      </ContentWrapper>
      <EditTable
        rows={orderLines?.length ? orderLines : []}
        columns={[
          {
            field: 'orderLineNumber',
            headerName: 'Line',
            width: 120,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.lineNumber,
          },
          {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params: GridRenderCellParams) => (
              <Typography
                color={params.row?.lastStatusLogEntry?.orderLineStatus?.color}
                fontWeight={600}>
                {params.row?.lastStatusLogEntry?.orderLineStatus?.name}
                <span
                  onClick={() =>
                    calHistoryOfStatussesModal({
                      uuid: params.row?.uuid,
                      orderNumber: orderData?.id,
                    })
                  }>
                  <HistoryIcon
                    fontSize="medium"
                    sx={{ color: '#aaa', marginLeft: '10px' }}
                  />
                </span>
              </Typography>
            ),
          },
          {
            field: 'statusDate',
            headerName: 'Status Date',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row?.lastStatusLogEntry?.createdAt).format(
                'DD.MM.YYYY',
              ),
          },
          {
            field: 'reference',
            headerName: 'Reference',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.clientReference,
          },
          {
            field: 'articleType',
            headerName: 'Article Type',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.articleType?.name,
          },
          {
            field: 'sourceLanguage',
            headerName: 'Source Language',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.sourceLanguage?.name,
          },
          {
            field: 'language',
            headerName: 'Language',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.language?.name,
          },
          {
            field: 'quantity',
            headerName: 'Quantity',
            width: 150,
          },
          {
            field: 'dueDatetime',
            headerName: 'Due Date/Time',
            width: 150,
            renderCell: (params: GridRenderCellParams) => (
              <Typography
                color={getDateColor(params.row?.dueDatetime, numberOfDays)}
                fontWeight={600}>
                {params.row?.dueDatetime !== null
                  ? moment(params.row?.dueDatetime).format('DD.MM.YYYY')
                  : moment(orderData?.orderedAt).format('DD.MM.YYYY')}
              </Typography>
            ),
          },
          {
            field: 'dateCritical',
            headerName: 'Due Date Critical',
            width: 150,
          },
          {
            field: 'projectManager',
            headerName: 'Project Manager',
            width: 150,
            renderCell: (params: GridRenderCellParams) => (
              <Link
                to={`/employees/${params.row?.pmEmployee?.uuid}`}
                style={{ textDecoration: 'none' }}>
                <Typography color={colors.info} fontWeight={600}>
                  {params?.row?.pmEmployee
                    ? getFullName(params?.row?.pmEmployee)
                    : ''}
                </Typography>
              </Link>
            ),
          },
          {
            field: 'projectManagerDueDatetime',
            headerName: 'Project Manager Due Date/Time',
            width: 200,
            renderCell: (params: GridRenderCellParams) => (
              <Typography
                color={getDateColor(params.row?.pmDueDateTime, numberOfDays)}
                fontWeight={600}>
                {moment(params.row?.pmDueDateTime).format('DD.MM.YYYY')}
              </Typography>
            ),
          },
          {
            field: 'clientInstructions',
            headerName: 'Client Instructions',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.clientInstructionsUrl,
          },
          {
            field: 'isNoEdit',
            headerName: 'No Edit',
            width: 150,
            renderCell: (params: any) => {
              return params?.value ? <CheckIcon color="secondary" /> : <></>;
            },
          },
          {
            field: 'project',
            headerName: 'Project',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.project?.name,
          },
          {
            field: 'createdAt',
            headerName: 'Created / Updated At',
            width: 200,
            renderCell: (params: GridRenderCellParams) => (
              <Box>
                <Typography>
                  {moment(params.row?.createdAt).format('DD.MM.YYYY. HH:mm')}
                </Typography>
                <Typography>
                  {moment(params.row?.updatedAt).format('DD.MM.YYYY. HH:mm')}
                </Typography>
              </Box>
            ),
          },
        ]}
        onAdd={addEntryHandler}
        onMessage={handleGoToMessages}
        onEdit={editEntryHandlerOrderLine}
        onCopy={copyEntryHandler}
        onDelete={deleteEntryHandlerOrderLine}
        editColumnWidth={160}
        getRowClassName={handleClassName}
        onSortModelChange={handleSort}
        pagination
        page={meta?.currentPage - 1 || 0}
        rowCount={meta?.totalItems}
        onPageChange={handlePage}
        pageSize={meta?.itemsPerPage}
        rowsPerPageOptions={[meta?.itemsPerPage]}
        paginationMode="server"
        sortingMode="server"
      />
      <Button
        variant="outlined"
        color="primary"
        sx={{ marginTop: '15px', fontSize: '15px' }}
        onClick={duplicateHandlerOrderLine}>
        Duplicate from another order
      </Button>
    </PageLayout>
  );
}

export default memo(OrderDetailsPage);
