import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      minWidth: '200px',
      maxWidth: '250px',
    },
  },
};

const useStyles = makeStyles(() => ({
  wrapperDropdown: {
    '&.multi': {
      padding: 0,
      borderRadius: '15px !important',
      minHeight: '40px !important',
      maxHeight: '55px !important',
      width: '100%',
    },
    '& .formControl': {
      margin: 0,
      backgroundColor: '#fff !important',
    },
    '& .formControl .MuiOutlinedInput-root.MuiInputBase-root': {
      minHeight: '40px',
    },
    '& .selectDropdown': {
      padding: '1px',
      height: '50px',
      minHeight: '40px',
      '& .MuiSelect-select': {
        padding: '15px 15px 15px 10px',
      },
      '& > div > span.MuiCheckbox-root': {
        display: 'none',
      },
    },
  },
}));

interface Dropdown {
  label: string;
  value?: string;
}
interface MultiSelectDropdownProps {
  inputId: string;
  name: string;
  initialData?: Dropdown[] | [];
  defaultValue?: string[];
  className?: string;
  inputValue?: string[];
  formikHook?: any;
}
function MultiSelectDropdown({
  initialData,
  inputId,
  name,
  defaultValue,
  className,
  formikHook,
  inputValue,
}: MultiSelectDropdownProps) {
  const classes = useStyles();

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedItems>) => {
    const { target } = event;
    setSelectedItems(
      // On autofill we get a stringified value.
      typeof target.value === 'string' ? target.value.split(',') : target.value,
    );

    if (formikHook) {
      formikHook.setFieldValue(inputId, target.value);
    }
  };

  useEffect(() => {
    if (inputValue?.length === 0) {
      setSelectedItems([]);
    }
  }, [inputValue]);

  useEffect(() => {
    if (defaultValue && defaultValue?.length > 0) {
      setSelectedItems(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Box className={classes.wrapperDropdown + ' multi'}>
      <FormControl sx={{ m: 1, width: '100%' }} className="formControl">
        {/*<InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>*/}
        <Select
          labelId="demo-multiple-checkbox-label"
          className={`selectDropdown ${className || ''}`}
          id={inputId}
          name={name}
          multiple
          value={selectedItems || inputValue}
          onChange={handleChange}
          //  input={<OutlinedInput label="Tag" />}
          //  renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}>
          {/* @ts-ignore */}
          {initialData?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox
                checked={selectedItems.some((value) => value === item.value)}
              />
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
export default MultiSelectDropdown;
