import { put, call, takeLatest } from 'redux-saga/effects';
import { actions as commonActions } from 'store/slices/common';
import {
  getLanguageRateAPI,
  addLanguageRateAPI,
  editLanguageRateAPI,
  deleteLanguageRateAPI,
} from 'store/api/languageRateAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { APIResult } from 'types/APITypes';
import { actions as languagesRateActions } from '../slices/languagesRateSlice';

function* getLanguageRatesWithFilters(action: PayloadAction<string>) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(getLanguageRateAPI, params);

  if (result?.data) {
    yield put(languagesRateActions.setLanguageRates(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addLanguageRate(action: PayloadAction<any>) {
  const data = action.payload;
  const filters = action.payload.filters;

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(addLanguageRateAPI, data);
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getLanguageRateAPI, {
      page: 1,
      ...filters,
    });
    if (result?.data) {
      yield put(languagesRateActions.setLanguageRates(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added new item to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

function* editLanguageRate(action: PayloadAction<any>) {
  const { urlSuffix } = action.payload;
  const data = action.payload;
  const filters = action?.payload?.filters || {};

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(
    editLanguageRateAPI,
    urlSuffix,
    data,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getLanguageRateAPI, {
      page: 1,
      ...filters,
    });
    if (result?.data) {
      yield put(languagesRateActions.setLanguageRates(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited this item`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

function* deleteLanguageRate(action: PayloadAction<any>) {
  const { urlSuffix } = action.payload;

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(
    deleteLanguageRateAPI,
    urlSuffix,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getLanguageRateAPI, {
      page: 1,
    });
    if (result?.data) {
      yield put(languagesRateActions.setLanguageRates(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted this item from the list`,
        severity: 'error',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

export default function* ARLanguagesRateWatcher() {
  yield takeLatest(
    languagesRateActions.getLanguageRates.type,
    getLanguageRatesWithFilters,
  );
  yield takeLatest(languagesRateActions.addLanguageRate.type, addLanguageRate);
  yield takeLatest(
    languagesRateActions.editLanguageRate.type,
    editLanguageRate,
  );
  yield takeLatest(
    languagesRateActions.deleteLanguageRate.type,
    deleteLanguageRate,
  );
}
