import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/clientsSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  getClientsApi,
  addClientsApi,
  editClientsApi,
  deleteClientsApi,
  sendClientsFiltersApi,
} from 'store/api/clientsAPI';
import { APIResult } from 'types/APITypes';
import { genericErrorMessageHelper } from 'helpers/messageHelpers';

function* getClients() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any> = yield call(getClientsApi);
  if (result?.data) {
    yield put(actions.setClients(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addClients(action: any) {
  const data = action.payload;
  const { error }: APIResult<any> = yield call(addClientsApi, data);

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getClientsApi);
    if (result?.data) {
      yield put(actions.setClients(result?.data));
      data.navigate('/clients');
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully a client`,
        severity: 'success',
      }),
    );
  }

  yield put(commonActions.setLoading(false));
}

function* editClients(action: any) {
  const urlSuffix = action.payload.uuid;
  const data = action.payload;
  const { error }: APIResult<any> = yield call(
    editClientsApi,
    urlSuffix,
    data,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getClientsApi);
    if (result?.data) {
      yield put(actions.setClients(result?.data));
      data.navigate('/clients');
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited client in the list`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }

  yield put(commonActions.setLoading(false));
}

function* deleteClients(action: any) {
  const urlSuffix = action.payload;
  const { result, error }: APIResult<never> = yield call(
    deleteClientsApi,
    urlSuffix,
  );

  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Cannot delete client due to existing relations.',
        severity: 'error',
      }),
    );
    yield put(commonActions.hideModal());
  } else if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
    yield put(commonActions.hideModal());
  } else if (result?.status === 200) {
    const { result }: APIResult<any> = yield call(getClientsApi);
    if (result?.data) {
      yield put(actions.setClients(result?.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted client from the list`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }

  yield put(commonActions.setLoading(false));
}

function* sendClientsFilters(action: any) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(sendClientsFiltersApi, params);

  if (result?.data) {
    yield put(actions.setClients(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

export default function* ClientsWatcher() {
  yield takeLatest(actions.getClients.type, getClients);
  yield takeLatest(actions.addClients.type, addClients);
  yield takeLatest(actions.editClients.type, editClients);
  yield takeLatest(actions.deleteClients.type, deleteClients);
  yield takeLatest(actions.sendClientsFilters.type, sendClientsFilters);
}
