import { createSlice } from '@reduxjs/toolkit';
import { PaginationMeta } from 'types/genericTypes';
import { ClientsType } from 'types/clientsTypes';

export interface Clients {
  items: ClientsType[];
  meta: PaginationMeta;
}

const initialState: Clients = {
  items: [
    {
      createdAt: '',
      name: '',
      internalName: '',
      source: '',
      timezone: {
        name: '',
        uuid: '',
      },
      type: '',
      updatedAt: '',
      id: null,
      uuid: '',
      writerLevel: '',
    },
  ],
  meta: {},
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    getClients: (state) => state,
    setClients: (state, action: any) => {
      state.items = action.payload.items;
      state.meta = action.payload.meta;
    },
    addClients: (state) => state,
    editClients: (state) => state,
    deleteClients: (state) => state,
    sendClientsFilters: (state) => state,
  },
});
export const actions: any = clientsSlice.actions;
export default clientsSlice.reducer;
