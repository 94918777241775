import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/targetAudienceSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  addTargetAudienceApi,
  getTargetAudienceApi,
  editTargetAudienceApi,
  deleteTargetAudienceApi,
} from 'store/api/targetAudienceAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { APIResult } from 'types/APITypes';

function* getTargetAudience() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any> = yield call(getTargetAudienceApi);
  if (result?.data?.length) {
    yield put(actions.setTargetAudience(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addTargetAudience(action: PayloadAction<any>) {
  const data = action.payload;
  const { result, error }: APIResult<any> = yield call(
    addTargetAudienceApi,
    data,
  );
  if (result?.status === 201) {
    const { result }: APIResult<any> = yield call(getTargetAudienceApi);
    if (result?.data?.length) {
      yield put(actions.setTargetAudience(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.name} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.name} already exist!`,
        severity: 'error',
      }),
    );
  }
}

function* deleteTargetAudience(action: PayloadAction<any>) {
  const { uuid } = action.payload;
  const { result }: APIResult<any> = yield call(
    deleteTargetAudienceApi,
    uuid! as string,
  );
  if (result?.status === 200) {
    const { result }: APIResult<any> = yield call(getTargetAudienceApi);
    if (result?.data?.length) {
      yield put(actions.setTargetAudience(result.data));
    }
    yield put(commonActions.hideModal());
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted item from the list`,
        severity: 'info',
      }),
    );
  }
}

function* editTargetAudience(action: PayloadAction<any>) {
  const urlSuffix = action.payload.uuid;
  const data = action.payload;

  const { result, error }: APIResult<any> = yield call(
    editTargetAudienceApi,
    urlSuffix,
    data,
  );
  if (result?.status === 200) {
    const { result }: APIResult<any> = yield call(getTargetAudienceApi);
    if (result?.data?.length) {
      yield put(actions.setTargetAudience(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${data.name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Target audience name is taken!',
        severity: 'error',
      }),
    );
  }
}

export default function* TargetAudienceWatcher() {
  yield takeLatest(actions.getTargetAudience.type, getTargetAudience);
  yield takeLatest(actions.addTargetAudience.type, addTargetAudience);
  yield takeLatest(actions.deleteTargetAudience.type, deleteTargetAudience);
  yield takeLatest(actions.editTargetAudience.type, editTargetAudience);
}
