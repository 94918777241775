import { FC, memo, useCallback } from 'react';
import { actions as orderEntriesActions } from 'store/slices/orderEntriesSlice';
import { useDispatch } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { Typography } from '@mui/material';
import {useNavigate} from "react-router-dom";

const OrderEntriesCancelDeleteModal: FC<GenericModalProps> = ({ data, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCancelModal = data.modalType === 'cancelModal';
  const modalDeleteText = 'to delete this order entry from the table';
  const modalCancelText = 'to cancel all order lines'
    const navigation = (params: string) => navigate(params)

  const confirmHandler = useCallback(() => {

      if(isCancelModal){
          dispatch(orderEntriesActions.cancelAllLines(data.orderEntries));
      }else{
          const params = {
              urlSuffix:data.orderEntries,
              navigator: navigation,
          }
          dispatch(orderEntriesActions.deleteEntries(params))
      }
  }, [dispatch]);

  return (
    <ConfirmationModal onCancel={onClose} onConfirm={confirmHandler} >
      <Typography>
          {`Are you sure you want ${isCancelModal ? modalCancelText : modalDeleteText} ?`}
      </Typography>
    </ConfirmationModal>
  );
};

export default memo(OrderEntriesCancelDeleteModal);
