import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/projectsSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  addProjectsApi,
  getProjectsApi,
  editProjectsApi,
  deleteProjectsApi,
  sendProjectsApi,
  getContactPersonsApi, getProjectsByContactPersonApi,
} from 'store/api/projectsAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { APIResult } from 'types/APITypes';

function* getProjects() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any> = yield call(getProjectsApi);
  if (result?.data) {
    yield put(actions.setProjects(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* getProjectsByContactPerson(action: PayloadAction<any>) {
  yield put(commonActions.setLoading(true));
  const params = action.payload
  const { result }: APIResult<any> = yield call(getProjectsByContactPersonApi, params);
  if (result?.data) {
    yield put(actions.setProjects(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addProjects(action: PayloadAction<any>) {
  const data = action.payload;
  const { result, error }: APIResult<any> = yield call(addProjectsApi, data);
  if (result?.status === 201) {
    const { result }: APIResult<any> = yield call(getProjectsApi);
    if (result?.data) {
      yield put(actions.setProjects(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.name} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.name} already exist!`,
        severity: 'error',
      }),
    );
  }
}

function* deleteProjects(action: PayloadAction<any>) {
  const { uuid } = action.payload;
  const { result }: APIResult<any> = yield call(
    deleteProjectsApi,
    uuid! as string,
  );
  if (result?.status === 200) {
    const { result }: APIResult<any> = yield call(getProjectsApi);
    if (result?.data) {
      yield put(actions.setProjects(result.data));
    }
    yield put(commonActions.hideModal());
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted item from the list`,
        severity: 'info',
      }),
    );
  }
}

function* editProjects(action: PayloadAction<any>) {
  const { uuid, data } = action.payload;

  const { result, error }: APIResult<any> = yield call(
    editProjectsApi,
    uuid,
    data,
  );
  if (result?.status === 200) {
    const { result }: APIResult<any> = yield call(getProjectsApi);
    if (result?.data) {
      yield put(actions.setProjects(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${data.name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Target audience name is taken!',
        severity: 'error',
      }),
    );
  }
}

function* sendProjectsFilters(action: any) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(sendProjectsApi, params);

  if (result?.data) {
    yield put(actions.setProjects(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* getContactPersons() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any> = yield call(getContactPersonsApi);
  if (result?.data) {
    yield put(actions.setContactPersons(result.data));
  }
  yield put(commonActions.setLoading(false));
}

export default function* ProjectsWatcher() {
  yield takeLatest(actions.getProjects.type, getProjects);
  yield takeLatest(actions.addProjects.type, addProjects);
  yield takeLatest(actions.deleteProjects.type, deleteProjects);
  yield takeLatest(actions.editProjects.type, editProjects);
  yield takeLatest(actions.sendProjectsFilters.type, sendProjectsFilters);
  yield takeLatest(actions.getContactPersons.type, getContactPersons);
  yield takeLatest(actions.getProjectsByContactPerson.type, getProjectsByContactPerson);
}
