import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { Mail } from 'components';
import Url from '../Url/Url';
import { empValues } from 'enums/employeeEnums';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
interface EmployeeDetailProps {
  title: empValues | string;
  email?: boolean;
  link?: string;
  text: string;
  type?: 'externalUrl' | 'internalUrl' | 'check';
  color?: string;
}

// @TODO - Rename to a more generic name
// cleanup interface

const EmployeeDetail: FC<EmployeeDetailProps> = ({
  title,
  text,
  link,
  email,
  type,
  color,
}) => {
  return (
    <Grid container flexDirection={'column'}>
      <Grid item>
        <Typography variant="subtitle2">{title}</Typography>
        {email ? (
          <Mail fontWeight={600} email={text}>
            {text}
          </Mail>
        ) : type === 'externalUrl' ? (
          <Url url={link || ''} text={text} external={true} />
        ) : type === 'internalUrl' ? (
          <Url url={link || ''} text={text} external={false} />
        ) : type === 'check' ? (
          text ? (
            <CheckIcon color="success" />
          ) : (
            <CloseIcon color="action" />
          )
        ) : (
          <Typography fontWeight={600} color={color || ''}>
            {text}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
export default EmployeeDetail;
