import { LanguageEditEntry, LanguageEntry } from 'types/languageTypes';
import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

export const sendLanguagesFiltersApi = (params: string) =>
  apiCall({
    url: Endpoint.Languages,
    method: Method.Get,
    params,
  });

const getLanguagesApi = () =>
  apiCall({
    url: Endpoint.Languages,
    method: Method.Get,
  });

const addLanguageApi = (data: LanguageEntry) =>
  apiCall({
    url: Endpoint.Languages,
    method: Method.Post,
    data,
  });

const deleteLanguageApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.Languages,
    method: Method.Delete,
    urlSuffix,
  });

const editLanguageApi = (payload: LanguageEditEntry) => {
  const { uuid: urlSuffix, ...data } = payload;
  return apiCall({
    url: Endpoint.Languages,
    method: Method.Put,
    data,
    urlSuffix,
  });
};

export { getLanguagesApi, addLanguageApi, deleteLanguageApi, editLanguageApi };
