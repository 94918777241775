import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ExpertiseResult} from 'types/expertiseTypes';
import {GenericIdNameType} from 'types/genericTypes';

export interface Expertise {
  expertise: GenericIdNameType[];
  meta: any;
}

const initialState: Expertise = {
  expertise: [],
  meta: {},
};

export const expertiseSlice = createSlice({
  name: 'expertise',
  initialState,
  reducers: {
    getExpertise: (state) => state,
    setExpertise: (state, action: PayloadAction<ExpertiseResult>) => {
      return {...state, expertise: action?.payload?.items, meta: action.payload.meta}
    },
    sendExpertiseFilters: (state) => state,
    addExpertise: (state) =>
      state,
    deleteExpertise: (state) =>
      state,
    editExpertise: (state) =>
      state,
  },
});
export const actions: any = expertiseSlice.actions;
export default expertiseSlice.reducer;
