import { FC, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import colors from 'styles/colors';

interface MailProps {
  email: string;
  fontWeight?: number;
}

const Mail: FC<PropsWithChildren<MailProps>> = ({
  email,
  fontWeight,
  children,
}) => {
  return (
    <a href={`mailto:${email}`} style={{ textDecoration: 'none' }}>
      <Typography fontWeight={fontWeight} color={colors.info}>
        {children}
      </Typography>
    </a>
  );
};
export default Mail;
