import { FC, memo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormLabel, Typography } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps } from 'types/genericTypes';
import { Switch } from '@mui/material';

const validationSchemaEdit = Yup.object().shape({
  wordsPerHour: Yup.number()
    .min(0, 'Words per hour must be greater or equal to 0')
    .required('Required'),
});

const validationSchemaAdd = validationSchemaEdit.shape({
  roleCode: Yup.string().required('Required'),
});

const defaultValues = {
  isActive: false,
  wordsPerHour: 0,
  roleCode: '',
};

type EmployeeViewRolesFormProps = {
  initialValues?: any;
  title: string;
  employeeName?: string;
  onSubmit: (values: any) => void;
  type: 'add' | 'edit';
  values?: any;
} & GenericModalProps;

const EmployeeViewRolesForm: FC<EmployeeViewRolesFormProps> = ({
  onClose,
  initialValues = defaultValues,
  employeeName,
  title,
  type,
  onSubmit,
  values,
}) => {
  const validationSchema =
    type === 'edit' ? validationSchemaEdit : validationSchemaAdd;
  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit(values);
    },
  });
  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <FormLabel>Employee</FormLabel>
      <Box sx={{ marginBottom: '30px' }}>
        <Typography fontWeight={600}>{employeeName}</Typography>
      </Box>

      <FormLabel>Role</FormLabel>
      {/* this is for dev purposes the check shuld be different */}
      {type === 'edit' ? (
        <Box sx={{ marginBottom: '30px' }}>
          <Typography fontWeight={600}>{formik.values.roleCode}</Typography>
        </Box>
      ) : (
        <TextField
          select
          name="roleCode"
          sx={{ width: '12rem', display: 'flex' }}
          size="small"
          value={formik.values.roleCode}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.roleCode)}
          helperText={formik.errors.roleCode}
          pressEnter={formik.submitForm}>
          {values}
        </TextField>
      )}
      <Box mb="10px">
        <FormLabel>is active</FormLabel>
        <Switch
          name="isActive"
          checked={formik.values.isActive}
          onChange={formik.handleChange}
        />
      </Box>
      <FormLabel>Words per hour</FormLabel>
      <TextField
        name="wordsPerHour"
        fullWidth
        size="small"
        type="number"
        value={formik.values.wordsPerHour}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.wordsPerHour)}
        helperText={formik.errors.wordsPerHour}
        pressEnter={formik.submitForm}
      />
    </SimpleFormModal>
  );
};

export default memo(EmployeeViewRolesForm);
