import { put, call, takeLatest } from 'redux-saga/effects';
import { actions as orderEntriesActions} from 'store/slices/orderEntriesSlice';
import { actions as commonActions } from 'store/slices/common';
import {
    getOrderEntriesApi,
    getOrderEntryItemApi,
    addOrderEntriesApi,
    editOrderEntriesApi,
    deleteOrderEntriesApi,
    sendOrderEntriesFiltersApi, cancelAllOrderLinesApi,
} from 'store/api/orderEntriesAPI';
import { APIResult } from 'types/APITypes';
import { genericErrorMessageHelper } from 'helpers/messageHelpers';
import {getOrderLinesByOrderApi} from "../api/orderLinesAPI";
import {actions as orderLinesActions} from 'store/slices/orderLinesSlice';

function* getOrderEntries(action: any) {
  yield put(commonActions.setLoading(true));

  const { result }: APIResult<any> = yield call(getOrderEntriesApi, action.payload);
  if (result?.data) {
    yield put(orderEntriesActions.setOrderEntries(result?.data));
  }
  yield put(commonActions.setLoading(false));
}
function* getOrderEntryItem(action: any) {

  const urlSuffix = action.payload.data.uuid;

  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any> = yield call(
    getOrderEntryItemApi,
    urlSuffix,
  );
  if (result?.data) {
    yield put(orderEntriesActions.setOrderEntryItem(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addEntries(action: any) {
  const data = action.payload;
  const { error }: APIResult<any> = yield call(
    addOrderEntriesApi,
    data,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getOrderEntriesApi);
    if (result?.data) {
      yield put(orderEntriesActions.setOrderEntries(result?.data));
      data.navigate('/orders-entries');
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully add order`,
        severity: 'success',
      }),
    );
  }

  yield put(commonActions.setLoading(false));
}

function* editEntries(action: any) {
  const urlSuffix = action.payload.uuid;
  const data = action.payload;

  const { error }: APIResult<any> = yield call(
    editOrderEntriesApi,
    urlSuffix,
    data,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getOrderEntriesApi);
    if (result?.data) {
      yield put(orderEntriesActions.setOrderEntries(result?.data));
      data.navigate('/orders-entries');
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited order entry in the list`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }

  yield put(commonActions.setLoading(false));
}

function* cancelAllLines(action: any) {
  const urlSuffix = action.payload
  const { result, error }: APIResult<never> = yield call(
      cancelAllOrderLinesApi,
      urlSuffix,
  );

  if (error) {
    yield put(
        commonActions.snackBar({
          open: true,
          message: genericErrorMessageHelper(error),
          severity: 'error',
        }),
    );
  } else if (result?.status === 200) {
    const { result }: APIResult<any> = yield call(  getOrderLinesByOrderApi, urlSuffix);
    // asd
      if (result?.data) {
          yield put(orderLinesActions.setOrderLines(result?.data));
      }
    yield put(
        commonActions.snackBar({
          open: true,
          message: `You have successfully canceled all order lines!`,
          severity: 'info',
        }),
    );

  }
  yield put(commonActions.hideModal());
  yield put(commonActions.setLoading(false));
}

function* deleteEntries(action: any) {
  const payload = action.payload
  const urlSuffix = payload.urlSuffix
  const navigation = payload.navigator
  const { result, error }: APIResult<never> = yield call(
    deleteOrderEntriesApi,
    urlSuffix,
  );

  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Cannot delete order entry due to existing relations.',
        severity: 'error',
      }),
    );
    yield put(commonActions.hideModal());
  } else if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
    yield put(commonActions.hideModal());
  } else if (result?.status === 200) {
    const { result }: APIResult<any> = yield call(getOrderEntriesApi);
    if (result?.data) {
      yield put(orderEntriesActions.setOrderEntries(result?.data));
    }
    navigation('/orders-entries');
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted order entry from the list`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }

  yield put(commonActions.setLoading(false));
}

function* sendEntriesFilters(action: any) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(sendOrderEntriesFiltersApi, params);

  if (result?.data) {
    yield put(orderEntriesActions.setOrderEntries(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

export default function* OrderEntriesWatcher() {
  yield takeLatest(orderEntriesActions.getOrderEntries.type, getOrderEntries);
  yield takeLatest(orderEntriesActions.getOrderEntryItem.type, getOrderEntryItem);
  yield takeLatest(orderEntriesActions.addEntries.type, addEntries);
  yield takeLatest(orderEntriesActions.editEntries.type, editEntries);
  yield takeLatest(orderEntriesActions.deleteEntries.type, deleteEntries);
  yield takeLatest(orderEntriesActions.sendEntriesFilters.type, sendEntriesFilters);
  yield takeLatest(orderEntriesActions.cancelAllLines.type, cancelAllLines);
}
