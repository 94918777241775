import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => {
  return {
    root: {
      position: 'relative',
      height: '70vh',
      '& .MuiTypography-h2': {
        fontSize: '40px',
        fontWeight: 700,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          fontSize: '24px',
          paddingLeft: '0.8rem',
        },
      },
      '& .MuiTypography-paragraph': {
        fontSize: '24px',
        fontWeight: 400,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
          paddingLeft: '0.8rem',
        },
      },
    },
    box: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, 50%)',
    },
  };
});
