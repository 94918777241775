import {makeStyles} from '@material-ui/core/styles';
import colors from 'styles/colors';

const styles = makeStyles((theme) => {
    return {
        Modal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            bgcolor: 'background.paper',
            borderRadius: '20px',
            boxShadow: '24',
            p: '4',
        },
        NonEditable: {
            fontFamily: theme.typography.fontFamily,
            padding: '7px 0',
            fontWeight: 700,
        },
        DropDown: {
            '&.MuiFormControl-root': {
                border: 'none',
            },
        },
        //-MuiInput-root
        Test: {
            width: '600',
            height: 'none !important',
        },
        DropdownContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'stretch',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
        },
        PermissionBox: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            position: 'relative',
            fontSize: '13px',
            backgroundColor: colors.mainBackground,
            borderRadius: '10px',
            paddingLeft: '10px',
            margin: '0 9px 7px 0',
        },
        ActionGridContainer: {
            backgroundColor: "white",
            borderRadius: "15px",
            width: '100%',
            display: 'flex',

        },
        PinsGridContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginLeft: '10px',
        },
        DropdownGridContainer: {
            width: '40%',
            marginLeft: '10px',
            marginTop: '15px',
        },
        SectionLabel: {
            marginTop: '15px',
            marginLeft: '10px',
        },
        Font: {
            fontFamily: 'Poppins',
        },
    };
});

export default styles;
