import { FC, memo, useCallback } from 'react';
import { actions as userActions } from 'store/slices/user';
import { useDispatch, useSelector } from 'hooks/redux';
import l from 'helpers/l';
import PermissionsForm from '../../Forms/PermissionsForm';
import { GenericModalProps } from 'types/genericTypes';
import { LanguageEntry } from 'types/languageTypes';

const LanguagesEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const submitHandler = useCallback(
    (values: LanguageEntry) => dispatch(userActions.editPermission(values)),
    [dispatch],
  );

  const entry = useSelector((state) =>
    data ? state.user.permissions.find((entry) => entry?.code === data) : null,
  );

  return entry ? (
    <PermissionsForm
      title={l('EDIT_PERMISSION')}
      onSubmit={submitHandler}
      initialValues={entry}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(LanguagesEditModal);
