import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { EmployeeStatusesAddEntry } from 'types/employeeStatusesTypes';
import apiCall from './apiCall';

const getEmployeeStatusesApi = () =>
  apiCall({
    url: Endpoint.EmployeeStatuses,
    method: Method.Get,
  });

const addEmployeeStatusesApi = (data: EmployeeStatusesAddEntry) =>
  apiCall({
    url: Endpoint.EmployeeStatuses,
    method: Method.Post,
    data,
  });

const deleteEmployeeStatusesApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.EmployeeStatuses,
    method: Method.Delete,
    urlSuffix,
  });

const editEmployeeStatusesApi = (data: EmployeeStatusesAddEntry) => {
  return apiCall({
    url: Endpoint.EmployeeStatuses,
    method: Method.Put,
    data,
  });
};

export {
  getEmployeeStatusesApi,
  addEmployeeStatusesApi,
  editEmployeeStatusesApi,
  deleteEmployeeStatusesApi,
};
