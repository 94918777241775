export const sanitizeNumericInput = (input: any) => {
  let value = input;

  // Remove any non-numeric characters except decimal point
  value = value.replace(/[^0-9.]/g, '');

  // Handle multiple decimal points by keeping only the first one
  const parts = value.split('.');
  if (parts.length > 2) {
    value = parts[0] + '.' + parts.slice(1).join('');
  }

  // Convert to number and ensure non-negative value
  const numValue = +value;
  if (numValue < 0) {
    value = 0;
  }

  return value;
};
