import { useEffect, useCallback, memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store/slices/employeeListSlice';
import { actions as commonActions } from 'store/slices/common';
import { actions as employeeStatusActions } from 'store/slices/employeeStatusesSlice';
import { GridRowParams, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import l from 'helpers/l';
import { PageLayout, EditTable, Filters, TextField } from 'components';
import { FormLabel, Grid } from '@mui/material';
import { columns, dropdown } from './utils';
import { RootState } from 'store';
import { createMenuItems } from 'helpers/dropdown';
import { useFormik } from 'formik';
import { formatFilters, formatSortOrderBy } from 'helpers/filters';
import { isEmpty } from 'lodash';
import { employeeFilter } from 'types/employeeTypes';
import { useNavigate } from 'react-router-dom';
import { ModalType } from 'enums/ModalEnums';

const initialValues: employeeFilter = {
  keyword: '',
  empStatusId: 'All',
  hasUser: 'All',
  userStatus: 'All',
};

const EmployeeListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state: RootState) => state.common.loading);
  const { employees, tableData } = useSelector(
    (state: RootState) => state.employees,
  );

  const [dropdownValues, setDropdownValues] = useState({});
  const [sort, setSort] = useState({});

  const empStatusDropdown = useSelector(
    (state: RootState) => state.employeeStatuses.employeeStatuses,
  ).map((status) => ({ label: status.name, value: status.id }));

  const { setValues, ...formik } = useFormik({
    initialValues,
    onSubmit: (values: employeeFilter) => {
      const formatValues = formatFilters(values);
      setDropdownValues(() => {
        return { ...formatValues };
      });

      if (!isEmpty(formatValues)) {
        const params = { ...formatValues, ...sort };
        dispatch(actions.sendFilters(params));
      } else {
        dispatch(actions.sendFilters(sort));
      }
    },
  });

  const addEntryHandler = useCallback(() => {
    navigate('/add-employee');
  }, [navigate]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      navigate(`/edit-employee/${params.id}`);
    },
    [navigate],
  );

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.EmployeesDelete,
          data: { employeeDetails: params.row } as any,
        }),
      );
    },
    [dispatch],
  );

  const viewEntityHandler = useCallback(
    (params: GridRowParams) => {
      navigate(`/employees/${params.id}`);
    },
    [navigate],
  );

  const onReset = useCallback(() => {
    setValues(initialValues);
    setDropdownValues({});
    dispatch(actions.sendFilters(sort));
  }, [dispatch, setValues, sort]);

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: formatSortOrderBy(item.field),
        order: item?.sort?.toUpperCase(),
      }))[0];
      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });
      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
        ...dropdownValues,
      };
      dispatch(actions.sendFilters(params));
    },
    [dispatch, dropdownValues],
  );

  const handlePage = useCallback(
    (page: number) => {
      const params = { ...dropdownValues, ...sort, page: page + 1 };
      dispatch(actions.sendFilters(params));
    },
    [dispatch, dropdownValues, sort],
  );

  useEffect(() => {
    dispatch(actions.getEmployeesList());
    dispatch(employeeStatusActions.getEmployeeStatuses());
  }, [dispatch]);

  return (
    <PageLayout title={l('PAGES.EMPLOYEES')} fullWidth={true}>
      <Filters handleFilters={formik.submitForm} onReset={onReset}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <FormLabel>Keyword</FormLabel>
            <TextField
              autoHeight
              fullWidth
              name="keyword"
              size="small"
              value={formik.values.keyword}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.keyword)}
            />
          </Grid>
          <Grid item xs={3}>
            <FormLabel>Employee status</FormLabel>
            <TextField
              select
              autoHeight
              fullWidth
              name="empStatusId"
              size="small"
              value={formik.values.empStatusId}
              onChange={formik.handleChange}>
              {createMenuItems([...dropdown.empStatusId, ...empStatusDropdown])}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <FormLabel>Has assigned user</FormLabel>
            <TextField
              select
              autoHeight
              fullWidth
              name="hasUser"
              size="small"
              value={formik.values.hasUser}
              onChange={formik.handleChange}>
              {createMenuItems(dropdown.hasUser)}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <FormLabel>User status</FormLabel>
            <TextField
              select
              autoHeight
              fullWidth
              name="userStatus"
              size="small"
              value={formik.values.userStatus}
              onChange={formik.handleChange}>
              {createMenuItems(dropdown.userStatus)}
            </TextField>
          </Grid>
        </Grid>
      </Filters>
      <EditTable
        rows={employees}
        columns={columns}
        loading={loading}
        onAdd={addEntryHandler}
        onEdit={editEntryHandler}
        onDelete={deleteEntryHandler}
        onView={viewEntityHandler}
        editColumnWidth={130}
        sortedFieldIndex={1}
        onSortModelChange={handleSort}
        pagination
        page={tableData.currentPage - 1 || 0}
        rowCount={tableData?.totalItems}
        onPageChange={handlePage}
        pageSize={tableData?.itemsPerPage}
        // rowsPerPageOptions={[tableData?.itemsPerPage]}
        paginationMode="server"
        sortingMode="server"
      />
    </PageLayout>
  );
};

export default memo(EmployeeListPage);
