import { FC, PropsWithChildren } from 'react';
import { ContentWrapper } from 'components';
import { Box, Button, Stack } from '@mui/material';

interface FormProps {
  onCancel?: () => void;
  onSubmit?: () => void;
  btnSubmitLabel?: string;
  btnCancelLabel?: string;
  className?: string;
  sx?: any;
  contentWrapperSx?: any;
}

const Form: FC<PropsWithChildren<FormProps>> = ({
  onCancel,
  onSubmit,
  btnCancelLabel,
  btnSubmitLabel,
  className = '',
  children,
  sx,
  contentWrapperSx,
}) => {
  return (
    <ContentWrapper sx={contentWrapperSx}>
      <Box className={className} sx={sx}>
        {children}
      </Box>
      <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
        {
          onSubmit &&
          <Button variant="contained" onClick={onSubmit}>
            {btnSubmitLabel}
          </Button>
        }
        {
          onCancel &&
          <Button variant="outlined" color="primary" onClick={onCancel}>
            {btnCancelLabel}
          </Button>
        }

      </Stack>
    </ContentWrapper>
  );
};
export default Form;
