import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getProjectsApi = () => {
  return apiCall({
    url: Endpoint.Projects,
    method: Method.Get,
  });
};

const getProjectsByContactPersonApi = (params?: string) => {
  return apiCall({
    url: Endpoint.Projects,
    method: Method.Get,
    params,
  });
};

const addProjectsApi = (data: any) => {
  return apiCall({
    url: Endpoint.Projects,
    method: Method.Post,
    data,
  });
};

const deleteProjectsApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.Projects,
    method: Method.Delete,
    urlSuffix,
  });
};

const editProjectsApi = (urlSuffix: string, data: any) => {
  return apiCall({
    url: Endpoint.Projects,
    method: Method.Put,
    urlSuffix,
    data,
  });
};

const sendProjectsApi = (params: string) =>
  apiCall({
    url: Endpoint.Projects,
    method: Method.Get,
    params,
  });

const getContactPersonsApi = () => {
  return apiCall({
    url: Endpoint.ContactPersons,
    method: Method.Get,
  });
};

export {
  getProjectsApi,
  addProjectsApi,
  deleteProjectsApi,
  editProjectsApi,
  sendProjectsApi,
  getContactPersonsApi,
  getProjectsByContactPersonApi,
};
