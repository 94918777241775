import { Box, Paper, IconButton, Button, Stack } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { FC, memo, PropsWithChildren } from 'react';
import styles from './ConfirmationModalStyles';
import l from 'helpers/l';

type SimpleFormModalType = {
  onCancel: () => void;
  onConfirm: () => void;
  btnConfirmLabel?: string;
  btnCancelLabel?: string;
};

const ConfirmationModal: FC<PropsWithChildren<SimpleFormModalType>> = ({
  onCancel,
  onConfirm,
  btnConfirmLabel = l('UI_LABELS.OK'),
  btnCancelLabel = l('UI_LABELS.CANCEL'),
  children,
}) => {
  const classes = styles();

  return (
    <Box className={classes.Modal}>
      <Paper style={{ borderRadius: '20px' }}>
        <Box textAlign="right">
          <IconButton color="primary" onClick={onCancel} component="button">
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box padding="0 20px 20px 20px">
          <Box mb="20px" justifyContent="center">
            {children}
          </Box>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button variant="contained" onClick={onConfirm}>
              {btnConfirmLabel}
            </Button>
            <Button variant="outlined" color="primary" onClick={onCancel}>
              {btnCancelLabel}
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
};

export default memo(ConfirmationModal);
