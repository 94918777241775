import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as commonActions } from 'store/slices/common';
import { EditTable, Filters, PageLayout, TextField } from 'components';
import l from 'helpers/l';
import {
  GridRenderCellParams,
  GridRowParams,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { ModalType } from 'enums/ModalEnums';
import { RootState } from 'store';
import { actions as pmOrderActions } from 'store/slices/projectManagerSlice';
import { actions as languageActions } from 'store/slices/languagesSlice';
import { actions as articleTypesActions } from 'store/slices/articleTypesSlice';
import { actions as orderLinesActions } from 'store/slices/orderLinesSlice';
import { actions as employeesActions } from 'store/slices/employeeListSlice';
import { actions as orderLineStatusesActions } from 'store/slices/orderLineStatusesSlice';
import moment from 'moment';
import { Box, FormLabel, Typography } from '@mui/material';
import { daysOverdueDropdown, getDateColor } from 'config/utilsFunctions';
import { numberOfDays } from 'constants/globalVariables';
import HistoryIcon from '@mui/icons-material/History';
import { useFormik } from 'formik';
import { formatFilters } from 'helpers/filters';
import { isEmpty, isNil } from 'lodash';
import { createMenuItems } from 'helpers/dropdown';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';
import MultiSelectDropdown from 'components/MultiSelectDropdown/MultiSelectDropdown';
import { Endpoint } from 'enums/APIEndpointEnum';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useNavigate } from 'react-router-dom';
import { ApiService } from 'store/api/api.service';
import { IUnreadMessages } from 'interfaces/messages';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface PMOrderLinesFilters {
  keyword: string;
  pmDueDateFrom: string;
  pmDueDateTo: string;
  statusUuids: string[];
  daysOverdue: string;
  articleTypeUuid: any;
  sourceLanguageUuid: any;
  languageUuid: any;
  assistantPmUuid: any;
  writerUuid: any;
  editorUuid: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    mainWrapper: {
      '& .MuiBadge-dot': {
        display: 'none',
      },
      '& .hasUnreadMessages.MuiDataGrid-row': {
        '& .MuiBadge-dot': {
          display: 'block',
        },
      },
    },
    dropdownMenu: {
      maxHeight: '300px !important',
    },
    statusBox: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .MuiOutlinedInput': {
        width: '100%',
        '& #statusUuids': {
          width: '100%',
        },
      },
    },
  }),
);
// PAGE RE-CREATED IN PMPage.tsx
const ProjectManagementPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [resetFilter, setResetFilter] = useState<boolean>(false);

  const [firstLoading, setFirstLoading] = useState(true);
  const [unreadMessages, setUnreadMessages] = useState<IUnreadMessages[]>([
    { state: false, orderLineUuid: '' },
  ]);

  const isLoading = useSelector((state: RootState) => state.common.loading);

  const { items, meta, filters } = useSelector(
    (state: RootState) => state?.pmOrderLines,
  );

  const languagesDropdown = useSelector(
    (state: RootState) => state?.languages?.items,
  ).map((entry) => ({ label: entry.name, value: entry.uuid }));

  const articleTypesDropdown = useSelector(
    (state: RootState) => state?.articleTypes?.articleTypes,
  ).map((entry) => ({ label: entry?.name, value: entry?.uuid }));

  const orderLineStatusesDropdown = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  ).map((entry: any) => ({ label: entry.name, value: entry.uuid }));

  const getDropdownValues = JSON.parse(localStorage.getItem('filterValues'));

  const orderLineStatusesInitialData = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  ).filter((entry: any) => {
    return (
      //  entry.name === 'PM' &&
      entry.code === 'pm' ||
      entry.code === 'pm_check' ||
      entry.code === 'reject' ||
      entry.code === 'edit_done'
    );
  });

  const orderLineStatusesInitialDataUuids = orderLineStatusesInitialData.map(
    (item: any) => item.uuid,
  );

  const writersDropdown = useSelector(
    (state: RootState) => state?.orderLines?.writers,
  ).map((entry) => ({
    label: entry?.firstName + ' ' + entry.lastName,
    value: entry?.uuid,
  }));

  const editorsDropdown = useSelector(
    (state: RootState) => state?.orderLines?.editors,
  ).map((entry) => ({
    label: entry?.firstName + ' ' + entry.lastName,
    value: entry?.uuid,
  }));

  const assistantPMDropdown = useSelector(
    (state: RootState) => state?.employees?.employeesByRole,
  ).map((entry: any) => ({
    label: entry?.firstName + ' ' + entry.lastName,
    value: entry?.uuid,
  }));

  const [pmDueDateFromFormat, setpmDueDateFromFormat] = useState<any>(
    filters.pmDueDateFrom || null,
  );

  const [pmDueDateToFormat, setpmDueDateToFormat] = useState<any>(
    filters.pmDueDateTo || null,
  );

  const [dropdownValues, setDropdownValues] = useState<any>({});
  const [sort, setSort] = useState({});

  const dateFormatter = (string: any) => {
    if (string !== null) {
      const splitDate = string.split('.');
      return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
    } else return '';
  };

  const initialValues: PMOrderLinesFilters = {
    keyword: filters.keyword || '',
    pmDueDateFrom: pmDueDateFromFormat,
    pmDueDateTo: pmDueDateToFormat,
    statusUuids: isNil(filters.statusUuids)
      ? orderLineStatusesInitialDataUuids
      : filters.statusUuids,

    daysOverdue: filters.daysOverdue || 'All',
    articleTypeUuid: filters.articleTypeUuid,
    sourceLanguageUuid: filters.sourceLanguageUuid,
    languageUuid: filters.languageUuid,
    assistantPmUuid: filters.assistantPmUuid,
    writerUuid: filters.writerUuid,
    editorUuid: filters.editorUuid,
  };

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      orderLinesActions.setOrderLine({});
      navigate(`/pm-work-screen-details/${params?.row?.uuid}`);
    },
    [navigate],
  );

  const handleGoToMessages = useCallback(
    (params: GridRowParams) => {
      // navigate(`${`/messages`}`);
      navigate(`/messages`, { state: params.id });
    },
    [navigate],
  );

  const { setValues, ...formik } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values: PMOrderLinesFilters) => {
      const objForFilterFormatter = { ...values };
      objForFilterFormatter.sourceLanguageUuid =
        values?.sourceLanguageUuid?.value || null;
      objForFilterFormatter.languageUuid = values?.languageUuid?.value || null;
      objForFilterFormatter.assistantPmUuid =
        values?.assistantPmUuid?.value || null;
      objForFilterFormatter.writerUuid = values?.writerUuid?.value || null;
      objForFilterFormatter.editorUuid = values?.editorUuid?.value || null;

      const formatValues = formatFilters(objForFilterFormatter);
      const objectForDropdownValues: any = {};
      let pmDueDateFrom = '';
      let pmDueDateTo = '';
      let statusUuids: any = [];
      if (pmDueDateFromFormat !== null) {
        pmDueDateFrom = moment(
          new Date(dateFormatter(pmDueDateFromFormat)),
        ).format('YYYY-MM-DDTHH:mm:ss.sssZ');
        objectForDropdownValues.pmDueDateFrom = pmDueDateFrom;
      }

      if (pmDueDateToFormat !== null) {
        pmDueDateTo = moment(new Date(dateFormatter(pmDueDateToFormat))).format(
          'YYYY-MM-DDTHH:mm:ss.sssZ',
        );
        objectForDropdownValues.pmDueDateTo = pmDueDateTo;
      }
      if (formik?.values?.statusUuids.length !== 0) {
        //params.statusUuids = formik?.values?.statusUuids;
        statusUuids = formik?.values?.statusUuids;
        objectForDropdownValues.statusUuids = statusUuids;
      }
      setDropdownValues({
        ...formatValues,
        ...objectForDropdownValues,
      });

      dispatch(
        pmOrderActions.setFilterParams({
          ...formatValues,
          ...objectForDropdownValues,
        }),
      );

      if (!isEmpty(formatValues)) {
        const params = {
          ...formatValues,
          ...sort,
          pmDueDateFrom,
          pmDueDateTo,
          statusUuids,
          limit: 50,
        };

        dispatch(pmOrderActions.sendProjectOrderLinesFilters(params));
      } else {
        dispatch(
          pmOrderActions.sendProjectOrderLinesFilters({ ...sort, limit: 50 }),
        );
      }
      savePMFilters();
    },
  });

  const savePMFilters = () => {
    dispatch(pmOrderActions.setProjectOrderLinesFilters(formik.values));
  }; // function for saving filters when go to other page and return to this

  const onReset = () => {
    setResetFilter(true);
    setValues({
      articleTypeUuid: '',
      assistantPmUuid: '',
      daysOverdue: 'All',
      editorUuid: '',
      keyword: '',
      languageUuid: '',
      pmDueDateFrom: '',
      pmDueDateTo: '',
      sourceLanguageUuid: '',
      statusUuids: orderLineStatusesInitialDataUuids,
      writerUuid: '',
    });
    formik.setFieldValue('statusUuids', orderLineStatusesInitialDataUuids);
    formik.setFieldValue('pmDueDateFrom', null);
    formik.setFieldValue('pmDueDateTo', null);

    const initialFilters = { ...formik.initialValues };
    initialFilters.pmDueDateFrom = null;
    initialFilters.pmDueDateTo = null;

    dispatch(
      pmOrderActions.setProjectOrderLinesFilters({
        articleTypeUuid: '',
        assistantPmUuid: '',
        daysOverdue: 'All',
        editorUuid: '',
        keyword: '',
        languageUuid: '',
        pmDueDateFrom: '',
        pmDueDateTo: '',
        sourceLanguageUuid: '',
        statusUuids: orderLineStatusesInitialDataUuids,
        writerUuid: '',
      }),
    );

    setDropdownValues({ statusUuids: orderLineStatusesInitialDataUuids });

    dispatch(
      pmOrderActions.setFilterParams({
        statusUuids: orderLineStatusesInitialDataUuids,
      }),
    );

    setpmDueDateFromFormat(null);
    setpmDueDateToFormat(null);
    dispatch(
      pmOrderActions.sendProjectOrderLinesFilters({
        ...sort,
        statusUuids: orderLineStatusesInitialDataUuids,
        limit: 50,
      }),
    );
  };

  useEffect(() => {
    setResetFilter(false);
  }, [resetFilter]);

  const handlepmDueDateFrom = (value: any, inputValue: any) => {
    setpmDueDateFromFormat(inputValue);
  };

  const handlepmDueDateTo = (value: any, inputValue: any) => {
    setpmDueDateToFormat(inputValue);
  };

  const datePickerStyles = {
    display: 'flex',
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    padding: '12px',
  };

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: item.field,
        order: item?.sort?.toUpperCase(),
      }))[0];

      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });

      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
        ...(getDropdownValues ? getDropdownValues : dropdownValues),
        limit: 50,
      };

      dispatch(pmOrderActions.sendProjectOrderLinesFilters(params));
    },
    [dispatch, dropdownValues, getDropdownValues],
  );

  const handlePage = useCallback(
    (page: number) => {
      const params = {
        ...(getDropdownValues ? getDropdownValues : dropdownValues),
        ...sort,
        page: page + 1,
        limit: 50,
      };

      dispatch(pmOrderActions.sendProjectOrderLinesFilters(params));
    },
    [dispatch, dropdownValues, sort, getDropdownValues],
  );

  const handleClassName = (params: GridRowParams) => {
    let className = '';

    unreadMessages?.map((item) => {
      if (item.orderLineUuid === params.row.uuid) {
        className = 'hasUnreadMessages';
      }
    });

    return className;
  };

  const calHistoryOfStatusesModal = useCallback(
    (uuid) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.StatusHistoryModal,
          data: uuid,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    const params: any = {
      urlSuffix: 'assistant_pm',
    };
    dispatch(languageActions.getLanguages());
    dispatch(articleTypesActions.getArticleTypes());
    dispatch(orderLinesActions.getOrderLinesWriters(''));
    dispatch(orderLinesActions.getOrderLinesEditors(''));
    dispatch(employeesActions.getEmployeesByRole(params));
    dispatch(orderLineStatusesActions.getOrderLineStatuses());
  }, [dispatch]);

  const isObjectWithValueAndLabel = (value) => {
    return (
      typeof value === 'object' &&
      value !== null &&
      value.hasOwnProperty('value') &&
      value.hasOwnProperty('label')
    );
  };

  useEffect(() => {
    if (firstLoading && orderLineStatusesInitialDataUuids.length !== 0) {
      const params = { limit: 50 };
      Object.entries(formik.values).map(([key, value]) => {
        if (value !== '' && value !== 'All') {
          if (isObjectWithValueAndLabel(value)) {
            params[key] = value.value;
          } else {
            // @ts-ignore
            params[key] = value;
          }
        }
        if (key === 'statusUuids') {
          // @ts-ignore
          params[key] = value;
        }
        if (key === 'pmDueDateFrom' || key === 'pmDueDateTo')
          params[key] = dateFormatter(value);
      }); // get saved filters when go to other page and return to this
      dispatch(pmOrderActions.sendProjectOrderLinesFilters(params));
      setTimeout(() => {
        setFirstLoading(false);
      }, 800);
    }

    setDropdownValues({
      statusUuids: orderLineStatusesInitialDataUuids,
    }); // have default initial status value in this state for handle page & handleSort functions
  }, [formik.values.statusUuids]);

  useEffect(() => {
    if (items.length) {
      const unreadMessagesApi: IUnreadMessages[] = [];
      items.map(
        (item) =>
          ApiService.endpoints
            .hasUnseenMessagesForGivenOrderLine(true, item.uuid)
            .then((res) => {
              if (res) {
                unreadMessagesApi.push({
                  state: res,
                  orderLineUuid: item.uuid,
                });
              }
            }),
        setUnreadMessages(unreadMessagesApi),
      );
    }
  }, [items]); // for unread messages on every row

  return (
    <PageLayout
      title={l('PAGES.PROJECT_MANAGER_SCREEN')}
      fullWidth
      className={classes.mainWrapper}>
      <Filters handleFilters={formik.submitForm} onReset={onReset}>
        <Box display={'flex'} alignItems="center">
          <Box minWidth={'200px'} mr="15px">
            <FormLabel>Keyword</FormLabel>
            <TextField
              autoHeight
              fullWidth
              name="keyword"
              size="small"
              value={formik.values.keyword}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.keyword)}
            />
          </Box>
          <Box minWidth={'200px'} mr="15px" alignSelf="baseline">
            <FormLabel>PM Due Date From</FormLabel>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardDatePicker
                autoOk={true}
                showTodayButton={true}
                value={pmDueDateFromFormat}
                inputValue={
                  initialValues?.pmDueDateFrom
                    ? initialValues?.pmDueDateFrom
                    : pmDueDateFromFormat
                }
                onChange={handlepmDueDateFrom}
                format="DD.MM.YYYY"
                style={datePickerStyles}
              />
            </MuiPickersUtilsProvider>
          </Box>

          <Box minWidth={'200px'} mr="15px" alignSelf={'baseline'}>
            <FormLabel>PM Due Date To</FormLabel>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardDatePicker
                autoOk={true}
                showTodayButton={true}
                value={pmDueDateToFormat}
                inputValue={
                  initialValues?.pmDueDateTo
                    ? initialValues?.pmDueDateTo
                    : pmDueDateToFormat
                }
                onChange={handlepmDueDateTo}
                format="DD.MM.YYYY"
                style={datePickerStyles}
              />
            </MuiPickersUtilsProvider>
          </Box>
          <Box minWidth={'200px'} mr="15px">
            <FormLabel>Days Overdue</FormLabel>
            <TextField
              select
              name="daysOverdue"
              size="small"
              value={formik.values.daysOverdue}
              onChange={formik.handleChange}
              sx={{ display: 'flex' }}
              SelectProps={{
                MenuProps: { PaperProps: { className: classes.dropdownMenu } },
              }}>
              {createMenuItems(daysOverdueDropdown())}
            </TextField>
          </Box>
          <Box
            minWidth={'200px'}
            maxWidth="200px"
            height="100%"
            mr="15px"
            className={classes.statusBox}>
            <FormLabel>Status</FormLabel>

            <MultiSelectDropdown
              initialData={orderLineStatusesDropdown}
              inputId="statusUuids"
              name="statusUuids"
              defaultValue={formik?.values?.statusUuids}
              inputValue={formik?.values?.statusUuids}
              formikHook={formik}
            />
          </Box>
          <Box minWidth={'200px'} mr="15px">
            <FormLabel>Article Type</FormLabel>
            {/*<TextField
              select
              name="articleTypeUuid"
              size="small"
              value={formik?.values?.articleTypeUuid || ''}
              onChange={formik.handleChange}
              pressEnter={formik.submitForm}>
              {createMenuItems(articleTypesDropdown)}
            </TextField>*/}
            <SuggestedDropdown
              filter
              url={Endpoint.ArticleTypes}
              inputId="articleTypeUuid"
              initialData={articleTypesDropdown}
              //   inputValue={formik?.values.languageUuid}
              inputValue={formik?.values.articleTypeUuid}
              dropdownLabel="name"
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              error={Boolean(formik.errors.articleTypeUuid)}
              helperText={formik.errors.articleTypeUuid}
              formikHook={formik}
              style={{ width: '100%' }}
              resetFilter={resetFilter}
              getWholeObjectAsValue
            />
          </Box>
          <Box minWidth={'200px'} mr="15px">
            <FormLabel>Language</FormLabel>
            <SuggestedDropdown
              filter
              url={Endpoint.Languages}
              inputId="languageUuid"
              initialData={languagesDropdown}
              //   inputValue={formik?.values.languageUuid}
              inputValue={formik?.values.languageUuid}
              dropdownLabel="name"
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              error={Boolean(formik.errors.languageUuid)}
              helperText={formik.errors.languageUuid}
              formikHook={formik}
              style={{ width: '100%' }}
              resetFilter={resetFilter}
              getWholeObjectAsValue
            />
          </Box>
          <Box minWidth={'200px'} mr="15px">
            <FormLabel>Source Language</FormLabel>
            <SuggestedDropdown
              filter
              url={Endpoint.Languages}
              inputId="sourceLanguageUuid"
              initialData={languagesDropdown}
              //   inputValue={formik?.values.sourceLanguageUuid}
              inputValue={formik?.values.sourceLanguageUuid}
              dropdownLabel="name"
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              error={Boolean(formik.errors.sourceLanguageUuid)}
              helperText={formik.errors.sourceLanguageUuid}
              formikHook={formik}
              style={{ width: '100%' }}
              resetFilter={resetFilter}
              getWholeObjectAsValue
            />
          </Box>
          <Box minWidth={'200px'} mr="15px">
            <FormLabel>Assistant PM</FormLabel>
            <SuggestedDropdown
              filter
              url={Endpoint.AssistantPm}
              inputId="assistantPmUuid"
              initialData={assistantPMDropdown}
              inputValue={formik?.values.assistantPmUuid}
              dropdownLabel={['firstName', 'lastName']}
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              error={Boolean(formik.errors.assistantPmUuid)}
              helperText={formik.errors.assistantPmUuid}
              formikHook={formik}
              getWholeObjectAsValue
              style={{ width: '100%' }}
              resetFilter={resetFilter}
            />
          </Box>
          <Box minWidth={'200px'} mr="15px">
            <FormLabel>Writer</FormLabel>
            <SuggestedDropdown
              filter
              url={Endpoint.OrderLinesWriters}
              inputId="writerUuid"
              initialData={writersDropdown}
              //   inputValue={formik?.values?.writerUuid}
              inputValue={formik?.values.writerUuid}
              dropdownLabel={['firstName', 'lastName']}
              dropdownValue="uuid"
              dataLocation="resultData"
              error={Boolean(formik.errors.writerUuid)}
              helperText={formik.errors.writerUuid}
              formikHook={formik}
              style={{ width: '100%' }}
              resetFilter={resetFilter}
              getWholeObjectAsValue
            />
          </Box>
          <Box minWidth={'200px'} mr="15px">
            <FormLabel>Editor</FormLabel>
            <SuggestedDropdown
              filter
              url={Endpoint.OrderLinesEditors}
              inputId="editorUuid"
              initialData={editorsDropdown}
              //   inputValue={formik?.values.editorUuid}
              inputValue={formik?.values.editorUuid}
              dropdownLabel={['firstName', 'lastName']}
              dropdownValue="uuid"
              dataLocation="resultData"
              error={Boolean(formik.errors.editorUuid)}
              helperText={formik.errors.editorUuid}
              formikHook={formik}
              style={{ width: '100%' }}
              resetFilter={resetFilter}
              getWholeObjectAsValue
            />
          </Box>
        </Box>
      </Filters>
      <EditTable
        getRowId={(row) => row.uuid}
        columns={[
          {
            field: 'contactPerson',
            headerName: 'Contact Person',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.order?.contactPerson?.nick,
          },
          {
            field: 'orderId',
            headerName: 'Order #',
            width: 100,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.order?.id,
          },
          {
            field: 'orderLineNumber',
            headerName: 'Line #',
            width: 100,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.lineNumber,
          },
          {
            field: 'orderedAt',
            headerName: 'Order Date',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row?.order?.orderedAt).format('DD.MM.YYYY. HH:mm'),
          },
          {
            field: 'statusDate',
            headerName: 'Status Date',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row?.order?.createdAt).format('DD.MM.YYYY. HH:mm'),
          },
          {
            field: 'status',
            headerName: 'Status',
            width: 180,
            renderCell: (params: GridRenderCellParams) => (
              <Typography
                color={params.row?.lastStatusLogEntry?.orderLineStatus?.color}
                fontWeight={600}>
                {params.row?.lastStatusLogEntry?.orderLineStatus?.name}
                <span
                  onClick={() => {
                    calHistoryOfStatusesModal({
                      uuid: params.row?.uuid,
                      orderNumber: params.row?.order?.id,
                      lineNumber: params?.row?.lineNumber,
                    });
                  }}>
                  <HistoryIcon
                    fontSize="medium"
                    sx={{ color: '#aaa', marginLeft: '10px' }}
                  />
                </span>
              </Typography>
            ),
          },
          {
            field: 'pmDueDateTime',
            headerName: 'PM Due Date',
            width: 180,
            renderCell: (params: GridRenderCellParams) => (
              <Typography
                color={getDateColor(params.row?.pmDueDateTime, numberOfDays)}
                fontWeight={600}>
                {moment(params.row?.pmDueDateTime).format('DD.MM.YYYY. HH:mm')}
              </Typography>
            ),
          },
          {
            field: 'dateCritical',
            headerName: 'Due Time Critical',
            width: 180,
          },
          {
            field: 'sourceLanguage',
            headerName: 'Source Language',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.sourceLanguage?.name,
          },
          {
            field: 'language',
            headerName: 'Language',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.language?.name,
          },
          {
            field: 'articleType',
            headerName: 'Article Type',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.articleType?.name,
          },
          {
            field: 'quantity',
            headerName: 'Quantity',
            width: 180,
          },
          {
            field: 'assistantProjectManager',
            headerName: 'Assistant Project Manager',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.assistantProjectManager !== null
                ? params.row?.assistantProjectManager?.firstName +
                  ' ' +
                  params.row?.assistantProjectManager?.lastName
                : '',
          },
          {
            field: 'writer',
            headerName: 'Writer',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.writer !== null
                ? params.row?.writer?.firstName +
                  ' ' +
                  params.row?.writer?.lastName
                : '',
          },
          {
            field: 'writerDueDate',
            headerName: 'Writer Due Date',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.writerDueDateTime !== null
                ? moment(params.row?.writerDueDateTime).format(
                    'DD.MM.YYYY. HH:mm',
                  )
                : '',
          },
          {
            field: 'writerDueDateChanges',
            headerName: 'Writer Due Date Changes',
            width: 180,
          },
          {
            field: 'editor',
            headerName: 'Editor',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.editor !== null
                ? params.row?.editor?.firstName +
                  ' ' +
                  params.row?.editor?.lastName
                : '',
          },
          {
            field: 'editorDueDateTime',
            headerName: 'Editor Due Date',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.editorDueDateTime !== null
                ? moment(params.row?.editorDueDateTime).format(
                    'DD.MM.YYYY. HH:mm',
                  )
                : '',
          },
          {
            field: 'project',
            headerName: 'Project',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.project?.name,
          },
          {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row?.updatedAt).format('DD.MM.YYYY. HH:mm'),
          },
          {
            field: 'projectManager',
            headerName: 'Project Manager',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.pmEmployee !== null
                ? params.row?.pmEmployee?.firstName +
                  ' ' +
                  params.row?.pmEmployee?.lastName
                : '',
          },
        ]}
        rows={items}
        loading={isLoading}
        onEdit={editEntryHandler}
        onMessage={handleGoToMessages}
        getRowClassName={handleClassName}
        sortedFieldIndex={19}
        onSortModelChange={handleSort}
        sortingOrderDirection="desc"
        pagination
        //@ts-ignore
        page={meta?.currentPage - 1 || 0}
        rowCount={meta?.totalItems}
        onPageChange={handlePage}
        pageSize={50}
        rowsPerPageOptions={[50]}
        paginationMode="server"
        sortingMode="server"
      />
    </PageLayout>
  );
};

export default memo(ProjectManagementPage);
