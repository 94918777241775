import { FC, memo, useCallback, useEffect } from 'react';
import { actions as userActions } from 'store/slices/user';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ReplacementsAddForm from 'components/Forms/ReplacementsAddForm';
import { RootState } from 'store';
import { createMenuItems } from 'helpers/dropdown';

const ReplacementsAddModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();
  const userRoles = useSelector((state: RootState) => state.user.userRoles).map(
    (role: any) => role.role.name,
  );

  const rolesDropdown = useSelector((state: RootState) => state.user.roles)
    .map(({ name, code }) => {
      return { label: name, value: code };
    })
    .filter((role) => {
      if (userRoles.includes(role.label)) return role;
      return null;
    });

  const rolesValues = createMenuItems(rolesDropdown);

  const submitHandler = useCallback(
    (values: any) => {
      dispatch(
        userActions.addReplacements({
          ...values,
        }),
      );
    },
    [dispatch, data.id],
  );

  useEffect(() => {
    dispatch(userActions.getRoles());
  }, [dispatch]);

  return (
    <ReplacementsAddForm
      title="Employee Replacement Add"
      employeeName={data.name}
      // nameLabel={l('NAME')}
      rolesValues={rolesValues}
      onSubmit={submitHandler}
      {...rest}
    />
  );
};

export default memo(ReplacementsAddModal);
