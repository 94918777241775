import { FC, memo, useCallback } from 'react';
import { actions as currenciesActions } from 'store/slices/currenciesSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { Typography } from '@mui/material';

const CurrenciesEditModal: FC<GenericModalProps> = ({ data, onClose }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state) =>
    data ? state.currencies.items.find((entry) => entry?.uuid === data) : null,
  );

  const confirmHandler = useCallback(
    () =>
      dispatch(
        currenciesActions.deleteCurrency({
          id: entry?.uuid,
          name: entry?.name,
        }),
      ),
    [dispatch, entry],
  );

  return entry ? (
    <ConfirmationModal onCancel={onClose} onConfirm={confirmHandler}>
      <Typography>
        Are you sure you want to delete <strong>{entry.name} </strong>
        from the table ?
      </Typography>
    </ConfirmationModal>
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(CurrenciesEditModal);
