import { FC, memo, useCallback } from 'react';
import { actions as countriesActions } from 'store/slices/countriesSlice';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import { GenericCodeNameType } from 'types/genericTypes';
import GenericCodeNameForm from 'components/Forms/GenericCodeNameForm';

const CountriesAddModal: FC<GenericModalProps> = (props) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: GenericCodeNameType) =>
      dispatch(countriesActions.addCountry(values)),
    [dispatch],
  );

  return (
    <GenericCodeNameForm
      title={l('ADD_COUNTRY')}
      onSubmit={submitHandler}
      {...props}
    />
  );
};

export default memo(CountriesAddModal);
