import React, { FC, useCallback, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { styles } from './EmailListTemplateStyles';
import { GridRowParams } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'hooks/redux';
import { RootState } from 'store';
import { actions } from 'store/slices/user';
import l from 'helpers/l';
import PageLayout from 'components/PageLayout/PageLayout';
import EditTable from 'components/Table/EditTable';

const EmailListTemplate: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const navigate = useNavigate();
  const emailTemplates = useSelector(
    (state: RootState) => state.user.emialTemplate,
  );

  useEffect(() => {
    dispatch(actions.getEmailTemplates());
  }, [dispatch]);

  const classes = styles();

  const editHandler = useCallback(
    (params: GridRowParams) => navigate(`/email-list/edit/${params.id}`),
    [navigate],
  );

  return (
    <PageLayout title={l('PAGES.EMAIL_TEMPLATES')}>
      <EditTable
        loading={isLoading}
        className={classes.TableHeader}
        rows={emailTemplates}
        onEdit={editHandler}
        columns={[
          {
            field: 'key',
            headerName: 'Code',
            width: 200,
          },
          {
            field: 'name',
            headerName: 'Name',
            width: 200,
          },
          {
            field: 'subject',
            headerName: 'Subject',
            width: 200,
          },
          {
            field: 'fields',
            headerName: l('PAGES.ACTIVE_FIELD'),
            width: 200,
          },
        ]}
        pageSize={undefined}
        rowsPerPageOptions={undefined}
        hideFooterPagination
        hideFooter
      />
    </PageLayout>
  );
};

export default memo(EmailListTemplate);
