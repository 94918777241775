import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => {
  return {
    InputWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginRight: '0 auto',
      marginBottom: '0.7rem',
    },
  };
});

export default styles;
