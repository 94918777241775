import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageLayout, EmployeeForm } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { actions as employeeActions } from 'store/slices/employeeListSlice';
import { actions as currencyActions } from 'store/slices/currenciesSlice';
import { actions as timezonesActions } from 'store/slices/timeZonesSlice';

const EmployeeAddPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useSelector(
    (state: RootState) => state.timeZones.items,
  );
  useSelector(
    (state: RootState) => state.currencies.items,
  );

  useEffect(() => {
    dispatch(timezonesActions.getTimeZones());
    dispatch(currencyActions.getCurrencyList());
  }, [dispatch]);

  // Currently hardcode the uuid for currency and timezone so we can test adding employees
  const handleSubmit = (values: any) => {
    const data = {
      ...values,
      statusId: values.statusId.toString(),
    };
    dispatch(employeeActions.addEmployee({ data, navigate }));
  };
  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <PageLayout title="Add employee" fullWidth={true}>
      <EmployeeForm onSubmit={handleSubmit} onCancel={handleCancel} />
    </PageLayout>
  );
};
export default EmployeeAddPage;
