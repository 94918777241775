import { FC, memo, useCallback } from 'react';
import { actions as apLanguageRateActions } from 'store/slices/apLanguageRateSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { Typography } from '@mui/material';

const APLanguageRateDeleteModal: FC<GenericModalProps> = ({
  data,
  onClose,
}) => {
  const dispatch = useDispatch();

  const entry = useSelector((state) =>
    state.languagesRate.items.find((item) => item.uuid === data.uuid),
  );

  const confirmHandler = useCallback(
    () =>
      dispatch(
        apLanguageRateActions.deleteAPLanguageRate({
          urlSuffix: data,
        }),
      ),
    [dispatch, entry],
  );

  return (
    <ConfirmationModal onCancel={onClose} onConfirm={confirmHandler}>
      <Typography>
        Are you sure you want to delete this item from the table ?
      </Typography>
    </ConfirmationModal>
  );
};

export default memo(APLanguageRateDeleteModal);
