import { FC, memo, useCallback } from 'react';
import { actions } from 'store/slices/checklistsContactPersonsSlice';
import l from 'helpers/l';
import { RootState } from 'store';
import ChecklistsContactPersonsForm from 'components/Forms/ChecklistsContactPersonsForm';
import { useDispatch, useSelector } from 'hooks/redux';

const ChecklistsContactPersonsEditModal: FC<any> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state: RootState) =>
    data
      ? state.checklistsContactPersons.items.find(
          (entry: any) => entry?.uuid === data,
        )
      : null,
  );

  const submitHandler = useCallback(
    (values: any) =>
      dispatch(
        actions.editChecklistsContactPersons({
          articleTypeUuid: values.articleTypeUuid,
          roleCode: values.roleCode,
          checklistId: values.checklistId,
          contactPersonUuid: values.contactPersonUuid,
          uuid: entry?.uuid,
        }),
      ),
    [dispatch, entry?.uuid],
  );

  const initialValues = {
    articleTypeUuid: entry.articleType.uuid,
    roleCode: entry.role.code,
    checklistId: entry.checklist.id,
    checklistName: entry.checklist.name,
    contactPersonUuid: entry.contactPerson.uuid,
    contactPersonNick: entry.contactPerson.nick,
  };

  return entry ? (
    <ChecklistsContactPersonsForm
      title={l('EDIT_CHECKLIST_CONTACT_PERSONS')}
      onSubmit={submitHandler}
      initialValues={initialValues}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(ChecklistsContactPersonsEditModal);
