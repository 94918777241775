import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropertyValueRow from 'components/PropertyValueRow/PropertyValueRow';
import { getFullName } from 'helpers/text';

interface IConversationInfoAccordion {
  conversationInfo: any;
  currentUser: any;
}
function ConversationInfoAccordion({
  conversationInfo,
  currentUser,
}: IConversationInfoAccordion) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography>Conversation Info</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box className="topBoxes" display="flex" justifyContent="space-between">
          <Box className="leftTopBox">
            <PropertyValueRow
              property="Order"
              value={conversationInfo?.orderId}
            />
            <PropertyValueRow
              property="Order Line"
              value={conversationInfo?.lineNumber}
            />
            <PropertyValueRow
              property="Contact Person"
              value={conversationInfo?.contactPerson?.nick}
            />
            <PropertyValueRow
              property="Topic"
              value={conversationInfo?.articleTopic}
            />
            <PropertyValueRow
              property="Language"
              value={conversationInfo?.languageName}
            />
          </Box>
          <Box className="rightTopBox">
            <PropertyValueRow
              property="Account Manager"
              value={
                conversationInfo?.accountManager !== null &&
                getFullName(conversationInfo?.accountManager)
              }
              color={
                conversationInfo?.accountManager?.uuid ===
                currentUser?.employee?.uuid
                  ? '#00a'
                  : 'inherit'
              }
            />
            <PropertyValueRow
              property="Order Receiver"
              value={
                conversationInfo?.receivedBy !== null &&
                getFullName(conversationInfo?.receivedBy)
              }
              color={
                conversationInfo?.receivedBy?.uuid ===
                currentUser?.employee?.uuid
                  ? '#00a'
                  : 'inherit'
              }
            />
            <PropertyValueRow
              property="Project Manager"
              value={
                conversationInfo?.projectManager !== null &&
                getFullName(conversationInfo?.projectManager)
              }
              color={
                conversationInfo?.projectManager?.uuid ===
                currentUser?.employee?.uuid
                  ? '#00a'
                  : 'inherit'
              }
            />
            <PropertyValueRow
              property="Assistant PM"
              value={
                conversationInfo?.assistantProjectManager !== null &&
                getFullName(conversationInfo?.assistantProjectManager)
              }
              color={
                conversationInfo?.assistantProjectManager?.uuid ===
                currentUser?.employee?.uuid
                  ? '#00a'
                  : 'inherit'
              }
            />
            <PropertyValueRow
              property="Writer"
              value={
                conversationInfo?.writer !== null &&
                getFullName(conversationInfo?.writer)
              }
              color={
                conversationInfo?.writer?.uuid === currentUser?.employee?.uuid
                  ? '#00a'
                  : 'inherit'
              }
            />
            <PropertyValueRow
              property="Editor"
              value={
                conversationInfo?.editor !== null &&
                getFullName(conversationInfo?.editor)
              }
              color={
                conversationInfo?.editor?.uuid === currentUser?.employee?.uuid
                  ? '#00a'
                  : 'inherit'
              }
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default ConversationInfoAccordion;
