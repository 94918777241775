export const resultDataMapping = (
  result: any,
  location: string,
  label: string | string[],
  value: string,
) => {
  switch (location) {
    case 'resultData':
      if (Array.isArray(result?.result?.data) && result?.result?.data) {
        return result.result.data.map((item: any) => {
          return {
            label: constructLabel(item, label),
            value: item[`${value}`],
          };
        });
      } else {
        return logFailMapping();
      }
    case 'resultDataItems':
      if (
        Array.isArray(result?.result?.data?.items) &&
        result?.result?.data?.items
      ) {
        return result.result.data.items.map((item: any) => {
          return {
            label: constructLabel(item, label),
            value: item[`${value}`],
            language: item?.language || null,
            roles: item?.roles || null,
          };
        });
      } else {
        return logFailMapping();
      }
    case 'resultDataItemsReplacements':
      if (
        Array.isArray(result?.result?.data?.items) &&
        result?.result?.data?.items
      ) {
        return result.result.data.items.map((item: any) => {
          return {
            label: constructLabel(item, label),
            value: item.role.code,
          };
        });
      } else {
        return logFailMapping();
      }
    default:
      return [];
  }
};

const logFailMapping = () => {
  console.error('Error with handling the fetched Suggested Dropdown results!');
  return [];
};

const constructLabel = (item: any, label: string | string[]) => {
  if (typeof label === 'string') {
    return item[`${label}`];
  } else {
    return label.reduce(
      (prev, curr) => item[`${prev}`] + ' ' + item[`${curr}`],
    );
  }
};
