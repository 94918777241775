import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationMeta } from 'types/genericTypes';

export interface Projects {
  items: [];
  meta: PaginationMeta;
  contactPersons: [];
}

const initialState: any = {
  items: [],
  meta: {},
  contactPersons: [],
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    getProjects: (state) => state,
    getProjectsByContactPerson: (state) => state,
    setProjects: (state, action: any) => {
      state.items = action.payload.items;
      state.meta = action.payload.meta;
    },
    addProjects: (state) => state,
    deleteProjects: (state) => state,
    editProjects: (state) => state,
    sendProjectsFilters: (state) => state,
    getContactPersons: (state) => state,
    setContactPersons: (state, action: any) => {
      state.contactPersons = action.payload;
    },
  },
});
export const actions: any = projectsSlice.actions;
export default projectsSlice.reducer;
