import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    categories: [],
};

export const reportCategoriesSlice = createSlice({
    name: 'reportCategories',
    initialState,
    reducers: {
        getReportCategories: (state) => state,
        setReportCategories: (state, action: PayloadAction<any>) => {
            state.categories = action.payload;
        },
        postReportCategory: (state) => state,
        editReportCategory: (state) => state,
        deleteReportCategory: (state) => state,
        assignRoleToReportCategory: (state) => state,
        unassignFromPostReportCategory: (state) => state,
    },
});
export const actions: any = reportCategoriesSlice.actions;
export default reportCategoriesSlice.reducer;
