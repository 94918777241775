import { FC, memo, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { TextField, Form } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/index';
import { actions as employeeTimezoneActions } from 'store/slices/timeZonesSlice';
import { createMenuItems } from 'helpers/dropdown';
import { Endpoint } from 'enums/APIEndpointEnum';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';
import InputRowWrapper from 'components/InputRowWrapper/InputRowWrapper';
import l from 'helpers/l';

const defaultValues: any = {
  name: '',
  internalName: '',
  source: '',
  writerLevel: '',
  type: 'Client',
  timezoneUuid: '',
};

const defaultValidation = Yup.object().shape({
  name: Yup.string().required('Required'),
  internalName: Yup.string().required('Required'),
  source: Yup.string(),
  writerLevel: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  timezoneUuid: Yup.string().required('Required'),
});

type ClientsFormProps = {
  initialValues?: any;
  validationSchema?: any;
  onSubmit: (values: any) => void;
  onCancel: () => void;
};

const ClientsForm: FC<ClientsFormProps> = ({
  initialValues = defaultValues,
  validationSchema = defaultValidation,
  onSubmit,
  onCancel,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(employeeTimezoneActions.getTimeZones());
  }, [dispatch]);

  const empTimezonesDropdown = useSelector(
    (state: RootState) => state.timeZones.items,
  ).map((tz) => ({ label: tz.name, value: tz.uuid }));

  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: any) => {
      onSubmit(values);
    },
  });

  const writerValues = createMenuItems([
    { label: 'High', value: 'High' },
    { label: 'Average', value: 'Average' },
  ]);

  const typeValues = createMenuItems([
    { label: 'Client', value: 'Client' },
    { label: 'Lead', value: 'Lead' },
  ]);

  return (
    <Form
      onSubmit={formik.submitForm}
      onCancel={onCancel}
      btnCancelLabel="Cancel"
      btnSubmitLabel="Save"
      sx={{ padding: '32px' }}>
      <InputRowWrapper label={l('NAME')} labelWidth="250px" inputWidth="60%">
        <TextField
          name="name"
          size="small"
          value={formik.values.name}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name}
        />
      </InputRowWrapper>
      <InputRowWrapper
        label={l('INTERNAL_NAME')}
        labelWidth="250px"
        inputWidth="60%">
        <TextField
          name="internalName"
          size="small"
          value={formik.values.internalName}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.internalName)}
          helperText={formik.errors.internalName}
        />
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="100%"></InputRowWrapper>
      <InputRowWrapper
        label={l('TIMEZONE')}
        labelWidth="250px"
        inputWidth="35%">
        <SuggestedDropdown
          url={Endpoint.TimeZones}
          inputId="timezoneUuid"
          initialData={empTimezonesDropdown}
          inputValue={{
            label: formik?.values?.timezone || '',
            value: formik?.values?.timezoneUuid || '',
          }}
          dropdownLabel="name"
          dropdownValue="uuid"
          dataLocation="resultDataItems"
          error={Boolean(formik.errors.timezoneUuid)}
          helperText={formik.errors.timezoneUuid}
          formikHook={formik}
          style={{ display: 'flex', flexDirection: 'row' }}
        />
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="50%"></InputRowWrapper>
      <InputRowWrapper
        label={l('WRITER_LEVEL')}
        labelWidth="250px"
        inputWidth="35%">
        <TextField
          select
          name="writerLevel"
          size="small"
          value={formik.values.writerLevel}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.writerLevel)}
          helperText={formik.errors.writerLevel}
          pressEnter={formik.submitForm}>
          {writerValues}
        </TextField>
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="35%"></InputRowWrapper>
      <InputRowWrapper label={l('TYPE')} labelWidth="250px" inputWidth="35%">
        <TextField
          select
          name="type"
          size="small"
          value={formik.values.type}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.type)}
          helperText={formik.errors.type}
          pressEnter={formik.submitForm}>
          {typeValues}
        </TextField>
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="100%"></InputRowWrapper>
      <InputRowWrapper label={l('SOURCE')} labelWidth="250px" inputWidth="60%">
        <TextField
          name="source"
          size="small"
          multiline
          rows={6}
          sx={{
            display: 'flex',
            height: 'max-content',
            marginBottom: '40px',
          }}
          value={formik.values.source}
          onChange={formik.handleChange}
          autoComplete="off"
        />
      </InputRowWrapper>
    </Form>
  );
};

export default memo(ClientsForm);
