import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getOrderLinesByOrderApi = (urlSuffix: string, params?: any) =>
  //const getOrderLinesByOrderApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.OrderLinesOrder,
    method: Method.Get,
    urlSuffix,
    params,
  });

export const sendOrderLinesByOrderFiltersApi = (urlSuffix: string, params?: any) =>
    apiCall({
        url: Endpoint.OrderLinesOrder,
        method: Method.Get,
        urlSuffix,
        params,
    });

const getOrderLineApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.OrderLines,
    method: Method.Get,
    urlSuffix,
  });

const getPmChecklistApi = (params: any) =>
  apiCall({
    url: Endpoint.PmCheckList,
    method: Method.Get,
    params,
  });
const getWriterCheckListApi = (params: any) =>
  apiCall({
    url: Endpoint.WriterCheckList,
    method: Method.Get,
    params,
  });
const getEditorCheckListApi = (params: any) =>
  apiCall({
    url: Endpoint.EditorCheckList,
    method: Method.Get,
    params,
  });

const postWriterCheckListApi = (data: any) =>
  apiCall({
    url: Endpoint.PostWriterCheckList,
    method: Method.Post,
    data,
  });
const postEditorCheckListApi = (data: any) =>
  apiCall({
    url: Endpoint.PostEditorCheckList,
    method: Method.Post,
    data,
  });
const postPmChecklistApi = (data: any) => {
  return apiCall({
    url: Endpoint.PostPmCheckList,
    method: Method.Post,
    data,
  });
};

const getOrderLinesWritersApi = (params?: any) =>
  apiCall({
    url: Endpoint.OrderLinesWriters,
    method: Method.Get,
    params,
  });
const getOrderLinesEditorsApi = (params?: any) =>
  apiCall({
    url: Endpoint.OrderLinesEditors,
    method: Method.Get,
    params,
  });

const addLineApi = (data: any) =>
  apiCall({
    url: Endpoint.OrderLines,
    method: Method.Post,
    data,
  });
const duplicateOrderLineApi = (data: any, urlSuffix: string) =>
  apiCall({
    url: Endpoint.DuplicateOrderLines,
    method: Method.Post,
    urlSuffix,
    data,
  });

const editLineApi = (urlSuffix: string, data: any) =>
  apiCall({
    url: Endpoint.OrderLines,
    method: Method.Put,
    urlSuffix,
    data,
  });

const deleteLineApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.OrderLines,
    method: Method.Delete,
    urlSuffix,
  });

//  Writer screen API
const getWriterOrderLinesAPI = (params: string) =>
  apiCall({
    url: Endpoint.OrderLinesWritersPage,
    method: Method.Get,
    params,
  });
const editWriterOrderLinesAPI = (urlSuffix: string, data: any) =>
  apiCall({
    url: Endpoint.OrderLinesWritersPage,
    method: Method.Put,
    urlSuffix,
    data,
  });

//  Editor screen API
const getEditorOrderLinesAPI = (params: string) =>
  apiCall({
    url: Endpoint.OrderLinesEditorsPage,
    method: Method.Get,
    params,
  });
const editEditorOrderLinesAPI = (urlSuffix: string, data: any) =>
  apiCall({
    url: Endpoint.OrderLinesEditorsPage,
    method: Method.Put,
    urlSuffix,
    data,
  });

// Duplicate order line
const orderLineDuplicateFromAPI = (params: string) =>
  apiCall({
    url: Endpoint.OrderLinesDuplicateFrom,
    method: Method.Get,
    params,
  });

export {
  getOrderLinesByOrderApi,
  getOrderLineApi,
  getOrderLinesWritersApi,
  getOrderLinesEditorsApi,
  addLineApi,
  editLineApi,
  deleteLineApi,
  getWriterOrderLinesAPI,
  editWriterOrderLinesAPI,
  getEditorOrderLinesAPI,
  editEditorOrderLinesAPI,
  getPmChecklistApi,
  postPmChecklistApi,
  getWriterCheckListApi,
  getEditorCheckListApi,
  postWriterCheckListApi,
  postEditorCheckListApi,
  orderLineDuplicateFromAPI,
  duplicateOrderLineApi,
};
