import { FC, PropsWithChildren } from 'react';
import { Box, FormLabel, Typography } from '@mui/material';

interface PropertyValueRowProps {
  property: string;
  value: string | boolean;
  style?: any;
  color?: string;
}

const PropertyValueRow: FC<PropsWithChildren<PropertyValueRowProps>> = ({
  property,
  value,
  style,
  color,
}) => {
  return (
    <Box className="propValueRow" display="flex" fontSize="14px" style={style}>
      <FormLabel>{property}:</FormLabel>

      <Typography fontWeight={600} color={color}>
        &nbsp;{value}
      </Typography>
    </Box>
  );
};
export default PropertyValueRow;
