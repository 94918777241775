import { Box, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'hooks/redux';
import { FC, memo, useCallback } from 'react';
import { RootState } from 'store';
import { actions } from 'store/slices/common';
import ModalContent from './ModalContent';

const ModalContainer: FC = () => {
  const dispatch = useDispatch();
  const { type, data } = useSelector((state: RootState) => state.common.modal);

  const closeHandler = useCallback(
    () => dispatch(actions.hideModal()),
    [dispatch],
  );

  return (
    <Modal open={Boolean(type)} onClose={closeHandler}>
      <Box>
        <ModalContent type={type} data={data} onClose={closeHandler} />
      </Box>
    </Modal>
  );
};

export default memo(ModalContainer);
