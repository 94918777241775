import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'hooks/redux';
import { actions as projectManagerActions } from 'store/slices/projectManagerSlice';
import { actions as employeesActions } from 'store/slices/employeeListSlice';
import EditTable from 'components/Table/EditTable';
import { GridRenderCellParams } from '@mui/x-data-grid';
import AdjustIcon from '@mui/icons-material/Adjust';
import { useFormik } from 'formik';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Endpoint } from 'enums/APIEndpointEnum';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const defaultValues: any = {
  writerUuid: '',
};

export type WriterModalProps = {
  closeHandler: any;
  open: boolean;
  orderLineDetailsUuid: any;
};

const WriterModal: FC<WriterModalProps> = ({
  closeHandler,
  open,
  orderLineDetailsUuid,
}) => {
  const dispatch = useDispatch();

  const writersData = useSelector(
    (state: RootState) => state?.pmOrderLines?.writers,
  );

  const submitHandler = useCallback(
    (values: any) => {
      const dataForm = {
        writerUuid: values,
        urlSuffix: `${orderLineDetailsUuid}/employees-and-dates`,
      };
      dispatch(
        projectManagerActions.editProjectEMployeeDatesOrderLines({
          ...dataForm,
        }),
      );
    },
    [dispatch],
  );

  const { setValues, ...formik } = useFormik({
    initialValues: defaultValues,
    validateOnChange: false,
    onSubmit: (values: any) => {
      submitHandler(values);
    },
  });

  useEffect(() => {
    const dataForm = {
      urlSuffix: `${orderLineDetailsUuid}/writers`,
    };
    const params: any = {
      urlSuffix: 'writer',
    };
    dispatch(projectManagerActions.getSuggestionWriters(dataForm));
    dispatch(employeesActions.getEmployeesByRole(params));
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={closeHandler} fullWidth maxWidth="xl">
      <DialogTitle color={'#1b4994'}>Set Writer</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" width="50%" mb="10px">
          <span
            style={{ marginRight: '15px' }}
            onClick={() => {
              submitHandler(formik.values.writerUuid);
              closeHandler();
            }}>
            <AdjustIcon />
          </span>
          <SuggestedDropdown
            url={Endpoint.EmployeeReplacementsRoles}
            urlSuffix="writer"
            inputId="writerUuid"
            //  initialData={writersDropdown}
            inputValue={formik?.values.writerUuid}
            dropdownLabel={['firstName', 'lastName']}
            dropdownValue="uuid"
            dataLocation="resultDataItems"
            //  error={Boolean(formik.errors.writerUuid)}
            //  helperText={formik.errors.writerUuid}
            formikHook={formik}
            style={{ width: '100%', height: '55px' }}
          />
        </Box>
        <EditTable
          rows={writersData}
          getRowId={(row) => row.writerUuid}
          columns={[
            {
              field: 'assign',
              headerName: 'Assign',
              width: 120,
              renderCell: (params: GridRenderCellParams) => (
                <span
                  onClick={() => {
                    submitHandler(params?.row.writerUuid);
                    closeHandler();
                  }}
                  style={{ cursor: 'pointer' }}>
                  <AdjustIcon />
                </span>
              ),
            },
            {
              field: 'writerName',
              headerName: 'Writer Name',
              width: 180,
            },
            {
              field: 'expertises',
              headerName: 'Expertise',
              width: 180,
            },
            {
              field: 'level',
              headerName: 'Level',
              width: 120,
            },
            {
              field: 'clientGrade',
              headerName: 'Client Grade',
              width: 120,
            },
            {
              field: 'averageGrade',
              headerName: 'Average Grade',
              width: 150,
            },
            {
              field: 'availability',
              headerName: 'Availability',
              width: 140,
            },
            {
              field: 'rate',
              headerName: 'Payment',
              width: 130,
            },
            {
              field: 'countryOfOrigin',
              headerName: 'Country of origin',
              width: 130,
              renderCell: (params: GridRenderCellParams) => {
                return (
                  <Typography>
                    {params?.row?.countryOfOrigin?.name || ''}
                  </Typography>
                );
              },
            },
          ]}
          editColumnWidth={5}
          hideFooter
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeHandler}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WriterModal;
