import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { employeeFilter } from 'types/employeeTypes';
import apiCall from './apiCall';

const getEmployeesApi = () =>
  apiCall({
    url: Endpoint.EmployeesList,
    method: Method.Get,
  });

const getTeamLeadsApi = () =>
  apiCall({
    url: Endpoint.TeamLeads,
    method: Method.Get,
  });

const getEmployeesRoleApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.EmployeeReplacementsRoles,
    method: Method.Get,
    urlSuffix,
  });

const getEmployeesPMApi = () =>
  apiCall({
    url: Endpoint.EmployeesOrderPms,
    method: Method.Get,
  });

const sendFiltersApi = (params: employeeFilter) =>
  apiCall({
    url: Endpoint.EmployeesList,
    method: Method.Get,
    params,
  });

const addEmployeeApi = (data: any) =>
  apiCall({
    url: Endpoint.EmployeesList,
    method: Method.Post,
    data,
  });

const EditEmployeeApi = (data: any, urlSuffix: string) =>
  apiCall({
    url: Endpoint.EmployeesList,
    method: Method.Put,
    data,
    urlSuffix,
  });

const deleteEmployeeApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.EmployeesList,
    method: Method.Delete,
    urlSuffix,
  });

const getDetailsPageApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.EmployeesList,
    method: Method.Get,
    urlSuffix,
  });

export {
  getEmployeesApi,
  getEmployeesRoleApi,
  getEmployeesPMApi,
  sendFiltersApi,
  addEmployeeApi,
  EditEmployeeApi,
  deleteEmployeeApi,
  getDetailsPageApi,
  getTeamLeadsApi,
};
