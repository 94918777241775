import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as commonActions } from 'store/slices/common';
import {
  addClientsRateAPI,
  deleteClientsRateAPI,
  editClientsRateAPI,
  getClientsRateAPI,
  getClientsRateFiltersAPI,
} from 'store/api/clientsRateAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { APIResult } from 'types/APITypes';
import { actions as clientsRateActions } from '../slices/clientsRateSlice';

function* getClientsRatesWithFilters(action: PayloadAction<string>) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(getClientsRateFiltersAPI, params);

  if (result?.data) {
    yield put(clientsRateActions.setClientsRates(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* getClientsRates() {
  yield put(commonActions.setLoading(true));

  const { result } = yield call(getClientsRateAPI);

  if (result?.data) {
    yield put(clientsRateActions.setClientsRates(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addClientsRate(action: PayloadAction<any>) {
  const data = action.payload;
  const filters = action.payload?.filters;

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(addClientsRateAPI, data);
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getClientsRateFiltersAPI, {
      page: 1,
      ...filters,
    });

    if (result?.data) {
      yield put(clientsRateActions.setClientsRates(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added new item to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

function* editClientsRate(action: PayloadAction<any>) {
  const { urlSuffix } = action.payload;
  const data = action.payload;
  const filters = action.payload?.filters || {};

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(
    editClientsRateAPI,
    urlSuffix,
    data,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getClientsRateFiltersAPI, {
      page: 1,
      ...filters,
    });
    if (result?.data) {
      yield put(clientsRateActions.setClientsRates(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited this item`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

function* deleteClientsRate(action: PayloadAction<any>) {
  const { urlSuffix } = action.payload;

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(deleteClientsRateAPI, urlSuffix);
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getClientsRateAPI);
    if (result?.data) {
      yield put(clientsRateActions.setClientsRates(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted this item from the list`,
        severity: 'error',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

export default function* ARClientsRateWatcher() {
  yield takeLatest(clientsRateActions.getClientsRates.type, getClientsRates);
  yield takeLatest(clientsRateActions.addClientsRate.type, addClientsRate);
  yield takeLatest(
    clientsRateActions.getClientsRatesWithFilters.type,
    getClientsRatesWithFilters,
  );
  yield takeLatest(clientsRateActions.editClientsRate.type, editClientsRate);
  yield takeLatest(
    clientsRateActions.deleteClientsRate.type,
    deleteClientsRate,
  );
}
