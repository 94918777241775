import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

export interface IProjectRateData {
  languageUuid: string;
  sourceLanguageUuid: string;
  projectUuid: string;
  ratePerWord: number;
  ratePerArticle: number;
}

const getProjectsRateAPI = (params: any) =>
  apiCall({
    url: Endpoint.ProjectRate,
    method: Method.Get,
    params,
  });

const addProjectsRateAPI = (data: IProjectRateData) =>
  apiCall({
    url: Endpoint.ProjectRate,
    method: Method.Post,
    data,
  });
const editProjectsRateAPI = (urlSuffix: string, data: IProjectRateData) =>
  apiCall({
    url: Endpoint.ProjectRate,
    method: Method.Put,
    urlSuffix,
    data,
  });
const deleteProjectsRateAPI = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.ProjectRate,
    method: Method.Delete,
    urlSuffix,
  });

export {
  getProjectsRateAPI,
  addProjectsRateAPI,
  editProjectsRateAPI,
  deleteProjectsRateAPI,
};
