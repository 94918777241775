import { FC, memo, useCallback } from 'react';
import { actions as userActions } from 'store/slices/user';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import RolesForm from 'components/Forms/EmployeeViewRolesForm';

const EmployeeViewRoleEditModal: FC<GenericModalProps> = ({
  data,
  ...rest
}) => {
  const { isActive, wordsPerHour, role, uuid } = data.data;
  const initialValues = {
    isActive,
    wordsPerHour,
    role: role.name,
  };

  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: any) => {
      const { isActive, wordsPerHour } = values;
      dispatch(
        userActions.editUserRole({
          id: uuid,
          isActive,
          wordsPerHour,
        }),
      );
    },
    [dispatch, uuid],
  );

  return data ? (
    <RolesForm
      title={l('EDIT_ROLE')}
      employeeName={data.name}
      onSubmit={submitHandler}
      type="edit"
      initialValues={initialValues}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(EmployeeViewRoleEditModal);
