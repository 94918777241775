import { PayloadAction } from '@reduxjs/toolkit';
import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/auth';
import {
  getAuthLoginApi,
  requestNewPasswordApi,
  resetPasswordApi,
  authMeApi,
  setPasswordApi,
} from 'store/api/authAPI';
import { actions as commonActions } from 'store/slices/common';
import {
  AuthWithNavigate,
  ForgottenPasswordProps,
  SetPasswordPropsPayload,
} from 'types/authTypes';
import { APIResult } from 'types/APITypes';
import { authMessage } from 'helpers/messageHelpers';
import l from 'helpers/l';

const auth = false;
function* authMe() {
  const { result, error } = yield call(authMeApi);
  if (result?.status === 200) {
    yield put(actions.setAuthMe(result?.data));
  }
  if (error) {
    yield put(actions.logout());
  }
}

function* getAuth(action: PayloadAction<AuthWithNavigate>) {
  const { navigate, ...values } = action.payload;
  const { result, error } = yield call(getAuthLoginApi, auth, values);
  if (result?.status === 201 && result?.data?.token) {
    const { user, token, permissions } = result.data;
    yield put(actions.setAuthLogin({ user, token, permissions }));
    yield put(
      commonActions.snackBar({
        open: true,
        message: l('AUTH.LOGIN_SUCCESS'),
        severity: 'success',
      }),
    );
    navigate('/');
  }
  if (error?.message) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: authMessage(error?.message),
        severity: 'error',
      }),
    );
  }
}

function* requestNewPassword(action: PayloadAction<ForgottenPasswordProps>) {
  const data = action.payload;
  const { result, error }: APIResult<null> = yield call(
    requestNewPasswordApi,
    auth,
    data,
  );
  if (result?.status === 201) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: l('AUTH.TOKEN'),
        severity: 'success',
      }),
    );
  }
  if (error?.message) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: authMessage(error.message),
        severity: 'error',
      }),
    );
  }
}

function* setPassword(action: PayloadAction<SetPasswordPropsPayload>) {
  const { navigate, page } = action.payload;
  const data = action.payload;
  const api = page === 'newUser' ? setPasswordApi : resetPasswordApi;
  const { result, error }: APIResult<null> = yield call(api, auth, data);
  if (result?.status === 201 || result?.status === 200) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: l('AUTH.NEW_PASSWORD'),
        severity: 'success',
      }),
    );
    navigate('/login');
  }
  if (error?.message) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: authMessage(error.message),
        severity: 'error',
      }),
    );
  }
}

export default function* authWatcher() {
  yield takeLatest(actions.authMe.type, authMe);
  yield takeLatest(actions.getAuthLogin.type, getAuth);
  yield takeLatest(actions.requestNewPassword.type, requestNewPassword);
  yield takeLatest(actions.setPassword.type, setPassword);
}
