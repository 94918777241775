import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationMeta } from 'types/genericTypes';
import { OrdersEntriesTypes } from 'types/ordersEntriesTypes';

export interface Orders {
  orderEntry: any;
  items: OrdersEntriesTypes[];
  meta: PaginationMeta;
}

const initialState: any = {
  orderEntry: {},
  items: [],
  meta: {},
};

export const orderEntriesSlice = createSlice({
  name: 'orderEntries',
  initialState,
  reducers: {
    getOrderEntries: (state) => state,
    getOrderEntryItem: (state) => state,
    setOrderEntries: (state, action: PayloadAction<any>) => {
      state.items = action.payload.items;
      state.meta = action.payload.meta;
    },
    setOrderEntryItem: (state, action: PayloadAction<any>) => {
      state.orderEntry = action.payload;
    },
    addEntries: (state) => state,
    editEntries: (state) => state,
    deleteEntries: (state) => state,
    sendEntriesFilters: (state) => state,
    cancelAllLines: (state) => state,
  },
});
export const actions: any = orderEntriesSlice.actions;
export default orderEntriesSlice.reducer;
