import { put } from 'redux-saga/effects';
import { actions as commonActions } from 'store/slices/common';
import { APIErrorType } from 'types/APITypes';
import l from './l';

//@TODO - Add params
export function genericErrorMessageHelper(
  error: APIErrorType,
  params?: object,
) {
  switch (error?.status) {
    case 400: {
      return l('ERROR_MESSAGES.ITEM_ALREADY_EXISTS', params);
    }

    case 403: {
      return l('ERROR.PERMISSIONS_ERROR', params);
    }

    case 404: {
      return l('ERROR_MESSAGES.ITEM_NOT_FOUND', params);
    }

    default: {
      return error?.message || l('ERROR_MESSAGES.UNKNOWN_ERROR');
    }
  }
}
export function authMessage(message: string) {
  switch (message) {
    case 'invalid-credentials': {
      return l('AUTH.INVALID_CREDENTIALS');
    }
    case 'too-many-login-attempts': {
      return l('AUTH.ATTEMPTS');
    }
    case 'user-not-active': {
      return l('AUTH.NOT_ACTIVE');
    }
    case 'user-not-found': {
      return l('AUTH.NOT_FOUND');
    }
    case 'password-already-used': {
      return l('AUTH.PASSWORD_ALREADY_USED');
    }
    case 'user-is-inactive': {
      return l('AUTH.USER_INACTIVE');
    }
    case 'user-not-inactive': {
      return l('AUTH.NOT_INACTIVE');
    }
    case 'user-not-locked': {
      return l('AUTH.NOT_LOCKED');
    }
    default: {
      return message || l('ERROR_MESSAGES.UNKNOWN_ERROR');
    }
  }
}
const employeeMessages = (message: string) => {
  switch (message) {
    case 'role-not-found': {
      return l('EMPLOYEE.ROLE_NOT_FOUND');
    }
    case 'employee-not-found': {
      return l('EMPLOYEE.EMPLOYEE_NOT_FOUND');
    }
    case 'role-not-assigned': {
      return l('EMPLOYEE.ROLE_NOT_ASSIGNED');
    }
    case 'role-already-assigned': {
      return l('EMPLOYEE.ALREADY_ASSIGNED');
    }
    case 'user-already-exists': {
      return l('EMPLOYEE.USERNAME_EXIST');
    }
    case 'user-already-attached': {
      return l('EMPLOYEE.USER_ATTACHED');
    }
    case 'employee-already-exists': {
      return l('EMPLOYEE.EMAIL_EXIST');
    }
    case 'emplyoee-email-is-taken': {
      return l('EMPLOYEE.EMAIL_EXIST');
    }
    case 'cannot delete employee because of existing relations': {
      return l('EMPLOYEE.DELETE_FAIL');
    }
    default: {
      return message || l('ERROR_MESSAGES.UNKNOWN_ERROR');
    }
  }
};

export function* userHelper(message: string) {
  yield put(
    commonActions.snackBar({
      open: true,
      message: authMessage(message),
      severity: 'error',
    }),
  );
}

export function* employeeMessagessHelper(message: string) {
  yield put(
    commonActions.snackBar({
      open: true,
      message: employeeMessages(message),
      severity: 'error',
    }),
  );
}
