import { FC, memo, useCallback } from 'react';
import { actions as countriesActions } from 'store/slices/countriesSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { Typography } from '@mui/material';

const CountriesDeleteModal: FC<GenericModalProps> = ({ data, onClose }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state) =>
    state.countries.items.find((entry: any) => entry?.uuid === data),
  );

  const confirmHandler = useCallback(
    () =>
      entry?.uuid
        ? dispatch(countriesActions.deleteCountry(entry.uuid))
        : console.log('@TODO - Show error'),
    [dispatch, entry?.uuid],
  );

  return entry ? (
    <ConfirmationModal onCancel={onClose} onConfirm={confirmHandler}>
      <Typography>
        Are you sure you want to delete <strong>{entry.name} </strong>
        from the table ?
      </Typography>
    </ConfirmationModal>
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(CountriesDeleteModal);
