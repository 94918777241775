import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  items: [],
  meta: {},
};

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    getQuestions: (state) => state,
    setQuestions: (state, action: PayloadAction<any[]>) => action.payload,
    sendQuestionsFilters: (state) => state,
    addQuestion: (state) => state,
    deleteQuestion: (state) => state,
    editQuestion: (state) => state,
  },
});
export const actions: any = questionsSlice.actions;
export default questionsSlice.reducer;
