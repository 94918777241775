import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import styles from './TextFieldStyles';

type TextFieldExtensionProps = {
  pressEnter?: () => void;
  autoHeight?: boolean;
};

const TextField = (props: TextFieldProps & TextFieldExtensionProps) => {
  const { pressEnter, autoHeight, ...rest } = props;
  const classes = styles();

  const handleEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      pressEnter && pressEnter();
    }
    if (rest.type === 'number' && event.key === 'e') {
      event.preventDefault();
    }
  };
  return (
    <MuiTextField
      fullWidth={true}
      onKeyDown={handleEnter}
      {...rest}
      onWheel={(event: any) => event.target.blur()}
      sx={{
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        "input[type='number']": {
          MozAppearance: 'textfield',
        },
        ...rest.sx,
      }}
    />
  );
};
export default TextField;
