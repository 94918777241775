import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import l from 'helpers/l';
import { formatTime } from 'helpers/timeHelpers';
import colors from 'styles/colors';
import { Mail } from 'components';
let status: string;

const statusColors: any = {
  new: '#000',
  locked: '#ffeb3b',
  inactive: '#D32F2F',
  active: '#2E7D32',
};

export const columns = [
  {
    field: 'id',
    headerName: 'id',
    width: 80,
  },
  {
    field: 'firstName',
    headerName: l('COMMON.FIRSTNAME'),
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        to={`/employees/${params.row.uuid}`}
        style={{ textDecoration: 'none' }}>
        <Typography color={colors.info} fontWeight={600}>
          {params.value}
        </Typography>
      </Link>
    ),
  },
  {
    field: 'lastName',
    headerName: l('COMMON.LASTNAME'),
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        to={`/employees/${params.row.uuid}`}
        style={{ textDecoration: 'none' }}>
        <Typography color={colors.info} fontWeight={600}>
          {params.value}
        </Typography>
      </Link>
    ),
  },
  {
    field: 'email',
    headerName: l('COMMON.EMAIL'),
    minWidth: 270,
    renderCell: (params: GridRenderCellParams) => (
      <Mail email={params.value}>{params.value}</Mail>
    ),
  },
  {
    field: 'status',
    headerName: l('COMMON.STATUS'),
    minWidth: 150,
    valueFormatter: (params: any) => params?.value?.name,
  },
  {
    field: 'user',
    headerName: l('USERNAME'),
    minWidth: 150,
    valueFormatter: (params: any) => {
      status = params?.value?.status;
      return params?.value?.username;
    },
  },
  {
    field: 'userstatus',
    headerName: l('COMMON.USERSTATUS'),
    minWidth: 150,
    renderCell: () => {
      return (
        <Typography fontWeight={600} color={statusColors[status]}>
          {status}
        </Typography>
      );
    },
  },
  {
    field: 'language',
    headerName: l('LANGUAGE'),
    minWidth: 150,
    renderCell: (params: any) => {
      return <Typography>{params?.value?.name}</Typography>;
    },
  },
  {
    field: 'timezone',
    headerName: l('TIMEZONE'),
    minWidth: 250,
    renderCell: (params: any) => {
      return <Typography>{params?.value?.name}</Typography>;
    },
  },
  {
    field: 'isExpert',
    headerName: l('EXPERT'),
    minWidth: 100,
    renderCell: (params: any) => {
      return params?.value ? <CheckIcon color="secondary" /> : <></>;
    },
  },
  {
    field: 'strikes',
    headerName: l('STRIKES'),
    minWidth: 100,
    renderCell: (params: any) => {
      return <Typography>{params?.value || ''}</Typography>;
    },
  },
  {
    field: 'countryOfResidence',
    headerName: l('COUNTRY_OF_RESIDENCE'),
    minWidth: 250,
    renderCell: (params: any) => {
      return <Typography>{params?.value?.name}</Typography>;
    },
  },
  {
    field: 'availableHours',
    headerName: l('AVAILABLE_HOURS'),
    minWidth: 150,
    renderCell: (params: any) => {
      return <Typography>{params?.value || ''}</Typography>;
    },
  },
  {
    field: 'hourlyRate',
    headerName: l('HOURLY_RATES'),
    minWidth: 150,
    renderCell: (params: any) => {
      return <Typography>{params?.value || ''}</Typography>;
    },
  },
  {
    field: 'createdAt',
    headerName: l('COMMON.CREATEDAT'),
    minWidth: 180,
    valueFormatter: (params: any) => formatTime(params?.value),
  },
  {
    field: 'updatedAt',
    headerName: l('COMMON.UPDATEDAT'),
    minWidth: 180,
    valueFormatter: (params: any) => formatTime(params?.value),
  },
];

export const dropdown = {
  empStatusId: [{ label: 'All', value: 'All' }],
  hasUser: [
    { label: 'All', value: 'All' },
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ],
  userStatus: [
    { label: 'All', value: 'All' },
    { label: 'New', value: 'New' },
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
    { label: 'Locked', value: 'Locked' },
  ],
};
