import { FC, memo, useCallback } from 'react';
import { actions } from 'store/slices/speakersSlice';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import EmployeeStatusesForm from 'components/Forms/EmployeeStatusesForm';
import { GenericIdNameType } from 'types/genericTypes';

const SpeakersAddModal: FC<GenericModalProps> = (props) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: GenericIdNameType) => dispatch(actions.addSpeakers(values)),
    [dispatch],
  );

  return (
    <EmployeeStatusesForm
      title={l('ADD_NEW_SPEAKER')}
      onSubmit={submitHandler}
      {...props}
    />
  );
};

export default memo(SpeakersAddModal);
