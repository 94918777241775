import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Box as MuiBox } from '@mui/material';

export const styles = makeStyles(() => {
  return {
    AccordionElevation: {
      '&:before': {
        height: '0px !important',
      },
    },
    //     'BoxOfAccordion': {
  };
});

export const BoxOfAccordion = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: '#272726',
  whiteSpace: 'nowrap',

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    paddingLeft: '0.5rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '14px',
    paddingLeft: '0.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
    paddingLeft: '1rem',
  },
}));

export const CollapseStyles = {
  position: 'fixed',
  height: '100%',
};
export const AccordionSummaryStyles = {
  marginBottom: { xs: '0.8rem', sm: '1.2rem', md: '1.4rem' },
  padding: 0,
  minHeight: 'unset',
  '.MuiAccordionSummary-content': {
    margin: 0,
    alignItems: 'center',
  },
  gap: { xs: '4rem', sm: '5rem', md: '7rem' },
};

export const AccordionStyles = {
  '&:before': {
    backgroundColor: 'transparent',
  },
};
export const AccordionDetailsStyles = {
  padding: 0,
  marginLeft: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
};

export const CollapseBoxStyles = {
  bgcolor: '#FFFFFF',
  padding: { xs: '1rem', sm: '1.5rem', md: '2rem' },
  height: '100%',
};

export const Box = styled(MuiBox)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  display: 'flex',
  alignItems: 'center',
  color: '#272726',
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    marginBottom: '1rem',
    gap: '0.5rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '14px',
    marginBottom: '1.2rem',
    gap: '0.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
    marginBottom: '1.4rem',
    gap: '1rem',
  },
}));

export const IconStyles = {
  color: '#5176AE',
  fontSize: { xs: '16px', sm: '20px', md: '24px' },
};

export const LinkStyles = {
  textDecoration: 'none',
};
