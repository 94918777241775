import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getChecklistsRolesApi = () =>
  apiCall({
    url: Endpoint.ChecklistsRoles,
    method: Method.Get,
  });

const sendChecklistsRolesFiltersApi = (params: string) =>
  apiCall({
    url: Endpoint.ChecklistsRoles,
    method: Method.Get,
    params,
  });

const addChecklistsRolesApi = (data: any) =>
  apiCall({
    url: Endpoint.ChecklistsRoles,
    method: Method.Post,
    data,
  });

const deleteChecklistsRolesApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.ChecklistsRoles,
    method: Method.Delete,
    urlSuffix,
  });

const editChecklistsRolesApi = (data: any) => {
  return apiCall({
    url: Endpoint.ChecklistsRoles,
    method: Method.Put,
    data,
  });
};

export {
  sendChecklistsRolesFiltersApi,
  getChecklistsRolesApi,
  addChecklistsRolesApi,
  editChecklistsRolesApi,
  deleteChecklistsRolesApi,
};
