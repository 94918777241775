import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationMeta } from 'types/genericTypes';

interface ILanguagesRate {
  items: any[];
  meta: PaginationMeta;
}
const initialState: ILanguagesRate = {
  items: [],
  meta: {},
};

export const languagesRateSlice = createSlice({
  name: 'languagesRate',
  initialState,
  reducers: {
    getLanguageRates: (state) => state,
    setLanguageRates: (state, action: PayloadAction<any>) => {
      state.items = action?.payload?.items;
      state.meta = action.payload.meta;
    },
    addLanguageRate: (state) => state,
    editLanguageRate: (state) => state,
    deleteLanguageRate: (state) => state,
  },
});
export const actions: any = languagesRateSlice.actions;
export default languagesRateSlice.reducer;
