import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { CountryEntry } from 'types/countriesType';

import apiCall from './apiCall';

export const sendCountriesFiltersApi = (params: string) =>
  apiCall({
    url: Endpoint.Countries,
    method: Method.Get,
    params,
  });

export const getCountries = () =>
  apiCall({
    url: Endpoint.Countries,
    method: Method.Get,
  });

export const addCountry = (data: Omit<CountryEntry, 'uuid'>) =>
  apiCall({
    url: Endpoint.Countries,
    method: Method.Post,
    data,
  });

export const editCountry = ({ uuid, ...data }: CountryEntry) =>
  apiCall({
    url: Endpoint.Countries,
    method: Method.Put,
    data,
    urlSuffix: uuid,
  });

export const deleteCountries = (uuid: string) =>
  apiCall({
    url: Endpoint.Countries,
    method: Method.Delete,
    urlSuffix: uuid,
  });
