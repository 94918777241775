import { Box, Typography } from '@mui/material';
import Goback from 'components/Goback/Goback';
import { FC } from 'react';

type PageTitleProps = {
  title: string;
  showBackButton?: boolean;
  urlHistory?: any;
};

const PageTitle: FC<PageTitleProps> = ({ title, showBackButton, urlHistory }) => {
  return (
    <Box
      mb={showBackButton ? '10px' : '20px'} // for message page to remove vertical scroll
      sx={{ paddingLeft: { xs: '20px', lg: 0, xl: 0 } }}>
      <Box display="flex" alignItems="center">
        {showBackButton && <Goback text="" location={urlHistory ? urlHistory : -1} />}
        {/* for now just on message page exist Goback button */}
        <Typography
          color="primary"
          variant="h4"
          component="h2"
          fontWeight={700}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default PageTitle;
