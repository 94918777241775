import {
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import EditTable from 'components/Table/EditTable';
import { Box } from '@mui/material';
import { ModalType } from 'enums/ModalEnums';
import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { actions as commonActions } from 'store/slices/common';
import { actions as userActions } from 'store/slices/user';
import { useParams } from 'react-router-dom';

interface EmployeePreferenceProps {
  employeeData: any;
}

const EmployeePreference: FC<EmployeePreferenceProps> = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const userPreferences = useSelector(
    (state: RootState) => state.user.userPreferences,
  );
  const { employeeDetails } = useSelector(
    (state: RootState) => state.employees,
  );

  const { id }: any = useParams();
  const addEntryHandler = useCallback(() => {
    dispatch(
      commonActions.showModal({
        type: ModalType.EmployeePreferenceAdd,
        data: employeeDetails,
      }),
    );
  }, [dispatch]);

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.EmployeePreferenceDelete,
          data: {
            uuid: params.row.uuid,
            employeeUuid: id || '',
            description: params.row.description,
          },
        }),
      );
    },
    [dispatch, id],
  );

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.EmployeePreferenceEdit,
          data: {
            uuid: params.row.uuid,
            employeeUuid: id,
            entry: params.row,
            employeeDetails: employeeDetails,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(userActions.getUserPreferences(id));
    return () => {
      dispatch(userActions.setUserPreferences([]));
    };
  }, [dispatch, id]);

  return (
    <Box style={{ margin: '-1px -10px' }}>
      {!isLoading && (
        <EditTable
          loading={isLoading}
          rows={userPreferences}
          sortedFieldIndex={0}
          columns={[
            {
              field: 'client',
              headerName: 'Client',
              width: 200,
              valueGetter: (params: GridValueGetterParams) =>
                params.row.contactPerson.client.internalName,
            },
            {
              field: 'contactPerson',
              headerName: 'Contact Person',
              width: 200,
              valueGetter: (params: GridValueGetterParams) =>
                params.row.contactPerson.nick,
            },
            {
              field: 'role',
              headerName: 'Role',
              width: 150,
              valueGetter: (params: GridValueGetterParams) =>
                params.row.employeeRole.role.name,
            },
            {
              field: 'level',
              headerName: 'Level',
              width: 100,
            },
            {
              field: 'description',
              headerName: 'Description',
              width: 300,
            },
          ]}
          onAdd={addEntryHandler}
          onDelete={deleteEntryHandler}
          getRowId={(row) => row.uuid}
          onEdit={editEntryHandler}
          hideFooterPagination
          hideFooter
        />
      )}
    </Box>
  );
};

export default EmployeePreference;
