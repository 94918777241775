import { put, call, takeLatest } from 'redux-saga/effects';
import { actions as commonActions } from '../slices/common';
import { actions as currencyActions } from '../slices/currenciesSlice';
import {
  CurrencyEntry,
  CurrencyNewEntry,
  Currency,
  CurrencyEntryDelete,
} from 'types/currencyTypes';
import { APIResult } from 'types/APITypes';
import * as api from 'store/api/currenciesAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { genericErrorMessageHelper } from '../../helpers/messageHelpers';

function* sendCurrencyFilters(action: PayloadAction<string>) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(api.sendCurrencyFilters, params);

  if (result?.data) {
    yield put(currencyActions.setCurrencyList(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* getCurrencyList() {
  yield put(commonActions.setLoading(true));

  const { result, error }: APIResult<Currency> = yield call(api.getCurrencies);
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else if (result?.data) {
    yield put(currencyActions.setCurrencyList(result.data));
  }

  yield put(commonActions.setLoading(false));
}

function* addCurrency(action: PayloadAction<CurrencyNewEntry>) {
  const { error }: APIResult<CurrencyEntry> = yield call(
    api.addCurrency,
    action.payload,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<Currency> = yield call(api.getCurrencies);
    if (result?.data) {
      yield put(currencyActions.setCurrencyList(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${action.payload.code} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }

  yield put(commonActions.setLoading(false));
}

function* editCurrency(action: PayloadAction<CurrencyEntry>) {
  const { result, error }: APIResult<CurrencyEntry> = yield call(
    api.editCurrency,
    action.payload,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else if (result?.data) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${result.data.code}`,
        severity: 'info',
      }),
    );
    yield put(currencyActions.editCurrencySuccess(result.data));
    yield put(commonActions.hideModal());
  }

  yield put(commonActions.setLoading(false));
}

function* deleteCurrency(action: PayloadAction<CurrencyEntryDelete>) {
  const { id, name } = action.payload;
  const { error } = yield call(api.deleteCurrencies, id!);

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<Currency> = yield call(api.getCurrencies);
    if (result?.data) {
      yield put(currencyActions.setCurrencyList(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }

  yield put(commonActions.setLoading(false));
}

export default function* timeZonesWatcher() {
  yield takeLatest(currencyActions.getCurrencyList.type, getCurrencyList);
  yield takeLatest(currencyActions.addCurrency.type, addCurrency);
  yield takeLatest(
    currencyActions.sendCurrencyFilters.type,
    sendCurrencyFilters,
  );
  yield takeLatest(currencyActions.editCurrency.type, editCurrency);
  yield takeLatest(currencyActions.deleteCurrency.type, deleteCurrency);
}
