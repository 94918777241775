import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, TextField } from 'components';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';
import { Endpoint } from 'enums/APIEndpointEnum';
import l from 'helpers/l';
import { RootState } from 'store';
import { actions as clientActions } from 'store/slices/clientsSlice';
import InputRowWrapper from 'components/InputRowWrapper/InputRowWrapper';

type ContactPersonsFormProps = {
  initialValues?: any;
  validationSchema?: any;
  onSubmit: (values: any) => void;
  onCancel: () => void;
};

const defaultValues = {
  clientUuid: '',
  nick: '',
  firstName: '',
  lastName: '',
  email: null,
  phone: null,
  notes: null,
  accountManagerUuid: null,
  currencyUuid: '',
};

export const ContactPersonsForm: FC<ContactPersonsFormProps> = ({
  initialValues = defaultValues,
  validationSchema,
  onSubmit,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const contactPersonClientsDropdown = useSelector(
    (state: RootState) => state.clients.items,
  ).map((client: any) => ({ label: client?.name, value: client?.uuid }));
  useEffect(() => {
    dispatch(clientActions.getClients());
  }, [dispatch]);

  const { setValues, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit(values);
    },
  });

  const empCurrenciesDropdown = useSelector(
    (state: RootState) => state.currencies.items,
  ).map((currency) => ({
    label: currency.code + ' - ' + currency.name,
    value: currency.uuid,
  }));

  const currencyLabel = formik?.values?.currency
    ? formik?.values?.currency?.name + ' ' + formik?.values?.currency?.code
    : '';
  return (
    <Form
      onSubmit={formik.submitForm}
      onCancel={onCancel}
      btnSubmitLabel="Save"
      btnCancelLabel="Cancel"
      sx={{ padding: '32px' }}>
      <InputRowWrapper
        label={l('CONTACT_PERSONS.CLIENT_NAME')}
        labelWidth="250px"
        inputWidth="60%">
        <SuggestedDropdown
          url={Endpoint.Clients}
          inputId="clientUuid"
          initialData={contactPersonClientsDropdown}
          error={Boolean(formik.errors.clientUuid)}
          helperText={formik.errors.clientUuid}
          inputValue={{
            label: formik?.values?.client?.name || '',
            value: formik?.values?.client?.uuid || '',
          }}
          dropdownLabel="name"
          dropdownValue="uuid"
          dataLocation="resultDataItems"
          orderBy="name"
          order="ASC"
          style={{ display: 'flex', flexDirection: 'row' }}
          formikHook={formik}
        />
      </InputRowWrapper>
      <InputRowWrapper
        label={l('CONTACT_PERSONS.NICK_NAME')}
        labelWidth="250px"
        inputWidth="60%">
        <TextField
          fullWidth
          name="nick"
          size="small"
          value={formik.values.nick}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.nick)}
          helperText={formik.errors.nick}
          pressEnter={formik.submitForm}
        />
      </InputRowWrapper>
      <InputRowWrapper
        label={l('COMMON.FIRSTNAME')}
        labelWidth="250px"
        inputWidth="60%">
        <TextField
          fullWidth
          name="firstName"
          size="small"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.firstName)}
          helperText={formik.errors.firstName}
          pressEnter={formik.submitForm}
        />
      </InputRowWrapper>
      <InputRowWrapper
        label={l('COMMON.LASTNAME')}
        labelWidth="250px"
        inputWidth="60%">
        <TextField
          fullWidth
          name="lastName"
          size="small"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.lastName)}
          helperText={formik.errors.lastName}
          pressEnter={formik.submitForm}
        />
      </InputRowWrapper>
      <InputRowWrapper
        label={l('COMMON.EMAIL')}
        labelWidth="250px"
        inputWidth="60%">
        <TextField
          fullWidth
          name="email"
          size="small"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email}
          pressEnter={formik.submitForm}
        />
      </InputRowWrapper>
      <InputRowWrapper
        label={l('COMMON.PHONE')}
        labelWidth="250px"
        inputWidth="60%">
        <TextField
          fullWidth
          name="phone"
          size="small"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.phone)}
          helperText={formik.errors.phone}
          pressEnter={formik.submitForm}
        />
      </InputRowWrapper>
      <InputRowWrapper
        label={l('GENERAL_NOTES_URL')}
        labelWidth="250px"
        inputWidth="60%">
        <TextField
          fullWidth
          rows={6}
          sx={{ height: 'max-content', marginBottom: '35px' }}
          name="notes"
          size="small"
          value={formik.values.notes}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.notes)}
          helperText={formik.errors.notes}
          pressEnter={formik.submitForm}
        />
      </InputRowWrapper>
      <InputRowWrapper
        label={l('CONTACT_PERSONS.ACCOUNT_MANAGER')}
        labelWidth="250px"
        inputWidth="60%">
        <SuggestedDropdown
          url={Endpoint.EmployeeReplacementsRoles}
          urlSuffix="am"
          inputId="accountManagerUuid"
          inputValue={{
            label:
              formik?.values?.accountManager?.firstName &&
              formik?.values?.accountManager?.lastName
                ? `${formik?.values?.accountManager?.firstName} ${formik?.values?.accountManager?.lastName}`
                : '',
            value: formik?.values?.accountManager?.uuid || '',
          }}
          dropdownLabel={['firstName', 'lastName']}
          dropdownValue="uuid"
          dataLocation="resultDataItems"
          formikHook={formik}
          style={{ display: 'flex', flexDirection: 'row' }}
        />
      </InputRowWrapper>
      <InputRowWrapper
        label={l('CURRENCY')}
        labelWidth="250px"
        inputWidth="60%">
        <SuggestedDropdown
          url={Endpoint.Currencies}
          inputId="currencyUuid"
          error={Boolean(formik.errors.currencyUuid)}
          helperText={formik.errors.currencyUuid}
          initialData={empCurrenciesDropdown}
          inputValue={{
            label: currencyLabel,
            value: formik?.values?.currency?.uuid || '',
          }}
          dropdownLabel={['name', 'code']}
          dropdownValue="uuid"
          dataLocation="resultDataItems"
          orderBy="name"
          order="ASC"
          style={{ display: 'flex', flexDirection: 'row' }}
          formikHook={formik}
        />
      </InputRowWrapper>
    </Form>
  );
};
