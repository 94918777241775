export enum empKeys {
  firstName = 'firstName',
  lastName = 'lastName',
  id = 'id',
  email = 'email',
  status = 'status',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  language = 'language',
  timezone = 'timezone',
  isExpert = 'isExpert',
  strike = 'strikes',
  countryOfOrigin = 'countryOfOrigin',
  countryOfResidence = 'countryOfResidence',
  currency = 'currency',
  availableHours = 'availableHours',
  hourlyRate = 'hourlyRate',
  startDate = 'startDate',
  isFirstInterview = 'isFirstInterview',
  isNewEmployee = 'isNewEmployee',
  isSeniorEmployee = 'isSeniorEmployee',
  linkedinUrl = 'linkedinUrl',
  cvUrl = 'cvUrl',
  qa = 'qa',
  paymentDetails = 'paymentDetails',
  paymentMethod = 'paymentMethod',
  passportNumber = 'passportNumber',
  isSignedContract = 'isSignedContract',
  referredBy = 'referredBy',
  teamLead = 'teamLead',
  isTranslationOnly = 'isTranslationOnly',
}

export enum empValues {
  id = 'ID',
  firstName = 'First Name',
  lastName = 'Last Name',
  email = 'Email',
  status = 'Employee status',
  createdAt = 'Created at',
  updatedAt = 'Updated at',
  language = 'Language',
  timezone = 'Timezone',
  isExpert = 'Expert',
  strike = 'Strike',
  countryOfOrigin = 'Country of Origin',
  countryOfResidence = 'Country of Residence',
  currency = 'Currency',
  availableHours = 'Available Hours',
  hourlyRate = 'Hourly Rate',
  startDate = 'Start Date',
  isFirstInterview = 'First Interview',
  isNewEmployee = 'New Employee',
  isSeniorEmployee = 'Senior Employee',
  linkedinUrl = 'LinkedIn Profile',
  cvUrl = 'CV',
  qa = 'Questions and Answers',
  paymentDetails = 'Payment Details',
  paymentMethod = 'Payment Method',
  passportNumber = 'Passport Number',
  isSignedContract = 'Contract Signed',
  referredBy = 'Referred By',
  teamLead = 'Team Leader',
  isTranslationOnly = 'Translation Only',
}
