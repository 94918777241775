import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as commonActions } from 'store/slices/common';
import {
  ContentWrapper,
  EmployeeDetail,
  Goback,
  PageLayout,
  Spinner,
} from 'components';
import l from 'helpers/l';
import { ModalType } from 'enums/ModalEnums';
import { RootState } from 'store';
import {
  actions as pmOrderActions,
  actions as projectManagerActions,
} from 'store/slices/projectManagerSlice';
import {
  actions as orderLinesActions,
  actions as orderLineActions,
} from 'store/slices/orderLinesSlice';
import moment from 'moment';
import { Box, Button, Typography } from '@mui/material';
import { getDateColor } from 'config/utilsFunctions';
import { numberOfDays } from 'constants/globalVariables';
import HistoryIcon from '@mui/icons-material/History';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectManagerForm from 'components/Forms/ProjectManagerForm';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WriterModal from '../../components/Modals/writerModal/WriterModal';

const ProjectManagementDetailPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [editWriterDueDate, setEditWriterDueDate] = useState(false);
  const [editEditorDueDate, setEditEditorDueDate] = useState(false);

  const [writerdueDateFormat, setWriterDueDateFormat] = useState(
    moment().format('DD.MM.YYYY'),
  );
  const [editordueDateFormat, setEditorDueDateFormat] = useState(
    moment().format('DD.MM.YYYY'),
  );

  //  const pmorderLineDetails = useSelector(
  //    (state: RootState) => state.pmOrderLines.items,
  //  ).find((line: any) => line.uuid === params.id);

  const orderLineDetails = useSelector(
    (state: RootState) => state?.orderLines?.item,
  );

  const checkList = useSelector(
    (state: RootState) => state?.orderLines?.pmCheckList,
  );

  const checkListCount = `PM Checklist ${checkList.checked}/${checkList.total}`;

  const handleSubmit = (values: any) => {
    const data = {
      ...values,
      urlSuffix: params.id,
      orderUuid: orderLineDetails?.order?.uuid,
    };

    dispatch(
      projectManagerActions.editProjectOrderLines({
        ...data,
        navigate,
      }),
    );
  };

  const calHistoryOfStatussesModal = useCallback(
    (uuid) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.StatusHistoryModal,
          data: uuid,
        }),
      );
    },
    [dispatch],
  );

  const handleOpenassistantPMModal = useCallback(
    (assistantPM, uuid) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.AssignAssistantPMModal,
          data: {
            assistantPM,
            uuid,
          },
        }),
      );
    },
    [dispatch],
  );

  useCallback(
    (uuid) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.WriterModal,
          data: {
            uuid,
          },
        }),
      );
    },
    [dispatch],
  );

  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditorModal = useCallback(
    (uuid) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.EditorModal,
          data: {
            uuid,
          },
        }),
      );
    },
    [dispatch],
  );

  const handleWriterDueDateChange = (value: any, inputValue: any) => {
    setWriterDueDateFormat(inputValue);
  };

  const dateFormatter = (string: any) => {
    const splitDate = string.split('.');
    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  };

  const setTime = (date: any) => {
    const split = String(date).split(' ');
    split[4] = '23:59:00';

    return split.join(' ');
  };

  const editWriterEditorDates = (type: 'writer' | 'editor') => {
    const dataForm: any = {
      urlSuffix: `${params.id}/employees-and-dates`,
    };
    if (type === 'writer') {
      dataForm.writerDueDateTime = setTime(
        new Date(dateFormatter(writerdueDateFormat)),
      );
    }
    if (type === 'editor') {
      dataForm.editorDueDateTime = setTime(
        new Date(dateFormatter(editordueDateFormat)),
      );
    }

    dispatch(
      projectManagerActions.editProjectEMployeeDatesOrderLines({
        ...dataForm,
      }),
    );
  };

  const handleEditorDueDateChange = (value: any, inputValue: any) => {
    setEditorDueDateFormat(inputValue);
  };

  const initialValues = {
    orderLineStatusUuid:
      orderLineDetails?.lastStatusLogEntry?.orderLineStatus?.uuid,
    statusDescription: orderLineDetails?.lastStatusLogEntry?.description,
    quantityWriter: orderLineDetails?.quantityWriter,
    pmCleanArticleUrl: orderLineDetails?.pmCleanArticleUrl,
    quantityClient: orderLineDetails?.quantityClient,
    pmRevisionComments: orderLineDetails?.pmRevisionComments,
    articleTopic: orderLineDetails?.articleTopic,
    articleTitle: orderLineDetails?.articleTitle,
    keywords: orderLineDetails?.keywords,
    expertise1: orderLineDetails?.expertise1,
    expertise1Id: orderLineDetails?.expertise1?.id,
    expertise1Name: orderLineDetails?.expertise1?.name,
    expertise2: orderLineDetails?.expertise2,
    expertise2Id: orderLineDetails?.expertise2?.id,
    expertise2Name: orderLineDetails?.expertise2?.name,
    expertise3: orderLineDetails?.expertise3,
    expertise3Id: orderLineDetails?.expertise3?.id,
    expertise3Name: orderLineDetails?.expertise3?.name,
    speakerId: orderLineDetails?.speaker?.id,
    speakerName: orderLineDetails?.speaker?.name,
    toneOfVoiceId: orderLineDetails?.toneOfVoice?.id,
    targetAudienceUuid: orderLineDetails?.targetAudience?.uuid,
    outlineUrl: orderLineDetails?.outlineUrl,
    exampleArticlesUrl: orderLineDetails?.exampleArticlesUrl,
    internalUrl: orderLineDetails?.internalUrl,
    externalUrl: orderLineDetails?.externalUrl,
    anchorText: orderLineDetails?.anchorText,
    anchorTextUrl: orderLineDetails?.anchorTextUrl,
    hostingSiteUrl: orderLineDetails?.hostingSiteUrl,
    hasMetaTitle: orderLineDetails?.hasMetaTitle,
    hasMetaDescription: orderLineDetails?.hasMetaDescription,
    hasImages: orderLineDetails?.hasImages,
    imagesUrl: orderLineDetails?.imagesUrl,
    writerArticleLink: orderLineDetails?.writerArticleLink,
  };

  useEffect(() => {
    dispatch(pmOrderActions.getProjectOrderLines());
    dispatch(orderLineActions.getOrderLine({ orderLineUuid: params.id }));
    dispatch(orderLineActions.getPmCheckList({ orderLineUuid: params.id }));
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, []);

  const datePickerStyles = {
    width: '100%',
    display: 'flex',
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    padding: '12px',
  };

  const editPmCheckList = useCallback(() => {
    dispatch(
      commonActions.showModal({
        type: ModalType.PmCheckList,
        data: { orderLineUuid: params?.id, role: 'pm' },
      }),
    );
  }, [dispatch]);

  if (loading) {
    return <Spinner title="Please wait..." />;
  }
  return (
    <PageLayout
      title={`${l('PAGES.PM_SCREEN')}: Order ${
        orderLineDetails?.order?.id
      }, Line ${orderLineDetails?.lineNumber}`}
      fullWidth>
      <Box
        style={{ width: '100%' }}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Box>
          <Goback
            text="back to list"
            location={'/pm-work-screen'}
            callback={orderLinesActions.setOrderLine({})}
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => editPmCheckList()}>
            {checkListCount}
          </Button>
        </Box>
      </Box>
      <ContentWrapper mb="20px">
        <Box display="flex">
          <EmployeeDetail
            title="Contact Person"
            text={
              orderLineDetails?.order?.contactPerson !== null
                ? orderLineDetails?.order?.contactPerson?.nick
                : ''
            }
          />
          <EmployeeDetail title="Order #" text={orderLineDetails?.order?.id} />
          <EmployeeDetail title="Line #" text={orderLineDetails?.lineNumber} />
          <EmployeeDetail
            title="Order date"
            text={moment(orderLineDetails?.order?.orderedAt).format(
              'DD.MM.YYYY.',
            )}
          />
          <EmployeeDetail
            title="Article Type"
            text={
              orderLineDetails?.articleType !== null
                ? orderLineDetails?.articleType?.name
                : ''
            }
          />
          <Box minWidth="160px">
            <EmployeeDetail
              title="Source Language"
              text={
                orderLineDetails?.sourceLanguage !== null
                  ? orderLineDetails?.sourceLanguage?.name
                  : ''
              }
            />
          </Box>
          <EmployeeDetail
            title="Language"
            text={
              orderLineDetails?.language !== null
                ? orderLineDetails?.language?.name
                : ''
            }
          />
          <EmployeeDetail title="Quantity" text={orderLineDetails?.quantity} />
          <Box minWidth="150px">
            <Typography variant="subtitle2">No edit</Typography>
            <span>{orderLineDetails?.isNoEdit ? <CheckIcon /> : ''}</span>
          </Box>
          <EmployeeDetail
            title="Project"
            text={orderLineDetails?.project?.name}
          />
        </Box>
        <Box display="flex" mt="30px">
          <Box minWidth="150px">
            <Typography variant="subtitle2">Current Status</Typography>
            <Box display="flex">
              <Typography
                fontWeight={600}
                color={
                  orderLineDetails?.lastStatusLogEntry?.orderLineStatus?.color
                }>
                {orderLineDetails?.lastStatusLogEntry?.orderLineStatus?.name}
              </Typography>
              <span
                onClick={() =>
                  calHistoryOfStatussesModal(orderLineDetails?.uuid)
                }>
                <HistoryIcon
                  fontSize="medium"
                  sx={{ color: '#aaa', marginLeft: '10px' }}
                />
              </span>
            </Box>
          </Box>
          <Box minWidth="220px">
            <Typography variant="subtitle2">Status Description</Typography>
            <Box display="flex">
              <Typography fontWeight={600}>
                {orderLineDetails?.order?.id}
              </Typography>
            </Box>
          </Box>
          <Box minWidth="220px">
            <Typography variant="subtitle2">
              Assistant Project Manager
            </Typography>
            <Box display="flex">
              <Typography fontWeight={600} sx={{ marginRight: '10px' }}>
                {orderLineDetails?.assistantProjectManager !== null
                  ? orderLineDetails?.assistantProjectManager?.firstName +
                    ' ' +
                    orderLineDetails?.assistantProjectManager?.lastName
                  : ''}
              </Typography>
              <span
                onClick={() =>
                  handleOpenassistantPMModal(
                    orderLineDetails?.assistantProjectManager?.uuid,
                    orderLineDetails?.uuid,
                  )
                }>
                <EditIcon sx={{ color: '#aaa', cursor: 'pointer' }} />
              </span>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexWrap="wrap" mt="30px">
          <Box minWidth="170px" mb="20px">
            <EmployeeDetail
              title="Project Manager"
              text={
                orderLineDetails?.pmEmployee !== null
                  ? orderLineDetails?.pmEmployee?.firstName +
                    ' ' +
                    orderLineDetails?.pmEmployee?.lastName
                  : ''
              }
            />
          </Box>
          <Box minWidth="170px">
            <Typography variant="subtitle2">PM Due Date</Typography>
            <Box display="flex">
              <Typography
                fontWeight={600}
                color={getDateColor(
                  orderLineDetails?.pmDueDateTime,
                  numberOfDays,
                )}>
                {moment(orderLineDetails?.pmDueDateTime).format(
                  'DD.MM.YYYY. HH:mm',
                )}
              </Typography>
            </Box>
          </Box>
          <Box minWidth="200px">
            <Typography variant="subtitle2">Writer</Typography>
            <Box display="flex">
              <Typography>
                {orderLineDetails?.writer !== null
                  ? orderLineDetails?.writer?.firstName +
                    ' ' +
                    orderLineDetails?.writer?.lastName
                  : ''}
              </Typography>
              <span onClick={() => handleClickOpen()}>
                <EditIcon sx={{ color: '#aaa', cursor: 'pointer' }} />
              </span>
            </Box>
          </Box>
          <Box minWidth="200px">
            <Typography variant="subtitle2">Writer Due Date</Typography>
            <Box display="flex">
              {orderLineDetails?.writerDueDateTime !== null && (
                <Typography
                  fontWeight={600}
                  color={getDateColor(
                    orderLineDetails?.writerDueDateTime,
                    numberOfDays,
                  )}>
                  {moment(orderLineDetails?.writerDueDateTime).format(
                    'DD.MM.YYYY. HH:mm',
                  )}
                </Typography>
              )}
              {!editWriterDueDate && (
                <span onClick={() => setEditWriterDueDate(!editWriterDueDate)}>
                  <EditIcon sx={{ color: '#aaa', cursor: 'pointer' }} />
                </span>
              )}
            </Box>
            {editWriterDueDate && (
              <Box display="flex">
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  libInstance={moment}>
                  <KeyboardDatePicker
                    autoOk
                    value={writerdueDateFormat}
                    inputValue={writerdueDateFormat}
                    onChange={handleWriterDueDateChange}
                    format="DD.MM.YYYY"
                    style={datePickerStyles}
                    // disablePast
                  />
                </MuiPickersUtilsProvider>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span
                    onClick={() => {
                      editWriterEditorDates('writer');
                      setEditWriterDueDate(!editWriterDueDate);
                    }}>
                    <CheckIcon sx={{ color: '#aaa', cursor: 'pointer' }} />
                  </span>
                  <span
                    onClick={() => setEditWriterDueDate(!editWriterDueDate)}>
                    <CloseIcon sx={{ color: '#aaa', cursor: 'pointer' }} />
                  </span>
                </div>
              </Box>
            )}
          </Box>
          <Box minWidth="200px">
            <Typography variant="subtitle2">Editor</Typography>
            <Box display="flex">
              <Typography>
                {orderLineDetails?.editor !== null
                  ? orderLineDetails?.editor?.firstName +
                    ' ' +
                    orderLineDetails?.editor?.lastName
                  : ''}
              </Typography>
              <span onClick={() => handleEditorModal(orderLineDetails?.uuid)}>
                <EditIcon sx={{ color: '#aaa', cursor: 'pointer' }} />
              </span>
            </Box>
          </Box>
          <Box minWidth="200px">
            <Typography variant="subtitle2">Editor Due Date</Typography>
            <Box display="flex">
              {orderLineDetails?.editorDueDateTime !== null && (
                <Typography
                  fontWeight={600}
                  color={getDateColor(
                    orderLineDetails?.editorDueDateTime,
                    numberOfDays,
                  )}>
                  {moment(orderLineDetails?.editorDueDateTime).format(
                    'DD.MM.YYYY. HH:mm',
                  )}
                </Typography>
              )}
              {!editEditorDueDate && (
                <span onClick={() => setEditEditorDueDate(!editEditorDueDate)}>
                  <EditIcon sx={{ color: '#aaa', cursor: 'pointer' }} />
                </span>
              )}
            </Box>
            {editEditorDueDate && (
              <Box display="flex">
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  libInstance={moment}>
                  <KeyboardDatePicker
                    autoOk
                    value={editordueDateFormat}
                    inputValue={editordueDateFormat}
                    onChange={handleEditorDueDateChange}
                    format="DD.MM.YYYY"
                    style={datePickerStyles}
                    // disablePast
                  />
                </MuiPickersUtilsProvider>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span
                    onClick={() => {
                      editWriterEditorDates('editor');
                      setEditEditorDueDate(!editEditorDueDate);
                    }}>
                    <CheckIcon sx={{ color: '#aaa', cursor: 'pointer' }} />
                  </span>
                  <span
                    onClick={() => setEditEditorDueDate(!editEditorDueDate)}>
                    <CloseIcon sx={{ color: '#aaa', cursor: 'pointer' }} />
                  </span>
                </div>
              </Box>
            )}
          </Box>
        </Box>
      </ContentWrapper>
      <ProjectManagerForm
        initialValues={initialValues}
        orderLineDetails={orderLineDetails}
        onSubmit={handleSubmit}
        onCancel={() => navigate(-1)}
      />
      <WriterModal
        closeHandler={handleClose}
        open={open}
        orderLineDetailsUuid={params.id}
      />
    </PageLayout>
  );
};

export default ProjectManagementDetailPage;
