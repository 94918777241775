import { put, call, takeLatest } from 'redux-saga/effects';
import { actions as commonActions } from 'store/slices/common';
import { actions as messagesActions } from 'store/slices/messagesSlice';
import {
  getAllMessageConversationsAPI,
  getAllMessagesInConversationAPI,
  sendNewMessageAPI,
  unseenMessagesMarkSeenAPI,
  hasAnyUnseenMessagesAPI,
  hasUnseenMessagesForGivenOrderLineAPI,
  hasUnseenMessagesOnOrderLinesAPI,
} from 'store/api/messagesAPI';

function* getAllMessageConversations(action: any) {
  const params = action.payload;
  yield put(commonActions.setLoading(true));

  const { result } = yield call(getAllMessageConversationsAPI, params);

  if (result?.data) {
    yield put(messagesActions.setMessagesConversations(result?.data));
  }
  yield put(commonActions.setLoading(false));
}
function* getAllMessagesInConversation(action: any) {
  const { urlSuffix } = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(getAllMessagesInConversationAPI, urlSuffix);

  if (result?.data) {
    yield put(messagesActions.setAllMessagesInConversation(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* sendNewMessage(action: any) {
  const data = action.payload;

  yield put(commonActions.setLoading(true));

  //   const { result } = yield call(sendNewMessageAPI, data);
  yield call(sendNewMessageAPI, data);

  // if (result?.data) {
  //   // yield put(messagesActions.setAllMessagesInConversation(result?.data));
  // }
  yield put(commonActions.setLoading(false));
}

function* unseenMessagesMarkSeen(action: any) {
  const { urlSuffix } = action.payload;
  yield put(commonActions.setLoading(true));

  const { result } = yield call(unseenMessagesMarkSeenAPI, urlSuffix);

  if (result) {
    yield put(messagesActions.setUnseenMessagesMarkSeen());
  }
  yield put(commonActions.setLoading(false));
}

function* hasAnyUnseenMessages() {
  yield put(commonActions.setLoading(true));

  const { result } = yield call(hasAnyUnseenMessagesAPI);

  if (result.status === 200) {
    yield put(messagesActions.setHasAnyUnseenMessages(result.data));
  }

  yield put(commonActions.setLoading(false));
}

function* hasUnseenMessagesForGivenOrderLine(action: any) {
  const { urlSuffix } = action.payload;
  yield put(commonActions.setLoading(true));

  const { result } = yield call(
    hasUnseenMessagesForGivenOrderLineAPI,
    urlSuffix,
  );

  if (result) {
    yield put(
      messagesActions.setOrderLinesWithUnreadMessages({
        uuid: urlSuffix,
        state: result.data,
      }),
    );
  }
  yield put(commonActions.setLoading(false));
}
function* hasUnseenMessagesOnOrderLines() {
  yield put(commonActions.setLoading(true));

  const { result } = yield call(hasUnseenMessagesOnOrderLinesAPI);

  if (result?.data) {
    yield put(messagesActions.setOrderLinesWithUnreadMessages(result.data));
  }
  yield put(commonActions.setLoading(false));
}

export default function* messagesWatcher() {
  yield takeLatest(
    messagesActions.getAllMessageConversations.type,
    getAllMessageConversations,
  );
  yield takeLatest(
    messagesActions.getAllMessagesInConversation.type,
    getAllMessagesInConversation,
  );
  yield takeLatest(messagesActions.sendNewMessage.type, sendNewMessage);
  yield takeLatest(
    messagesActions.unseenMessagesMarkSeen.type,
    unseenMessagesMarkSeen,
  );
  yield takeLatest(
    messagesActions.hasAnyUnseenMessages.type,
    hasAnyUnseenMessages,
  );
  yield takeLatest(
    messagesActions.hasUnseenMessagesForGivenOrderLine.type,
    hasUnseenMessagesForGivenOrderLine,
  );
  yield takeLatest(
    messagesActions.hasUnseenMessagesOnOrderLines.type,
    hasUnseenMessagesOnOrderLines,
  );
}
