import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { Role } from 'interfaces/roles';
import apiCall from './apiCall';

const getRolesApi = () =>
  apiCall({
    url: Endpoint.Roles,
    method: Method.Get,
  });

const addRolesApi = (data: Role['entry']) =>
  apiCall({
    url: Endpoint.Roles,
    method: Method.Post,
    data,
  });

const deleteRolesApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.Roles,
    method: Method.Delete,
    urlSuffix,
  });

const editRolesApi = (data: Role['entry']) => {
  return apiCall({
    url: Endpoint.Roles,
    method: Method.Put,
    urlSuffix: data.code,
    data,
  });
};

export { getRolesApi, addRolesApi, editRolesApi, deleteRolesApi };