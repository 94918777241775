import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

export const getClientsApi = () =>
  apiCall({
    url: Endpoint.Clients,
    method: Method.Get,
  });

export const addClientsApi = (data: any) =>
  apiCall({
    url: Endpoint.Clients,
    method: Method.Post,
    data,
  });

export const editClientsApi = (urlSuffix: string, data: any) =>
  apiCall({
    url: Endpoint.Clients,
    method: Method.Put,
    urlSuffix,
    data,
  });

export const deleteClientsApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.Clients,
    method: Method.Delete,
    urlSuffix,
  });

export const sendClientsFiltersApi = (params: string) =>
  apiCall({
    url: Endpoint.Clients,
    method: Method.Get,
    params,
  });
