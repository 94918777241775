import { FC, memo, useCallback } from 'react';
import { actions as orderTypesActions } from 'store/slices/orderTypesSlice';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import EmployeeStatusesForm from 'components/Forms/EmployeeStatusesForm';
import { OrderTypesActionEntry } from 'types/orderTypes';

const OrderTypeAddModal: FC<GenericModalProps> = (props) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: OrderTypesActionEntry) =>
      dispatch(orderTypesActions.addOrderTypes(values)),
    [dispatch],
  );

  return (
    <EmployeeStatusesForm
      title={l('ADD_NEW_ORDER_TYPE')}
      onSubmit={submitHandler}
      {...props}
    />
  );
};

export default memo(OrderTypeAddModal);
