import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as commonActions } from 'store/slices/common';
import { EditTable } from 'components';
import {
  GridRenderCellParams,
  GridRowParams,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { ModalType } from 'enums/ModalEnums';
import { RootState } from 'store';
import { actions as pmOrderActions } from 'store/slices/projectManagerSlice';
import { actions as orderLinesActions } from 'store/slices/orderLinesSlice';
import moment from 'moment';
import { Typography } from '@mui/material';
import { getDateColor } from 'config/utilsFunctions';
import { numberOfDays } from 'constants/globalVariables';
import HistoryIcon from '@mui/icons-material/History';
import { useNavigate } from 'react-router-dom';
import { ApiService } from 'store/api/api.service';
import { IUnreadMessages } from 'interfaces/messages';

const PMTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [unreadMessages, setUnreadMessages] = useState<IUnreadMessages[]>([
    { state: false, orderLineUuid: '' },
  ]);

  const isLoading = useSelector((state: RootState) => state.common.loading);
  const { items, meta, sort, filterDropdownValues } = useSelector(
    (state: RootState) => state?.pmOrderLines,
  );

  const getFilterParams = JSON.parse(localStorage.getItem('filterValues'));
  const handleClassName = (params: GridRowParams) => {
    let className = '';

    unreadMessages?.map((item) => {
      if (item.orderLineUuid === params.row.uuid) {
        className = 'hasUnreadMessages';
      }
    });

    return className;
  };

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      orderLinesActions.setOrderLine({});
      navigate(`/pm-work-screen-details/${params?.row?.uuid}`);
    },
    [navigate],
  );

  const handleGoToMessages = useCallback(
    (params: GridRowParams) => {
      // navigate(`${`/messages`}`);
      navigate(`/messages`, { state: params.id });
    },
    [navigate],
  );

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: item.field,
        order: item?.sort?.toUpperCase(),
      }))[0];

      dispatch(
        pmOrderActions.setSortParams({
          orderBy: formatValues.orderBy,
          order: formatValues.order,
        }),
      );

      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
        ...(getFilterParams ? getFilterParams : filterDropdownValues),
        limit: 50,
      };

      dispatch(pmOrderActions.sendProjectOrderLinesFilters(params));
    },
    [dispatch, filterDropdownValues, getFilterParams],
  );

  const handlePage = useCallback(
    (page: number) => {
      const params = {
        ...(getFilterParams ? getFilterParams : filterDropdownValues),
        ...sort,
        page: page + 1,
        limit: 50,
      };

      dispatch(pmOrderActions.sendProjectOrderLinesFilters(params));
    },
    [dispatch, filterDropdownValues, sort, getFilterParams],
  );

  const calHistoryOfStatusesModal = useCallback(
    (uuid) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.StatusHistoryModal,
          data: uuid,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (items.length) {
      const unreadMessagesApi: IUnreadMessages[] = [];
      items.map(
        (item) =>
          ApiService.endpoints
            .hasUnseenMessagesForGivenOrderLine(true, item.uuid)
            .then((res) => {
              if (res) {
                unreadMessagesApi.push({
                  state: res,
                  orderLineUuid: item.uuid,
                });
              }
            }),
        setUnreadMessages(unreadMessagesApi),
      );
    }
  }, [items]); // for unread messages on every row

  return (
    <EditTable
      getRowId={(row) => row.uuid}
      columns={[
        {
          field: 'contactPerson',
          headerName: 'Contact Person',
          width: 180,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.order?.contactPerson?.nick,
        },
        {
          field: 'orderId',
          headerName: 'Order #',
          width: 100,
          valueGetter: (params: GridValueGetterParams) => params.row?.order?.id,
        },
        {
          field: 'orderLineNumber',
          headerName: 'Line #',
          width: 100,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.lineNumber,
        },
        {
          field: 'orderedAt',
          headerName: 'Order Date',
          width: 180,
          valueGetter: (params: GridValueGetterParams) =>
            moment(params.row?.order?.orderedAt).format('DD.MM.YYYY. HH:mm'),
        },
        {
          field: 'statusDate',
          headerName: 'Status Date',
          width: 180,
          valueGetter: (params: GridValueGetterParams) =>
            moment(params.row?.order?.createdAt).format('DD.MM.YYYY. HH:mm'),
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 180,
          renderCell: (params: GridRenderCellParams) => (
            <Typography
              color={params.row?.lastStatusLogEntry?.orderLineStatus?.color}
              fontWeight={600}>
              {params.row?.lastStatusLogEntry?.orderLineStatus?.name}
              <span
                onClick={() => {
                  calHistoryOfStatusesModal({
                    uuid: params.row?.uuid,
                    orderNumber: params.row?.order?.id,
                    lineNumber: params?.row?.lineNumber,
                  });
                }}>
                <HistoryIcon
                  fontSize="medium"
                  sx={{ color: '#aaa', marginLeft: '10px' }}
                />
              </span>
            </Typography>
          ),
        },
        {
          field: 'pmDueDateTime',
          headerName: 'PM Due Date',
          width: 180,
          renderCell: (params: GridRenderCellParams) => (
            <Typography
              color={getDateColor(params.row?.pmDueDateTime, numberOfDays)}
              fontWeight={600}>
              {moment(params.row?.pmDueDateTime).format('DD.MM.YYYY. HH:mm')}
            </Typography>
          ),
        },
        {
          field: 'dateCritical',
          headerName: 'Due Time Critical',
          width: 180,
        },
        {
          field: 'sourceLanguage',
          headerName: 'Source Language',
          width: 180,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.sourceLanguage?.name,
        },
        {
          field: 'language',
          headerName: 'Language',
          width: 180,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.language?.name,
        },
        {
          field: 'articleType',
          headerName: 'Article Type',
          width: 180,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.articleType?.name,
        },
        {
          field: 'quantity',
          headerName: 'Quantity',
          width: 180,
        },
        {
          field: 'assistantProjectManager',
          headerName: 'Assistant Project Manager',
          width: 180,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.assistantProjectManager !== null
              ? params.row?.assistantProjectManager?.firstName +
                ' ' +
                params.row?.assistantProjectManager?.lastName
              : '',
        },
        {
          field: 'writer',
          headerName: 'Writer',
          width: 180,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.writer !== null
              ? params.row?.writer?.firstName +
                ' ' +
                params.row?.writer?.lastName
              : '',
        },
        {
          field: 'writerDueDate',
          headerName: 'Writer Due Date',
          width: 180,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.writerDueDateTime !== null
              ? moment(params.row?.writerDueDateTime).format(
                  'DD.MM.YYYY. HH:mm',
                )
              : '',
        },
        {
          field: 'writerDueDateChanges',
          headerName: 'Writer Due Date Changes',
          width: 180,
        },
        {
          field: 'editor',
          headerName: 'Editor',
          width: 180,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.editor !== null
              ? params.row?.editor?.firstName +
                ' ' +
                params.row?.editor?.lastName
              : '',
        },
        {
          field: 'editorDueDateTime',
          headerName: 'Editor Due Date',
          width: 180,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.editorDueDateTime !== null
              ? moment(params.row?.editorDueDateTime).format(
                  'DD.MM.YYYY. HH:mm',
                )
              : '',
        },
        {
          field: 'project',
          headerName: 'Project',
          width: 180,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.project?.name,
        },
        {
          field: 'updatedAt',
          headerName: 'Updated At',
          width: 180,
          valueGetter: (params: GridValueGetterParams) =>
            moment(params.row?.updatedAt).format('DD.MM.YYYY. HH:mm'),
        },
        {
          field: 'projectManager',
          headerName: 'Project Manager',
          width: 180,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.pmEmployee !== null
              ? params.row?.pmEmployee?.firstName +
                ' ' +
                params.row?.pmEmployee?.lastName
              : '',
        },
      ]}
      rows={items}
      loading={isLoading}
      onEdit={editEntryHandler}
      onMessage={handleGoToMessages}
      getRowClassName={handleClassName}
      sortedFieldIndex={19}
      onSortModelChange={handleSort}
      sortingOrderDirection="desc"
      pagination
      //@ts-ignore
      page={meta?.currentPage - 1 || 0}
      rowCount={meta?.totalItems}
      onPageChange={handlePage}
      pageSize={50}
      rowsPerPageOptions={[50]}
      paginationMode="server"
      sortingMode="server"
    />
  );
};

export default memo(PMTable);
