import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as commonActions } from 'store/slices/common';
import { actions as orderEntriesSlice } from 'store/slices/orderEntriesSlice';
import { GridRowParams, GridSortItem, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid';
import { FormLabel, Grid } from '@mui/material';
import l from 'helpers/l';
import { EditTable, Filters, PageLayout, TextField } from 'components';
import { Check as CheckIcon } from '@mui/icons-material';
import { RootState } from 'store';
import { useNavigate } from 'react-router-dom';
import { ModalType } from 'enums/ModalEnums';
import moment from 'moment';
import { useFormik } from 'formik';
import { formatFilters } from 'helpers/filters';
import { isEmpty } from 'lodash';
import { actions as contactPersonActions } from 'store/slices/contactPersonsSlice';
import { actions as clientActions } from 'store/slices/clientsSlice';
import { actions as employeeActions } from 'store/slices/employeeListSlice';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';
import { Endpoint } from 'enums/APIEndpointEnum';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { actions as authActions } from 'store/slices/auth';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  mainWrapper: {
    '& .MuiBadge-dot': {
      display: 'none',
    },
  },
}));

interface OrderEntriesFilters {
  keyword: string;
  clientUuid: string;
  contactPersonUuid: string;
  receivedByUuid: string;
  orderDateFrom: string;
  orderDateTo: string;
}
const initialValues: OrderEntriesFilters = {
  keyword: '',
  clientUuid: '',
  contactPersonUuid: '',
  receivedByUuid: '',
  orderDateFrom: '',
  orderDateTo: '',
};

function OrderEntriesPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [orderDateFromFormat, setOrderDateFromFormat] = useState<any>(null);
  const [orderDateToFormat, setOrderDateToFormat] = useState<any>(null);

  useSelector((state: RootState) => state.common.loading);
  const { items, meta } = useSelector((state: RootState) => state.orderEntries);

  const [dropdownValues, setDropdownValues] = useState({});
  const [sort, setSort] = useState({});

  const [resetFilter, setResetFilter] = useState<boolean>(false);

  //  const orderEntries = useSelector(
  //    (state: RootState) => state.orderEntries.items,
  //  );

  const employeesDropdown = useSelector(
    (state: RootState) => state.employees?.employees,
  ).map((employee: any) => ({
    label: employee?.firstName + ' ' + employee?.lastName,
    value: employee?.uuid,
  }));

  const clientsDropdown = useSelector(
    (state: RootState) => state.clients?.items,
  ).map((client: any) => ({
    label: client?.internalName,
    value: client?.uuid,
  }));

  const contactPersonDropdown = useSelector(
    (state: RootState) => state?.contactPersons?.items,
  ).map((conPerson: any) => ({
    label: conPerson?.nick,
    value: conPerson?.uuid,
  }));

  const userRoles = useSelector(
    (state: RootState) => state.auth.user.employee.roles,
  );

  const isOrderReceiver = userRoles?.find((role: any) => {
    return role.role.code === 'receiver';
  });

  const isOrderReceiverActive = isOrderReceiver?.isActive;

  const addEntryHandler = useCallback(() => {
    navigate('/add-order');
  }, [navigate]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      navigate(`/edit-order/${params?.row?.uuid}`);
    },
    [navigate],
  );

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.OrderEntriesDelete,
          data: { orderEntries: params.row.uuid } as any,
        }),
      );
    },
    [dispatch],
  );

  const viewEntryHandler = useCallback(
    (params: GridRowParams) => {
      navigate(`/orders-entries/${params?.row?.uuid}`);
    },
    [navigate],
  );

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: item.field,
        order: item?.sort?.toUpperCase(),
      }))[0];
      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });
      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
        limit: 50,
        ...dropdownValues,
      };

      dispatch(orderEntriesSlice.sendEntriesFilters(params));
    },
    [dispatch, dropdownValues],
  );

  const handlePage = useCallback(
    (page: number) => {
      const params = { ...dropdownValues, ...sort, page: page + 1, limit: 50 };

      dispatch(orderEntriesSlice.sendEntriesFilters(params));
    },
    [dispatch, dropdownValues, sort],
  );

  const { setValues, ...formik } = useFormik({
    initialValues,
    onSubmit: (values: OrderEntriesFilters) => {
      const formatValues = formatFilters(values);
      const objectForDropdownValues: any = {};

      let orderDateFrom = '';
      let orderDateTo = '';
      if (orderDateFromFormat !== null) {
        orderDateFrom = moment(
          new Date(dateFormatter(orderDateFromFormat)),
        ).format('YYYY-MM-DDTHH:mm:ss.sssZ');
        objectForDropdownValues.orderDateFrom = orderDateFrom;
      }
      if (orderDateToFormat !== null) {
        orderDateTo = moment(new Date(dateFormatter(orderDateToFormat))).format(
          'YYYY-MM-DDTHH:mm:ss.sssZ',
        );
        objectForDropdownValues.orderDateTo = orderDateTo;
      }
      setDropdownValues({
        ...formatValues,
        ...objectForDropdownValues,
      });
      if (!isEmpty(formatValues)) {
        const params = { ...formatValues, ...sort, orderDateFrom, orderDateTo };

        dispatch(orderEntriesSlice.sendEntriesFilters(params));
      } else {
        dispatch(orderEntriesSlice.sendEntriesFilters(sort));
      }
    },
  });

  const dateFormatter = (string: any) => {
    if (string !== null) {
      const splitDate = string.split('.');
      return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
    } else return '';
  };

  const onReset = useCallback(() => {
    setResetFilter(true);
    setValues(initialValues);
    setDropdownValues({});
    setOrderDateFromFormat(null);
    setOrderDateToFormat(null);
    dispatch(orderEntriesSlice.sendEntriesFilters(sort));
  }, [dispatch, setValues, sort]);

  useEffect(() => {
    const params = {
      limit: 50,
    };
    dispatch(orderEntriesSlice.getOrderEntries(params));
    dispatch(contactPersonActions.getContactPersonsList());
    dispatch(clientActions.getClients());
    dispatch(employeeActions.getEmployeesList());
    dispatch(authActions.authMe());
  }, [dispatch]);

  useEffect(() => {
    setResetFilter(false);
  }, [resetFilter]);

  const handleOrderDateChange = (value: any, inputValue: any) => {
    //setOrderDate(value);
    setOrderDateFromFormat(inputValue);
  };
  const handleOrderDateToChange = (value: any, inputValue: any) => {
    //setOrderDate(value);
    setOrderDateToFormat(inputValue);
  };

  const datePickerStyles = {
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    padding: '12px',
  };

  return (
    <PageLayout
      title={l('PAGES.ORDER_ENTRIES')}
      fullWidth={true}
      className={classes.mainWrapper}>
      <Filters handleFilters={formik.submitForm} onReset={onReset}>
        <Grid width={'100%'} container spacing={1} alignItems="start">
          <Grid item md={2}>
            <FormLabel>Keyword</FormLabel>
            <TextField
              autoHeight
              fullWidth
              name="keyword"
              size="small"
              value={formik.values.keyword}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.keyword)}
            />
          </Grid>
          <Grid item md={2}>
            <FormLabel>Client</FormLabel>
            <SuggestedDropdown
              filter
              url={Endpoint.Clients}
              inputId="clientUuid"
              initialData={clientsDropdown}
              inputValue={formik?.values.clientUuid}
              dropdownLabel="internalName"
              dropdownValue="uuid"
              dataLocation="resultDataItems"
              error={Boolean(formik.errors.clientUuid)}
              helperText={formik.errors.clientUuid}
              formikHook={formik}
              order="ASC"
              style={{ width: '100%' }}
              resetFilter={resetFilter}
            />
          </Grid>
          <Grid item md={2}>
            <FormLabel>Contact Person</FormLabel>
            <SuggestedDropdown
              filter
              url={Endpoint.ContactPersons}
              inputId="contactPersonUuid"
              initialData={contactPersonDropdown}
              inputValue={formik?.values.contactPersonUuid}
              dropdownLabel={'nick'}
              //  dropdownLabel={['firstName', 'lastName']}
              dropdownValue="uuid"
              order="ASC"
              dataLocation="resultDataItems"
              error={Boolean(formik.errors.contactPersonUuid)}
              helperText={formik.errors.contactPersonUuid}
              formikHook={formik}
              style={{ width: '100%' }}
              resetFilter={resetFilter}
            />
          </Grid>
          {!isOrderReceiverActive && (
            <Grid item md={2}>
              <FormLabel>Received by</FormLabel>
              <SuggestedDropdown
                url={Endpoint.EmployeesList}
                inputId="receivedByUuid"
                initialData={employeesDropdown}
                inputValue={formik?.values?.receivedByUuid}
                dropdownLabel={['firstName', 'lastName']}
                dropdownValue="uuid"
                order="ASC"
                dataLocation="resultDataItems"
                error={Boolean(formik.errors.receivedByUuid)}
                helperText={formik.errors.receivedByUuid}
                formikHook={formik}
                style={{ width: '100%' }}
                filter
                resetFilter={resetFilter}
              />
            </Grid>
          )}
          <Grid item md={2}>
            <FormLabel>Order Date From</FormLabel>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardDatePicker
                autoOk={true}
                showTodayButton={true}
                value={orderDateFromFormat}
                inputValue={orderDateFromFormat}
                onChange={handleOrderDateChange}
                format="DD.MM.YYYY"
                style={datePickerStyles}
                error={Boolean(formik.errors.orderDateFrom)}
                helperText={formik.errors.orderDateFrom}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={2}>
            <FormLabel>Order Date To</FormLabel>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
              <KeyboardDatePicker
                autoOk={true}
                showTodayButton={true}
                value={orderDateToFormat}
                inputValue={orderDateToFormat}
                onChange={handleOrderDateToChange}
                format="DD.MM.YYYY"
                style={datePickerStyles}
                error={Boolean(formik.errors.orderDateTo)}
                helperText={formik.errors.orderDateTo}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Filters>
      <EditTable
        rows={items}
        columns={[
          {
            field: 'id',
            headerName: 'Id',
            width: 80,
          },
          {
            field: 'clientName',
            headerName: 'Client Name',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              params.row.contactPerson?.client?.internalName,
          },
          {
            field: 'contactPerson',
            headerName: 'Contact Person',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row.contactPerson.nick,
          },
          {
            field: 'orderedAt',
            headerName: 'Order Date',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row.orderedAt).format('DD.MM.YYYY'),
          },
          {
            field: 'createdAt',
            headerName: 'Created At',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row.createdAt).format('DD.MM.YYYY. hh:mm'),
          },
          {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row.updatedAt).format('DD.MM.YYYY. hh:mm'),
          },
          {
            field: 'receivedBy',
            headerName: 'Received By',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
              params.row.receivedBy?.firstName +
              ' ' +
              params.row.receivedBy?.lastName,
          },
          {
            field: 'dueDatetime',
            headerName: 'Due Date/time',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row.dueDatetime).format('DD.MM.YYYY'),
          },
          {
            field: 'dateCritical',
            headerName: 'Date Critical',
            width: 150,
          },
          {
            field: 'isCorrection',
            headerName: 'Correction',
            width: 130,
            renderCell: (params: any) => {
              return params?.value ? <CheckIcon color="secondary" /> : <></>;
            },
          },
          {
            field: 'isRatePerArticleClient',
            headerName: 'Rate per article client',
            width: 130,
            renderCell: (params: any) => {
              return params?.value ? <CheckIcon color="secondary" /> : <></>;
            },
          },
          {
            field: 'isRatePerArticleWriter',
            headerName: 'Rate per article writer',
            width: 130,
            renderCell: (params: any) => {
              return params?.value ? <CheckIcon color="secondary" /> : <></>;
            },
          },
          {
            field: 'isNoCharge',
            headerName: 'No charge',
            width: 130,
            renderCell: (params: any) => {
              return params?.value ? <CheckIcon color="secondary" /> : <></>;
            },
          },
        ]}
        onAdd={addEntryHandler}
        onEdit={editEntryHandler}
        onDelete={deleteEntryHandler}
        onView={viewEntryHandler}
        editColumnWidth={140}
        sortedFieldIndex={5}
        onSortModelChange={handleSort}
        sortingOrderDirection="desc"
        pagination
        page={meta?.currentPage - 1 || 0}
        rowCount={meta?.totalItems}
        onPageChange={handlePage}
        pageSize={50}
        rowsPerPageOptions={[50]}
        paginationMode="server"
        sortingMode="server"
      />
    </PageLayout>
  );
}

export default memo(OrderEntriesPage);
