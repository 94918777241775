import axios from 'axios';
import { Endpoint, Method } from 'enums/APIEndpointEnum';
import authHeaders from 'helpers/auth';
type APICallProps = {
  url: Endpoint;
  method: Method;
  data?: any;
  urlSuffix?: string;
  auth?: any;
  params?: any;
  contentType?: string;
};
export default async function apiCall({
  url,
  method,
  data,
  urlSuffix,
  auth = true,
  params,
  contentType = 'application/json',
}: APICallProps) {
  try {
    const config: any = {
      baseURL: process.env.REACT_APP_API,
      url: [url, urlSuffix].filter(Boolean).join('/'),
      method,
      data,
      params,
      headers: {
        'Content-type': contentType,
        ...(auth && authHeaders()),
      },
    }
    const result = await axios(config);
    return { result };
  } catch (error: any) {
    if (error?.response) {
      return {
        error: {
          status: parseInt(error.response?.status),
          message: error.response?.data?.message,
        },
      };
    }
    if (error instanceof Error) {
      return {
        error: {
          message: error.message,
        },
      };
    }
  }
}
