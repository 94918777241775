import { put, call, takeLatest } from 'redux-saga/effects';
import { actions as commonActions } from 'store/slices/common';
import {
  getAPEmployeeProjectRateAPI,
  addAPEmployeeProjectRateAPI,
  editAPEmployeeProjectRateAPI,
  deleteAPEmployeeProjectRateAPI,
} from 'store/api/apEmployeeProjectRateAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { APIResult } from 'types/APITypes';
import { actions as apEmployeeProjectRate } from '../slices/apEmployeeProjectRateSlice';

function* getAPEmployeeProjectRate(action: PayloadAction<string>) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(getAPEmployeeProjectRateAPI, params);

  if (result?.data) {
    yield put(apEmployeeProjectRate.setAPEmployeeProjectRate(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addAPEmployeeProjectRate(action: PayloadAction<any>) {
  const data = action.payload;
  const filters = action.payload?.filters || {};

  delete data?.filters;

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(
    addAPEmployeeProjectRateAPI,
    data,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getAPEmployeeProjectRateAPI, {
      page: 1,
      ...filters,
    });
    if (result?.data) {
      yield put(apEmployeeProjectRate.setAPEmployeeProjectRate(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added new item to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

function* editAPEmployeeProjectRate(action: PayloadAction<any>) {
  const { urlSuffix } = action.payload;
  const data = action.payload;
  const filters = action.payload?.filters || {};

  delete data?.filters;

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(
    editAPEmployeeProjectRateAPI,
    urlSuffix,
    data,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getAPEmployeeProjectRateAPI, {
      page: 1,
      ...filters,
    });
    if (result?.data) {
      yield put(apEmployeeProjectRate.setAPEmployeeProjectRate(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited this item`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

function* deleteAPEmployeeProjectRate(action: PayloadAction<any>) {
  const { urlSuffix } = action.payload;

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(
    deleteAPEmployeeProjectRateAPI,
    urlSuffix,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getAPEmployeeProjectRateAPI, {
      page: 1,
    });
    if (result?.data) {
      yield put(apEmployeeProjectRate.setAPEmployeeProjectRate(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted this item from the list`,
        severity: 'error',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

export default function* APEmployeeProjectRateWatcher() {
  yield takeLatest(
    apEmployeeProjectRate.getAPEmployeeProjectRate.type,
    getAPEmployeeProjectRate,
  );
  yield takeLatest(
    apEmployeeProjectRate.addAPEmployeeProjectRate.type,
    addAPEmployeeProjectRate,
  );
  yield takeLatest(
    apEmployeeProjectRate.editAPEmployeeProjectRate.type,
    editAPEmployeeProjectRate,
  );
  yield takeLatest(
    apEmployeeProjectRate.deleteAPEmployeeProjectRate.type,
    deleteAPEmployeeProjectRate,
  );
}
