import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GenericIdNameType } from 'types/genericTypes';
export interface TonesOfVoice {
  voices: GenericIdNameType[];
}

const initialState: TonesOfVoice = {
  voices: [],
};

export const voicesSlice = createSlice({
  name: 'voices',
  initialState,
  reducers: {
    getTonesOfVoices: (state) => state,
    setTonesOfVoices: (state, action: PayloadAction<GenericIdNameType[]>) => {
      state.voices = action.payload;
    },
    addTonesOfVoices: (state) =>
      state,
    deleteTonesOfVoices: (state) =>
      state,
    editTonesOfVoices: (state) =>
      state,
  },
});
export const actions: any = voicesSlice.actions;
export default voicesSlice.reducer;
