import AuthContainer from 'components/AuthContainer/AuthContainer';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { actions as authActions } from 'store/slices/auth';
import { actions as commonActions } from 'store/slices/common';
import { Box, FormLabel, Typography } from '@mui/material';
import { TextField } from 'components';
import { SetPasswordPageProps, SetPasswordProps } from 'types/authTypes';
import l from 'helpers/l';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FC } from 'react';

const initialValues: SetPasswordProps = {
  password: '',
  confirmPassword: '',
};

const validationSchema = Yup.object({
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      'Please enter a valid password.',
    ),
  confirmPassword: Yup.string()
    .required('Field is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
const SetPassword: FC<SetPasswordPageProps> = ({ page }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const pageTtitle =
    page === 'newUser'
      ? l('UI_LABELS.SET_PASSWORD')
      : l('UI_LABELS.RESET_PASSWORD');

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: SetPasswordProps) => {
      if (token) {
        dispatch(authActions.setPassword({ ...values, token, navigate, page }));
      } else {
        dispatch(
          commonActions.snackBar({
            open: true,
            message: l('AUTH.NO_TOKEN'),
            severity: 'error',
          }),
        );
      }
    },
  });
  return (
    <AuthContainer
      title={pageTtitle}
      buttonText={l('UI_LABELS.SET')}
      onSubmit={formik.submitForm}>
      <Box mb="10px">
        <FormLabel>{l('PASSWORD')}</FormLabel>
        <Typography gutterBottom variant="subtitle2">
          At least 8 characters, at least one capital letter A-Z, one small
          letter a-z and one figure 0-9
        </Typography>
        <TextField
          name="password"
          type="password"
          fullWidth
          size="small"
          value={formik.values.password}
          onChange={formik.handleChange}
          autoComplete={'off'}
          error={Boolean(formik.errors.password)}
          helperText={formik.errors.password}
          pressEnter={formik.submitForm}
        />
        <FormLabel>{l('REPASSWORD')}</FormLabel>
        <TextField
          name="confirmPassword"
          type="password"
          fullWidth
          size="small"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          autoComplete={'off'}
          error={Boolean(formik.errors.confirmPassword)}
          helperText={formik.errors.confirmPassword}
          pressEnter={formik.submitForm}
        />
      </Box>
    </AuthContainer>
  );
};
export default SetPassword;
