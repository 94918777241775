import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  items: [],
  meta: {},
};

export const ChecklistsContactPersonsSlice = createSlice({
  name: 'ChecklistsContactPersons',
  initialState,
  reducers: {
    getChecklistsContactPersons: (state) => state,
    setChecklistsContactPersons: (state, action: PayloadAction<any[]>) => action.payload,
    sendChecklistsContactPersonsFilters: (state) => state,
    addChecklistsContactPersons: (state) => state,
    deleteChecklistsContactPersons: (state) => state,
    editChecklistsContactPersons: (state) => state,
  },
});
export const actions: any = ChecklistsContactPersonsSlice.actions;
export default ChecklistsContactPersonsSlice.reducer;
