import { FC, memo} from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormLabel, Typography } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps } from 'types/genericTypes';
import { Endpoint } from 'enums/APIEndpointEnum';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';

const validationSchemaAdd = Yup.object().shape({
  questionId: Yup.string().required('Required'),
  answer: Yup.string().required('Required'),
});

const defaultValues = {
  questionId: '',
  answer: '',
};

type EmployeeQuestionFormProps = {
  initialValues?: any;
  title: string;
  employeeName?: string;
  employeeUuid?: string;
  onSubmit: (values: any) => void;
  values?: any;
  unassignedQuestionsValues?: any;
} & GenericModalProps;

const EmployeeViewQuestionForm: FC<EmployeeQuestionFormProps> = ({
  onClose,
  initialValues = defaultValues,
  employeeName,
  employeeUuid,
  title,
  unassignedQuestionsValues,
  onSubmit,
}) => {
  const validationSchema = validationSchemaAdd;

  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit({
        values,
      });
    },
  });

  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <FormLabel>Employee</FormLabel>
      <Box sx={{ marginBottom: '30px' }}>
        <Typography fontWeight={600}>{employeeName}</Typography>
      </Box>
      <FormLabel>Question</FormLabel>
      {unassignedQuestionsValues ? (
        <SuggestedDropdown
          url={Endpoint.UserNotAssignedQuestions}
          urlSuffix={employeeUuid}
          inputId="questionId"
          initialData={unassignedQuestionsValues}
          error={Boolean(formik.errors.questionId)}
          helperText={formik.errors.questionId}
          dropdownLabel="name"
          dropdownValue="id"
          dataLocation="resultData"
          formikHook={formik}
        />
      ) : (
        <Typography sx={{ marginBottom: '20px', fontWeight: 700 }}>
          {initialValues?.question.name}
        </Typography>
      )}
      <FormLabel>Answer</FormLabel>
      <TextField
  multiline
  rows={6}
  name='answer'
  sx={{ display: 'flex', height: 'max-content', width: '100%' }}
  size='small'
  value={formik.values.answer}
  error={Boolean(formik.errors.answer)}
  helperText={formik.errors.answer}
  onChange={formik.handleChange}
  autoComplete='off'
  pressEnter={formik.submitForm}/>
    </SimpleFormModal>
  );
};

export default memo(EmployeeViewQuestionForm);
