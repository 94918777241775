import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  Languages,
  EmailEditTemplate,
  EmailListTemplate,
  TimeZonesPage,
  Login,
  ForgottenPassword,
  CurrenciesPage,
  Permissions,
  CountriesPage,
  Roles,
  SetPassword,
  EmployeesListPage,
  EmployeeDetailsPage,
  EmployeeAddPage,
  EmployeeEditPage,
  Expertise,
  EmployeeStatuses,
  Questions,
  Voices,
  Payment,
  ArticleTypes,
  OrderTypes,
  OrderLineStatuses,
  OrderLineStatusRoles,
  Checklists,
  Speakers,
  ContactPersons,
  ContactPersonsAdd,
  ContactPersonsEdit,
  Clients,
  TargetAudience,
  OrderEntriesPage,
  OrderEntriesAddPage,
  OrderEntriesEditPage,
  OrderDetailsPage,
  OrderDeliveryPage,
  Projects,
  ClientsEditPage,
  ClientsAddPage,
  OrderLineEditPage,
  ChecklistsRoles,
  ChecklistsContactPersons,
  ProjectManagementPage,
  ProjectManagementDetailPage,
  EditorPage,
  EditorDetailPage,
  WriterPage,
  WriterDetailPage,
  ReportParametersPage,
  MessagesPage,
  ReportCategoriesPage,
  ARLanguageRatesPage,
  ARProjectRatesPage,
  ARClientsRatePage,
  APLanguageRatePage,
  APEmployeeLanguageRatePage,
  APEmployeeProjectRatePage,
} from 'pages';
import { Guard } from 'components';
import ErrorHandler from 'components/ErrorHandler/ErrorHandler';
import OrderLineAddPage from 'pages/OrderLineAddPage/OrderLineAddPage';
import ReportsPage from './pages/Reports/ReportsPage';
import ReportWOParametersPage from './pages/Reports/ReportWOParametersPage';
import RunReportsPage from './pages/Reports/RunReportsPage';
import ReportResultsPage from './pages/Reports/ReportResultsPage';
import ReportManagementByCategory from './pages/Reports/ReportsManagement/ReportManagementByCategoryPage';
import ReportsManagementAddEditPage from './pages/Reports/ReportsManagement/ReportsManagementAddEditPage';
import PMPage from './pages/ProjectManagementPage/PMPage';

const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<div />} />
      <Route
        path="/languages"
        element={
          <Guard allow={['languages_management']}>
            <Languages />
          </Guard>
        }
      />
      <Route
        path="/email-list"
        element={
          <Guard allow={['email_templates_management']}>
            <EmailListTemplate />
          </Guard>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotten-password" element={<ForgottenPassword />} />
      <Route
        path="/reset-password"
        element={<SetPassword page="resetPass" />}
      />
      <Route path="/set-password" element={<SetPassword page="newUser" />} />
      <Route
        path="/email-list/edit/:id"
        element={
          <Guard allow={['email_templates_management']}>
            <EmailEditTemplate />
          </Guard>
        }
      />
      <Route
        path="/timezones"
        element={
          <Guard allow={['timezones_list']}>
            <TimeZonesPage />
          </Guard>
        }
      />
      <Route
        path="/currencies"
        element={
          <Guard allow={['currencies_management']}>
            <CurrenciesPage />
          </Guard>
        }
      />
      <Route
        path="/countries"
        element={
          <Guard allow={['countries_management']}>
            <CountriesPage />
          </Guard>
        }
      />
      <Route
        path="/permissions"
        element={
          <Guard allow={['permissions_management']}>
            <Permissions />
          </Guard>
        }
      />
      <Route
        path="/roles"
        element={
          <Guard allow={['roles_management']}>
            <Roles />
          </Guard>
        }
      />
      <Route
        path="/employees"
        element={
          <Guard allow={['employees_list']}>
            <EmployeesListPage />
          </Guard>
        }
      />
      <Route
        path="/employees/:id"
        element={
          <Guard allow={['employees_list']}>
            <EmployeeDetailsPage />
          </Guard>
        }
      />
      <Route
        path="/add-employee"
        element={
          <Guard allow={['employees_edit']}>
            <EmployeeAddPage />
          </Guard>
        }
      />
      <Route
        path="/edit-employee/:id"
        element={
          <Guard allow={['employees_edit']}>
            <EmployeeEditPage />
          </Guard>
        }
      />
      <Route
        path="/expertise"
        element={
          <Guard allow={['expertise_management']}>
            <Expertise />
          </Guard>
        }
      />
      <Route
        path="/employee-statuses"
        element={
          <Guard allow={['employee_statuses_management']}>
            <EmployeeStatuses />
          </Guard>
        }
      />
      <Route
        path="/questions"
        element={
          <Guard allow={['questions_management']}>
            <Questions />
          </Guard>
        }
      />
      <Route
        path="/tone-of-voices"
        element={
          <Guard allow={['tones_of_voice_management']}>
            <Voices />
          </Guard>
        }
      />
      <Route
        path="/payment"
        element={
          <Guard allow={['payment_methods_management']}>
            <Payment />
          </Guard>
        }
      />
      <Route
        path="/target-audience"
        element={
          <Guard allow={['target_audience_management']}>
            <TargetAudience />
          </Guard>
        }
      />
      <Route
        path="/projects"
        element={
          <Guard allow={['projects']}>
            <Projects />
          </Guard>
        }
      />
      <Route
        path="/article-types"
        element={
          <Guard allow={['article_types_management']}>
            <ArticleTypes />
          </Guard>
        }
      />
      <Route
        path="/order-types"
        element={
          <Guard allow={['order_types_management']}>
            <OrderTypes />
          </Guard>
        }
      />
      <Route
        path="/order-line-statuses"
        element={
          <Guard allow={['order_line_status_management']}>
            <OrderLineStatuses />
          </Guard>
        }
      />
      <Route
        path="/order-line-statuses-assignment"
        element={
          <Guard allow={['order_line_status_roles_management']}>
            <OrderLineStatusRoles />
          </Guard>
        }
      />
      <Route
        path="/checklists"
        element={
          <Guard allow={['checklist_management']}>
            <Checklists />
          </Guard>
        }
      />
      <Route
        path="/checklists-roles"
        element={
          <Guard allow={['checklist_management']}>
            <ChecklistsRoles />
          </Guard>
        }
      />
      <Route
        path="/checklists-contact-persons"
        element={
          <Guard allow={['checklist_management']}>
            <ChecklistsContactPersons />
          </Guard>
        }
      />
      <Route
        path="/speakers"
        element={
          <Guard allow={['speakers_management']}>
            <Speakers />
          </Guard>
        }
      />
      <Route
        path="/contact-persons"
        element={
          <Guard allow={['contact_persons_management']}>
            <ContactPersons />
          </Guard>
        }
      />
      <Route
        path="/contact-persons/add-contact-person"
        element={
          <Guard allow={['contact_persons_management']}>
            <ContactPersonsAdd />
          </Guard>
        }
      />
      <Route
        path="/edit-contact-persons/:contactPerson"
        element={
          <Guard allow={['contact_persons_management']}>
            <ContactPersonsEdit />
          </Guard>
        }
      />
      <Route
        path="/clients"
        element={
          <Guard allow={['clients_management']}>
            <Clients />
          </Guard>
        }
      />
      <Route
        path="/add-client"
        element={
          <Guard allow={['clients_management']}>
            <ClientsAddPage />
          </Guard>
        }
      />
      <Route
        path="/edit-client/:client"
        element={
          <Guard allow={['clients_management']}>
            <ClientsEditPage />
          </Guard>
        }
      />
      <Route
        path="/orders-entries"
        element={
          <Guard allow={['order_receiving_unrestricted', 'order_receiving']}>
            <OrderEntriesPage />
          </Guard>
        }
      />
      <Route
        path="/add-order"
        element={
          <Guard allow={['order_receiving', 'order_receiving_unrestricted']}>
            <OrderEntriesAddPage />
          </Guard>
        }
      />
      <Route
        path="/edit-order/:id"
        element={
          <Guard allow={['order_receiving', 'order_receiving_unrestricted']}>
            <OrderEntriesEditPage />
          </Guard>
        }
      />
      <Route
        path="/orders-entries/:id"
        element={
          <Guard allow={['order_receiving', 'order_receiving_unrestricted']}>
            <OrderDetailsPage />
          </Guard>
        }
      />
      <Route
        path="/add-order-line/:id"
        element={
          <Guard allow={['order_receiving', 'order_receiving_unrestricted']}>
            <OrderLineAddPage type={'add'} />
          </Guard>
        }
      />
      <Route
        path="/duplicate-order-line/:id"
        element={
          <Guard allow={['order_receiving', 'order_receiving_unrestricted']}>
            <OrderLineAddPage type={'duplicate'} />
          </Guard>
        }
      />
      <Route
        path="/edit-order-line/:id/:id"
        element={
          <Guard allow={['order_receiving', 'order_receiving_unrestricted']}>
            <OrderLineEditPage />
          </Guard>
        }
      />
      <Route
        path="/pm-work-screen"
        element={
          <Guard
            allow={[
              'pm_screen_unrestricted',
              'pm_screen',
              'pm_screen_limited',
              'pm_screen_limited',
            ]}>
            <PMPage />
          </Guard>
        }
      />
      <Route
        path="/pm-work-screen-details/:id"
        element={
          <Guard allow={['pm_screen']}>
            <ProjectManagementDetailPage />
          </Guard>
        }
      />
      <Route
        path="/order-delivery"
        element={
          <Guard
            allow={[
              'order_delivery_screen_unrestricted',
              'order_delivery_screen',
              'order_delivery_screen_account_manager',
            ]}>
            <OrderDeliveryPage />
          </Guard>
        }
      />
      <Route
        path="/editor-screen"
        element={
          <Guard allow={['editor_screen']}>
            <EditorPage />
          </Guard>
        }
      />
      <Route
        path="/editor-detail-screen/:id"
        element={
          <Guard allow={['editor_screen']}>
            <EditorDetailPage />
          </Guard>
        }
      />
      <Route
        path="/writer-screen"
        element={
          <Guard allow={['writer_screen']}>
            <WriterPage />
          </Guard>
        }
      />
      <Route
        path="/writer-detail-screen/:id"
        element={
          <Guard allow={['writer_screen']}>
            <WriterDetailPage />
          </Guard>
        }
      />
      <Route
        path="/report-parameters"
        element={
          <Guard allow={['report_builder']}>
            <ReportParametersPage />
          </Guard>
        }
      />
      <Route
        path="/report-categories"
        element={
          <Guard allow={['report_builder']}>
            <ReportCategoriesPage />
          </Guard>
        }
      />
      <Route
        path="/report-categories"
        element={
          <Guard allow={['report_builder']}>
            <ReportCategoriesPage />
          </Guard>
        }
      />
      <Route
        path="/reports"
        element={
          <Guard allow={['report_builder', 'report_consumer']}>
            <ReportsPage />
          </Guard>
        }
      />
      <Route
        path="/report_result_w_o_parameters/:reportId"
        element={
          <Guard allow={['report_builder', 'report_consumer']}>
            <ReportWOParametersPage />
          </Guard>
        }
      />
      <Route
        path="/run_report/:reportUuid"
        element={
          <Guard allow={['report_builder', 'report_consumer']}>
            <RunReportsPage />
          </Guard>
        }
      />
      <Route
        path="/report_results/:reportUuid"
        element={
          <Guard allow={['report_builder', 'report_consumer']}>
            <ReportResultsPage />
          </Guard>
        }
      />
      <Route
        path="/report_management_by_category/:id"
        element={
          <Guard allow={['report_builder', 'report_consumer']}>
            <ReportManagementByCategory />
          </Guard>
        }
      />
      <Route
        path="/report_management"
        element={
          <Guard allow={['report_builder', 'report_consumer']}>
            <ReportsManagementAddEditPage />
          </Guard>
        }
      />
      <Route
        path="/messages"
        element={
          <Guard
            allow={[
              'order_receiving_unrestricted',
              'order_receiving',
              'pm_screen',
              'order_delivery_screen',
              'editor_screen',
              'writer_screen',
              'chat',
            ]}>
            <MessagesPage />
          </Guard>
        }
      />
      <Route
        path="/language-rates"
        element={
          <Guard allow={['ar_language_prices_management']}>
            <ARLanguageRatesPage />
          </Guard>
        }
      />
      <Route
        path="/project-rates"
        element={
          <Guard allow={['ar_project_prices_management']}>
            <ARProjectRatesPage />
          </Guard>
        }
      />
      <Route
        path="/client-rates"
        element={
          <Guard allow={['ar_client_prices_management']}>
            <ARClientsRatePage />
          </Guard>
        }
      />
      <Route
        path="/ap-language-rates"
        element={
          <Guard allow={['ap_language_prices_management']}>
            <APLanguageRatePage />
          </Guard>
        }
      />
      <Route
        path="/ap-employee-language-rates"
        element={
          <Guard allow={['ap_language_prices_management']}>
            <APEmployeeLanguageRatePage />
          </Guard>
        }
      />
      <Route
        path="/ap-employee-project-rates"
        element={
          <Guard allow={['ap_language_prices_management']}>
            <APEmployeeProjectRatePage />
          </Guard>
        }
      />
      <Route path="*" element={<ErrorHandler statusCode={404} />} />
    </Routes>
  );
};

export default AppRoutes;
