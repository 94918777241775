import { makeStyles } from '@material-ui/core/styles';
const styles = makeStyles(() => {
  return {
    Tabs: {
      '& .MuiTabs-root': {
        minHeight: 'unset',
      },
      '& .MuiTab-root': {
        marginRight: '10px',
        backgroundColor: '#e2e2e2',
        borderRadius: '15px 15px 0 0',
        padding: '10px',
        minHeight: '30px',
        color: 'black',
      },
      '& .Mui-selected': {
        backgroundColor: '#fff',
      },
      '& .MuiTabPanel-root': {
        padding: '0',
      },
    },
    tabsSingular: {
      '& .MuiTab-root': {
        textTransform: 'capitalize',
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
  };
});
export default styles;
