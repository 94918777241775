import {call, put, takeLatest} from 'redux-saga/effects';
import {actions as commonActions} from 'store/slices/common';

import {APIResult} from 'types/APITypes';
import {
  DeleteReportExecutionApi,
  executeReportApi,
  executeReportWithParametersApi,
  exportToPDFApi,
  exportToXLSXApi,
  getPreviousExecutionsOfReportApi, getReportByUuidApi,
  getReportsApi,
  getRunReportFiledsApi,
  ReportRunQueryApi,
  SaveOfReportApi,
  sendPreviousExecutionsOfReportFiltersApi,
} from '../../api/reportsAPI';
import {actions as reportsSlice} from "../../slices/reportSlices/reportsSlice";
import {PayloadAction} from "@reduxjs/toolkit";
import fileDownload from 'js-file-download';

function* getReports() {
    yield put(commonActions.setLoading(true));
    const {result}: APIResult<any> = yield call(getReportsApi);
    if (result?.data) {
        yield put(reportsSlice.setReports(result?.data));
    }
    yield put(commonActions.setLoading(false));
}

function* getReportByUuid(action: PayloadAction<any>) {
  yield put(commonActions.setLoading(true));
  const urlSuffix = action.payload.urlSuffix;
  const {result}: APIResult<any> = yield call(getReportByUuidApi, urlSuffix);
  if (result?.data) {
    yield put(reportsSlice.setReportsByUuid(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* executeReport(action: PayloadAction<any>) {
    yield put(commonActions.setLoading(true));
    const urlSuffix = action.payload.urlSuffix;
    const data = action.payload.reportParameters;

    const dataToSend = {
        parameters: data,
    }
    if (data) {
        const {result, error}: APIResult<any> = yield call(executeReportWithParametersApi, urlSuffix, dataToSend.parameters);
        if (result?.data) {
            yield put(reportsSlice.setExecutedReport(result?.data));
        }
        if (error) {
            yield put(
              commonActions.snackBar({
                  open: true,
                  message: error.message,
                  severity: 'error',
              }),
            );
        }
    } else {
        const {result, error}: APIResult<any> = yield call(executeReportApi, urlSuffix);
        if (result?.data) {
            yield put(reportsSlice.setExecutedReport(result?.data));
        }
        if (error) {
            yield put(
              commonActions.snackBar({
                  open: true,
                  message: error.message,
                  severity: 'error',
              }),
            );
        }
    }

    yield put(commonActions.setLoading(false));
}

function* deleteReportExecution(action: PayloadAction<any>) {
    yield put(commonActions.setLoading(true));
    const urlSuffix = action.payload.urlSuffix
    const tableUrlSuffix = action.payload.tableUrlSuffix

    const {result, error}: APIResult<any> = yield call(DeleteReportExecutionApi, urlSuffix);

    if (result?.status === 200) {
        const {result}: APIResult<any> = yield call(getPreviousExecutionsOfReportApi, tableUrlSuffix);

        if (result?.data) {
            yield put(reportsSlice.setPreviousExecutionsOfReport(result?.data));
        }

        yield put(
          commonActions.snackBar({
              open: true,
              message: `You have successfully deleted execution`,
              severity: 'success',
          }),
        );
    }else if(error){
        yield put(
          commonActions.snackBar({
              open: true,
              message: error.message,
              severity: 'error',
          }),
        );
    }

    yield put(commonActions.setLoading(false));
}

function* runReport(action: PayloadAction<any>) {
    yield put(commonActions.setLoading(true));
    const urlSuffix = action.payload.urlSuffix
    const data = action.payload.reportParameters

    const {result, error}: APIResult<any> = yield call(executeReportWithParametersApi, urlSuffix, data);
    if (result?.data) {
        yield put(reportsSlice.setRunReport(result?.data));
    }

    if (error) {
        yield put(
          commonActions.snackBar({
              open: true,
              message: error.message,
              severity: 'error',
          }),
        );
    }
    yield put(commonActions.setLoading(false));
}

function* previousExecutionsOfReport(action: PayloadAction<any>) {
    yield put(commonActions.setLoading(true));
    const urlSuffix = action.payload.urlSuffix

    const {result, error}: APIResult<any> = yield call(getPreviousExecutionsOfReportApi, urlSuffix);
    if (result?.data) {
        yield put(reportsSlice.setPreviousExecutionsOfReport(result?.data));
    }

    if (error) {
        yield put(
          commonActions.snackBar({
              open: true,
              message: error.message,
              severity: 'error',
          }),
        );
    }

    yield put(commonActions.setLoading(false));
}

function* getRunReportsFields(action: PayloadAction<any>) {
    yield put(commonActions.setLoading(true));
    const urlSuffix = action.payload.urlSuffix
    const {result, error}: APIResult<any> = yield call(getRunReportFiledsApi, urlSuffix);
    if (result?.data) {
        yield put(reportsSlice.setRunReportsFields(result?.data));
    }
    if (error) {
        yield put(
          commonActions.snackBar({
              open: true,
              message: error.message,
              severity: 'error',
          }),
        );
    }
    yield put(commonActions.setLoading(false));
}

function* sendExecutedReportFilters(action: PayloadAction<string>) {
    const data: any = action.payload;
    const urlSuffix = data.urlSuffix
    const params = {
        order: data.order,
        orderBy: data.orderBy,
        page: data.page,
    }

    yield put(commonActions.setLoading(true));
    const {result, error}: APIResult<any> = yield call(executeReportApi, urlSuffix, params, data)
    yield put(reportsSlice.setExecutedReport([]));
    if (result?.data) {
        yield put(reportsSlice.setExecutedReport(result?.data));
    }

    if (error) {
        yield put(
          commonActions.snackBar({
              open: true,
              message: error.message,
              severity: 'error',
          }),
        );
    }
    yield put(commonActions.setLoading(false));
}

function* exportPDF(action: PayloadAction<any>) {
    yield put(commonActions.setLoading(true));
    const urlSuffix = action.payload.urlSuffix
    const {result, error}: APIResult<any> = yield call(exportToPDFApi, urlSuffix, action.payload.data, 'application/json');
    if (result?.data) {

        const outputFilename = `${Date.now()}.pdf`;

        fileDownload(
          result?.data,
          outputFilename,
        )
    }
    if (error) {
        yield put(
          commonActions.snackBar({
              open: true,
              message: error.message,
              severity: 'error',
          }),
        );
    }
    yield put(commonActions.setLoading(false));
}

function* exportXLSX(action: PayloadAction<any>) {
    yield put(commonActions.setLoading(true));
    const urlSuffix = action.payload.urlSuffix
    const {result, error}: APIResult<any> = yield call(exportToXLSXApi, urlSuffix, action.payload.data, 'application/json');
    if (result?.data) {

        const byteCharacters = atob(result?.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

        fileDownload(
          blob,
          'xlsx.xlsx',
        )
    }
    if (error) {
        yield put(
          commonActions.snackBar({
              open: true,
              message: error.message,
              severity: 'error',
          }),
        );
    }
    yield put(commonActions.setLoading(false));
}

function* saveReport(action: PayloadAction<any>) {
    yield put(commonActions.setLoading(true));
    const data = action.payload

    const {result, error}: APIResult<any> = yield call(SaveOfReportApi, data);
    if (result?.data) {
        yield put(
          commonActions.snackBar({
              open: true,
              message: `You have successfully saved report`,
              severity: 'success',
          }),
        );
    }
    if (error) {
        yield put(
          commonActions.snackBar({
              open: true,
              message: error.message,
              severity: 'error',
          }),
        );
    }

    yield put(commonActions.setLoading(false));
}

function* runReportQuery(action: PayloadAction<any>) {
    yield put(commonActions.setLoading(true));
    const data = action.payload

    const {result, error}: APIResult<any> = yield call(ReportRunQueryApi, data);
    if (result?.data) {
        yield put(reportsSlice.setRunReportQuery(result?.data));
    }
    if (error) {
        yield put(
          commonActions.snackBar({
              open: true,
              message: error.message,
              severity: 'error',
          }),
        );
    }
    yield put(commonActions.setLoading(false));
}

function* sendPreviousExecutionsOfReportFilters(action: any) {
    const urlSuffix = action.payload.urlSuffix;
    const params = action.payload;
    delete params.urlSuffix;
    yield put(commonActions.setLoading(true));

    const { result, error }: APIResult<any> = yield call(
      sendPreviousExecutionsOfReportFiltersApi,
      urlSuffix,
      params,
    );

    if (error) {
        yield put(
          commonActions.snackBar({
              open: true,
              message: error.message,
              severity: 'error',
          }),
        );
    } else {
        if (result?.data) {
            yield put(reportsSlice.setPreviousExecutionsOfReport(result?.data));
        }
    }
    yield put(commonActions.setLoading(false));
}

export default function* ReportsWatcher() {
    yield takeLatest(reportsSlice.getReports.type, getReports);
    yield takeLatest(reportsSlice.getReportByUuid.type, getReportByUuid);
    yield takeLatest(reportsSlice.executeReport.type, executeReport);
    yield takeLatest(reportsSlice.sendExecutedReportFilters.type, sendExecutedReportFilters);
    yield takeLatest(reportsSlice.exportXLSX.type, exportXLSX);
    yield takeLatest(reportsSlice.exportPDF.type, exportPDF);
    yield takeLatest(reportsSlice.previousExecutionsOfReport.type, previousExecutionsOfReport);
    yield takeLatest(reportsSlice.getRunReportsFields.type, getRunReportsFields);
    yield takeLatest(reportsSlice.runReport.type, runReport);
    yield takeLatest(reportsSlice.saveReport.type, saveReport);
    yield takeLatest(reportsSlice.runReportQuery.type, runReportQuery);
    yield takeLatest(reportsSlice.deleteReportExecution.type, deleteReportExecution);
    yield takeLatest(reportsSlice.sendPreviousExecutionsOfReportFilters.type, sendPreviousExecutionsOfReportFilters);
}
