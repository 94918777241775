import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/articleTypesSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  addArticleTypesApi,
  deleteArticleTypesApi,
  editArticleTypesApi,
  getArticleTypesApi,
} from 'store/api/articleTypesAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { ArticleTypesActionEntry } from 'types/articleTypes';
import { ArticleTypesEntry } from 'types/articleTypes';
import { APIResult } from 'types/APITypes';

function* getArticleTypes() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<ArticleTypesEntry[]> = yield call(
    getArticleTypesApi,
  );
  if (result?.data?.length) {
    yield put(actions.setArticleTypes(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addArticleTypes(action: PayloadAction<ArticleTypesActionEntry>) {
  const data = action.payload;
  const { result, error }: APIResult<ArticleTypesActionEntry> = yield call(
    addArticleTypesApi,
    data,
  );
  if (result?.status === 201) {
    const { result }: APIResult<ArticleTypesEntry[]> = yield call(
      getArticleTypesApi,
    );
    if (result?.data?.length) {
      yield put(actions.setArticleTypes(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.name} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.name} already exists`,
        severity: 'error',
      }),
    );
  }
}

function* deleteArticleTypes(action: PayloadAction<ArticleTypesEntry>) {
  const { uuid, name } = action.payload;
  const { result }: APIResult<ArticleTypesEntry> = yield call(
    deleteArticleTypesApi,
    uuid! as string,
  );
  if (result?.status === 200) {
    const { result }: APIResult<ArticleTypesEntry[]> = yield call(
      getArticleTypesApi,
    );
    if (result?.data?.length) {
      yield put(actions.setArticleTypes(result.data));
    }
    yield put(commonActions.hideModal());
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
  }
}

function* editArticleTypes(action: PayloadAction<ArticleTypesEntry>) {
  const { name, uuid } = action.payload;

  const { result, error }: APIResult<ArticleTypesEntry> = yield call(
    editArticleTypesApi,
    { uuid, name },
  );
  if (result?.status === 200) {
    const { result }: APIResult<ArticleTypesEntry[]> = yield call(
      getArticleTypesApi,
    );
    if (result?.data?.length) {
      yield put(actions.setArticleTypes(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Employee status name is taken',
        severity: 'error',
      }),
    );
  }
}

export default function* articleTypesWatcher() {
  yield takeLatest(actions.getArticleTypes.type, getArticleTypes);
  yield takeLatest(actions.addArticleTypes.type, addArticleTypes);
  yield takeLatest(actions.deleteArticleTypes.type, deleteArticleTypes);
  yield takeLatest(actions.editArticleTypes.type, editArticleTypes);
}
