import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationMeta } from 'types/genericTypes';

interface IAPEmployeeProjectRate {
  items: any[];
  meta: PaginationMeta;
}
const initialState: IAPEmployeeProjectRate = {
  items: [],
  meta: {},
};

export const apEmployeeProjectRateSlice = createSlice({
  name: 'apEmployeeProjectRate',
  initialState,
  reducers: {
    getAPEmployeeProjectRate: (state) => state,
    setAPEmployeeProjectRate: (state, action: PayloadAction<any>) => {
      state.items = action?.payload?.items;
      state.meta = action.payload.meta;
    },
    addAPEmployeeProjectRate: (state) => state,
    editAPEmployeeProjectRate: (state) => state,
    deleteAPEmployeeProjectRate: (state) => state,
  },
});
export const actions: any = apEmployeeProjectRateSlice.actions;
export default apEmployeeProjectRateSlice.reducer;
