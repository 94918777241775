import { FC, memo, useCallback, useEffect } from 'react';
import { actions as userActions } from 'store/slices/user';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import UserQuestionAddForm from 'components/Forms/UserQuestionAddForm';
import { RootState } from 'store';
import { createMenuItems } from 'helpers/dropdown';

const UserQuestionAddModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const userUnassignedQuestions = useSelector(
    (state: RootState) => state.user.userUnassignedQuestions,
  ).map((exp: any) => exp.name);

  const userUnassignedQuestionsDropdown = useSelector(
    (state: RootState) => state.user.userUnassignedQuestions,
  )
    .map(({ name, id }) => {
      return { label: name, value: id };
    })
    .filter((role) => {
      if (userUnassignedQuestions.includes(role.label)) return role;
      return null;
    });

  const unassignedQuestionsValues = createMenuItems(
    userUnassignedQuestionsDropdown,
  );

  useEffect(() => {
    dispatch(userActions.getUserUnassignedQuestions(data.id));
  }, [dispatch, data.id]);

  const submitHandler = useCallback(
    (values: any) => {
      dispatch(
        userActions.addUserQuestions({
          ...values.values,
          employeeUuid: data.id,
        }),
      );
    },
    [dispatch, data.id],
  );

  return (
    <UserQuestionAddForm
      title="Employee Question Add"
      employeeName={data.name}
      onSubmit={submitHandler}
      employeeUuid={data.id}
      unassignedQuestionsValues={unassignedQuestionsValues}
      {...rest}
    />
  );
};

export default memo(UserQuestionAddModal);
