import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/questionsSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  sendQuestionsFiltersApi,
  addQuestionApi,
  getQuestionsApi,
  editQuestionApi,
  deleteQuestionApi,
} from 'store/api/questionsAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { APIResult } from 'types/APITypes';

function* sendQuestionsFilters(action: PayloadAction<string>) {
  yield put(commonActions.setLoading(true));
  const params = action.payload;
  const { result } = yield call(sendQuestionsFiltersApi, params);

  if (result?.data) {
    yield put(actions.setQuestions(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* getQuestions() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any> = yield call(getQuestionsApi);
  if (result?.data) {
    yield put(actions.setQuestions(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addQuestion(action: PayloadAction<any>) {
  const data = action.payload;
  const { result, error }: APIResult<any> = yield call(addQuestionApi, data);
  if (result?.status === 201) {
    const { result }: APIResult<any[]> = yield call(getQuestionsApi);
    if (result?.data) {
      yield put(actions.setQuestions(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.name} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.name} already exists`,
        severity: 'error',
      }),
    );
  }
}

function* deleteQuestion(action: PayloadAction<any>) {
  const { id, name } = action.payload;
  const { result }: APIResult<any> = yield call(
    deleteQuestionApi,
    id! as string,
  );
  if (result?.status === 200) {
    const { result }: APIResult<any[]> = yield call(getQuestionsApi);
    if (result?.data) {
      yield put(actions.setQuestions(result.data));
    }
    yield put(commonActions.hideModal());
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
  }
}

function* editQuestion(action: PayloadAction<any>) {
  const { name, id } = action.payload;

  const { result, error }: APIResult<any> = yield call(editQuestionApi, {
    id,
    name,
  });
  if (result?.status === 200) {
    const { result }: APIResult<any[]> = yield call(getQuestionsApi);
    if (result?.data) {
      yield put(actions.setQuestions(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Question already exist!',
        severity: 'error',
      }),
    );
  }
}

export default function* questionsWatcher() {
  yield takeLatest(actions.sendQuestionsFilters.type, sendQuestionsFilters);
  yield takeLatest(actions.getQuestions.type, getQuestions);
  yield takeLatest(actions.addQuestion.type, addQuestion);
  yield takeLatest(actions.deleteQuestion.type, deleteQuestion);
  yield takeLatest(actions.editQuestion.type, editQuestion);
}
