export function getDateColor(
  dateStr: string,
  XnumberOfDays: number = 5,
): string {
  //  const dueDate = new Date(dateStr).setHours(0, 0, 0, 0);
  //  const today = new Date().setHours(0, 0, 0, 0);
  //  const dayAsMs = 86400000;

  //  if (today > dueDate) return '#D9001B';
  //  else if (dueDate - today > XnumberOfDays * dayAsMs) return '#078E24';
  //  else return '#F59A23';
  const dueDate = new Date(dateStr).setHours(0, 0, 0, 0);
  const today = new Date().setHours(0, 0, 0, 0);
  const dayAsMs = 86400000;
  const daysRemaining = dueDate - today + dayAsMs;

  if (today > dueDate) return '#D9001B';
  else if (daysRemaining > XnumberOfDays * dayAsMs) return '#078E24';
  else return '#F59A23';
}

export function daysOverdueDropdown() {
  const negativeArray = <any>[
    {
      label: `All`,
      value: 'All',
    },
  ];
  for (let i = -15; i < 0; i++) {
    negativeArray.push({
      label: `${i} or less`,
      value: i,
    });
  }
  negativeArray.push({
    label: `0`,
    value: 0,
  });
  for (let i = 1; i < 16; i++) {
    negativeArray.push({
      label: `${i} or more`,
      value: i,
    });
  }
  return negativeArray;
}

export function statusData() {
  return [
    { label: 'PM', value: 'pm' },
    { label: 'Assigned', value: 'assigned' },
    { label: 'PM Check', value: 'pmCheck' },
    { label: 'For Edit', value: 'forEdit' },
    { label: 'Edit Done', value: 'editDone' },
    //{ label: 'Reject', value: 'reject' },
    { label: 'PM Approved', value: 'pmApproved' },
  ];
}

export function numbersDataDropdown() {
  const numberArray = <any>[];
  for (let i = 0; i <= 10; i++) {
    numberArray.push({
      label: i,
      value: i,
    });
  }
  return numberArray;
}

export function removeDuplicates(arr: any[]) {
  return arr.reduce(function (acc, curr) {
    if (!acc.includes(curr)) {
      acc.push(curr);
    }
    return acc;
  }, []);
}

export const findAndMap = (values: any, dropdowns: any, mappings: any) => {
  // Helper function to remove properties with undefined, empty strings, or null values
  const cleanObject = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([_, v]) => v !== undefined && v !== '' && v !== null,
      ),
    );
  };

  const mappedObject = {};

  mappings.forEach((mapping) => {
    const selectedItem = dropdowns[mapping.dropdown].find(
      (item) => item.value === values[mapping.valueKey],
    );

    if (selectedItem) {
      mappedObject[mapping.mapKey] = selectedItem[mapping.sourceKey];
    } else {
      console.log(
        `No match found for ${mapping.valueKey} in ${mapping.dropdown}`,
      );
    }
  });

  return cleanObject(mappedObject);
};
export const AP_E_PR_MAPPINGS = [
  {
    dropdown: 'rolesDropdown',
    valueKey: 'roleCode',
    mapKey: 'roleCode',
    sourceKey: 'value',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'sourceLanguageUuid',
    mapKey: 'sourceLanguage',
    sourceKey: 'label',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'sourceLanguageUuid',
    mapKey: 'sourceLanguageUuid',
    sourceKey: 'value',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'languageUuid',
    mapKey: 'language',
    sourceKey: 'label',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'languageUuid',
    mapKey: 'languageUuid',
    sourceKey: 'value',
  },
  {
    dropdown: 'projectsDropdown',
    valueKey: 'projectUuid',
    mapKey: 'project',
    sourceKey: 'label',
  },
  {
    dropdown: 'projectsDropdown',
    valueKey: 'projectUuid',
    mapKey: 'projectUuid',
    sourceKey: 'value',
  },
];

export const AP_E_LR_MAPPINGS = [
  {
    dropdown: 'rolesDropdown',
    valueKey: 'roleCode',
    mapKey: 'roleCode',
    sourceKey: 'value',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'sourceLanguageUuid',
    mapKey: 'sourceLanguage',
    sourceKey: 'label',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'sourceLanguageUuid',
    mapKey: 'sourceLanguageUuid',
    sourceKey: 'value',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'languageUuid',
    mapKey: 'language',
    sourceKey: 'label',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'languageUuid',
    mapKey: 'languageUuid',
    sourceKey: 'value',
  },
  {
    dropdown: 'employeesDropdown',
    valueKey: 'employeeUuid',
    mapKey: 'employee',
    sourceKey: 'label',
  },
  {
    dropdown: 'employeesDropdown',
    valueKey: 'employeeUuid',
    mapKey: 'employeeUuid',
    sourceKey: 'value',
  },
];

export const AP_LR_MAPPINGS = [
  {
    dropdown: 'rolesDropdown',
    valueKey: 'roleCode',
    mapKey: 'roleCode',
    sourceKey: 'value',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'sourceLanguageUuid',
    mapKey: 'sourceLanguage',
    sourceKey: 'label',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'sourceLanguageUuid',
    mapKey: 'sourceLanguageUuid',
    sourceKey: 'value',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'languageUuid',
    mapKey: 'language',
    sourceKey: 'label',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'languageUuid',
    mapKey: 'languageUuid',
    sourceKey: 'value',
  },
];

export const AR_LR_MAPPINGS = [
  {
    dropdown: 'languagesDropdown',
    valueKey: 'sourceLanguageUuid',
    mapKey: 'sourceLanguage',
    sourceKey: 'label',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'sourceLanguageUuid',
    mapKey: 'sourceLanguageUuid',
    sourceKey: 'value',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'languageUuid',
    mapKey: 'language',
    sourceKey: 'label',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'languageUuid',
    mapKey: 'languageUuid',
    sourceKey: 'value',
  },
];

export const AR_PR_MAPPINGS = [
  {
    dropdown: 'languagesDropdown',
    valueKey: 'sourceLanguageUuid',
    mapKey: 'sourceLanguage',
    sourceKey: 'label',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'sourceLanguageUuid',
    mapKey: 'sourceLanguageUuid',
    sourceKey: 'value',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'languageUuid',
    mapKey: 'language',
    sourceKey: 'label',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'languageUuid',
    mapKey: 'languageUuid',
    sourceKey: 'value',
  },
  {
    dropdown: 'projectsDropdown',
    valueKey: 'projectUuid',
    mapKey: 'project',
    sourceKey: 'label',
  },
  {
    dropdown: 'projectsDropdown',
    valueKey: 'projectUuid',
    mapKey: 'projectUuid',
    sourceKey: 'value',
  },
];

export const AR_CR_MAPPINGS = [
  {
    dropdown: 'languagesDropdown',
    valueKey: 'sourceLanguageUuid',
    mapKey: 'sourceLanguage',
    sourceKey: 'label',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'sourceLanguageUuid',
    mapKey: 'sourceLanguageUuid',
    sourceKey: 'value',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'languageUuid',
    mapKey: 'language',
    sourceKey: 'label',
  },
  {
    dropdown: 'languagesDropdown',
    valueKey: 'languageUuid',
    mapKey: 'languageUuid',
    sourceKey: 'value',
  },
  {
    dropdown: 'contactPersonsDropdown',
    valueKey: 'contactPersonUuid',
    mapKey: 'contactPerson',
    sourceKey: 'label',
  },
  {
    dropdown: 'contactPersonsDropdown',
    valueKey: 'contactPersonUuid',
    mapKey: 'contactPersonUuid',
    sourceKey: 'value',
  },
];
