import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getNotAssignedOrderLineStatusTransitionsApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.OrderLineStatusTransitionNotAssigned,
    method: Method.Get,
    urlSuffix,
  });

const getAssignedOrderLineStatusTransitionsApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.OrderLineStatusTransitionAssigned,
    method: Method.Get,
    urlSuffix,
  });

const deleteOrderLineStatusTransitionsApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.OrderLineStatusTransition,
    method: Method.Delete,
    urlSuffix,
  });

const addOrderLineStatusTransitionsApi = (data: any) =>
  apiCall({
    url: Endpoint.OrderLineStatusTransition,
    method: Method.Post,
    data,
  });

export {
  getNotAssignedOrderLineStatusTransitionsApi,
  getAssignedOrderLineStatusTransitionsApi,
  addOrderLineStatusTransitionsApi,
  deleteOrderLineStatusTransitionsApi,
};
