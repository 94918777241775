import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  items: [],
  meta: {},
};

export const checklistsRolesSlice = createSlice({
  name: 'checklistsRoles',
  initialState,
  reducers: {
    getChecklistsRoles: (state) => state,
    setChecklistsRoles: (state, action: PayloadAction<any[]>) => action.payload,
    sendChecklistsRolesFilters: (state) => state,
    addChecklistsRoles: (state) => state,
    deleteChecklistsRoles: (state) => state,
    editChecklistsRoles: (state) => state,
  },
});
export const actions: any = checklistsRolesSlice.actions;
export default checklistsRolesSlice.reducer;
