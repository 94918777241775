import { FC, memo, useCallback } from 'react';
import { actions } from 'store/slices/checklistsSlice';
import { useDispatch } from 'hooks/redux';
import ChecklistsForm from 'components/Forms/ChecklistForm';
import l from 'helpers/l';

const ChecklistsAddModal: FC<any> = (props) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: any) => dispatch(actions.addChecklists(values)),
    [dispatch],
  );

  return (
    <ChecklistsForm
      title={l('ADD_CHECKLIST')}
      onSubmit={submitHandler}
      {...props}
    />
  );
};

export default memo(ChecklistsAddModal);
