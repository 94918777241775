import { FC, memo, useCallback } from 'react';
import { actions } from 'store/slices/checklistsRolesSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import ChecklistsRolesForm from 'components/Forms/ChecklistsRolesForm';
import { RootState } from 'store';

const ChecklistsRolesEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state: RootState) =>
    data
      ? state.checklistsRoles.items.find((entry: any) => entry?.uuid === data)
      : null,
  );

  const submitHandler = useCallback(
    (values: any) =>
      dispatch(
        actions.editChecklistsRoles({
          articleTypeUuid: values.articleTypeUuid,
          roleCode: values.roleCode,
          checklistId: values.checklistId,
          uuid: entry?.uuid,
        }),
      ),
    [dispatch, entry?.uuid],
  );

  const initialValues = {
    articleTypeUuid: entry.articleType.uuid,
    roleCode: entry.role.code,
    checklistId: entry.checklist.id,
    checklistName: entry.checklist.name,
  };

  return entry ? (
    <ChecklistsRolesForm
      title={l('EDIT_CHECKLIST_ROLES')}
      onSubmit={submitHandler}
      initialValues={initialValues}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(ChecklistsRolesEditModal);
