import { FC, memo, useCallback } from 'react';
import { actions as expertiseActions } from 'store/slices/experiseSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { Typography } from '@mui/material';

const ExpertiseDeleteModal: FC<GenericModalProps> = ({ data, onClose }) => {
  const dispatch = useDispatch();
  const entry = useSelector((state) =>
    data
      ? state.expertise.expertise.find((entry) => entry?.name === data)
      : null,
  );

  const confirmHandler = useCallback(() => {
    if (entry?.id) {
      dispatch(
        expertiseActions.deleteExpertise({
          ...entry,
        }),
      );
    }
  }, [dispatch, entry]);

  return entry ? (
    <ConfirmationModal onCancel={onClose} onConfirm={confirmHandler}>
      <Typography>
        Are you sure you want to delete <strong>{entry.name} </strong>
        from the table ?
      </Typography>
    </ConfirmationModal>
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(ExpertiseDeleteModal);
