import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { ArticleTypesActionEntry } from 'types/articleTypes';
import apiCall from './apiCall';
import { ArticleTypesEntry } from 'types/articleTypes';

const getArticleTypesApi = () =>
  apiCall({
    url: Endpoint.ArticleTypes,
    method: Method.Get,
  });

const addArticleTypesApi = (data: ArticleTypesActionEntry) =>
  apiCall({
    url: Endpoint.ArticleTypes,
    method: Method.Post,
    data,
  });

const deleteArticleTypesApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.ArticleTypes,
    method: Method.Delete,
    urlSuffix,
  });

const editArticleTypesApi = ({ uuid, ...data }: ArticleTypesEntry) => {
  return apiCall({
    url: Endpoint.ArticleTypes,
    urlSuffix: uuid.toString(),
    method: Method.Put,
    data,
  });
};

export {
  getArticleTypesApi,
  addArticleTypesApi,
  editArticleTypesApi,
  deleteArticleTypesApi,
};
