import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/orderLinesSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  addLineApi,
  deleteLineApi,
  editEditorOrderLinesAPI,
  editLineApi,
  editWriterOrderLinesAPI,
  getEditorCheckListApi,
  getEditorOrderLinesAPI,
  getOrderLineApi,
  getOrderLinesByOrderApi,
  getOrderLinesEditorsApi,
  getOrderLinesWritersApi,
  getPmChecklistApi,
  getWriterCheckListApi,
  getWriterOrderLinesAPI,
  postEditorCheckListApi,
  postPmChecklistApi,
  postWriterCheckListApi,
  sendOrderLinesByOrderFiltersApi,
  orderLineDuplicateFromAPI,
  duplicateOrderLineApi,
} from 'store/api/orderLinesAPI';
import { APIResult } from 'types/APITypes';
import { PayloadAction } from '@reduxjs/toolkit';

function* getOrderLinesByOrder(action: any) {
  const urlSuffix = action.payload.orderUuid;
  const params = action.payload;
  delete params.orderUuid;

  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    getOrderLinesByOrderApi,
    urlSuffix,
    params,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    //const { result }: APIResult<any> = yield call(getOrderLinesApi);
    if (result?.data) {
      yield put(actions.setOrderLines(result?.data));
    }
  }
}

function* sendOrderLinesFilters(action: any) {
  const urlSuffix = action.payload.orderUuid;
  const params = action.payload;
  delete params.orderUuid;
  yield put(commonActions.setLoading(true));

  const { result, error }: APIResult<any> = yield call(
    sendOrderLinesByOrderFiltersApi,
    urlSuffix,
    params,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    if (result?.data) {
      yield put(actions.setOrderLines(result?.data));
    }
  }
  yield put(commonActions.setLoading(false));
}

function* getPmCheckList(action: any) {
  const urlSuffix = action.payload;

  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    getPmChecklistApi,
    urlSuffix,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    if (result?.data) {
      yield put(actions.setPmCheckList(result?.data));
    }
  }
  yield put(commonActions.setLoading(false));
}

function* getWriterCheckList(action: any) {
  const urlSuffix = action.payload;
  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    getWriterCheckListApi,
    urlSuffix,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    if (result?.data) {
      yield put(actions.setWriterCheckList(result?.data));
    }
  }
  yield put(commonActions.setLoading(false));
}

function* getEditorCheckList(action: any) {
  const urlSuffix = action.payload;

  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    getEditorCheckListApi,
    urlSuffix,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    if (result?.data) {
      yield put(actions.setEditorCheckList(result?.data));
    }
  }
  yield put(commonActions.setLoading(false));
}

function* postWriterCheckList(action: PayloadAction<any>) {
  const data = action.payload;
  const { result, error }: APIResult<any> = yield call(
    postWriterCheckListApi,
    data,
  );
  if (result?.status === 201) {
    const urlSuffix = { orderLineUuid: data.orderLineUuid };
    const { result }: APIResult<any> = yield call(
      getWriterCheckListApi,
      urlSuffix,
    );

    if (result?.data) {
      yield put(actions.setWriterCheckList(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully saved`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `Error`,
        severity: 'error',
      }),
    );
  }
}

function* postEditorCheckList(action: PayloadAction<any>) {
  const data = action.payload;
  const { result, error }: APIResult<any> = yield call(
    postEditorCheckListApi,
    data,
  );
  if (result?.status === 201) {
    const urlSuffix = { orderLineUuid: data.orderLineUuid };
    const { result }: APIResult<any> = yield call(
      getEditorCheckListApi,
      urlSuffix,
    );

    if (result?.data) {
      yield put(actions.setEditorCheckList(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully saved`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `Error`,
        severity: 'error',
      }),
    );
  }
}

function* postPmChecklist(action: PayloadAction<any>) {
  const data = action.payload;
  const { result, error }: APIResult<any> = yield call(
    postPmChecklistApi,
    data,
  );
  if (result?.status === 201) {
    const urlSuffix = { orderLineUuid: data.orderLineUuid };
    const { result }: APIResult<any> = yield call(getPmChecklistApi, urlSuffix);

    if (result?.data) {
      yield put(actions.setPmCheckList(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully saved`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `Error`,
        severity: 'error',
      }),
    );
  }
}

function* getOrderLine(action: any) {
  const urlSuffix = action.payload.orderLineUuid;

  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    getOrderLineApi,
    urlSuffix,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    //const { result }: APIResult<any> = yield call(getOrderLinesApi);
    if (result?.data) {
      yield put(actions.setOrderLine(result?.data));
    }
  }
  yield put(commonActions.setLoading(false));
}

function* getOrderLinesWriters(action?: any) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    getOrderLinesWritersApi,
    params,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    //const { result }: APIResult<any> = yield call(getOrderLinesApi);
    if (result?.data) {
      yield put(actions.setOrderLinesWriters(result?.data));
    }
  }
  yield put(commonActions.setLoading(false));
}
function* getOrderLinesEditors(action?: any) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    getOrderLinesEditorsApi,
    params,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    //const { result }: APIResult<any> = yield call(getOrderLinesApi);
    if (result?.data) {
      yield put(actions.setOrderLinesEditors(result?.data));
    }
  }
  yield put(commonActions.setLoading(false));
}

function* addLine(action: any) {
  const data = action.payload;
  const { result, error }: APIResult<any> = yield call(addLineApi, data);

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    //const { result }: APIResult<any> = yield call(getOrderLinesApi);
    if (result?.data) {
      yield put(actions.setOrderLines(result?.data));
      data.navigate(`/orders-entries/${data?.orderUuid}`);
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully add order line.`,
        severity: 'success',
      }),
    );
  }

  yield put(commonActions.setLoading(false));
}

function* duplicateOrderLine(action: any) {
  const data = action.payload;
  const urlSuffix = data?.uuid || '';
  const { result, error }: APIResult<any> = yield call(
    duplicateOrderLineApi,
    data,
    urlSuffix,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    //const { result }: APIResult<any> = yield call(getOrderLinesApi);
    if (result?.data) {
      yield put(actions.setOrderLines(result?.data));
      data.navigate(`/orders-entries/${data?.orderUuid}`);
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully duplicated order line.`,
        severity: 'success',
      }),
    );
  }

  yield put(commonActions.setLoading(false));
}

function* editLine(action: any) {
  const urlSuffix = action.payload.orderLineUuid;
  const data = action.payload;

  const { result, error }: APIResult<any> = yield call(
    editLineApi,
    urlSuffix,
    data,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error?.message,
        severity: 'error',
      }),
    );
  } else {
    //  const { result }: APIResult<any> = yield call(getOrderLinesApi(data)); //maybe this line need to be fixed
    if (result?.data) {
      yield put(actions.setOrderLines(result?.data));
      data.navigate(`/orders-entries/${data?.orderUuid}`);
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited order line in the list`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }

  yield put(commonActions.setLoading(false));
}

function* deleteLine(action: any) {
  const urlSuffix = action.payload?.orderLineEntry;
  const orderEntryID = action.payload?.orderEntry;
  const { result, error }: APIResult<never> = yield call(
    deleteLineApi,
    urlSuffix,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
    yield put(commonActions.hideModal());
  } else if (result?.status === 200) {
    const { result }: APIResult<any> = yield call(
      getOrderLinesByOrderApi,
      orderEntryID,
    );
    if (result?.data) {
      yield put(actions.setOrderLines(result?.data));
      //yield put(actions.setOrderEntries(result?.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted order line from the list`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }

  yield put(commonActions.setLoading(false));
}
// WRITER ORDER LINES PAGE
function* getOrderLinesWriterPage(action: any) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    getWriterOrderLinesAPI,
    params,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    if (result?.data) {
      yield put(actions.setOrderLinesWriterPage(result.data));
    }
  }
  yield put(commonActions.setLoading(false));
}
function* editOrderLinesWriterPage(action: any) {
  const urlSuffix = action.payload.urlSuffix;
  const data = action.payload;

  yield put(commonActions.setLoading(true));

    const { result, error }: APIResult<any> = (yield call(editWriterOrderLinesAPI, urlSuffix, data));
    if (error ) {
      yield put(
          commonActions.snackBar({
            open: true,
            message:  error?.message,
            severity: 'error',
          }),
      );
    } else {
      if (result?.data && !data.error) {
        data.navigate(`/writer-screen`);
      }
    }

  yield put(commonActions.setLoading(false));
}

// EDITOR ORDER LINES PAGE
function* getOrderLinesEditorPage(action: any) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    getEditorOrderLinesAPI,
    params,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    if (result?.data) {
      yield put(actions.setOrderLinesEditorPage(result.data));
    }
  }
  yield put(commonActions.setLoading(false));
}

function* editOrderLinesEditorPage(action: any) {
  const urlSuffix = action.payload.urlSuffix;
  const data = action.payload;

  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    editEditorOrderLinesAPI,
    urlSuffix,
    data,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    if (result?.data) {
      //  yield put(actions.setOrderLinesEditorPage(result.data));
      data.navigate(`/editor-screen`);
    }
  }
  yield put(commonActions.setLoading(false));
}

function* orderLineDuplicateFrom(action: any) {
  const params = action.payload.params;
  const data = action.payload;

  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    orderLineDuplicateFromAPI,
    params,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    if (result?.data) {
      yield put(actions.setOrderLine(result.data)); // TODO problem with redux (not fill item order line)
      data.navigate(`/duplicate-order-line/${data.orderId}`, {
        state: { isCorrection: action.payload.isCorrection, hasLocation: true },
      });
      yield put(commonActions.hideModal());
    }
  }
  yield put(commonActions.setLoading(false));
}

export default function* OrderLinesWatcher() {
  yield takeLatest(actions.getOrderLinesByOrder.type, getOrderLinesByOrder);
  yield takeLatest(actions.getOrderLine.type, getOrderLine);
  yield takeLatest(actions.getPmCheckList.type, getPmCheckList);
  yield takeLatest(actions.getWriterCheckList.type, getWriterCheckList);
  yield takeLatest(actions.getEditorCheckList.type, getEditorCheckList);
  yield takeLatest(actions.postWriterCheckList.type, postWriterCheckList);
  yield takeLatest(actions.postEditorCheckList.type, postEditorCheckList);
  yield takeLatest(actions.postPmChecklist.type, postPmChecklist);
  yield takeLatest(actions.getOrderLinesWriters.type, getOrderLinesWriters);
  yield takeLatest(actions.getOrderLinesEditors.type, getOrderLinesEditors);
  yield takeLatest(actions.addLine.type, addLine);
  yield takeLatest(actions.editLine.type, editLine);
  yield takeLatest(actions.deleteLine.type, deleteLine);
  yield takeLatest(actions.sendOrderLinesFilters.type, sendOrderLinesFilters);
  yield takeLatest(actions.duplicateOrderLine.type, duplicateOrderLine);
  // WRITER PAGES
  yield takeLatest(
    actions.getOrderLinesWriterPage.type,
    getOrderLinesWriterPage,
  );
  yield takeLatest(
    actions.editOrderLinesWriterPage.type,
    editOrderLinesWriterPage,
  );
  // EDITOR PAGES
  yield takeLatest(
    actions.getOrderLinesEditorPage.type,
    getOrderLinesEditorPage,
  );
  yield takeLatest(
    actions.editOrderLinesEditorPage.type,
    editOrderLinesEditorPage,
  );
  // Duplicate order line
  yield takeLatest(actions.orderLineDuplicateFrom.type, orderLineDuplicateFrom);
}
