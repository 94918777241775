import { FC, memo, useCallback } from 'react';
import { actions as countriesActions } from 'store/slices/countriesSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import { GenericCodeNameType } from 'types/genericTypes';
import GenericCodeNameForm from 'components/Forms/GenericCodeNameForm';

const CountriesEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state) =>
    data
      ? state.countries.items.find((entry: any) => entry?.uuid === data)
      : null,
  );

  const submitHandler = useCallback(
    (values: GenericCodeNameType) =>
      entry?.uuid
        ? dispatch(
            countriesActions.editCountry({ uuid: entry?.uuid, ...values }),
          )
        : console.log('@TODO - Show error'),
    [dispatch, entry?.uuid],
  );

  return entry ? (
    <GenericCodeNameForm
      title={l('EDIT_COUNTRY')}
      onSubmit={submitHandler}
      initialValues={entry}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(CountriesEditModal);
