import { FC, memo, useCallback } from 'react';
import { actions as apLanguageRateActions } from 'store/slices/apLanguageRateSlice';
import { actions } from 'store/slices/common';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import APLanguageRatesForm from 'components/Forms/APLanguageRatesForm';

const APLanguageRateEditModal: FC<GenericModalProps> = ({ data }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state) =>
    data?.id
      ? state.apLanguageRate.items.find((entry) => entry?.uuid === data?.id)
      : null,
  );

  const initialValues = {
    sourceLanguage: entry?.sourceLanguage?.name,
    sourceLanguageUuid: entry?.sourceLanguage?.uuid,
    language: entry?.language?.name,
    languageUuid: entry?.language?.uuid,
    roleCode: entry?.role?.code,
    ratePerWord: entry?.ratePerWord,
    ratePerArticle: entry?.ratePerArticle,
  };

  const submitHandler = useCallback(
    (values: any) =>
      dispatch(
        apLanguageRateActions.editAPLanguageRate({
          urlSuffix: entry?.uuid,
          filters: data?.filters,
          ...values,
        }),
      ),
    [dispatch, entry?.uuid, data],
  );

  const closeHandler = useCallback(
    () => dispatch(actions.hideModal()),
    [dispatch],
  );

  return (
    <APLanguageRatesForm
      onSubmit={submitHandler}
      title="Edit AP Language Rate"
      onClose={closeHandler}
      initialValues={initialValues}
    />
  );
};

export default memo(APLanguageRateEditModal);
