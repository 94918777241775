import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { GenericIdNameType } from 'types/genericTypes';
import apiCall from './apiCall';

const getSpeakersApi = () =>
  apiCall({
    url: Endpoint.Speakers,
    method: Method.Get,
  });

const addSpeakersApi = (data: GenericIdNameType) =>
  apiCall({
    url: Endpoint.Speakers,
    method: Method.Post,
    data,
  });

const deleteSpeakersApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.Speakers,
    method: Method.Delete,
    urlSuffix,
  });

const editSpeakersApi = (data: GenericIdNameType) => {
  return apiCall({
    url: Endpoint.Speakers,
    method: Method.Put,
    data,
  });
};

export { addSpeakersApi, getSpeakersApi, editSpeakersApi, deleteSpeakersApi };
