import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GenericIdNameType } from 'types/genericTypes';

export interface EmployeeStatuses {
  employeeStatuses: GenericIdNameType[];
}

const initialState: EmployeeStatuses = {
  employeeStatuses: [{ id: 0, name: '' }],
};

export const employeeStatuesSlice = createSlice({
  name: 'employeeStatuses',
  initialState,
  reducers: {
    getEmployeeStatuses: (state) => state,
    setEmployeeStatuses: (
      state,
      action: PayloadAction<GenericIdNameType[]>,
    ) => {
      state.employeeStatuses = action.payload;
    },
    addEmployeeStatuses: (
      state,

    ) => state,
    deleteEmployeeStatuses: (state) =>
      state,
    editEmployeeStatuses: (
      state,

    ) => state,
  },
});
export const actions: any = employeeStatuesSlice.actions;
export default employeeStatuesSlice.reducer;
