import { put, call, takeLatest } from 'redux-saga/effects';
import { actions, EmailTemplate, User } from 'store/slices/user';
import { actions as commonActions } from 'store/slices/common';
import { actions as employeeActions } from 'store/slices/employeeListSlice';
import {
  getEmailTemplatesApi,
  editEmailTemplateApi,
  getPermissionsApi,
  putPermissionApi,
  getRolesApi,
  putRolePermissionApi,
  deleteRolePermissionApi,
  editRoleApi,
  addRoleApi,
  deleteRoleApi,
  createUserApi,
  inactivateUserApi,
  activateUserApi,
  unlockUserApi,
  getUserRolesApi,
  editUserRoleApi,
  assignRoleApi,
  deleteUserRoleApi,
  getReplacementsApi,
  deleteReplacementApi,
  addReplacementsApi,
  getReplacementsByRolesApi,
  getUserLanguagesApi,
  getUserUnassignedLanguagesApi,
  addUserLanguageApi,
  deleteUserLanguageApi,
  getUserExpertiseApi,
  getUserUnassignedExpertiseApi,
  addUserExpertiseApi,
  deleteUserExpertiseApi,
  editUserExpertiseApi,
  getUserQuestionsApi,
  getUserUnassignedQuestionsApi,
  addUserQuestionsApi,
  deleteUserQuestionsApi,
  editUserQuestionsApi,
  getUserPreferencesApi,
  addUserPreferencesApi,
  editUserPreferencesApi,
  deleteUserPreferencesApi,
  editUserNameApi,
  editUserLanguageApi,
} from 'store/api/user';
import { PayloadAction } from '@reduxjs/toolkit';
import { RoleDeleteEntry, Roles } from 'types/userRoleTypes';
import { PermissionEntry } from 'types/permissionTypes';
import { APIResult } from 'types/APITypes';
import {
  userHelper,
  employeeMessagessHelper,
  genericErrorMessageHelper,
} from 'helpers/messageHelpers';
import { GenericIdNameType } from 'types/genericTypes';
import { getDetailsPageApi } from 'store/api/employeesApi';

function* getEmailTemplates() {
  yield put(commonActions.setLoading(true));
  const { result } = yield call(getEmailTemplatesApi);
  if (result?.data) {
    yield put(actions.setEmailTemplates(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* editEmailTemplate(action: PayloadAction<EmailTemplate>) {
  yield put(commonActions.setLoading(true));
  const { result } = yield call(editEmailTemplateApi, action.payload);
  if (result?.status === 200) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${action.payload.name}`,
        severity: 'info',
      }),
    );
  } else {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `There was an error while trying to edit ${action.payload.name}`,
        severity: 'error',
      }),
    );
  }
  yield put(commonActions.setLoading(false));
}

function* getPermissions() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any> = yield call(getPermissionsApi);
  if (result?.data) {
    yield put(actions.setPermissions(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* editPermission(action: PayloadAction<PermissionEntry>) {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any> = yield call(
    putPermissionApi,
    action.payload.code,
    action.payload,
  );
  if (result) {
    yield put(actions.editPermissionSuccess(action.payload));
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${action.payload.name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  } else {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `There is code with the same name already!`,
        severity: 'error',
      }),
    );
  }
  yield put(commonActions.setLoading(false));
}

function* getRoles() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<Roles[]> = yield call(getRolesApi);
  if (result?.data?.length) {
    yield put(actions.setRoles(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* editRole(action: PayloadAction<any>) {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any> = yield call(
    editRoleApi,
    action.payload.oldCode,
    { name: action.payload.name, code: action.payload.code },
  );

  if (result) {
    yield put(actions.editRolePermissionSuccess(action.payload));
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${action.payload.name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  } else {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `Error!`,
        severity: 'error',
      }),
    );
  }
  yield put(commonActions.setLoading(false));
}

function* addRole(action: PayloadAction<Roles>) {
  const data = action.payload;
  const { result, error }: APIResult<Roles> = yield call(addRoleApi, data);
  if (result?.status === 201) {
    const { result }: APIResult<Roles[]> = yield call(getRolesApi);
    if (result?.data?.length) {
      yield put(actions.setRoles(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.code} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.code} already exists`,
        severity: 'error',
      }),
    );
  }
}

function* deleteRole(action: PayloadAction<RoleDeleteEntry>) {
  const { code, name } = action.payload;
  const { result } = yield call(deleteRoleApi, code!);
  if (result?.status === 200) {
    const { result }: APIResult<Roles[]> = yield call(getRolesApi);
    if (result?.data?.length) {
      yield put(actions.setRoles(result.data));
    }
    yield put(commonActions.hideModal());
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
  }
}

function* putRolePermission(action: PayloadAction<Roles>) {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any> = yield call(
    putRolePermissionApi,
    action.payload.code,
    { permission: action.payload.permissions },
  );

  if (result) {
    const { result }: APIResult<Roles[]> = yield call(getRolesApi);
    if (result?.data?.length) {
      yield put(actions.setRoles(result.data));
    }

    yield put(
      commonActions.snackBar({
        open: true,
        message: `Successfully added permission "${action.payload.permissions}" to "${action.payload.name}"`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  } else {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `Error!`,
        severity: 'error',
      }),
    );
  }
  yield put(commonActions.setLoading(false));
}

function* deleteRolePermission(action: PayloadAction<Roles>) {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any> = yield call(
    deleteRolePermissionApi,
    action.payload.code,
    { permission: action.payload.permissions },
  );
  if (result) {
    const { result }: APIResult<Roles[]> = yield call(getRolesApi);
    if (result?.data?.length) {
      yield put(actions.setRoles(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `Successfully removed permission "${action.payload.permissions}" from "${action.payload.name}"`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  } else {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `Error!`,
        severity: 'error',
      }),
    );
  }
  yield put(commonActions.setLoading(false));
}

function* createUser(action: any) {
  const data = action.payload;
  const { result, error } = yield call(createUserApi, data);
  if (result?.status === 201) {
    yield put(employeeActions.createUpdateUser(result?.data));
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'User created successfully',
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'The user name should be 30 characters max',
        severity: 'error',
      }),
    );
  } else if (error?.message) {
    yield call(employeeMessagessHelper, error.message);
  }
}

function* inactivateUser(action: any) {
  const urlSuffix = action.payload;
  const { result, error } = yield call(inactivateUserApi, urlSuffix);
  if (result?.data) {
    yield put(employeeActions.createUpdateUser(result.data));
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'User has been inactivated',
        severity: 'success',
      }),
    );
  }
  if (error?.message) {
    yield call(userHelper, error.message);
  }
}

function* activateUser(action: any) {
  const urlSuffix = action.payload;
  const { result, error } = yield call(activateUserApi, urlSuffix);
  if (result?.data) {
    yield put(employeeActions.createUpdateUser(result.data));
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'User has been activated',
        severity: 'success',
      }),
    );
  }
  if (error?.message) {
    yield call(userHelper, error.message);
  }
}

function* unlockUser(action: any) {
  const urlSuffix = action.payload;
  const { result, error } = yield call(unlockUserApi, urlSuffix);
  if (result?.data) {
    yield put(employeeActions.createUpdateUser(result.data));
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'User has been unlocked',
        severity: 'success',
      }),
    );
  }
  if (error?.message) {
    yield call(userHelper, error.message);
  }
}

function* getUserRoles(action: any) {
  const urlSuffix = action.payload;
  const { result } = yield call(getUserRolesApi, urlSuffix);
  if (result?.data) {
    yield put(actions.setUserRoles(result.data));
  }
}

function* editUserRole(action: any) {
  const { id: urlSuffix, ...data } = action.payload;
  const { result, error } = yield call(editUserRoleApi, urlSuffix, data);
  if (result?.data) {
    yield put(actions.editUserRoleSuccess(result.data));
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Role updated successfully',
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.message) {
    yield call(employeeMessagessHelper, error.message);
  }
}

function* assignRole(action: any) {
  const data = action.payload;
  const { result, error } = yield call(assignRoleApi, data);
  if (result?.data) {
    yield put(actions.assignRoleSuccess(result.data));
    yield put(
      commonActions.snackBar({
        open: true,
        message: `Successfully added ${result.data.role.name}`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.message) {
    yield call(employeeMessagessHelper, error.message);
  }
}

function* deleteUserRole(action: any) {
  const { urlSuffix, role } = action.payload;
  const { result, error } = yield call(deleteUserRoleApi, urlSuffix);
  if (result?.status === 200) {
    yield put(actions.deleteUserRoleSuccess(urlSuffix));
    yield put(
      commonActions.snackBar({
        open: true,
        message: `Role ${role} has been deleted`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.message) {
    yield call(employeeMessagessHelper, error.message);
  }
}

function* getReplacements(action: any) {
  const urlSuffix = action.payload;
  const { result }: APIResult<any> = yield call(getReplacementsApi, urlSuffix);
  if (result?.data) {
    yield put(actions.setReplacements(result.data));
  }
}

function* addReplacements(action: any) {
  yield put(commonActions.setLoading(true));
  const data = action.payload;
  const { error }: APIResult<any> = yield call(addReplacementsApi, data);
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
    yield put(commonActions.setLoading(false));
  } else {
    const { result, error }: APIResult<any> = yield call(
      getReplacementsApi,
      data.replacerUuid,
    );
    if (error) {
      yield put(
        commonActions.snackBar({
          open: true,
          message: genericErrorMessageHelper(error),
          severity: 'error',
        }),
      );
      yield put(commonActions.setLoading(false));
    } else if (result?.data) {
      yield put(actions.setReplacements(result.data));
      yield put(
        commonActions.snackBar({
          open: true,
          message: 'You have successfully added a replacement employee',
          severity: 'success',
        }),
      );
      yield put(commonActions.setLoading(false));
    }
  }
  yield put(commonActions.hideModal());
}

function* deleteReplacement(action: any) {
  const urlSuffix = action.payload;
  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    deleteReplacementApi,
    urlSuffix,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else if (result?.data) {
    yield put(actions.deleteReplacement(result.data));
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'You have successfully removed replacement employee',
        severity: 'success',
      }),
    );
  }
  yield put(commonActions.setLoading(false));
  yield put(commonActions.hideModal());
}

function* getReplacementsByRoles(action: any) {
  const urlSuffix = action.payload.roleCode;
  const params = { excludeEmployeeUuid: action.payload?.choosenEmployee?.uuid };

  yield put(commonActions.setLoading(true));
  if (urlSuffix !== undefined) {
    const { result }: APIResult<[]> = yield call(
      getReplacementsByRolesApi,
      urlSuffix,
      params,
    );
    if (result?.data) {
      yield put(actions.setReplacementsByRoles(result.data));
    }
  }
  yield put(commonActions.setLoading(false));
}

function* getUserLanguages(action: any) {
  const urlSuffix = action.payload;
  const { result }: APIResult<any> = yield call(getUserLanguagesApi, urlSuffix);
  if (result?.data) {
    yield put(actions.setUserLanguages(result.data));
  }
}

function* getUserUnassignedLanguages(action: any) {
  const urlSuffix = action.payload;
  const { result }: APIResult<any> = yield call(
    getUserUnassignedLanguagesApi,
    urlSuffix,
  );
  if (result?.data) {
    yield put(actions.setUserUnassignedLanguages(result.data));
  }
}

function* addUserLanguage(action: any) {
  yield put(commonActions.setLoading(true));
  const data = action.payload;
  const { error }: APIResult<any> = yield call(addUserLanguageApi, data);
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
    yield put(commonActions.setLoading(false));
  } else {
    const { result, error }: APIResult<any> = yield call(
      getUserLanguagesApi,
      data.employeeUuid,
    );
    if (error) {
      yield put(
        commonActions.snackBar({
          open: true,
          message: genericErrorMessageHelper(error),
          severity: 'error',
        }),
      );
      yield put(commonActions.setLoading(false));
    } else if (result?.data) {
      yield put(actions.setUserLanguages(result.data));
      yield put(
        commonActions.snackBar({
          open: true,
          message: 'You have successfully added a new language',
          severity: 'success',
        }),
      );
      yield put(commonActions.setLoading(false));
    }
  }
  yield put(commonActions.hideModal());
}

function* deleteUserLanguage(action: any) {
  const urlSuffix = action.payload;
  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    deleteUserLanguageApi,
    urlSuffix,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else if (result?.data) {
    yield put(actions.deleteUserLanguage(result.data));
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'You have successfully removed a language',
        severity: 'success',
      }),
    );
  }
  yield put(commonActions.setLoading(false));
  yield put(commonActions.hideModal());
}

function* editUserLanguage(action: any) {
  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    editUserLanguageApi,
    action.payload.employeeId,
    action.payload.values,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else if (result?.status === 200) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'You have successfully edited a language',
        severity: 'success',
      }),
    );
  }
  yield put(commonActions.setLoading(false));
  yield put(commonActions.hideModal());
}

function* getUserExpertise(action: any) {
  const urlSuffix = action.payload;
  const { result }: APIResult<any> = yield call(getUserExpertiseApi, urlSuffix);
  if (result?.data) {
    yield put(actions.setUserExpertise(result.data));
  }
}

function* getUserUnassignedExpertise(action: any) {
  const urlSuffix = action.payload;
  const { result }: APIResult<any> = yield call(
    getUserUnassignedExpertiseApi,
    urlSuffix,
  );
  if (result?.data) {
    yield put(actions.setUserUnassignedExpertise(result.data));
  }
}

function* addUserExpertise(action: any) {
  yield put(commonActions.setLoading(true));
  const data = action.payload;
  const { error }: APIResult<any> = yield call(addUserExpertiseApi, data);
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
    yield put(commonActions.setLoading(false));
  } else {
    const { result, error }: APIResult<any> = yield call(
      getUserExpertiseApi,
      data.employeeUuid,
    );
    if (error) {
      yield put(
        commonActions.snackBar({
          open: true,
          message: genericErrorMessageHelper(error),
          severity: 'error',
        }),
      );
      yield put(commonActions.setLoading(false));
    } else if (result?.data) {
      yield put(actions.setUserExpertise(result.data));
      yield put(
        commonActions.snackBar({
          open: true,
          message: 'You have successfully added a new expertise',
          severity: 'success',
        }),
      );
      yield put(commonActions.setLoading(false));
    }
  }
  yield put(commonActions.hideModal());
}

function* deleteUserExpertise(action: any) {
  const urlSuffix = action.payload;
  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    deleteUserExpertiseApi,
    urlSuffix,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else if (result?.data) {
    yield put(actions.deleteUserExpertise(result.data));
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'You have successfully removed expertise',
        severity: 'success',
      }),
    );
  }
  yield put(commonActions.setLoading(false));
  yield put(commonActions.hideModal());
}

function* editUserExpertise(action: any) {
  const data = action.payload;
  yield put(commonActions.setLoading(true));
  const { error }: APIResult<any> = yield call(
    editUserExpertiseApi,
    data.uuid,
    data,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'You have successfully edited expertise',
        severity: 'success',
      }),
    );
  }
  yield put(commonActions.setLoading(false));
  yield put(commonActions.hideModal());
}

// Employee Preferences

function* getUserPreferences(action: any) {
  const urlSuffix = action.payload;
  const { result }: APIResult<User['userPreferences']> = yield call(
    getUserPreferencesApi,
    urlSuffix,
  );
  if (result?.status === 200) {
    yield put(actions.setUserPreferences(result.data));
  }
}

function* addUserPreferences(action: any) {
  const { id, data } = action.payload;
  const { result, error }: APIResult<User['userPreferences']> = yield call(
    addUserPreferencesApi,
    data,
  );
  if (result?.status === 201) {
    const { result }: APIResult<User['userPreferences']> = yield call(
      getUserPreferencesApi,
      id,
    );
    if (result?.status === 200) {
      yield put(actions.setUserPreferences(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added preference to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Preferred employee already exists',
        severity: 'error',
      }),
    );
  }
  if (error?.status === 404) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Contact person not found (contact-person-not-found)',
        severity: 'error',
      }),
    );
  }
}

function* editUserPreferences(action: any) {
  const { id, uuid, data } = action.payload;
  const { result, error }: APIResult<User['userPreferences']> = yield call(
    editUserPreferencesApi,
    id,
    data,
  );
  if (result?.status === 200 || result?.status === 201) {
    const { result }: APIResult<User['userPreferences']> = yield call(
      getUserPreferencesApi,
      uuid,
    );
    if (result?.status === 200) {
      yield put(actions.setUserPreferences(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${data.description}`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Preferred employee already exists',
        severity: 'error',
      }),
    );
  }
  if (error?.status === 404) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Contact person not found (contact-person-not-found)',
        severity: 'error',
      }),
    );
  }
}

function* deleteUserPreferences(
  action: PayloadAction<{
    uuid: string;
    employeeUuid: string;
    description: string;
  }>,
) {
  const { uuid, employeeUuid, description } = action.payload;
  const { result, error }: APIResult<GenericIdNameType> = yield call(
    deleteUserPreferencesApi,
    uuid,
  );
  if (result?.status === 200) {
    const { result }: APIResult<User['userPreferences']> = yield call(
      getUserPreferencesApi,
      employeeUuid,
    );
    if (result?.status === 200) {
      yield put(actions.setUserPreferences(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${description} from the list`,
        severity: 'info',
      }),
    );
  }
  if (error?.status === 500) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'This item can not be deleted due to existing relations',
        severity: 'error',
      }),
    );
  } else if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  }
  yield put(commonActions.hideModal());
}

// Questions Logic Below

function* getUserQuestions(action: any) {
  const urlSuffix = action.payload;
  const { result }: APIResult<any> = yield call(getUserQuestionsApi, urlSuffix);
  if (result?.data) {
    yield put(actions.setUserQuestions(result.data));
  }
}

function* getUserUnassignedQuestions(action: any) {
  const urlSuffix = action.payload;
  const { result }: APIResult<any> = yield call(
    getUserUnassignedQuestionsApi,
    urlSuffix,
  );
  if (result?.data) {
    yield put(actions.setUserUnassignedQuestions(result.data));
  }
}

function* addUserQuestions(action: any) {
  yield put(commonActions.setLoading(true));
  const data = action.payload;
  const { error }: APIResult<any> = yield call(addUserQuestionsApi, data);
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
    yield put(commonActions.setLoading(false));
  } else {
    const { result, error }: APIResult<any> = yield call(
      getUserQuestionsApi,
      data.employeeUuid,
    );
    if (error) {
      yield put(
        commonActions.snackBar({
          open: true,
          message: genericErrorMessageHelper(error),
          severity: 'error',
        }),
      );
      yield put(commonActions.setLoading(false));
    } else if (result?.data) {
      yield put(actions.setUserQuestions(result.data));
      yield put(
        commonActions.snackBar({
          open: true,
          message: 'You have successfully added a new question',
          severity: 'success',
        }),
      );
      yield put(commonActions.setLoading(false));
    }
  }
  yield put(commonActions.hideModal());
}

function* deleteUserQuestions(action: any) {
  const urlSuffix = action.payload;
  yield put(commonActions.setLoading(true));
  const { result, error }: APIResult<any> = yield call(
    deleteUserQuestionsApi,
    urlSuffix,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else if (result?.data) {
    yield put(actions.deleteUserQuestions(result.data));
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'You have successfully removed question',
        severity: 'success',
      }),
    );
  }
  yield put(commonActions.setLoading(false));
  yield put(commonActions.hideModal());
}

function* editUserQuestions(action: any) {
  const data = action.payload;
  yield put(commonActions.setLoading(true));
  const { error }: APIResult<any> = yield call(
    editUserQuestionsApi,
    data.uuid,
    data,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'You have successfully edited question',
        severity: 'success',
      }),
    );
  }
  yield put(commonActions.setLoading(false));
  yield put(commonActions.hideModal());
}

function* editEmployeeUsername(action: any) {
  const data = action.payload;

  yield put(commonActions.setLoading(true));
  const { error }: APIResult<any> = yield call(
    editUserNameApi,
    data.urlSuffix,
    data,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else {
    const { result } = yield call(getDetailsPageApi, data.id);
    if (result?.data) {
      yield put(employeeActions.setDetailsPage(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'You have successfully edited username',
        severity: 'success',
      }),
    );
  }
  yield put(commonActions.setLoading(false));
  yield put(commonActions.hideModal());
}

export default function* UserWatcher() {
  yield takeLatest(actions.editUserLanguage.type, editUserLanguage);
  yield takeLatest(actions.getEmailTemplates.type, getEmailTemplates);
  yield takeLatest(actions.editEmailTemplate.type, editEmailTemplate);
  yield takeLatest(actions.getPermissions.type, getPermissions);
  yield takeLatest(actions.editPermission.type, editPermission);
  yield takeLatest(actions.getRoles.type, getRoles);
  yield takeLatest(actions.editRole.type, editRole);
  yield takeLatest(actions.addRole.type, addRole);
  yield takeLatest(actions.deleteRole.type, deleteRole);
  yield takeLatest(actions.editRolePermission, putRolePermission);
  yield takeLatest(actions.deleteRolePermission.type, deleteRolePermission);
  yield takeLatest(actions.createUser.type, createUser);
  yield takeLatest(actions.inactivateUser.type, inactivateUser);
  yield takeLatest(actions.activateUser.type, activateUser);
  yield takeLatest(actions.unlockUser.type, unlockUser);
  yield takeLatest(actions.getUserRoles.type, getUserRoles);
  yield takeLatest(actions.editUserRole.type, editUserRole);
  yield takeLatest(actions.assignRole.type, assignRole);
  yield takeLatest(actions.deleteUserRole.type, deleteUserRole);
  yield takeLatest(actions.getReplacements.type, getReplacements);
  yield takeLatest(actions.deleteReplacement.type, deleteReplacement);
  yield takeLatest(actions.addReplacements.type, addReplacements);
  yield takeLatest(actions.getReplacementsByRoles.type, getReplacementsByRoles);
  yield takeLatest(actions.getUserLanguages.type, getUserLanguages);
  yield takeLatest(
    actions.getUserUnassignedLanguages.type,
    getUserUnassignedLanguages,
  );
  yield takeLatest(actions.addUserLanguage.type, addUserLanguage);
  yield takeLatest(actions.deleteUserLanguage.type, deleteUserLanguage);
  yield takeLatest(
    actions.getUserUnassignedExpertise.type,
    getUserUnassignedExpertise,
  );
  yield takeLatest(actions.getUserExpertise.type, getUserExpertise);
  yield takeLatest(actions.addUserExpertise.type, addUserExpertise);
  yield takeLatest(actions.deleteUserExpertise.type, deleteUserExpertise);
  yield takeLatest(actions.editUserExpertise.type, editUserExpertise);
  yield takeLatest(
    actions.getUserUnassignedQuestions.type,
    getUserUnassignedQuestions,
  );
  yield takeLatest(actions.getUserPreferences.type, getUserPreferences);
  yield takeLatest(actions.addUserPreferences.type, addUserPreferences);
  yield takeLatest(actions.editUserPreferences.type, editUserPreferences);
  yield takeLatest(actions.deleteUserPreferences.type, deleteUserPreferences);
  yield takeLatest(actions.getUserQuestions.type, getUserQuestions);
  yield takeLatest(actions.addUserQuestions.type, addUserQuestions);
  yield takeLatest(actions.deleteUserQuestions.type, deleteUserQuestions);
  yield takeLatest(actions.editUserQuestions.type, editUserQuestions);
  yield takeLatest(actions.editEmployeeUsername.type, editEmployeeUsername);
}
