import moment from 'moment';

function getLang() {
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language;
}

function formatDate(date: Date) {
  return date.toLocaleString(getLang(), {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export const formatTimeZone = (name: string) => {
  const timeOffset = moment()
    .tz(name)
    .format('Z')
    .split(':')[0]
    .replace('0', '');

  return timeOffset === '+0' ? 'GMT' : `GMT${timeOffset}`;
};

export const gmtToInt = (value: string): number =>
  parseInt(value.replace('GMT', '')) || 0;

export const formatTime = (value: string): string => {
  const date = new Date(value);
  return formatDate(new Date(date));
};

export const dateFormatter = (string: any) => {
  if (string !== null) {
    const splitDate = string.split('.');
    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  } else return '';
};

