import React from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { getFullName } from 'helpers/text';
import { TextField } from 'components';

interface INotifyList {
  conversationInfo: any;
  currentUser: any;
  formik: any;
}
function NotifyList({ conversationInfo, currentUser, formik }: INotifyList) {

  return (
    <>
      <TextField
        type="text"
        name="messageText"
        size="small"
        multiline
        minRows={1}
        maxRows={4}
        value={formik?.values?.messageText || ''}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.messageText)}
        helperText={formik.errors.messageText}
      />
      <Typography sx={{ margin: '5px 0', fontWeight: 600 }}>Notify</Typography>
      <Box display="flex" alignItems="center" className="notifyWrapper">
        {conversationInfo?.accountManager !== null &&
          conversationInfo?.accountManager?.uuid !==
            currentUser?.employee?.uuid && (
            <Box className="personWrapper">
              <Box>
                <Checkbox
                  //   defaultChecked
                  id={`accountManagerUuid`}
                  checked={formik.values.accountManagerUuid}
                  onChange={formik.handleChange}
                />{' '}
                <Typography>
                  {getFullName(conversationInfo?.accountManager)}
                </Typography>
              </Box>
              <Typography>Account Manager</Typography>
            </Box>
          )}
        {conversationInfo?.receivedBy !== null &&
          conversationInfo?.receivedBy?.uuid !==
            currentUser?.employee?.uuid && (
            <Box className="personWrapper">
              <Box>
                <Checkbox
                  //   defaultChecked
                  id={`receivedByUuid`}
                  checked={formik.values.receivedByUuid}
                  onChange={formik.handleChange}
                />{' '}
                <Typography>
                  {getFullName(conversationInfo?.receivedBy)}
                </Typography>
              </Box>
              <Typography>Order Receiver</Typography>
            </Box>
          )}
        {conversationInfo?.projectManager !== null &&
          conversationInfo?.projectManager?.uuid !==
            currentUser?.employee?.uuid && (
            <Box className="personWrapper">
              <Box>
                <Checkbox
                  //   defaultChecked
                  id={`projectManagerUuid`}
                  checked={formik.values.projectManagerUuid}
                  onChange={formik.handleChange}
                />{' '}
                <Typography>
                  {getFullName(conversationInfo?.projectManager)}
                </Typography>
              </Box>
              <Typography>Project Manager</Typography>
            </Box>
          )}
        {conversationInfo?.assistantProjectManager !== null &&
          conversationInfo?.assistantProjectManager?.uuid !==
            currentUser?.employee?.uuid && (
            <Box className="personWrapper">
              <Box>
                <Checkbox
                  //   defaultChecked
                  id={`assistantProjectManagerUuid`}
                  checked={formik.values.assistantProjectManagerUuid}
                  onChange={formik.handleChange}
                />{' '}
                <Typography>
                  {getFullName(conversationInfo?.assistantProjectManager)}
                </Typography>
              </Box>
              <Typography>Assistant PManager</Typography>
            </Box>
          )}
        {conversationInfo?.writer !== null &&
          conversationInfo?.writer?.uuid !== currentUser?.employee?.uuid && (
            <Box className="personWrapper">
              <Box>
                <Checkbox
                  //   defaultChecked
                  id={`writerUuid`}
                  checked={formik.values.writerUuid}
                  onChange={formik.handleChange}
                />{' '}
                <Typography>{getFullName(conversationInfo?.writer)}</Typography>
              </Box>
              <Typography>Writer</Typography>
            </Box>
          )}
        {conversationInfo?.editor !== null &&
          conversationInfo?.editor?.uuid !== currentUser?.employee?.uuid && (
            <Box className="personWrapper">
              <Box>
                <Checkbox
                  //   defaultChecked
                  id={`editorUuid`}
                  checked={formik.values.editorUuid}
                  onChange={formik.handleChange}
                />{' '}
                <Typography>{getFullName(conversationInfo?.editor)}</Typography>
              </Box>
              <Typography>Editor</Typography>
            </Box>
          )}
      </Box>
    </>
  );
}

export default NotifyList;
