import { useEffect, memo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageLayout } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { actions as orderLineActions } from 'store/slices/orderLinesSlice';
import l from 'helpers/l';
import OrderLinesForm from 'components/Forms/OrderLinesForm';
import { RootState } from 'store';
import { actions as articleTypesActions } from 'store/slices/articleTypesSlice';

function OrderLineEditPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  const splitLocation = location.pathname.split('/');
  const orderEntryUuid = splitLocation[2];
  const orderLineUuid = splitLocation[3];

  const articleTypesDropdown = useSelector(
    (state: RootState) => state?.articleTypes?.articleTypes,
  ).map((entry) => ({ label: entry.name, value: entry.uuid }));

  useSelector((state: RootState) => state.orderEntries?.orderEntry);

  const orderLines: any = useSelector(
    (state: RootState) => state?.orderLines?.items,
  );

  const exactOrderLine = orderLines?.find(
    (line: any) => line?.uuid === params.id,
  );

  const initialValues = {
    orderLineStatusUuid:
      exactOrderLine?.lastStatusLogEntry?.orderLineStatus?.uuid,
    clientReference: exactOrderLine?.clientReference,
    articleTypeUuid: exactOrderLine?.articleType?.uuid,
    articleType: exactOrderLine?.articleType?.name,
    sourceLanguageUuid: exactOrderLine?.sourceLanguage?.uuid,
    sourceLanguage: exactOrderLine?.sourceLanguage?.name,
    languageUuid: exactOrderLine?.language?.uuid,
    language: exactOrderLine?.language?.name,
    quantity: exactOrderLine?.quantity,
    dueDate: exactOrderLine?.dueDatetime,
    dateCritical: exactOrderLine?.dateCritical,
    pmEmployeeUuid: exactOrderLine?.pmEmployee?.uuid,
    pmEmployee: exactOrderLine?.pmEmployee?.name,
    pmDueDate: exactOrderLine?.pmDueDateTime,
    clientInstructionsUrl: exactOrderLine?.clientInstructionsUrl,
    instructions: exactOrderLine?.instructions,
    isNoEdit: exactOrderLine?.isNoEdit,
    projectUuid: exactOrderLine?.project?.uuid,
    project: exactOrderLine?.project?.name,
    assistantProjectManagerUuid: exactOrderLine?.assistantProjectManager?.uuid,

    assistantProjectManager: `${exactOrderLine?.assistantProjectManager?.firstName} ${exactOrderLine?.assistantProjectManager?.lastName}`,
  };

  const handleSubmit = ({ dueDate, pmDueDate, ...values }: any) => {
    const data = {
      orderUuid: orderEntryUuid,
      orderLineUuid,
      ...values,
    };

    dispatch(orderLineActions.editLine({ ...data, navigate }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(articleTypesActions.getArticleTypes());
  }, [dispatch]);

  return (
    <PageLayout title={l('PAGES.ORDER_LINE_EDIT')} fullWidth={true}>
      <OrderLinesForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        initialValues={initialValues}
        articleTypesDropdown={articleTypesDropdown}
        type="edit"
      />
    </PageLayout>
  );
}

export default memo(OrderLineEditPage);
