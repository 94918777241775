import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/checklistsRolesSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  addChecklistsRolesApi,
  getChecklistsRolesApi,
  editChecklistsRolesApi,
  deleteChecklistsRolesApi,
  sendChecklistsRolesFiltersApi,
} from 'store/api/checklistsRolesAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { GenericIdNameType } from 'types/genericTypes';
import { APIResult } from 'types/APITypes';

function* sendChecklistsRolesFilters(action: PayloadAction<string>) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(sendChecklistsRolesFiltersApi, params);

  if (result?.data) {
    yield put(actions.setChecklistsRoles(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* getChecklistsRoles() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any[]> = yield call(getChecklistsRolesApi);
  if (result?.data) {
    yield put(actions.setChecklistsRoles(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addChecklistsRoles(action: PayloadAction<any>) {
  const data = action.payload;
  const { result, error }: APIResult<any> = yield call(
    addChecklistsRolesApi,
    data,
  );
  if (result?.data) {

    // if (result?.data) {
    //   yield put(actions.setChecklistsRoles(result.data));
    // }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `Item already exists`,
        severity: 'error',
      }),
    );
  }
}

function* deleteChecklistsRoles(action: PayloadAction<GenericIdNameType>) {
  const { id} = action.payload;
  const { result, error }: APIResult<GenericIdNameType> = yield call(
    deleteChecklistsRolesApi,
    id! as string,
  );
  if (result?.status === 200) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getChecklistsRolesApi,
    );
    if (result?.data) {
      yield put(actions.setChecklistsRoles(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted the item from the list`,
        severity: 'info',
      }),
    );
  }else if (error) {
    yield put(
        commonActions.snackBar({
          open: true,
          message: error.message,
          severity: 'error',
        }),
    );
  }
  yield put(commonActions.hideModal());
}

function* editChecklistsRoles(action: PayloadAction<any>) {
  const { result, error }: APIResult<any> = yield call(
    editChecklistsRolesApi,
    action.payload,
  );
  if (result?.status === 200) {
    const { result }: APIResult<any[]> = yield call(getChecklistsRolesApi);
    if (result?.data) {
      yield put(actions.setChecklistsRoles(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited the item`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'The entry already exists!',
        severity: 'error',
      }),
    );
  }
}

export default function* ChecklistsRolesWatcher() {
  yield takeLatest(
    actions.sendChecklistsRolesFilters.type,
    sendChecklistsRolesFilters,
  );
  yield takeLatest(actions.getChecklistsRoles.type, getChecklistsRoles);
  yield takeLatest(actions.addChecklistsRoles.type, addChecklistsRoles);
  yield takeLatest(actions.deleteChecklistsRoles.type, deleteChecklistsRoles);
  yield takeLatest(actions.editChecklistsRoles.type, editChecklistsRoles);
}
