import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OrderLineStatusType,
  OrderLineStatusActionEntry,
  OrderLineStatusActionEdit,
} from 'types/orderLineStatusesTypes';

export interface OrderLineStatusesState {
  orderLineStatuses: OrderLineStatusType[] | [];
}

const initialState: OrderLineStatusesState = {
  orderLineStatuses: [],
};

export const orderLineStatusesSlice = createSlice({
  name: 'orderLineStatuses',
  initialState,
  reducers: {
    getOrderLineStatuses: (state) => state,
    getOrderLineStatusesManage: (state) => state,
    setOrderLineStatuses: (
      state,
      action: PayloadAction<OrderLineStatusType[]>,
    ) => {
      state.orderLineStatuses = action.payload;
    },
    addOrderLineStatuses: (
      state,

    ) => state,
    deleteOrderLineStatuses: (
      state,

    ) => state,
    editOrderLineStatuses: (
      state,

    ) => state,
  },
});
export const actions: any = orderLineStatusesSlice.actions;
export default orderLineStatusesSlice.reducer;
