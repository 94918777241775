import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/slices/common';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { RootState } from 'store';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notifications = () => {
  const dispatch = useDispatch();
  const {
    open,
    message = '',
    severity = undefined,
  } = useSelector((state: RootState) => state.common.snackBar);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(actions.snackBar({ open: false }));
  };

  return (
    <Snackbar
      key={message}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
export default Notifications;
