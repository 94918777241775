import { FC, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import colors from 'styles/colors';
import IosShareIcon from '@mui/icons-material/IosShare';

interface UrlProps {
  url: string;
  text?: string;
  external?: boolean;
  fontWeight?: number;
}

const Url: FC<PropsWithChildren<UrlProps>> = ({
  text,
  url,
  external = false,
  fontWeight = 600,
}) => {
  return (
    <a
      href={url}
      style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
      <Typography fontWeight={fontWeight} color={colors.info}>
        {text || url}
      </Typography>
      {external && (text || url) && (
        <IosShareIcon
          color={'info'}
          sx={{
            fontSize: '12px',
            transform: 'rotate(45deg)',
            marginLeft: '3px',
          }}
        />
      )}
    </a>
  );
};
export default Url;
