import { FC, memo, useCallback, useEffect} from 'react';
import { actions as projectsActions } from 'store/slices/projectsSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import ProjectsAddForm from 'components/Forms/ProjectsAddForm';
import { RootState } from 'store';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';

const ProjectsAddModal: FC<GenericModalProps> = ({
  onClose,
  data,
  ...rest
}) => {
  const dispatch: any = useDispatch();
  const contacts = useSelector(
    (state: RootState) => state.projects.contactPersons.items,
  )?.map(({ nick, uuid }: any) => ({ label: nick, value: uuid }));

  const submitHandler = useCallback(
    (values: any) => {
      dispatch(
        projectsActions.addProjects({
          ...values,
        }),
      );
    },
    [dispatch],
  );

  // useEffect(() => {
  //   if (contacts?.length) {
  //     setContactsDropdown(contacts);
  //   }
  // }, [contacts]);

  useEffect(() => {
    dispatch(projectsActions.getContactPersons());
  }, [dispatch]);

  return (
    <ProjectsAddForm
      title={l('ADD_PROJECT')}
      onSubmit={submitHandler}
      contactPersonDropdown={contacts}
      onClose={onClose}
      {...rest}
    />
  );
};

export default memo(ProjectsAddModal);
