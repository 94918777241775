import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getReportParametersApi = () =>
    apiCall({
        url: Endpoint.ReportParameters,
        method: Method.Get,
    });
const postReportParametersApi = (data: any) =>
    apiCall({
        url: Endpoint.ReportParameters,
        method: Method.Post,
        data,
    });
const getReportParametersTablesApi = () =>
    apiCall({
        url: Endpoint.ReportParametersTables,
        method: Method.Get,
    });
const getReportParametersColumnsApi = (params: any) =>
    apiCall({
        url: Endpoint.ReportParametersColumns,
        method: Method.Get,
        params,
    });
const deleteReportParametersApi = (urlSuffix: string) =>
    apiCall({
        url: Endpoint.DeleteReportParameter,
        method: Method.Delete,
        urlSuffix,
    });
const editReportParametersApi = (urlSuffix: string, data: any) =>
    apiCall({
        url: Endpoint.EditReportParameter,
        method: Method.Put,
        urlSuffix,
        data,
    });

export const sendReportParametersFiltersApi = (urlSuffix: string, params?: any) =>
    apiCall({
        url: Endpoint.ReportParameters,
        method: Method.Get,
        urlSuffix,
        params,
    });

const getReportCategoriesApi = () =>
    apiCall({
        url: Endpoint.ReportCategories,
        method: Method.Get,
    });

const postReportCategoryApi = (data: any) =>
    apiCall({
        url: Endpoint.CrudReportCategory,
        method: Method.Post,
        data,
    });
const assignRoleToReportCategoryApi = (data: any) =>
    apiCall({
        url: Endpoint.AssignRoleToReportCategory,
        method: Method.Patch,
        data,
    });
const unassignFromPostReportCategoryApi = (data: any) =>
    apiCall({
        url: Endpoint.UnassignFromPostReportCategory,
        method: Method.Patch,
        data,
    });
const editReportCategoryApi = (urlSuffix: string, data: any) =>
    apiCall({
        url: Endpoint.CrudReportCategory,
        method: Method.Put,
        urlSuffix,
        data,
    });

const deleteReportCategoryApi = (urlSuffix: string) =>
    apiCall({
        url: Endpoint.ExecuteReport,
        method: Method.Delete,
        urlSuffix,
    });

const getReportsApi = () =>
    apiCall({
        url: Endpoint.CrudReportCategory,
        method: Method.Get,
    });

const getReportByUuidApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.ExecuteReport,
    method: Method.Get,
    urlSuffix,
  });
const executeReportApi = (urlSuffix: string, params?:any, data?: any) =>
    apiCall({
        url: Endpoint.ExecuteReport,
        method: Method.Post,
        urlSuffix,
        params,
        data,
    });
const executeReportWithParametersApi = (urlSuffix: string, data?: any) =>
    apiCall({
        url: Endpoint.ExecuteReport,
        method: Method.Post,
        urlSuffix,
        data,
    });
const exportToXLSXApi = (urlSuffix: string, data: any, contentType: string) =>
    apiCall({
        url: Endpoint.ExportXLSX,
        method: Method.Post,
        urlSuffix,
         data,
        contentType,
    });
const exportToPDFApi = (urlSuffix: string, data: any, contentType: string) =>
    apiCall({
        url: Endpoint.ExportPDF,
        method: Method.Post,
        urlSuffix,
        data,
        contentType,
    });

const getPreviousExecutionsOfReportApi = (urlSuffix: string) =>
    apiCall({
        url: Endpoint.PreviousExecutionsOfReport,
        method: Method.Get,
        urlSuffix,
    });
const getRunReportFiledsApi = (urlSuffix: string) =>
    apiCall({
        url: Endpoint.ExecuteReport,
        method: Method.Get,
        urlSuffix,
    });
const SaveOfReportApi = (data: any) =>
    apiCall({
        url: Endpoint.SaveOfReport,
        method: Method.Post,
        data,
    });
const ReportRunQueryApi = (params: any) =>
    apiCall({
        url: Endpoint.ReportRunQuery,
        method: Method.Get,
        params,
    });

const DeleteReportExecutionApi = (urlSuffix: string) =>
    apiCall({
        url: Endpoint.DeleteReportExecution,
        method: Method.Delete,
        urlSuffix,
    });

export const sendPreviousExecutionsOfReportFiltersApi = (urlSuffix: string, params?: any) =>
    apiCall({
        url: Endpoint.PreviousExecutionsOfReport,
        method: Method.Get,
        urlSuffix,
        params,
    });

export {
    getReportParametersApi,
    getReportParametersTablesApi,
    getReportParametersColumnsApi,
    postReportParametersApi,
    deleteReportParametersApi,
    editReportParametersApi,
    getReportCategoriesApi,
    postReportCategoryApi,
    assignRoleToReportCategoryApi,
    unassignFromPostReportCategoryApi,
    editReportCategoryApi,
    deleteReportCategoryApi,
    getReportsApi,
    executeReportApi,
    exportToXLSXApi,
    exportToPDFApi,
    executeReportWithParametersApi,
    getPreviousExecutionsOfReportApi,
    getRunReportFiledsApi,
    SaveOfReportApi,
    ReportRunQueryApi,
    DeleteReportExecutionApi,
    getReportByUuidApi,
};
