import { FC, memo, useCallback } from 'react';
import { actions as paymentActions } from 'store/slices/paymentSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import EmployeeStatusesForm from 'components/Forms/EmployeeStatusesForm';
import { RootState } from 'store';
import { GenericIdNameType } from 'types/genericTypes';

const PaymentEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state: RootState) =>
    data ? state.payment.payment.find((entry) => entry?.name === data) : null,
  );

  const submitHandler = useCallback(
    (values: GenericIdNameType) =>
      dispatch(paymentActions.editPayment({ ...values })),
    [dispatch, entry?.name],
  );

  return entry ? (
    <EmployeeStatusesForm
      title={l('EDIT_EMPLOYEE_STATUS')}
      onSubmit={submitHandler}
      initialValues={entry}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(PaymentEditModal);
