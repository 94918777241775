import { useSelector } from 'react-redux';
import { RootState } from 'store';

function useCheckPermissions(allow: any, deny?: any) {
  const permissions = useSelector((state: RootState) => state.auth.permissions);

  if (
    Array.isArray(allow) &&
    allow.length &&
    !allow.some((role) => permissions?.includes(role))
  ) {
    return false;
  }

  return !(Array.isArray(deny) &&
    deny.length &&
    deny.some((role) => permissions?.includes(role)));
}

export default useCheckPermissions;
