import { FC, memo, useCallback } from 'react';
import { actions as rolesActions } from 'store/slices/user';
import { useDispatch, useSelector } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import RolesForm from 'components/Forms/RolesForm';
import { GenericCodeNameType } from 'types/genericTypes';
import { RootState } from 'store';

const RoleEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state: RootState) =>
    data ? state.user.roles.find((entry) => entry?.code === data) : null,
  );

  const oldCode = entry?.code;

  const submitHandler = useCallback(
    (values: GenericCodeNameType) =>
      dispatch(rolesActions.editRole({ ...values, oldCode: oldCode })),
    [dispatch, entry?.code],
  );

  return entry ? (
    <RolesForm
      title={l('EDIT_ROLE')}
      onSubmit={submitHandler}
      initialValues={entry}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(RoleEditModal);
