import { useEffect, useState, memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store/slices/timeZonesSlice';
import { Table } from 'components';
import {
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import l from 'helpers/l';
import { RootState } from 'store';
import { formatTimeZone } from 'helpers/timeHelpers';
import { PageLayout } from 'components';

const TimeZonesPage = () => {
  const dispatch = useDispatch();
  const [sort, setSort] = useState({});
  const loading = useSelector((state: RootState) => state.common.loading);
  const timeZonesData = useSelector(
    (state: RootState) => state.timeZones.items,
  );
  const meta = useSelector((state: RootState) => state.timeZones.meta);

  useEffect(() => {
    dispatch(actions.getTimeZones());
  }, [dispatch]);

  const handlePage = useCallback(
    (page: number) => {
      const params = { ...sort, page: page + 1 };
      dispatch(actions.sendTimeZoneFilters(params));
    },
    [dispatch, sort],
  );

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: item.field,
        order: item?.sort?.toUpperCase(),
      }))[0];

      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });
      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
      };
      dispatch(actions.sendTimeZoneFilters(params));
    },
    [dispatch],
  );

  return (
    <PageLayout title={l('PAGES.TIME_ZONES')}>
      <Table
        rows={timeZonesData}
        columns={[
          { field: 'name', headerName: 'Name', width: 300 },
          {
            field: 'timeZone',
            headerName: 'Time Zone',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
              `${formatTimeZone(params.row.name) || ''}`,
          },
        ]}
        loading={loading}
        onSortModelChange={handleSort}
        pagination
        page={meta.currentPage !== undefined ? meta.currentPage - 1 : 0}
        rowCount={meta?.totalItems}
        onPageChange={handlePage}
        pageSize={meta?.itemsPerPage}
        rowsPerPageOptions={[meta?.itemsPerPage || 10]}
        paginationMode="server"
        sortingMode="server"
      />
    </PageLayout>
  );
};
export default memo(TimeZonesPage);
