import { makeStyles } from '@material-ui/core/styles';
const styles = makeStyles(() => {
  return {
    Spinner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  };
});

export default styles;
