import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { GenericModalProps } from 'types/genericTypes';
import { actions as userActions } from 'store/slices/user';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { Typography } from '@mui/material';

const EmployeePreferencesDelete: FC<GenericModalProps> = ({
  data,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { uuid, employeeUuid, description } = data;

  const confirmHandler = useCallback(() => {
    dispatch(
      userActions.deleteUserPreferences({ uuid, employeeUuid, description }),
    );
  }, [dispatch]);

  return data ? (
    <ConfirmationModal onCancel={onClose} onConfirm={confirmHandler}>
      <Typography>
        Are you sure you want to delete <strong>{data?.description} </strong>
        from the table ?
      </Typography>
    </ConfirmationModal>
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default EmployeePreferencesDelete;
