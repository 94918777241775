import { FC, memo} from 'react';
import { useDispatch } from 'hooks/redux';
import { actions as userActions } from 'store/slices/user';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import GenericNameForm from 'components/Forms/GenericNameForm';

const EmployeesCreateModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();
  const { uuid, firstName, lastName } = data;

  const submitHandler = ({ name }: { name: string }) => {
    const data = { username: name, employeeUuid: uuid };
    dispatch(userActions.createUser(data));
  };

  return uuid ? (
    <GenericNameForm
      title={'Employee Create User'}
      formEntity={l('PAGES.EMPLOYEE')}
      formEntityText={`${firstName} ${lastName}`}
      inputFieldLabel={l('USERNAME')}
      onSubmit={submitHandler}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(EmployeesCreateModal);
