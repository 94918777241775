import languages from './languagesSlice';
import common from './common';
import auth from './auth';
import user from './user';
import timeZones from './timeZonesSlice';
import currencies from './currenciesSlice';
import countries from './countriesSlice';
import employees from './employeeListSlice';
import employeeStatuses from './employeeStatusesSlice';
import expertise from './experiseSlice';
import questions from './questionsSlice';
import voices from './voicesSlice';
import payment from './paymentSlice';
import articleTypes from './articleTypesSlice';
import orderTypes from './orderTypesSlice';
import orderEntries from './orderEntriesSlice';
import orderLineStatuses from './orderLineStatusesSlice';
import orderLineStatusRoles from './orderLineStatusRolesSlice';
import orderLineStatusTransitions from './orderLineStatusTransitionSlice';
import orderLinesDelivery from './orderLinesDeliverySlice';
import checklists from './checklistsSlice';
import checklistsRoles from './checklistsRolesSlice';
import checklistsContactPersons from './checklistsContactPersonsSlice';
import roles from './rolesSlice';
import speakers from './speakersSlice';
import contactPersons from './contactPersonsSlice';
import clients from './clientsSlice';
import targetAudience from './targetAudienceSlice';
import projects from './projectsSlice';
import orderLines from './orderLinesSlice';
import pmOrderLines from './projectManagerSlice';
import orderLineStatusLog from './orderLineStatusLogSlice';
import reportParameters from './reportSlices/reportParameterSlice';
import reportCategories from './reportSlices/reportCategoriesSlice';
import reportManagement from './reportSlices/reportManagementSlices/reportManagementSlice';
import reports from './reportSlices/reportsSlice';
import messages from './messagesSlice';
import languagesRate from './languagesRateSlice'; // ar
import projectsRate from './projectsRateSlice'; // ar
import clientsRate from './clientsRateSlice'; // ar
import apLanguageRate from './apLanguageRateSlice'; // ap
import apEmployeeLanguageRate from './apEmployeeLanguageRateSlice'; // ap
import apEmployeeProjectRate from './apEmployeeProjectRateSlice'; // ap

const reducer = {
  languages,
  common,
  auth,
  user,
  timeZones,
  currencies,
  countries,
  employees,
  employeeStatuses,
  expertise,
  questions,
  voices,
  payment,
  roles,
  articleTypes,
  orderTypes,
  orderEntries,
  orderLineStatuses,
  orderLineStatusRoles,
  orderLineStatusTransitions,
  orderLinesDelivery,
  checklists,
  checklistsRoles,
  checklistsContactPersons,
  speakers,
  contactPersons,
  clients,
  targetAudience,
  projects,
  orderLines,
  pmOrderLines,
  orderLineStatusLog,
  reportParameters,
  messages,
  reportCategories,
  reports,
  reportManagement,
  languagesRate,
  projectsRate,
  clientsRate,
  apLanguageRate,
  apEmployeeLanguageRate,
  apEmployeeProjectRate,
};

export default reducer;
