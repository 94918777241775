import { FC, memo, useCallback } from 'react';
import { actions as languageActions } from 'store/slices/languagesSlice';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import GenericCodeNameForm from 'components/Forms/GenericCodeNameForm';
import { GenericCodeNameType } from 'types/genericTypes';

const LanguagesAddModal: FC<GenericModalProps> = (props) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: GenericCodeNameType) =>
      dispatch(languageActions.addLanguage(values)),
    [dispatch],
  );

  return (
    <GenericCodeNameForm
      title={l('ADD_NEW_LANGUAGE')}
      nameLabel={l('LANGUAGE')}
      onSubmit={submitHandler}
      {...props}
    />
  );
};

export default memo(LanguagesAddModal);
