import { FC, memo, useCallback, useEffect } from 'react';
import { actions as orderLineStatusLogActions } from 'store/slices/orderLineStatusLogSlice';
import { useDispatch } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import EditTable from 'components/Table/EditTable';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { GridValueGetterParams } from '@mui/x-data-grid';
import moment from 'moment';
import { actions } from 'store/slices/common';
import l from 'helpers/l';
import { Box } from '@mui/material';

const StatusHistoryModal: FC<GenericModalProps> = ({ data }) => {
  const dispatch = useDispatch();

  const orderLineStatusLog = useSelector(
    (state: RootState) => state.orderLineStatusLog?.items,
  );

  const isLoading = useSelector((state: RootState) => state?.common?.loading);

  useEffect(() => {
    dispatch(orderLineStatusLogActions.getOrderLineStatusLog({ uuid: data?.uuid }));
  }, [dispatch]);

  const closeHandler = useCallback(
    () => dispatch(actions.hideModal()),
    [dispatch],
  );

  return (
    <SimpleFormModal
      onClose={closeHandler}
      title={`${l('STATUS_HISTORY')}: Order ${data?.orderNumber || ''}, Line ${orderLineStatusLog[0]?.orderLine?.lineNumber || ''}`}
      style={{ width: '900px' }}>
      <Box style={{ maxHeight: '550px', overflowY: 'auto' }}>
        <EditTable
          rows={orderLineStatusLog}
          loading={isLoading}
          columns={[
            {
              field: 'name',
              headerName: 'Date/Time',
              width: 180,
              valueGetter: (params: GridValueGetterParams) =>
                moment(params.row.createdAt).format('DD.MM.YYYY hh:mm:ss'),
            },
            {
              field: 'employee',
              headerName: 'Employee',
              width: 180,
              valueGetter: (params: GridValueGetterParams) =>
                params.row?.employee?.firstName +
                ' ' +
                params.row?.employee?.lastName,
            },
            {
              field: 'status',
              headerName: 'Status',
              width: 100,
              valueGetter: (params: GridValueGetterParams) =>
                params.row?.orderLineStatus?.name,
            },
            {
              field: 'description',
              headerName: 'Description',
              width: 300,
            },
          ]}
          editColumnWidth={5}
          hideFooter
        />
      </Box>
    </SimpleFormModal>
  );
};

export default memo(StatusHistoryModal);
