import { FC, memo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormLabel } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { LanguageEntry } from 'types/languageTypes';
import { GenericModalProps } from 'types/genericTypes';
import { Roles } from 'types/userRoleTypes';
import l from 'helpers/l';

const defaultValues: Roles = {
  code: '',
  name: '',
};

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
});

type RolesFormProps = {
  initialValues?: LanguageEntry;
  title: string;
  onSubmit: (values: LanguageEntry) => void;
} & GenericModalProps;

const RolesForm: FC<RolesFormProps> = ({
  onClose,
  initialValues = defaultValues,
  title,
  onSubmit,
}) => {
  const { setValues, ...formik }: any = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: Roles) => {
      onSubmit(values);
    },
  });

  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <FormLabel>{l('CODE')}</FormLabel>
      {formik?.values?.isFixed ? (
        <Box
          sx={{ fontFamily: 'Poppins', fontWeight: 600, marginBottom: '30px' }}>
          {formik.values.code}
        </Box>
      ) : (
        <TextField
          name="code"
          sx={{ width: '12rem', display: 'flex' }}
          size="small"
          value={formik.values.code}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.code)}
          helperText={formik.errors.code}
          pressEnter={formik.submitForm}
        />
      )}
      <FormLabel>{l('NAME')}</FormLabel>
      <TextField
        name="name"
        fullWidth
        size="small"
        value={formik.values.name}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.name)}
        helperText={formik.errors.name}
        pressEnter={formik.submitForm}
      />
    </SimpleFormModal>
  );
};

export default memo(RolesForm);
