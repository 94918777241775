import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ArticleTypesEntry } from 'types/articleTypes';

export interface ArticleTypes {
  articleTypes: ArticleTypesEntry[];
}

const initialState: ArticleTypes = {
  articleTypes: [],
};

export const articleTypesSlice = createSlice({
  name: 'articleTypes',
  initialState,
  reducers: {
    getArticleTypes: (state) => state,
    setArticleTypes: (state, action: PayloadAction<ArticleTypesEntry[]>) => {
      state.articleTypes = action.payload;
    },
    addArticleTypes: (state) =>
      state,
    deleteArticleTypes: (
      state,

    ) => state,
    editArticleTypes: (state) =>
      state,
  },
});
export const actions: any = articleTypesSlice.actions;
export default articleTypesSlice.reducer;
