import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LanguageEntry,
  Languages,
} from 'types/languageTypes';

const initialState: Languages = {
  items: [],
  meta: {},
};

export const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    getLanguages: (state) => state,
    setLanguages: (state, action: PayloadAction<Languages>) => {
      state.items = action?.payload?.items;
      state.meta = action.payload.meta;
    },
    sendLanguageFilters: (state) => state,
    addLanguage: (state) => state,
    addLanguageSuccess: (state, action: PayloadAction<LanguageEntry>) => {
      const data = action.payload;
      state.items = [...state.items, data];
    },
    deleteLanguage: (state) =>
      state,
    deleteLanguageSuccess: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.items = state.items.filter((lang) => lang.uuid !== id);
    },
    editLanguage: (state) => state,
    editLanguageSuccess: (state, action: PayloadAction<LanguageEntry>) => {
      const id = action.payload.uuid;
      const findIndex = state.items.findIndex((lang) => lang.uuid === id);
      const copyState = state.items.slice();
      copyState.splice(findIndex, 1, action.payload);
      state.items = copyState;
    },
  },
});
export const actions: any = languagesSlice.actions;
export default languagesSlice.reducer;
