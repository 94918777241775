import { FC, memo, useCallback } from 'react';
import { actions as apEmployeeProjectRateActions } from 'store/slices/apEmployeeProjectRateSlice';
import { useDispatch } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import { actions } from 'store/slices/common';
import APEmployeeProjectRatesForm from 'components/Forms/APEmployeeProjectRatesForm';

const APEmployeeProjectRateAddModal: FC<GenericModalProps> = ({ data }) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: any) =>
      dispatch(
        apEmployeeProjectRateActions.addAPEmployeeProjectRate({
          ...values,
          filters: data?.filters,
        }),
      ),
    [dispatch, data],
  );

  const closeHandler = useCallback(
    () => dispatch(actions.hideModal()),
    [dispatch],
  );

  return (
    <APEmployeeProjectRatesForm
      onSubmit={submitHandler}
      title="Add AP Employee Project Rate"
      onClose={closeHandler}
      initialValues={data?.filters}
    />
  );
};

export default memo(APEmployeeProjectRateAddModal);
