import { FC, memo} from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormLabel} from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps } from 'types/genericTypes';
import { Endpoint } from 'enums/APIEndpointEnum';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';

const defaultValidation = Yup.object().shape({
  name: Yup.string().required('Required'),
  contactPersonUuid: Yup.string().required('Required'),
});

const defaultValues = {
  name: '',
  contactPersonUuid: '',
};

type ProjectFormProps = {
  initialValues?: any;
  validationSchema?: any;
  contactPersonDropdown: [];
  title: string;
  onSubmit: (values: any) => void;
} & GenericModalProps;

const ProjectsAddForm: FC<ProjectFormProps> = ({
  onClose,
  initialValues = defaultValues,
  validationSchema = defaultValidation,
  contactPersonDropdown,
  title,
  onSubmit,
}) => {
  const { setValues, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit({
        ...values,
      });
    },
  });

  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <FormLabel>Name</FormLabel>
      <TextField
  name='name'
  sx={{ width: '100%', display: 'flex' }}
  size='small'
  value={formik.values.name}
  onChange={formik.handleChange}
  autoComplete='off'
  error={Boolean(formik.errors.name)}
  helperText={formik.errors.name}
  pressEnter={formik.submitForm}/>
      <FormLabel>Contact Person</FormLabel>
      <SuggestedDropdown
        url={Endpoint.ContactPersons}
        inputId="contactPersonUuid"
        initialData={contactPersonDropdown}
        inputValue={{
          label: formik?.values?.contactPerson?.nick,
          value: formik?.values?.contactPerson?.uuid,
        }}
        dropdownLabel="nick"
        dropdownValue="uuid"
        dataLocation="resultDataItems"
        error={Boolean(formik.errors.contactPersonUuid)}
        helperText={formik.errors.contactPersonUuid}
        formikHook={formik}
      />
    </SimpleFormModal>
  );
};

export default memo(ProjectsAddForm);
