import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getTargetAudienceApi = () => {
  return apiCall({
    url: Endpoint.TargetAudience,
    method: Method.Get,
  });
};

const addTargetAudienceApi = (data: any) => {
  return apiCall({
    url: Endpoint.TargetAudience,
    method: Method.Post,
    data,
  });
};

const deleteTargetAudienceApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.TargetAudience,
    method: Method.Delete,
    urlSuffix,
  });
};

const editTargetAudienceApi = (urlSuffix: string, data: any) => {
  return apiCall({
    url: Endpoint.TargetAudience,
    method: Method.Put,
    urlSuffix,
    data,
  });
};

export {
  getTargetAudienceApi,
  addTargetAudienceApi,
  deleteTargetAudienceApi,
  editTargetAudienceApi,
};
