import { PageLayout } from 'components';
import { ContactPersonsForm } from 'components/Forms/ContactPersonsForm';
import l from 'helpers/l';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { actions as contactPersonActions } from 'store/slices/contactPersonsSlice';
import * as Yup from 'yup';
import { actions as employeeCurrenciesActions } from '../../../store/slices/currenciesSlice';

export const ContactPersonsEdit = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contactPerson, setContactPerson] = useState<any>();

  const entry = useSelector((state: RootState) =>
    state.contactPersons.items.find(
      (cp: any) => cp.uuid === params.contactPerson,
    ),
  );
  useEffect(() => {
    if (entry)
      setContactPerson({ ...entry, currencyUuid: entry?.currency?.uuid || '' });
  }, [entry]);

  const submitHandler = (values: any) => {
    const data = { ...values };
    const uuid = params.contactPerson;
    dispatch(contactPersonActions.editContactPerson({ uuid, data, navigate }));
  };

  const cancelHandler = () => {
    navigate('/contact-persons');
  };

  useEffect(() => {
    dispatch(contactPersonActions.getContactPersonsList());
    dispatch(employeeCurrenciesActions.getCurrencyList());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    clientUuid: Yup.string(),
    nick: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string().email('Invalid email adress').nullable(),
    phone: Yup.string().nullable(),
    notes: Yup.string().nullable(),
    accountManagerUuid: Yup.string().nullable(),
    currencyUuid: Yup.string().nullable(),
  });

  return (
    <PageLayout title={`${l('CONTACT_PERSONS.EDIT')}`} fullWidth={true}>
      {contactPerson && (
        <ContactPersonsForm
          initialValues={contactPerson}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
          onCancel={cancelHandler}
        />
      )}
    </PageLayout>
  );
};

export default memo(ContactPersonsEdit);
