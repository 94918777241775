import { FC, memo, useCallback, useEffect } from 'react';
import { actions as userActions } from 'store/slices/user';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import UserLanguageAddForm from 'components/Forms/UserLanguageAddForm';
import { RootState } from 'store';
import { createMenuItems } from 'helpers/dropdown';

const UserLanguageAddModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();
  const userUnassignedLanguages = useSelector(
    (state: RootState) => state.user.userUnassignedLanguages,
  ).map((lang: any) => lang.name);

  const userUnassignedLanguagesDropdown = useSelector(
    (state: RootState) => state.user.userUnassignedLanguages,
  )
    .map(({ name, uuid }) => {
      return { label: name, value: uuid };
    })
    .filter((role) => {
      if (userUnassignedLanguages.includes(role.label)) return role;
      return null;
    });

  const unassignedLanguagesValues = createMenuItems(
    userUnassignedLanguagesDropdown,
  );

  const levelDropdown = [
    {
      label: 'High',
      value: 'high',
    },
    {
      label: 'Low',
      value: 'low',
    },
  ];

  const editorLevelDropdown = [
    {
      label: 'High',
      value: 'high',
    },
    {
      label: 'Low',
      value: 'low',
    },
  ];

  const levelValues = createMenuItems(levelDropdown);
  const editorLevelValues = createMenuItems(editorLevelDropdown);

  const submitHandler = useCallback(
    (values: any) => {
      dispatch(
        userActions.addUserLanguage({
          ...values.values,
          employeeUuid: data.id,
        }),
      );
    },
    [dispatch, data.id],
  );

  useEffect(() => {
    dispatch(userActions.getUserUnassignedLanguages(data.id));
  }, [dispatch, data.id]);

  return (
    <UserLanguageAddForm
      title="Employee Language Add"
      employeeName={data.name}
      onSubmit={submitHandler}
      employeeUuid={data.id}
      unassignedLanguagesValues={unassignedLanguagesValues}
      levelValues={levelValues}
      editorLevelValues={editorLevelValues}
      {...rest}
    />
  );
};

export default memo(UserLanguageAddModal);
