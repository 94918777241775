import { FC, memo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Switch } from '@mui/material';
import { Form, TextField } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/index';
import { actions as clientsActions } from 'store/slices/clientsSlice';
import { actions as contactPersonActions } from 'store/slices/contactPersonsSlice';
import { actions as orderTypesActions } from 'store/slices/orderTypesSlice';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { createMenuItems } from 'helpers/dropdown';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import InputRowWrapper from 'components/InputRowWrapper/InputRowWrapper';
import l from 'helpers/l';
import { Endpoint } from '../../enums/APIEndpointEnum';
import { SuggestedDropdown } from '../SuggestedDropdown/SuggestedDropdown';

const defaultValues: any = {
  client: '',
  contactPersonUuid: '',
  orderDate: '',
  dueDate: '',
  dateCritical: 'must',
  orderTypeId: 0,
  isCorrection: false,
  isRatePerArticleClient: false,
  isRatePerArticleWriter: false,
  isNoCharge: false,
  comments: '',
};

const validationSchema = Yup.object().shape({
  client: Yup.string(),
  contactPersonUuid: Yup.string().required('Field is required!'),
  orderDate: Yup.date(),
  dueDate: Yup.date(),
  dateCritical: Yup.string().required('Field is required!'),
  orderTypeId: Yup.number(),
  isCorrection: Yup.bool(),
  isRatePerArticleClient: Yup.bool(),
  isRatePerArticleWriter: Yup.bool(),
  isNoCharge: Yup.bool(),
  comments: Yup.string(),
});

type IOrderEntriesForm = {
  initialValues?: any;
  onSubmit: (values: any) => void;
  onCancel: () => void;
};

const OrderEntriesForm: FC<IOrderEntriesForm> = ({
  initialValues = defaultValues,
  onSubmit,
  onCancel,
}) => {
  const dispatch = useDispatch();

  const [orderDateFormat, setOrderDateFormat] = useState<any>(
    initialValues?.orderDate
      ? moment(initialValues?.orderDate).format('DD.MM.YYYY')
      : moment().format('DD.MM.YYYY'),
  );

  const [dueDateFormat, setDueDateFormat] = useState<any>(
    initialValues?.dueDate
      ? moment(initialValues?.dueDate).format('DD.MM.YYYY')
      : moment().format('DD.MM.YYYY'),
  );

  const contactPersonDropdown = useSelector(
    (state: RootState) => state.contactPersons.items,
  ).map((person) => ({
    label: person.nick,
    // label: `${person.firstName} ${person.lastName}`,
    value: person.uuid,
  }));

  const orderTypesDropdown = useSelector(
    (state: RootState) => state.orderTypes.orderTypes,
  ).map((status) => ({
    label: status.name,
    value: status.id,
  }));

  const dateFormatter = (string: any) => {
    const splitDate = string.split('.');
    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  };

  const dueDateCriticalValues = createMenuItems([
    { label: 'must', value: 'must' },
    { label: 'critical', value: 'critical' },
    { label: 'non-critical', value: 'nonCritical' },
  ]);

  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit({
        ...values,
        orderTypeId: formik?.values?.orderTypeId || null,
        dueDatetime: setTime(new Date(dateFormatter(dueDateFormat))),
        orderedAt: setTime(new Date(dateFormatter(orderDateFormat))),
      });
    },
  });

  const setTime = (date: any) => {
    const split = String(date).split(' ');
    split[4] = '23:59:00';

    return split.join(' ');
  };

  const handleDueDateChange = (value: any, inputValue: any) => {
    setDueDateFormat(inputValue);
  };

  const handleOrderDateChange = (value: any, inputValue: any) => {
    setOrderDateFormat(inputValue);
  };

  useEffect(() => {
    dispatch(clientsActions.getClients());
    dispatch(contactPersonActions.getContactPersonsList());
    dispatch(orderTypesActions.getOrderTypes());
  }, [dispatch]);

  const datePickerStyles = {
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    padding: '12px',
    width: '100%',
  };

  const minimalDate = new Date();

  return (
    <Form
      onSubmit={formik.submitForm}
      onCancel={onCancel}
      btnCancelLabel="Cancel"
      btnSubmitLabel="Save"
      sx={{ padding: '32px' }}>
      <InputRowWrapper
        label={l('CONTACT_PERSON')}
        labelWidth="250px"
        inputWidth="60%">
        <SuggestedDropdown
          url={Endpoint.ContactPersons}
          inputId="contactPersonUuid"
          initialData={contactPersonDropdown}
          inputValue={{
            label: formik?.values?.contactPersonName || '',
            value: formik?.values?.contactPersonUuid || '',
          }}
          dropdownLabel="nick"
          dropdownValue="uuid"
          dataLocation="resultDataItems"
          orderBy="nick"
          order="ASC"
          error={Boolean(formik?.errors?.contactPersonUuid)}
          helperText={formik.errors.contactPersonUuid}
          style={{ display: 'flex', flexDirection: 'row' }}
          formikHook={formik}
        />
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="90%"></InputRowWrapper>
      <InputRowWrapper
        label={l('ORDER_DATE')}
        labelWidth="250px"
        inputWidth="35%">
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <KeyboardDatePicker
            autoOk={true}
            showTodayButton={true}
            value={orderDateFormat}
            inputValue={orderDateFormat}
            onChange={handleOrderDateChange}
            format="DD.MM.YYYY"
            style={datePickerStyles}
          />
        </MuiPickersUtilsProvider>
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="90%"></InputRowWrapper>
      <InputRowWrapper
        label={l('ORDER_DUE_DATE')}
        labelWidth="250px"
        inputWidth="35%">
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <KeyboardDatePicker
            autoOk
            showTodayButton
            value={dueDateFormat}
            inputValue={dueDateFormat}
            onChange={handleDueDateChange}
            format="DD.MM.YYYY"
            style={datePickerStyles}
            minDate={minimalDate}
          />
        </MuiPickersUtilsProvider>
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="90%"></InputRowWrapper>
      <InputRowWrapper
        label={l('ORDER_DATE_CRITICAL')}
        labelWidth="250px"
        inputWidth="35%">
        <TextField
          select
          name="dateCritical"
          size="small"
          value={formik?.values?.dateCritical}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.dateCritical)}
          sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          helperText={formik.errors.dateCritical}>
          {dueDateCriticalValues}
        </TextField>
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="90%"></InputRowWrapper>
      <InputRowWrapper
        label={l('ORDER_CHARACTER')}
        labelWidth="250px"
        inputWidth="35%">
        <TextField
          select
          name="orderTypeId"
          size="small"
          value={formik?.values?.orderTypeId || ''}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.orderTypeId)}
          helperText={formik.errors.orderTypeId}>
          {orderTypesDropdown?.length
            ? createMenuItems(orderTypesDropdown)
            : createMenuItems([{ label: 'NO DATA', value: '' }])}
        </TextField>
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="90%"></InputRowWrapper>
      <InputRowWrapper
        label={l('CORRECTION')}
        labelWidth="250px"
        inputWidth="35%">
        <Switch
          id="isCorrection"
          value={formik?.values?.isCorrection}
          defaultChecked={initialValues.isCorrection}
          size="small"
          onChange={formik.handleChange}
        />
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="90%"></InputRowWrapper>
      <InputRowWrapper
        label={l('ORDER_RATE_CLIENT')}
        labelWidth="250px"
        inputWidth="35%">
        <Switch
          id="isRatePerArticleClient"
          value={formik?.values?.isRatePerArticleClient}
          defaultChecked={initialValues.isRatePerArticleClient}
          size="small"
          onChange={formik.handleChange}
        />
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="90%"></InputRowWrapper>
      <InputRowWrapper
        label={l('ORDER_RATE_WRITER')}
        labelWidth="250px"
        inputWidth="35%">
        <Switch
          id="isRatePerArticleWriter"
          value={formik?.values?.isRatePerArticleWriter}
          defaultChecked={initialValues.isRatePerArticleWriter}
          size="small"
          onChange={formik.handleChange}
        />
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="90%"></InputRowWrapper>
      <InputRowWrapper
        label={l('ORDER_NOCHARGE')}
        labelWidth="250px"
        inputWidth="35%">
        <Switch
          id="isNoCharge"
          value={formik?.values?.isNoCharge}
          defaultChecked={initialValues.isNoCharge}
          size="small"
          onChange={formik.handleChange}
        />
      </InputRowWrapper>
      <InputRowWrapper labelWidth="250px" inputWidth="90%"></InputRowWrapper>
      <InputRowWrapper
        label={l('COMMENTS')}
        labelWidth="250px"
        inputWidth="60%">
        <TextField
          name="comments"
          fullWidth
          size="small"
          value={formik?.values?.comments}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.comments)}
          helperText={formik.errors.comments}
        />
      </InputRowWrapper>
    </Form>
  );
};

export default memo(OrderEntriesForm);
