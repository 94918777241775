import { Endpoint, Method } from 'enums/APIEndpointEnum';
import {
  ForgottenPasswordProps,
  LoginProps,
  ResetPassApi,
} from 'types/authTypes';
import apiCall from './apiCall';

const authMeApi = () => apiCall({ url: Endpoint.AuthMe, method: Method.Get });

const getAuthLoginApi = (auth: boolean, data: LoginProps) =>
  apiCall({
    url: Endpoint.AuthLogin,
    method: Method.Post,
    auth,
    data,
  });

const requestNewPasswordApi = (auth: boolean, data: ForgottenPasswordProps) =>
  apiCall({
    url: Endpoint.NewPassword,
    method: Method.Post,
    auth,
    data,
  });

const resetPasswordApi = (auth: boolean, data: ResetPassApi) =>
  apiCall({
    url: Endpoint.ResetPassword,
    method: Method.Post,
    auth,
    data,
  });

const setPasswordApi = (auth: boolean, data: ResetPassApi) =>
  apiCall({
    url: Endpoint.SetPassword,
    method: Method.Patch,
    auth,
    data,
  });

export {
  authMeApi,
  getAuthLoginApi,
  requestNewPasswordApi,
  resetPasswordApi,
  setPasswordApi,
};
