import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getOrderLinesDeliveryApi = (params: any) => {
  return apiCall({
    url: Endpoint.OrderLinesDelivery,
    method: Method.Get,
    params: { ...params, statusUuids: params?.statusUuids ? [params.statusUuids].toString() : [].toString() },
  })
};

const editOrderLinesDeliveryApi = (data: any, urlSuffix: any) => {
    return apiCall({
        url: Endpoint.OrderLinesDelivery,
        method: Method.Put,
        data,
        urlSuffix,
    });
};

const sendOrderLinesDeliveryApi = (params: any) =>
  apiCall({
    url: Endpoint.OrderLinesDelivery,
    method: Method.Get,
    params: { ...params, statusUuids: params?.statusUuids ? [params.statusUuids].toString() : [].toString() },
  })

export { getOrderLinesDeliveryApi, sendOrderLinesDeliveryApi, editOrderLinesDeliveryApi };
