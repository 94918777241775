import { FC } from 'react';
import { Switch } from '@mui/material';
import { useDispatch } from 'react-redux';
import { actions as userActions } from 'store/slices/user';

const EmployeeRolesSwitch: FC<any> = ({ data }) => {
  const { isActive, wordsPerHour, uuid } = data;
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked: isActive } = event.target;
    dispatch(
      userActions.editUserRole({
        id: uuid,
        isActive,
        wordsPerHour,
      }),
    );
  };

  return <Switch size="small" checked={isActive} onChange={handleChange} />;
};
export default EmployeeRolesSwitch;
