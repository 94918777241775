import React, { FC } from 'react';
import {
  Logout,
  //   NotificationsOutlined,
  EmailOutlined,

} from '@mui/icons-material';
import { Badge, IconButton, Menu, MenuItem } from '@mui/material';

interface HeaderMobileMenuProps {
  id: string;
  mobileMoreAnchorEl: null | HTMLElement;
  isMobileMenuOpen: boolean;
  handleMobileMenuClose: (state: boolean) => void;
  handleProfileMenuOpen: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
}

const HeaderMobileMenu: FC<HeaderMobileMenuProps> = ({
  id,
  mobileMoreAnchorEl,
  isMobileMenuOpen,
  handleMobileMenuClose,
  handleProfileMenuOpen,
}) => {
  return (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={id}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge>
            <SettingsOutlined />
          </Badge>
        </IconButton>
        <p>Settings</p>
      </MenuItem> */}
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit">
          <Badge variant={'dot'}>
            <EmailOutlined />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton size="large" color="inherit">
          <Logout />
        </IconButton>
        <p>Log Out</p>
      </MenuItem>
    </Menu>
  );
};

export default HeaderMobileMenu;
