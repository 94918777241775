import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  items: [],
};

export const orderLineStatusLog = createSlice({
  name: 'orderLineStatusLog',
  initialState,
  reducers: {
    getOrderLineStatusLog: (state) => state,
    setOrderLineStatusLog: (state, action: PayloadAction<any>) => {
      state.items = action.payload;
    },
  },
});
export const actions: any = orderLineStatusLog.actions;
export default orderLineStatusLog.reducer;
