import { FC, memo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormLabel } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps } from 'types/genericTypes';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

const defaultValues = {
  name: '',
};

type ReportCategoriesFormProps = {
  initialValues?: any;
  title: string;
  onSubmit: any;
} & GenericModalProps;

const ReportCategoriesForm: FC<ReportCategoriesFormProps> = ({
  onClose,
  initialValues = defaultValues,
  title,
  onSubmit,
}) => {
  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit({
        ...values,
      });
    },
  });

  return (
    <SimpleFormModal
      style={{ width: '80%' }}
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <FormLabel>Name</FormLabel>
      <Box sx={{ width: '100%' }}>
        <TextField
          name="name"
          size="small"
          value={formik.values.name || ''}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name}
          onChange={formik.handleChange}
        />
      </Box>
    </SimpleFormModal>
  );
};

export default memo(ReportCategoriesForm);
