import { FC, memo, useCallback } from 'react';
import { actions as languageRateActions } from 'store/slices/languagesRateSlice';
import { useDispatch } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ARLanguageRateForm from 'components/Forms/ARLanguageRateForm';
import { actions } from 'store/slices/common';

const LanguageRatesAddModal: FC<GenericModalProps> = ({ data }) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: any) =>
      dispatch(languageRateActions.addLanguageRate({ ...values, ...data })),
    [dispatch],
  );

  const closeHandler = useCallback(
    () => dispatch(actions.hideModal()),
    [dispatch],
  );

  return (
    <ARLanguageRateForm
      onSubmit={submitHandler}
      title="Add Language Rate"
      onClose={closeHandler}
      initialValues={data?.filters}
    />
  );
};

export default memo(LanguageRatesAddModal);
