import { FC, memo, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormLabel } from '@mui/material';
import { TextField } from 'components';
import l from 'helpers/l';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps } from 'types/genericTypes';
import { useDispatch, useSelector } from 'react-redux';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';
import { RootState } from 'store';
import { actions as languageActions } from 'store/slices/languagesSlice';
import { actions as roleActions } from 'store/slices/rolesSlice';
import { actions as employeesActions } from 'store/slices/employeeListSlice';
import { Endpoint } from 'enums/APIEndpointEnum';
import { createMenuItems } from 'helpers/dropdown';
import { sanitizeNumericInput } from 'helpers/sanitzeNumericInput';

const validationSchema = Yup.object().shape({
  //   sourceLanguageUuid: Yup.string().required('This field is required!'),
  sourceLanguageUuid: Yup.string().nullable(),
  languageUuid: Yup.string()
    .required('This field is required!')
    .notOneOf(
      [Yup.ref('sourceLanguageUuid')],
      'Language must not have the same value as Source Language',
    ),
  roleCode: Yup.string().required('This field is required!'),
  employeeUuid: Yup.string().required('This field is required!'),
  ratePerWord: Yup.number().required('This field is required!'),
  ratePerArticle: Yup.number().nullable(),
});

const defaultValues: any = {
  sourceLanguageUuid: '',
  languageUuid: '',
  roleCode: '',
  employeeUuid: '',
  ratePerWord: '',
  ratePerArticle: '',
};

type IAPEmployeeLanguageRatesForm = {
  initialValues?: any;
  title: string;
  onSubmit: (values: any) => void;
} & GenericModalProps;

const APEmployeeLanguageRatesForm: FC<IAPEmployeeLanguageRatesForm> = ({
  onClose,
  initialValues = defaultValues,
  title,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const languagesDropdown = useSelector(
    (state: RootState) => state?.languages?.items,
  ).map((entry) => ({ label: entry.name, value: entry.uuid }));

  const rolesDropdown = useSelector((state: RootState) => {
    return state?.roles?.roles;
  }).map((entry) => ({
    value: entry.code,
    label: entry.name,
  }));

  const employeesDropdown = useSelector(
    (state: RootState) => state?.employees.employeesByRole,
  ).map((entry: any) => ({
    roles: entry.roles.map((role: any) => role.role.code),
    value: entry.uuid,
    label: entry.firstName + ' ' + entry.lastName,
  }));

  const { setValues, ...formik }: any = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    dispatch(languageActions.getLanguages());
    dispatch(roleActions.getRoles());
    dispatch(employeesActions.getEmployeesList());
  }, [dispatch]);

  useEffect(() => {
    if (formik.values.roleCode !== '') {
      const params: any = {
        urlSuffix: formik.values.roleCode,
      };
      dispatch(employeesActions.getEmployeesByRole(params));
    }
  }, [formik.values.roleCode]);

  useEffect(() => {
    if (initialValues?.employeeUuid) {
      setValues((prevValues: any) => ({
        ...prevValues,
        employeeUuid: initialValues.employeeUuid,
      }));
    }
  }, [initialValues]);

  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}
      style={{ maxHeight: '95%', overflowY: 'auto' }}>
      <FormLabel>{l('SOURCE_LANGUAGE')}</FormLabel>
      <SuggestedDropdown
        url={Endpoint.Languages}
        inputId="sourceLanguageUuid"
        initialData={languagesDropdown}
        inputValue={
          initialValues?.sourceLanguageUuid
            ? {
                label: initialValues.sourceLanguage,
                value: initialValues.sourceLanguageUuid,
              }
            : ''
        }
        dropdownLabel="name"
        dropdownValue="uuid"
        dataLocation="resultDataItems"
        orderBy="code"
        order="ASC"
        error={Boolean(formik.errors.sourceLanguageUuid)}
        helperText={formik.errors.sourceLanguageUuid}
        formikHook={formik}
        style={{ display: 'flex', flexDirection: 'row' }}
      />
      <FormLabel>{l('LANGUAGE')}</FormLabel>
      <SuggestedDropdown
        url={Endpoint.Languages}
        inputId="languageUuid"
        error={Boolean(formik.errors.languageUuid)}
        helperText={formik.errors.languageUuid}
        initialData={languagesDropdown}
        inputValue={
          initialValues?.language
            ? {
                label: initialValues?.language,
                value: initialValues?.languageUuid,
              }
            : ''
        }
        dropdownLabel="name"
        dropdownValue="uuid"
        dataLocation="resultDataItems"
        orderBy="code"
        order="ASC"
        formikHook={formik}
        style={{ display: 'flex', flexDirection: 'row' }}
      />
      <FormLabel>{l('ROLE')}</FormLabel>
      <TextField
        select
        name="roleCode"
        size="small"
        value={formik?.values?.roleCode || ''}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.roleCode)}
        helperText={formik.errors.roleCode}
        pressEnter={formik.submitForm}>
        {createMenuItems(rolesDropdown)}
      </TextField>
      {formik.values.roleCode !== '' && (
        <>
          <FormLabel>{l('EMPLOYEE.EMPLOYEE')}</FormLabel>
          <SuggestedDropdown
            select={(data) => {
              return data;
            }}
            disabled={!formik.values.languageUuid || !formik.values.roleCode}
            url={Endpoint.EmployeesListQuery}
            inputId="employeeUuid"
            initialData={[]}
            apiParams={{
              language: formik.values.languageUuid,
              role: formik.values.roleCode,
            }}
           // urlSuffix={`?language=${formik.values.languageUuid}&role=${formik.values.roleCode}`}
            inputValue={
              initialValues?.employeeUuid
                ? {
                    label: initialValues?.employee,
                    value: initialValues?.employeeUuid,
                  }
                : ''
            }
            dropdownLabel={['firstName', 'lastName']}
            dropdownValue="uuid"
            dataLocation="resultDataItems"
            orderBy="code"
            order="ASC"
            filter
            formikHook={formik}
            style={{ display: 'flex', flexDirection: 'row' }}
          />
        </>
      )}
      <FormLabel>{l('RATE_PER_WORD')}</FormLabel>
      <TextField
        name="ratePerWord"
        size="small"
        value={formik?.values?.ratePerWord || ''}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.ratePerWord)}
        helperText={formik.errors.ratePerWord}
        pressEnter={formik.submitForm}
        onInput={(e: any) => {
          e.target.value = sanitizeNumericInput(e.target.value);
        }}
      />
      <FormLabel>{l('RATE_PER_ARTICLE')}</FormLabel>
      <TextField
        name="ratePerArticle"
        size="small"
        value={formik?.values?.ratePerArticle || ''}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.ratePerArticle)}
        helperText={formik.errors.ratePerArticle}
        pressEnter={formik.submitForm}
        onInput={(e: any) => {
          e.target.value = sanitizeNumericInput(e.target.value);
        }}
      />
    </SimpleFormModal>
  );
};

export default memo(APEmployeeLanguageRatesForm);
