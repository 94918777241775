import { put, call, takeLatest } from 'redux-saga/effects';
import { actions as commonActions } from 'store/slices/common';
import {
  getProjectsRateAPI,
  addProjectsRateAPI,
  editProjectsRateAPI,
  deleteProjectsRateAPI,
} from 'store/api/projectsRateAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { APIResult } from 'types/APITypes';
import { actions as projectsRateActions } from '../slices/projectsRateSlice';

function* getProjectsRatesWithFilters(action: PayloadAction<string>) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(getProjectsRateAPI, params);

  if (result?.data) {
    yield put(projectsRateActions.setProjectsRates(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addProjectsRate(action: PayloadAction<any>) {
  const data = action.payload;
  const filters = action.payload?.filters || {};

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(addProjectsRateAPI, data);
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getProjectsRateAPI, {
      page: 1,
      ...filters,
    });
    if (result?.data) {
      yield put(projectsRateActions.setProjectsRates(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added new item to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

function* editProjectsRate(action: PayloadAction<any>) {
  const { urlSuffix } = action.payload;
  const data = action.payload;
  const filters = action.payload?.filters;

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(
    editProjectsRateAPI,
    urlSuffix,
    data,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getProjectsRateAPI, {
      page: 1,
      ...filters,
    });
    if (result?.data) {
      yield put(projectsRateActions.setProjectsRates(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited this item`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

function* deleteProjectsRate(action: PayloadAction<any>) {
  const { urlSuffix } = action.payload;

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(
    deleteProjectsRateAPI,
    urlSuffix,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getProjectsRateAPI, {
      page: 1,
    });
    if (result?.data) {
      yield put(projectsRateActions.setProjectsRates(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted this item from the list`,
        severity: 'error',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

export default function* ARProjectsRateWatcher() {
  yield takeLatest(
    projectsRateActions.getProjectsRates.type,
    getProjectsRatesWithFilters,
  );
  yield takeLatest(projectsRateActions.addProjectsRate.type, addProjectsRate);
  yield takeLatest(projectsRateActions.editProjectsRate.type, editProjectsRate);
  yield takeLatest(
    projectsRateActions.deleteProjectsRate.type,
    deleteProjectsRate,
  );
}
