import { FC, memo, useCallback } from 'react';
import { actions as apLanguageRateActions } from 'store/slices/apLanguageRateSlice';
import { useDispatch } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import { actions } from 'store/slices/common';
import APLanguageRatesForm from 'components/Forms/APLanguageRatesForm';

const APLanguageRateAddModal: FC<GenericModalProps> = ({ data }) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: any) =>
      dispatch(
        apLanguageRateActions.addAPLanguageRate({
          ...values,
          filters: data?.filters,
        }),
      ),
    [dispatch, data],
  );

  const closeHandler = useCallback(
    () => dispatch(actions.hideModal()),
    [dispatch],
  );

  return (
    <APLanguageRatesForm
      onSubmit={submitHandler}
      title="Add AP Language Rate"
      onClose={closeHandler}
      initialValues={data?.filters}
    />
  );
};

export default memo(APLanguageRateAddModal);
