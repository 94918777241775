import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getOrderEntriesApi = (params?: any) =>
  apiCall({
    url: Endpoint.OrderEntries,
    method: Method.Get,
    params,
  });

const addOrderEntriesApi = (data: any) =>
  apiCall({
    url: Endpoint.OrderEntries,
    method: Method.Post,
    data,
  });

const getOrderEntryItemApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.OrderEntries,
    method: Method.Get,
    urlSuffix,
  });

const editOrderEntriesApi = (urlSuffix: string, data: any) => {
  return apiCall({
    url: Endpoint.OrderEntries,
    method: Method.Put,
    urlSuffix,
    data,
  });
};

const deleteOrderEntriesApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.OrderEntries,
    method: Method.Delete,
    urlSuffix,
  });
const cancelAllOrderLinesApi = (urlSuffix: string) =>
    apiCall({
        url: Endpoint.CancelAllOrderLines,
        method: Method.Put,
        urlSuffix,
    });

const sendOrderEntriesFiltersApi = (params: string) =>
  apiCall({
    url: Endpoint.OrderEntries,
    method: Method.Get,
    params,
  });

export {
  getOrderEntriesApi,
  addOrderEntriesApi,
  getOrderEntryItemApi,
  editOrderEntriesApi,
  deleteOrderEntriesApi,
  sendOrderEntriesFiltersApi,
  cancelAllOrderLinesApi,
};
