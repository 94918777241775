import {FC, memo, useCallback} from 'react';
import {useDispatch} from 'hooks/redux';
import {GenericModalProps} from 'types/genericTypes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import {Typography} from '@mui/material';
import {actions as reportParameterSlice} from "../../../store/slices/reportSlices/reportParameterSlice";

const ReportParametersDeleteModal: FC<GenericModalProps> = ({ data, onClose }) => {
    const dispatch = useDispatch();

    const submitHandler = useCallback(() => {
         dispatch(reportParameterSlice.deleteReportParameter(data.uuid));
    }, [dispatch, data.uuid]);

    return (
        <ConfirmationModal onConfirm={submitHandler} onCancel={onClose}>
            <Typography>
                Are you sure you want to delete <strong>{data.name}</strong>{' '}
                from your table of report parameters?
            </Typography>
        </ConfirmationModal>
    );
};

export default memo(ReportParametersDeleteModal);
