import { FC, memo, useCallback, useEffect } from 'react';
import { actions as userActions } from 'store/slices/user';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import UserExpertiseAddForm from 'components/Forms/UserExpertiseAddForm';
import { RootState } from 'store';
import { createMenuItems } from 'helpers/dropdown';

const UserExpertiseAddModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();
  const userUnassignedExpertise = useSelector(
    (state: RootState) => state.user.userUnassignedExpertise,
  ).map((exp: any) => exp.name);

  const userUnassignedExpertiseDropdown = useSelector(
    (state: RootState) => state.user.userUnassignedExpertise,
  )
    .map(({ name, id }) => {
      return { label: name, value: id };
    })
    .filter((role) => {
      if (userUnassignedExpertise.includes(role.label)) return role;
      return null;
    });

  const unassignedExpertiseValues = createMenuItems(
    userUnassignedExpertiseDropdown,
  );

  let levelDropdown = [];

  for (let i = 1; i < 11; i++) {
    levelDropdown.push({ label: `${i}`, value: i });
  }

  const levelValues = createMenuItems(levelDropdown);

  const submitHandler = useCallback(
    (values: any) => {
      dispatch(
        userActions.addUserExpertise({
          ...values.values,
          employeeUuid: data.id,
        }),
      );
    },
    [dispatch, data.id],
  );

  useEffect(() => {
    dispatch(userActions.getUserUnassignedExpertise(data.id));
  }, [dispatch, data.id]);

  return (
    <UserExpertiseAddForm
      title="Employee Expertise Add"
      employeeName={data.name}
      employeeUuid={data.id}
      onSubmit={submitHandler}
      unassignedExpertiseValues={unassignedExpertiseValues}
      levelValues={levelValues}
      {...rest}
    />
  );
};

export default memo(UserExpertiseAddModal);
