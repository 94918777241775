import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { actions as commonActions } from 'store/slices/common';
import { actions as userActions } from 'store/slices/user';
import { actions as employeeActions } from 'store/slices/employeeListSlice';
import {
  Box,
  Button,
  Grid,
  Stack,
  Tab,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  ContentWrapper,
  EmployeeDetail,
  EmployeeViewRoles,
  Goback,
  PageLayout,
  Spinner,
} from 'components';
import EmployeeViewReplacements from 'components/EmployeeReplacements/EmployeeReplacements';
import l from 'helpers/l';
import { RootState } from 'store';
import { renderItem, switchPlaces, inactive } from './utils';
import { ModalType } from 'enums/ModalEnums';
import React, { useEffect, useState } from 'react';
import { empValues } from 'enums/employeeEnums';
import { formatTime } from 'helpers/timeHelpers';
import styles from './employeeDetailsStyles';
import EmployeeViewLanguages from 'components/EmployeeLanguages/EmployeeLanguages';
import EmployeeViewExpertise from 'components/EmployeeExpertise/EmployeeExpertise';
import EmployeeViewQuestions from 'components/EmployeeQuestions/EmployeeQuestions';
import EmployeePreference from 'components/EmployeePreference/EmployeePreference';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    employeeDetail: {
      '& .MuiAccordion-root': {
        marginTop: '30px',
        marginBottom: '80px',
        borderRadius: '15px',
        // backgroundColor: '#f2f2f2',
        '&:before': {
          display: 'none',
        },
        '& .MuiAccordionSummary-root': {},
      },
    },
  }),
);

const EmployeeDetailsPage = () => {
  const classes = styles();
  const classes2 = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { employeeDetails } = useSelector(
    (state: RootState) => state.employees,
  );

  const loading = useSelector((state: RootState) => state.common.loading);
  const [value, setValue] = useState('1');

  const handleCreateUser = () => {
    dispatch(
      commonActions.showModal({
        type: ModalType.EmployeesCreate,
        data: employeeDetails,
      }),
    );
  };

  const handleDelete = () => {
    dispatch(
      commonActions.showModal({
        type: ModalType.EmployeesDelete,
        data: { employeeDetails, navigate } as any,
      }),
    );
  };

  useEffect(() => {
    dispatch(employeeActions.getDetailsPage(id));
  }, [dispatch, id]);

  const handleCopyToClipboard = async () => {
    await navigator.clipboard.writeText(employeeDetails.registrationUrl);
  };

  const openModalForEditUsername = () => {
    dispatch(
      commonActions.showModal({
        type: ModalType.ChangeUserNameModal,
        data: employeeDetails,
        //   data: {username: employeeDetails.user.username , urlSuffix: employeeDetails.user.uuid },
      }),
    );
  };

  const handleEdit = () => navigate(`/edit-employee/${id}`);

  return loading ? (
    <Spinner title="Loading, please wait..." />
  ) : (
    <PageLayout
      title={`${l('PAGES.EMPLOYEE')} ${employeeDetails?.firstName} 
      ${employeeDetails?.lastName}`}
      fullWidth={true}
      className={classes2.employeeDetail}>
      <Goback text="back to list" location={'/employees'} />
      <Accordion>
        {/* if want default expand then put defaultExpanded props to Accordion */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography>
            Employee Details:
            <span style={{ marginLeft: '10px' }}>
              ID: <b>{employeeDetails.id}</b>{' '}
            </span>
            <span style={{ margin: '0 10px' }}>
              First Name: <b>{employeeDetails.firstName}</b>{' '}
            </span>
            <span>
              Last Name: <b>{employeeDetails.lastName}</b>
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ContentWrapper mb="20px">
            <Box minWidth={800}>
              <Box mb="20px">
                <Grid container spacing={4}>
                  {Object.entries(switchPlaces(employeeDetails)).map((emp) => {
                    if (emp[0] == 'qa' || emp[0] == 'paymentDetails') {
                      return (
                        <Grid item key={emp[0]} style={{ maxWidth: '500px' }}>
                          {renderItem(emp)}
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid item key={emp[0]}>
                          {renderItem(emp)}
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Box>
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                {!employeeDetails?.user && (
                  <Button onClick={handleCreateUser} variant="contained">
                    Create user
                  </Button>
                )}
                <Button variant="contained" onClick={handleEdit}>
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleDelete}>
                  Delete
                </Button>
              </Stack>
            </Box>
          </ContentWrapper>
          {employeeDetails?.user && (
            <>
              <ContentWrapper>
                <Grid container spacing={4} alignItems={'center'}>
                  <Grid item>
                    <EmployeeDetail
                      title={'Username'}
                      text={employeeDetails?.user?.username}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={openModalForEditUsername}>
                      Change username
                    </Button>
                  </Grid>
                  <Grid item>
                    <EmployeeDetail
                      title={'Status'}
                      text={employeeDetails?.user?.status}
                    />
                  </Grid>
                  {employeeDetails?.user?.status === 'locked' && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          dispatch(
                            userActions.unlockUser(employeeDetails?.user?.uuid),
                          )
                        }>
                        Unlock
                      </Button>
                    </Grid>
                  )}
                  {inactive.includes(employeeDetails?.user?.status) &&
                    employeeDetails?.user?.username != 'admin' && (
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            dispatch(
                              userActions.inactivateUser(
                                employeeDetails?.user?.uuid,
                              ),
                            )
                          }>
                          Inactivate
                        </Button>
                      </Grid>
                    )}
                  {employeeDetails?.user?.status === 'inactive' && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          dispatch(
                            userActions.activateUser(
                              employeeDetails?.user?.uuid,
                            ),
                          )
                        }>
                        Activate
                      </Button>
                    </Grid>
                  )}
                  {employeeDetails?.user &&
                    employeeDetails?.user?.status === 'new' && (
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleCopyToClipboard()}>
                          Copy activation link
                        </Button>
                      </Grid>
                    )}
                  <Grid item>
                    <EmployeeDetail
                      title={empValues.createdAt}
                      text={formatTime(employeeDetails?.createdAt)}
                    />
                  </Grid>
                  <Grid item>
                    <EmployeeDetail
                      title={empValues.updatedAt}
                      text={formatTime(employeeDetails?.updatedAt)}
                    />
                  </Grid>
                </Grid>
              </ContentWrapper>
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Box className={classes.Tabs} sx={{ width: '100%' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(event: React.SyntheticEvent, tab: string) =>
                setValue(tab)
              }
              className={classes.tabsSingular}>
              <Tab value="1" label="Roles" />
              <Tab value="2" label="Replacements" />
              <Tab value="3" label="Languages" />
              <Tab value="4" label="Expertise" />
              <Tab value="5" label="Questions" />
              <Tab value="6" label="Preference" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <EmployeeViewRoles employeeData={employeeDetails} />
          </TabPanel>
          <TabPanel value="2">
            <EmployeeViewReplacements employeeData={employeeDetails} />
          </TabPanel>
          <TabPanel value="3">
            <EmployeeViewLanguages employeeData={employeeDetails} />
          </TabPanel>
          <TabPanel value="4">
            <EmployeeViewExpertise employeeData={employeeDetails} />
          </TabPanel>
          <TabPanel value="5">
            <EmployeeViewQuestions employeeData={employeeDetails} />
          </TabPanel>
          <TabPanel value="6">
            <EmployeePreference employeeData={employeeDetails} />
          </TabPanel>
        </TabContext>
      </Box>
    </PageLayout>
  );
};
export default EmployeeDetailsPage;
