import { FC, memo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormLabel, Typography } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps } from 'types/genericTypes';
import { Endpoint } from 'enums/APIEndpointEnum';
import { SuggestedDropdown } from 'components/SuggestedDropdown/SuggestedDropdown';

const validationSchemaAdd = Yup.object().shape({
  languageUuid: Yup.string(),
  level: Yup.string().required('Required'),
  editorLevel: Yup.string().required('Required'),
});

const defaultValues = {
  languageUuid: '',
  level: '',
  editorLevel: '',
};

type EmployeeLanguageFormProps = {
  initialValues?: any;
  title: string;
  employeeName?: string;
  employeeUuid?: string;
  onSubmit: (values: any) => void;
  values?: any;
  unassignedLanguagesValues?: any;
  levelValues?: any;
  editorLevelValues?: any;
  editMode?: boolean;
} & GenericModalProps;

const EmployeeViewLanguageForm: FC<EmployeeLanguageFormProps> = ({
  onClose,
  initialValues = defaultValues,
  employeeName,
  employeeUuid,
  title,
  onSubmit,
  unassignedLanguagesValues,
  levelValues,
  editorLevelValues,
  editMode,
}) => {
  const validationSchema = validationSchemaAdd;

  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit({
        values,
      });
    },
  });

  return (
    <SimpleFormModal
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <FormLabel>Employee</FormLabel>
      <Box sx={{ marginBottom: '30px' }}>
        <Typography fontWeight={600}>{employeeName}</Typography>
      </Box>
      {!editMode && (
        <>
          <FormLabel>Language</FormLabel>
          <SuggestedDropdown
            url={Endpoint.UserNotAssignedLanguages}
            urlSuffix={employeeUuid}
            inputId="languageUuid"
            inputValue={
              initialValues?.language
                ? {
                    label: initialValues.language.name,
                    value: initialValues.language.uuid,
                  }
                : ''
            }
            initialData={unassignedLanguagesValues}
            dropdownLabel="name"
            dropdownValue="uuid"
            dataLocation="resultData"
            error={Boolean(formik.errors.languageUuid)}
            helperText={formik.errors.languageUuid}
            formikHook={formik}
          />
        </>
      )}
      <FormLabel>Level</FormLabel>
      <TextField
        select
        name="level"
        sx={{ width: '12rem', display: 'flex' }}
        size="small"
        value={formik.values.level}
        error={Boolean(formik.errors.level)}
        helperText={formik.errors.level}
        onChange={formik.handleChange}
        autoComplete="off"
        pressEnter={formik.submitForm}>
        {levelValues}
      </TextField>
      <FormLabel>Editor Level</FormLabel>
      <TextField
        select
        name="editorLevel"
        sx={{ width: '12rem', display: 'flex' }}
        size="small"
        value={formik.values.editorLevel}
        error={Boolean(formik.errors.editorLevel)}
        helperText={formik.errors.editorLevel}
        onChange={formik.handleChange}
        autoComplete="off"
        pressEnter={formik.submitForm}>
        {editorLevelValues}
      </TextField>
    </SimpleFormModal>
  );
};

export default memo(EmployeeViewLanguageForm);
