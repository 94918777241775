import { GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import EditTable from 'components/Table/EditTable';
import { Box } from '@mui/material';
import { ModalType } from 'enums/ModalEnums';
import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { actions as commonActions } from 'store/slices/common';
import { actions as userActions } from 'store/slices/user';

interface EmployeeViewExpertiseProps {
  employeeData: any;
}

const EmployeeViewExpertise: FC<EmployeeViewExpertiseProps> = ({
  employeeData,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const { userExpertise } = useSelector((state: RootState) => state.user);
  const { employeeDetails } = useSelector(
    (state: RootState) => state.employees,
  );
  const name = `${employeeDetails.firstName} ${employeeDetails.lastName}`;

  const addEntryHandler = useCallback(() => {
    dispatch(
      commonActions.showModal({
        type: ModalType.UserExpertiseAdd,
        data: { id: employeeDetails.uuid, name } as any,
      }),
    );
  }, [dispatch, employeeDetails.uuid, name]);

  useEffect(() => {
    dispatch(userActions.getUserExpertise(employeeData.uuid));
  }, [dispatch, employeeData.uuid]);

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.UserExpertiseDelete,
          data: params.row as any,
        }),
      );
    },
    [dispatch],
  );

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.UserExpertiseEdit,
          data: { data: params.row, name } as any,
        }),
      );
    },
    [dispatch, name],
  );

  return (
    <Box style={{ margin: '-1px -10px' }}>
      {!isLoading && (
        <EditTable
          loading={isLoading}
          rows={userExpertise}
          sortedFieldIndex={0}
          columns={[
            {
              field: 'expertise',
              headerName: 'Expertise',
              width: 150,
              valueGetter: (params: GridValueGetterParams) =>
                params.row.expertise.name,
            },
            {
              field: 'level',
              headerName: 'Level',
              width: 150,
            },
            {
              field: 'description',
              headerName: 'Description',
              width: 2000,
            },
          ]}
          onAdd={addEntryHandler}
          onDelete={deleteEntryHandler}
          onEdit={editEntryHandler}
          hideFooterPagination
          hideFooter
        />
      )}
    </Box>
  );
};

export default EmployeeViewExpertise;
