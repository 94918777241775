import { Box, Breakpoint, FormLabel } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { createStyles, makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    inputRowWrapper: {
      '& > div': {
        minWidth: '300px',
      },
    },
  }),
);

type InputRowWrapper = {
  inputWidth?: string | Breakpoint | undefined;
  labelWidth?: string | Breakpoint | undefined;
  maxWidth?: string | Breakpoint | undefined;
  marginRight?: any;
  marginBottom?: any;
  sx?: any;
  label?: string;
  className?: any;
};

const InputRowWrapper: FC<PropsWithChildren<InputRowWrapper>> = ({
  children,
  inputWidth,
  maxWidth,
  labelWidth,
  label,
  marginRight = '3rem',
  marginBottom = '0rem',
  sx,
  className = ' ',
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        display: 'inline-flex',
        position: 'relative',
        justifyItems: 'space-between',
        alignItems: 'baseline',
        padding: 0,
        marginRight: `${marginRight}`,
        marginBottom: `${marginBottom}`,
        minWidth: `${inputWidth ? inputWidth : '700px'}`,
        maxWidth: `${maxWidth ? maxWidth : ''}`,
      }}
      className={className + classes.inputRowWrapper}
      {...props}>
      <FormLabel
        sx={{
          minWidth: `${labelWidth ? labelWidth : '150px'}`,
        }}>
        {label}
      </FormLabel>
      {children}
    </Box>
  );
};

export default InputRowWrapper;
