import { FC, PropsWithChildren } from 'react';
import { ContentWrapper } from 'components';
import { Box, Button, Stack } from '@mui/material';

interface FormProps {
  onCancel: () => void;
  onSubmit: () => void;
  btnSubmitLabel: string;
  btnCancelLabel: string;
  className?: string;
  sx?: any;
}

export const FloatingForm: FC<PropsWithChildren<FormProps>> = ({
  onCancel,
  onSubmit,
  btnCancelLabel,
  btnSubmitLabel,
  sx,
  children,
}) => {
  return (
    <ContentWrapper>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row wrap',
          padding: '2rem',
          ...sx,
        }}>
        {children}
      </Box>
      <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
        <Button variant="contained" onClick={onSubmit}>
          {btnSubmitLabel}
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancel}>
          {btnCancelLabel}
        </Button>
      </Stack>
    </ContentWrapper>
  );
};
