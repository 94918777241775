import {call, put, takeLatest} from 'redux-saga/effects';
import {actions as commonActions} from 'store/slices/common';

import {APIResult} from 'types/APITypes';
import {
    assignRoleToReportCategoryApi,
    getReportCategoriesApi,
    postReportCategoryApi,
    unassignFromPostReportCategoryApi,
    editReportCategoryApi, deleteReportCategoryApi,
} from "../../api/reportsAPI";
import {actions as reportCategoriesSlice} from "../../slices/reportSlices/reportCategoriesSlice";
import {PayloadAction} from "@reduxjs/toolkit";
import {genericErrorMessageHelper} from "../../../helpers/messageHelpers";

function* getReportCategories() {
    yield put(commonActions.setLoading(true));
    const {result}: APIResult<any> = yield call(getReportCategoriesApi);
    if (result?.data) {
        yield put(reportCategoriesSlice.setReportCategories(result?.data));
    }
    yield put(commonActions.setLoading(false));
}

function* postReportCategory(action: PayloadAction<any>) {
    const data = action.payload;

    const { result, error }: APIResult<any> = yield call(
        postReportCategoryApi,
        data,
    );

    if (result?.status === 201) {
        const { result }: APIResult<any> = yield call(
            getReportCategoriesApi,
        );
        if (result?.data?.length) {
            yield put(reportCategoriesSlice.setReportCategories(result?.data));
        }
        yield put(
            commonActions.snackBar({
                open: true,
                message: `You have successfully added report category`,
                severity: 'success',
            }),
        );
    }
    if (error?.status === 400) {
        yield put(
            commonActions.snackBar({
                open: true,
                message: genericErrorMessageHelper(error),
                severity: 'error',
            }),
        );
    }
    yield put(commonActions.hideModal());
}

function* assignRoleToReportCategory(action: PayloadAction<any>) {
    yield put(commonActions.setLoading(true));
    const data = action.payload;
    const { result, error }: APIResult<any> = yield call(
        assignRoleToReportCategoryApi,
        data,
    );
    if (result?.status === 200) {
        const { result }: APIResult<any> = yield call(
            getReportCategoriesApi,
        );
        if (result?.data?.length) {
            yield put(reportCategoriesSlice.setReportCategories(result?.data));
        }
        yield put(
            commonActions.snackBar({
                open: true,
                message: `You have successfully assigned role`,
                severity: 'success',
            }),
        );
    }
    if (error?.status === 400) {
        yield put(
            commonActions.snackBar({
                open: true,
                message: genericErrorMessageHelper(error),
                severity: 'error',
            }),
        );
    }
    yield put(commonActions.setLoading(false));
}

function* unassignFromPostReportCategory(action: PayloadAction<any>) {
    yield put(commonActions.setLoading(true));
    const data = action.payload;
    const { result, error }: APIResult<any> = yield call(
        unassignFromPostReportCategoryApi,
        data,
    );
    if (result?.status === 200) {
        const { result }: APIResult<any> = yield call(
            getReportCategoriesApi,
        );
        if (result?.data?.length) {
            yield put(reportCategoriesSlice.setReportCategories(result?.data));
        }
        yield put(
            commonActions.snackBar({
                open: true,
                message: `You have successfully unassigned role`,
                severity: 'success',
            }),
        );
    }
    if (error?.status === 400) {
        yield put(
            commonActions.snackBar({
                open: true,
                message: genericErrorMessageHelper(error),
                severity: 'error',
            }),
        );
    }
    yield put(commonActions.setLoading(false));
}

function* editReportCategory(action: PayloadAction<any>) {
    const data = action.payload;

    const { result, error }: APIResult<any> = yield call(
        editReportCategoryApi,
        data.urlSuffix,
        data.data,
    );

    if (result?.status === 200) {
        const { result }: APIResult<any> = yield call(
            getReportCategoriesApi,
        );
        if (result?.data?.length) {
            yield put(reportCategoriesSlice.setReportCategories(result?.data));
        }
        yield put(
            commonActions.snackBar({
                open: true,
                message: `You have successfully edited report category`,
                severity: 'success',
            }),
        );
    }
    if (error?.status === 400) {
        yield put(
            commonActions.snackBar({
                open: true,
                message: genericErrorMessageHelper(error),
                severity: 'error',
            }),
        );
    }
    yield put(commonActions.hideModal());
}

function* deleteReportCategory(action: PayloadAction<any>) {
    const data = action.payload;

    const { result, error }: APIResult<any> = yield call(
        deleteReportCategoryApi,
        data.urlSuffix,
    );

    if (result?.status === 200) {
        const { result }: APIResult<any> = yield call(
            getReportCategoriesApi,
        );
        if (result?.data?.length) {
            yield put(reportCategoriesSlice.setReportCategories(result?.data));
        }
        yield put(
            commonActions.snackBar({
                open: true,
                message: `You have successfully edited report category`,
                severity: 'success',
            }),
        );
    }
    if (error?.status === 400) {
        yield put(
            commonActions.snackBar({
                open: true,
                message: genericErrorMessageHelper(error),
                severity: 'error',
            }),
        );
    }
    yield put(commonActions.hideModal());
}

export default function* ReportCategoriesWatcher() {
    yield takeLatest(reportCategoriesSlice.getReportCategories.type, getReportCategories);
    yield takeLatest(reportCategoriesSlice.postReportCategory.type, postReportCategory);
    yield takeLatest(reportCategoriesSlice.assignRoleToReportCategory.type, assignRoleToReportCategory);
    yield takeLatest(reportCategoriesSlice.unassignFromPostReportCategory.type, unassignFromPostReportCategory);
    yield takeLatest(reportCategoriesSlice.editReportCategory.type, editReportCategory);
    yield takeLatest(reportCategoriesSlice.deleteReportCategory.type, deleteReportCategory);

}
