import React, { FC, memo } from 'react';
import {
  Checkbox,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { useFormik } from 'formik';
import Table from '@mui/material/Table';
import {
  PmCheckListItemTypes,
  PmCheckListTypes,
} from '../../types/PmCheckListTypes';

interface PmCheckListFormProps {
  onClose: () => void;
  title: string;
  onSubmit: (values: PmCheckListTypes) => void;
  initialValues: any;
}

const PmCheckListForm: FC<PmCheckListFormProps> = ({
  onClose,
  title,
  onSubmit,
  initialValues,
}) => {
  const { setValues, ...formik } = useFormik({
    initialValues,
    onSubmit: (values: PmCheckListTypes) => {
      onSubmit(values);
    },
  });

  return (
    <SimpleFormModal
      style={{ width: '60%' }}
      onClose={onClose}
      onSubmit={formik.submitForm}
      title={title}>
      <TableContainer sx={{ maxHeight: '30rem' }}>
        <Table stickyHeader aria-label="simple table" size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell width={10} sx={{ fontWeight: 900 }} align="left">
                Id
              </TableCell>
              <TableCell sx={{ fontWeight: 900 }} align="left">
                Description
              </TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {formik.values.items.map(
              (item: PmCheckListItemTypes, index: number) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {item.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <Checkbox
                      defaultChecked={initialValues.isChecked}
                      id={`items.${[index]}.isChecked`}
                      checked={item.isChecked}
                      onChange={formik.handleChange}
                    />
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </SimpleFormModal>
  );
};

export default memo(PmCheckListForm);
