import { put, call, takeLatest } from 'redux-saga/effects';
import { actions as commonActions } from '../slices/common';
import { actions as timeZoneActions } from '../slices/timeZonesSlice';
import { TimeZoneEntry } from 'types/TimeZoneTypes';
// import { formatTimeZone } from 'helpers/timeHelpers';
import { PayloadAction } from '@reduxjs/toolkit';
import { APIResult } from 'types/APITypes';
import * as api from '../api/timeZonesAPI';
import { genericErrorMessageHelper } from '../../helpers/messageHelpers';

function* sendTimeZoneFilters(action: PayloadAction<string>) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(api.sendTimeZoneFilters, params);

  if (result?.data) {
    yield put(timeZoneActions.setTimeZones(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* getTimeZones() {
  yield put(commonActions.setLoading(true));

  const { error }: APIResult<TimeZoneEntry> = yield call(
    api.getTimeZones,
  );

  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<TimeZoneEntry> = yield call(api.getTimeZones);
    if (result?.data) {
      yield put(timeZoneActions.setTimeZones(result.data));
    }
  }

  yield put(commonActions.setLoading(false));
}

export default function* timeZonesWatcher() {
  yield takeLatest(
    timeZoneActions.sendTimeZoneFilters.type,
    sendTimeZoneFilters,
  );
  yield takeLatest(timeZoneActions.getTimeZones.type, getTimeZones);
}
