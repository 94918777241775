import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

export const getContactPersonsApi = () => {
  return apiCall({
    url: Endpoint.ContactPersons,
    method: Method.Get,
  });
};

export const AddContactPersonsApi = (data: any) => {
  return apiCall({
    url: Endpoint.ContactPersons,
    method: Method.Post,
    data,
  });
};

export const getContactPersonByUuid = (data: any) => {
  return apiCall({
    url: Endpoint.ContactPersons,
    method: Method.Get,
    data,
  });
};

export const deleteContactPersonApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.ContactPersons,
    method: Method.Delete,
    urlSuffix,
  });

export const editContactPersonApi = (uuid: string, data: any) => {
  return apiCall({
    url: Endpoint.ContactPersons,
    urlSuffix: uuid,
    method: Method.Put,
    data,
  });
};

export const sendContactPersonApi = (params: string) =>
  apiCall({
    url: Endpoint.ContactPersons,
    method: Method.Get,
    params,
  });
