import { createSlice } from '@reduxjs/toolkit';
import { PaginationMeta } from 'types/genericTypes';

export interface OrderLinesDeliveryProps {
  items: any[];
  meta: PaginationMeta;
  filters: any;
}

const initialState: OrderLinesDeliveryProps = {
  items: [],
  meta: {},
  filters: {},
};

export const OrderLinesDeliverySlice = createSlice({
  name: 'orderLinesDelivery',
  initialState,
  reducers: {
    getOrderLinesDelivery: (state) => state,
    editOrderLinesDelivery: (state) => state,
    setOrderLinesDelivery: (state, action: any) => {
      state.items = action.payload.items;
      state.meta = action.payload.meta;
    },
    sendOrderLinesDeliveryFilters: (state) => state,
    setOrdeLinesDeliveryFilters: (state, action: any) => {
      state.filters = action.payload;
    },
    getOrdeLinesDeliveryFilters: (state) => state.filters,
  },
});
export const actions: any = OrderLinesDeliverySlice.actions;
export default OrderLinesDeliverySlice.reducer;
