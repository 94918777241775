import { useEffect, useCallback, memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as commonActions } from 'store/slices/common';
import { actions as projectsActions } from 'store/slices/projectsSlice';
import {
  GridRowParams,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import l from 'helpers/l';
import { PageLayout, EditTable, Filters, TextField } from 'components';
import { FormLabel, Grid } from '@mui/material';
import { RootState } from 'store';
import { createMenuItems } from 'helpers/dropdown';
import { useFormik } from 'formik';
import { formatFilters, formatSortOrderBy } from 'helpers/filters';
import { isEmpty } from 'lodash';
import { ModalType } from 'enums/ModalEnums';
import moment from 'moment';

const initialValues: any = {
  keyword: '',
  contactPersonUuid: '',
};

const ProjectsPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.common.loading);
  const { items, meta } = useSelector((state: RootState) => state.projects);
  const [dropdownValues, setDropdownValues] = useState({});
  const [sort, setSort] = useState({});

  const contactsDropdown = useSelector(
    (state: RootState) => state.projects?.contactPersons?.items,
  )?.map(({ nick, uuid }: any) => ({ label: nick, value: uuid }));

  const { setValues, ...formik } = useFormik({
    initialValues,
    onSubmit: (values: any) => {
      const formatValues = formatFilters(values);
      setDropdownValues(() => {
        return { ...formatValues };
      });
      if (!isEmpty(formatValues)) {
        const params = { ...formatValues, ...sort };
        dispatch(projectsActions.sendProjectsFilters(params));
      } else {
        dispatch(projectsActions.sendProjectsFilters(sort));
      }
    },
  });

  const addEntryHandler = useCallback(() => {
    dispatch(commonActions.showModal({ type: ModalType.ProjectsAdd }));
  }, [dispatch]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.ProjectsEdit,
          data: params.row.uuid,
        }),
      );
    },
    [dispatch],
  );

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.ProjectsDelete,
          data: params.row.name.toString(),
        }),
      );
    },
    [dispatch],
  );

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: formatSortOrderBy(item.field),
        order: item?.sort?.toUpperCase(),
      }))[0];
      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });
      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
        ...dropdownValues,
      };
      dispatch(projectsActions.sendProjectsFilters(params));
    },
    [dispatch, dropdownValues],
  );

  const handlePage = useCallback(
    (page: number) => {
      const params = { ...dropdownValues, ...sort, page: page + 1 };
      dispatch(projectsActions.sendProjectsFilters(params));
    },
    [dispatch, dropdownValues, sort],
  );

  const onReset = useCallback(() => {
    setValues(initialValues);
    setDropdownValues({});
    dispatch(projectsActions.sendProjectsFilters(sort));
  }, [dispatch, setValues, sort]);

  useEffect(() => {
    dispatch(projectsActions.getContactPersons());
    dispatch(projectsActions.getProjects());
  }, [dispatch]);

  return (
    <PageLayout title={l('PAGES.PROJECTS')} fullWidth={true}>
      <Filters handleFilters={formik.submitForm} onReset={onReset}>
        <Grid
          container
          spacing={2}
          columns={{ xs: 4, md: 20 }}
          alignItems="center">
          <Grid item xs={4} md={8}>
            <FormLabel>Keyword</FormLabel>
            <TextField
              autoHeight
              fullWidth
              name="keyword"
              size="small"
              value={formik.values.keyword}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.keyword)}
            />
          </Grid>
          <Grid item xs={4} md={8}>
            <FormLabel>Contact Person</FormLabel>
            <TextField
              select
              autoHeight
              fullWidth
              name="contactPersonUuid"
              size="small"
              value={formik.values.contactPersonUuid}
              onChange={formik.handleChange}>
              {contactsDropdown?.length && createMenuItems(contactsDropdown)}
            </TextField>
          </Grid>
        </Grid>
      </Filters>
      <EditTable
        rows={items}
        columns={[
          {
            field: 'name',
            headerName: 'Project Name',
            width: 200,
          },
          {
            field: 'contactPerson',
            headerName: 'Contact Person',
            valueGetter: (params: GridValueGetterParams) =>
              params.row.contactPerson.nick,
            width: 200,
          },
          {
            field: 'createdAt',
            headerName: 'Created At',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row.createdAt).format('DD.MM.YYYY hh:mm:ss'),
          },
          {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row.updatedAt).format('DD.MM.YYYY hh:mm:ss'),
          },
        ]}
        loading={loading}
        onAdd={addEntryHandler}
        onEdit={editEntryHandler}
        onDelete={deleteEntryHandler}
        editColumnWidth={130}
        sortedFieldIndex={1}
        onSortModelChange={handleSort}
        pagination
        page={meta?.currentPage - 1 || 0}
        rowCount={meta?.totalItems}
        onPageChange={handlePage}
        pageSize={meta?.itemsPerPage}
        rowsPerPageOptions={[meta.itemsPerPage || 10]}
        paginationMode="server"
        sortingMode="server"
      />
    </PageLayout>
  );
};

export default memo(ProjectsPage);
