import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/orderLineStatusesSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  addOrderLineStatusesApi,
  editOrderLineStatusesApi,
  getOrderLineStatusesApi,
  getOrderLineStatusesManageApi,
  deleteOrderLineStatusesApi,
} from 'store/api/orderLineStatusesAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  OrderLineStatusActionEdit,
  OrderLineStatusActionEntry,
  OrderLineStatusType,
} from 'types/orderLineStatusesTypes';
import { GenericIdNameType } from 'types/genericTypes';
import { genericErrorMessageHelper } from '../../helpers/messageHelpers';
import { APIResult } from 'types/APITypes';

function* getOrderLineStatuses() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<OrderLineStatusType[]> = yield call(
    getOrderLineStatusesApi,
  );
  if (result?.data?.length) {
    yield put(actions.setOrderLineStatuses(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* getOrderLineStatusesManage() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<OrderLineStatusType[]> = yield call(
    getOrderLineStatusesManageApi,
  );
  if (result?.data?.length) {
    yield put(actions.setOrderLineStatuses(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addOrderLineStatuses(
  action: PayloadAction<OrderLineStatusActionEntry>,
) {
  const data = action.payload;
  const { result, error }: APIResult<OrderLineStatusActionEntry> = yield call(
    addOrderLineStatusesApi,
    data,
  );
  if (result?.status === 201) {
    const { result }: APIResult<OrderLineStatusType[]> = yield call(
      getOrderLineStatusesManageApi,
    );
    if (result?.data?.length) {
      yield put(actions.setOrderLineStatuses(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.name} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.name} already exists`,
        severity: 'error',
      }),
    );
  }
}

function* deleteOrderLineStatuses(action: PayloadAction<OrderLineStatusType>) {
  const { uuid, name } = action.payload;
  const { result, error }: APIResult<GenericIdNameType> = yield call(
    deleteOrderLineStatusesApi,
    uuid,
  );
  if (error?.status === 500) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'This item can not be deleted due to existing relations',
        severity: 'error',
      }),
    );
  } else if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  }
  if (result?.status === 200) {
    const { result }: APIResult<OrderLineStatusType[]> = yield call(
      getOrderLineStatusesManageApi,
    );
    if (result?.data?.length) {
      yield put(actions.setOrderLineStatuses(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
  }
  yield put(commonActions.hideModal());
}

function* editOrderLineStatuses(
  action: PayloadAction<OrderLineStatusActionEdit>,
) {
  const { data, uuid } = action.payload;
  const { result, error }: APIResult<OrderLineStatusType> = yield call(
    editOrderLineStatusesApi,
    {
      uuid,
      data,
    },
  );
  if (result?.status === 200) {
    const { result }: APIResult<OrderLineStatusType[]> = yield call(
      getOrderLineStatusesManageApi,
    );
    if (result?.data?.length) {
      yield put(actions.setOrderLineStatuses(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${data.name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Order line status name is taken',
        severity: 'error',
      }),
    );
  }
}

export default function* orderLineStatusesWatcher() {
  yield takeLatest(actions.getOrderLineStatuses.type, getOrderLineStatuses);
  yield takeLatest(actions.getOrderLineStatusesManage.type, getOrderLineStatusesManage);
  yield takeLatest(actions.addOrderLineStatuses.type, addOrderLineStatuses);
  yield takeLatest(
    actions.deleteOrderLineStatuses.type,
    deleteOrderLineStatuses,
  );
  yield takeLatest(actions.editOrderLineStatuses.type, editOrderLineStatuses);
}
