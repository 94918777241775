import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PageLayout from 'components/PageLayout/PageLayout';
import l from 'helpers/l';
import EditTable from 'components/Table/EditTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { actions } from 'store/slices/checklistsContactPersonsSlice';
import { ModalType } from 'enums/ModalEnums';
import { actions as commonActions } from 'store/slices/common';
import { GridRowParams, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { Filters, TextField } from 'components';
import { FormLabel, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { formatFilters } from 'helpers/filters';
import { isEmpty } from 'lodash';
import { Typography } from '@mui/material';
import { createMenuItems } from 'helpers/dropdown';
import { actions as articleTypesActions } from 'store/slices/articleTypesSlice';
import { actions as contactPersonsActions } from 'store/slices/contactPersonsSlice';
import { actions as rolesActions } from 'store/slices/user';

const initialValues = {
  keyword: '',
  roleCode: '',
  articleTypeUuid: '',
  contactPersonUuid: '',
};

const ChecklistsContactPersons: FC = () => {
  const [sort, setSort] = useState({});
  const [dropdownValues, setDropdownValues] = useState({});

  const modalChange = useSelector((state: RootState) => state.common.modal);
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const { items, meta } = useSelector(
    (state: RootState) => state.checklistsContactPersons,
  );

  const dispatch = useDispatch();

  const ref = useRef({
    articleTypeUuid: '',
    roleCode: '',
    contactPersonUuid: '',
    contactPersonNick: '',
  });

  const { setValues, ...formik } = useFormik({
    initialValues,
    onSubmit: (values: any) => {
      ref.current.articleTypeUuid = values.articleTypeUuid;
      ref.current.roleCode = values.roleCode;
      ref.current.contactPersonUuid = values.contactPersonUuid;
      const formatValues = formatFilters(values);
      setDropdownValues(() => {
        return { ...formatValues };
      });
      if (!isEmpty(formatValues)) {
        const params = { ...formatValues, ...sort };
        dispatch(actions.sendChecklistsContactPersonsFilters(params));
      } else {
        dispatch(actions.sendChecklistsContactPersonsFilters(sort));
      }
    },
  });

  const addEntryHandler = useCallback(() => {
    return dispatch(
      commonActions.showModal({
        type: ModalType.ChecklistsContactPersonsAdd,
        data: {
          articleTypeUuid: ref.current.articleTypeUuid,
          roleCode: ref.current.roleCode,
          contactPersonUuid: ref.current.contactPersonUuid,
          contactPersonNick: ref.current.contactPersonNick,
        },
      }),
    );
  }, [dispatch]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.ChecklistsContactPersonsEdit,
          data: params.id.toString(),
        }),
      );
    },
    [dispatch],
  );

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.ChecklistsContactPersonsDelete,
          data: params.id.toString(),
        }),
      );
    },
    [dispatch],
  );

  const handlePage = useCallback(
    (page: number) => {
      const params = { ...dropdownValues, ...sort, page: page + 1 };
      dispatch(actions.sendChecklistsContactPersonsFilters(params));
    },
    [dispatch, sort, dropdownValues],
  );

  const onReset = useCallback(() => {
    ref.current.articleTypeUuid = '';
    ref.current.roleCode = '';
    ref.current.contactPersonUuid = '';
    setValues(initialValues);
    setDropdownValues({});
    dispatch(actions.sendChecklistsContactPersonsFilters(sort));
  }, [dispatch, setValues, sort]);

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: item.field,
        order: item?.sort?.toUpperCase(),
      }))[0];
      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });
      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
        ...dropdownValues,
      };

      dispatch(actions.sendChecklistsContactPersonsFilters(params));
    },
    [dispatch, dropdownValues],
  );

  const articleTypesDropdown = useSelector(
    (state: RootState) => state.articleTypes.articleTypes,
  ).map((articleTypes) => ({
    label: articleTypes.name,
    value: articleTypes.uuid,
  }));

  const rolesDropdown = useSelector((state: RootState) => state.user.roles).map(
    (roles) => ({
      label: roles.name,
      value: roles.code,
    }),
  );

  const contactPersonsDropdown = useSelector(
    (state: RootState) => state.contactPersons.items,
  ).map((items) => ({
    label: items.nick,
    value: items.uuid,
  }));

  const allItem = [{ value: '', label: '--all' }];

  useEffect(() => {
    dispatch(actions.getChecklistsContactPersons());
    dispatch(rolesActions.getRoles());
    dispatch(articleTypesActions.getArticleTypes());
    dispatch(contactPersonsActions.getContactPersonsList());
  }, [dispatch]);

  useEffect(() => {
    const { keyword, roleCode, articleTypeUuid, contactPersonUuid } =
      formik.values;
    if (
      keyword !== '' ||
      roleCode !== '' ||
      articleTypeUuid !== '' ||
      contactPersonUuid !== ''
    ) {
      const params = {
        ...sort,
        ...dropdownValues,
      };

      dispatch(actions.sendChecklistsContactPersonsFilters(params));
    } else {
      dispatch(actions.getChecklistsContactPersons());
    }
  }, [modalChange]);

  return (
    <PageLayout title={l('PAGES.CHECKLISTS_CONTACT_PERSONS')} fullWidth={true}>
      <Filters handleFilters={formik.submitForm} onReset={onReset}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <FormLabel>{l('KEYWORD')}</FormLabel>
            <TextField
              autoHeight
              fullWidth
              name="keyword"
              size="small"
              value={formik.values.keyword}
              onChange={formik.handleChange}
              autoComplete="off"
              error={formik.touched.keyword && Boolean(formik.errors.keyword)}
            />
          </Grid>
          <Grid item>
            <FormLabel>{l('ARTICLE_TYPE')}</FormLabel>
            <TextField
              select
              name="articleTypeUuid"
              sx={{ width: '12rem', display: 'flex' }}
              size="small"
              value={formik.values.articleTypeUuid}
              onChange={formik.handleChange}
              autoComplete="off"
              error={
                formik.touched.articleTypeUuid &&
                Boolean(formik.errors.articleTypeUuid)
              }
              helperText={formik.errors.articleTypeUuid}
              pressEnter={formik.submitForm}>
              {createMenuItems(allItem.concat(articleTypesDropdown))}
            </TextField>
          </Grid>
          <Grid item>
            <FormLabel>{l('ROLE')}</FormLabel>
            <TextField
              select
              name="roleCode"
              sx={{ width: '12rem', display: 'flex' }}
              size="small"
              value={formik.values.roleCode}
              onChange={formik.handleChange}
              autoComplete="off"
              error={formik.touched.roleCode && Boolean(formik.errors.roleCode)}
              helperText={formik.errors.roleCode}
              pressEnter={formik.submitForm}>
              {createMenuItems(allItem.concat(rolesDropdown))}
            </TextField>
          </Grid>
          <Grid item>
            <FormLabel>{l('CONTACT_PERSON')}</FormLabel>
            <TextField
              select
              name="contactPersonUuid"
              sx={{ width: '12rem', display: 'flex' }}
              size="small"
              value={formik.values.contactPersonUuid}
              onChange={formik.handleChange}
              autoComplete="off"
              error={
                formik.touched.contactPersonUuid &&
                Boolean(formik.errors.contactPersonUuid)
              }
              helperText={formik.errors.contactPersonUuid}
              pressEnter={formik.submitForm}>
              {createMenuItems(allItem.concat(contactPersonsDropdown))}
            </TextField>
          </Grid>
        </Grid>
      </Filters>
      <EditTable
        getRowId={(row) => row.uuid}
        loading={isLoading}
        columns={[
          {
            field: 'articleType',
            headerName: l('ARTICLE_TYPE'),
            minWidth: 150,
            renderCell: (params: any) => {
              return <Typography>{params?.value?.name}</Typography>;
            },
          },
          {
            field: 'role',
            headerName: l('ROLE'),
            minWidth: 300,
            renderCell: (params: any) => {
              return <Typography>{params?.value?.name}</Typography>;
            },
          },
          {
            field: 'contactPerson',
            headerName: l('CONTACT_PERSON'),
            minWidth: 200,
            renderCell: (params: any) => {
              return <Typography>{params?.value?.nick}</Typography>;
            },
          },
          {
            field: 'checklist',
            headerName: l('CHECKLIST'),
            flex: 1,
            renderCell: (params: any) => {
              return <Typography>{params?.value?.name}</Typography>;
            },
          },
        ]}
        rows={items}
        onAdd={addEntryHandler}
        onEdit={editEntryHandler}
        onDelete={deleteEntryHandler}
        sortedFieldIndex={0}
        onSortModelChange={handleSort}
        pagination
        page={meta.currentPage - 1 || 0}
        rowCount={meta?.totalItems}
        onPageChange={handlePage}
        pageSize={meta?.itemsPerPage}
        rowsPerPageOptions={[meta?.itemsPerPage]}
        paginationMode="server"
        sortingMode="server"
      />
    </PageLayout>
  );
};

export default memo(ChecklistsContactPersons);
