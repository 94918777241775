import { makeStyles } from '@material-ui/core/styles';
import colors from 'styles/colors';
const styles = makeStyles((theme) => {
  return {
    Modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      bgcolor: 'background.paper',
      borderRadius: '20px',
      boxShadow: '24',
      p: '4',
    },
    NonEditable: {
      fontFamily: theme.typography.fontFamily,
      padding: '7px 0',
      fontWeight: 700,
    },
    DropDown: {
      '&.MuiFormControl-root': {
        height: 'auto',
        border: 'none',
        flexDirection: 'row',
        gap: '1.5rem',
        alignItems: 'center',
      },
    },
    //-MuiInput-root
    Test: {
      width: '290px',
      height: 'unset !important',
    },
    PermissionBox: {
      fontSize: '13px',
      backgroundColor: colors.mainBackground,
      borderRadius: '10px',
      paddingLeft: '10px',
      margin: '0 9px 7px 0',
    },
  };
});

export default styles;
