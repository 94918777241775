import { FC, memo, useCallback, useEffect } from 'react';
import { actions as questionsActions } from 'store/slices/questionsSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import QuestionsForm from 'components/Forms/QuestionsFrom';
import { RootState } from 'store';
import { QuestionEntry } from 'types/questionsTypes';

const QuestionsEditModal: FC<GenericModalProps> = (props) => {
  const dispatch = useDispatch();

  const entry = useSelector((state: RootState) =>
    props.data
      ? state.questions?.items?.find((entry: any) => entry?.name === props.data)
      : null,
  );

  const submitHandler = useCallback(
    (values: QuestionEntry) =>
      dispatch(questionsActions.editQuestion({ ...values })),
    [dispatch],
  );

  useEffect(() => {
    dispatch(questionsActions.getQuestions());
  }, [dispatch]);

  return entry ? (
    <QuestionsForm
      title={l('EDIT_QUESTION')}
      onSubmit={submitHandler}
      initialValues={entry}
      {...props}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(QuestionsEditModal);
