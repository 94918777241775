import axios from 'axios';
import { Endpoint} from 'enums/APIEndpointEnum';
import authHeaders from 'helpers/auth';

export const ApiService = {
  endpoints: {
    hasUnseenMessagesForGivenOrderLine: async (
      auth: any,
      urlSuffix: string,
    ) => {
      const config: any = {
        baseURL: process.env.REACT_APP_API,
        url: `${Endpoint.UnseenMessagesHasUnseenMessages}/${urlSuffix}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          ...(auth && authHeaders()),
        },
      }
      return await axios(config)
        .then((res) => res.data)
        .catch((e) => e.response);
    },
  },
};
