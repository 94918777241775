import { FC, memo, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { PageTitle } from 'components';
import styles from 'styles/main';

interface PageLayoutProps {
  title: string;
  fullWidth?: boolean;
  messageScreen?: boolean;
  style?: any;
  className?: any;
  urlHistory?: any;
}

const PageLayout: FC<PropsWithChildren<PageLayoutProps>> = ({
  title,
  fullWidth = false,
  messageScreen,
  children,
  className,
  urlHistory,
  ...props
}) => {
  const { wrapper, globalPaddings } = styles();
  const componentStyles = !fullWidth ? wrapper : globalPaddings;
  const stylesBox = messageScreen && {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  };
  return (
    <Box
      className={componentStyles + ' layoutWrapper ' + className}
      style={{ height: messageScreen ? '99%' : '' }}>
      <Box
        className="titlePageBox"
        padding={messageScreen ? '0' : '20px 0 50px'}
        style={stylesBox}
        {...props}>
        <PageTitle title={title} showBackButton={messageScreen} urlHistory={urlHistory}/>
        {children}
      </Box>
    </Box>
  );
};

export default memo(PageLayout);
