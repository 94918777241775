import { call, put, takeLatest } from 'redux-saga/effects';
import {
  actions as orderLinesDelivery,
  actions,
} from 'store/slices/orderLinesDeliverySlice';
import { actions as commonActions } from 'store/slices/common';
import {
  editOrderLinesDeliveryApi,
  getOrderLinesDeliveryApi,
  sendOrderLinesDeliveryApi,
} from 'store/api/orderLinesDeliveryAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { APIResult } from 'types/APITypes';
import { GenericIdNameType } from '../../types/genericTypes';
import { useDispatch } from '../../hooks/redux';
import { useSelector } from 'react-redux';

function* getOrderLinesDelivery(action: any) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));
  const { result }: APIResult<any> = yield call(
    getOrderLinesDeliveryApi,
    params,
  );
  if (result?.data) {
    yield put(actions.setOrderLinesDelivery(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* sendOrderLinesDeliveryFilters(action: any) {
  const params = action.payload;
  yield put(actions.setOrdeLinesDeliveryFilters(params));

  yield put(commonActions.setLoading(true));

  const { result } = yield call(sendOrderLinesDeliveryApi, params);

  if (result?.data) {
    yield put(actions.setOrderLinesDelivery(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* editOrderLinesDelivery(action: PayloadAction<any>) {
  const data = action.payload.payload;
  const params = action.payload.filters;

  const cloneData = { ...data };
  const orderId = data.orderUuid;
  delete cloneData.orderUuid;

  const { result: resultEdit, error }: APIResult<GenericIdNameType> =
    yield call(editOrderLinesDeliveryApi, cloneData, orderId);
  if (resultEdit?.status === 200) {
    const { result }: APIResult<any> = yield call(
      getOrderLinesDeliveryApi,
      params,
    );

    if (result?.data) {
      yield put(actions.setOrderLinesDelivery(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited`,
        severity: 'info',
      }),
    );
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  }
  yield put(commonActions.hideModal());
}

export default function* OrderLinesDeliveryWatcher() {
  yield takeLatest(actions.getOrderLinesDelivery.type, getOrderLinesDelivery);
  yield takeLatest(
    actions.sendOrderLinesDeliveryFilters.type,
    sendOrderLinesDeliveryFilters,
  );
  yield takeLatest(actions.editOrderLinesDelivery.type, editOrderLinesDelivery);
}
