import ErrorHandler from 'components/ErrorHandler/ErrorHandler';
import useCheckPermissions from 'hooks/useCheckPermissions';
import { FC, PropsWithChildren } from 'react';
import { GuardType } from 'types/guardTypes';

export type GuardProps = GuardType & {
  fallback?: JSX.Element;
};

const Guard: FC<PropsWithChildren<any>> = ({ children, allow, deny }) => {
  const hasPermissions = useCheckPermissions(allow, deny);

  return hasPermissions ? <>{children}</> : <ErrorHandler />;
};

export default Guard;
