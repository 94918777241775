import { Box, Button, Paper, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { FC, PropsWithChildren } from 'react';

interface FiltersProps {
  handleFilters?: () => void;
  onReset?: () => void;
}

const Filters: FC<PropsWithChildren<FiltersProps>> = ({
  children,
  handleFilters,
  onReset,
}) => {
  return (
    <Box mb="10px">
      <Paper style={{ borderRadius: '20px', padding: '20px 20px 0 20px' }}>
        <Box style={{ overflowX: 'auto', paddingBottom: '20px' }}>
          <Box minWidth={'100%'} sx={{ display: 'flex', position: 'relative' }}>
            <Box
              className="inputsButtons"
              sx={{ display: 'flex', position: 'relative' }}>
              <Box
                className="filterInputs"
                sx={{ display: 'flex', position: 'relative' }}
                minWidth={'60%'}>
                {children}
              </Box>
              <Box
                className="submitResetButtons"
                display="flex"
                alignItems="center"
                position="relative">
                <Button variant="contained" onClick={handleFilters}>
                  Go
                </Button>
                <IconButton
                  color="secondary"
                  onClick={onReset}
                  component="button">
                  <CloseIcon fontSize="large" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
export default Filters;
