import { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import { GenericCodeNameType } from 'types/genericTypes';
import { actions as userActions } from '../../../store/slices/user';
import { RootState } from '../../../store';
import UserLanguageAddForm from 'components/Forms/UserLanguageAddForm';
import { createMenuItems } from 'helpers/dropdown';

const UserLanguageEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();
  const { userLanguages } = useSelector((state: RootState) => state.user);

  const entry = useSelector((state) =>
    data
      ? userLanguages.find((entry: any) => entry?.uuid === data.rowId)
      : null,
  );

  const submitHandler = useCallback(
    (values: GenericCodeNameType) => {
      dispatch(
        userActions.editUserLanguage({
          ...values,
          employeeId: data.rowId,
        }),
      );
    },
    [dispatch, entry?.uuid],
  );

  const userUnassignedLanguages = useSelector(
    (state: RootState) => state.user.userUnassignedLanguages,
  ).map((lang: any) => lang.name);

  const userUnassignedLanguagesDropdown = useSelector(
    (state: RootState) => state.user.userUnassignedLanguages,
  )
    .map(({ name, uuid }) => {
      return { label: name, value: uuid };
    })
    .filter((role) => {
      if (userUnassignedLanguages.includes(role.label)) return role;
      return null;
    });

  const unassignedLanguagesValues = createMenuItems(
    userUnassignedLanguagesDropdown,
  );

  const levelDropdown = [
    {
      label: 'High',
      value: 'high',
    },
    {
      label: 'Low',
      value: 'low',
    },
  ];

  const editorLevelDropdown = [
    {
      label: 'High',
      value: 'high',
    },
    {
      label: 'Low',
      value: 'low',
    },
  ];

  const levelValues = createMenuItems(levelDropdown);
  const editorLevelValues = createMenuItems(editorLevelDropdown);

  useEffect(() => {
    dispatch(userActions.getUserUnassignedLanguages(data.employeeId));
  }, [dispatch, data.employeeId]);

  return entry ? (
    <UserLanguageAddForm
      editMode
      title="Employee Language Edit"
      employeeName={data.name}
      onSubmit={submitHandler}
      employeeUuid={data.employeeId}
      unassignedLanguagesValues={unassignedLanguagesValues}
      levelValues={levelValues}
      editorLevelValues={editorLevelValues}
      initialValues={{
        language: entry?.language,
        level: entry?.level,
        editorLevel: entry?.editorLevel,
      }}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(UserLanguageEditModal);
