import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { CurrencyEntry } from 'types/currencyTypes';

import apiCall from './apiCall';

export const sendCurrencyFilters = (params: string) =>
  apiCall({
    url: Endpoint.Currencies,
    method: Method.Get,
    params,
  });

export const getCurrencies = () =>
  apiCall({
    url: Endpoint.Currencies,
    method: Method.Get,
  });

export const addCurrency = (data: Omit<CurrencyEntry, 'uuid'>) =>
  apiCall({
    url: Endpoint.Currencies,
    method: Method.Post,
    data,
  });

export const editCurrency = (data: CurrencyEntry) =>
  apiCall({
    url: Endpoint.Currencies,
    method: Method.Put,
    data: { newCode: data.code, newName: data.name },
    urlSuffix: data.uuid,
  });

export const deleteCurrencies = (uuid: string) =>
  apiCall({
    url: Endpoint.Currencies,
    method: Method.Delete,
    urlSuffix: uuid,
  });
