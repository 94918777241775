import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationMeta } from 'types/genericTypes';

interface IProjectsRate {
  items: any[];
  meta: PaginationMeta;
}
const initialState: IProjectsRate = {
  items: [],
  meta: {},
};

export const projectsRateSlice = createSlice({
  name: 'projectsRate',
  initialState,
  reducers: {
    getProjectsRates: (state) => state,
    setProjectsRates: (state, action: PayloadAction<any>) => {
      state.items = action?.payload?.items;
      state.meta = action.payload.meta;
    },
    addProjectsRate: (state) => state,
    editProjectsRate: (state) => state,
    deleteProjectsRate: (state) => state,
  },
});
export const actions: any = projectsRateSlice.actions;
export default projectsRateSlice.reducer;
