import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { GenericIdNameType } from 'types/genericTypes';
import apiCall from './apiCall';

const getTonesOfVoiceApi = (auth: boolean) =>
  apiCall({
    url: Endpoint.Voices,
    method: Method.Get,
    auth,
  });

const addTonesOfVoiceApi = (data: GenericIdNameType, auth: boolean) =>
  apiCall({
    url: Endpoint.Voices,
    method: Method.Post,
    data,
    auth,
  });

const deleteTonesOfVoiceApi = (urlSuffix: string, auth: boolean) =>
  apiCall({
    url: Endpoint.Voices,
    method: Method.Delete,
    urlSuffix,
    auth,
  });

const editTonesOfVoiceApi = (data: GenericIdNameType, auth: boolean) => {
  return apiCall({
    url: Endpoint.Voices,
    method: Method.Put,
    data,
    auth,
  });
};

export {
  addTonesOfVoiceApi,
  getTonesOfVoiceApi,
  editTonesOfVoiceApi,
  deleteTonesOfVoiceApi,
};
