import { put, call, takeLatest } from 'redux-saga/effects';
import { actions as commonActions } from 'store/slices/common';
import {
  getAPEmployeeLanguageRateAPI,
  addAPEmployeeLanguageRateAPI,
  editAPEmployeeLanguageRateAPI,
  deleteAPEmployeeLanguageRateAPI,
} from 'store/api/apEmployeeLanguageRateAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { APIResult } from 'types/APITypes';
import { actions as apEmployeeLanguageRate } from '../slices/apEmployeeLanguageRateSlice';

function* getAPEmployeeLanguageRate(action: PayloadAction<string>) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(getAPEmployeeLanguageRateAPI, params);

  if (result?.data) {
    yield put(apEmployeeLanguageRate.setAPEmployeeLanguageRate(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addAPEmployeeLanguageRate(action: PayloadAction<any>) {
  const data = { ...action.payload };
  const filters = action.payload?.filters || {};

  delete data.filters;

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(
    addAPEmployeeLanguageRateAPI,
    data,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(
      getAPEmployeeLanguageRateAPI,
      {
        page: 1,
        ...filters,
      },
    );
    if (result?.data) {
      yield put(apEmployeeLanguageRate.setAPEmployeeLanguageRate(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added new item to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

function* editAPEmployeeLanguageRate(action: PayloadAction<any>) {
  const { urlSuffix } = action.payload;
  const data = { ...action.payload };
  const filters = action.payload?.filters || {};

  delete data.filters;

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(
    editAPEmployeeLanguageRateAPI,
    urlSuffix,
    data,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(
      getAPEmployeeLanguageRateAPI,
      {
        page: 1,
        ...filters,
      },
    );
    if (result?.data) {
      yield put(apEmployeeLanguageRate.setAPEmployeeLanguageRate(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited this item`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

function* deleteAPEmployeeLanguageRate(action: PayloadAction<any>) {
  const { urlSuffix } = action.payload;

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(
    deleteAPEmployeeLanguageRateAPI,
    urlSuffix,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(
      getAPEmployeeLanguageRateAPI,
      {
        page: 1,
      },
    );
    if (result?.data) {
      yield put(apEmployeeLanguageRate.setAPEmployeeLanguageRate(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted this item from the list`,
        severity: 'error',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

export default function* APEmployeeLanguageRateWatcher() {
  yield takeLatest(
    apEmployeeLanguageRate.getAPEmployeeLanguageRate.type,
    getAPEmployeeLanguageRate,
  );
  yield takeLatest(
    apEmployeeLanguageRate.addAPEmployeeLanguageRate.type,
    addAPEmployeeLanguageRate,
  );
  yield takeLatest(
    apEmployeeLanguageRate.editAPEmployeeLanguageRate.type,
    editAPEmployeeLanguageRate,
  );
  yield takeLatest(
    apEmployeeLanguageRate.deleteAPEmployeeLanguageRate.type,
    deleteAPEmployeeLanguageRate,
  );
}
