export function replaceItem<T>(
  source: T[],
  match: (entry: T) => boolean,
  newValue: T,
): T[] {
  const index = source.findIndex(match);
  return index >= 0
    ? [...source.slice(0, index), newValue, ...source.slice(index + 1)]
    : source;
}
