import { makeStyles } from '@material-ui/core/styles';
const styles = makeStyles(() => {
  return {
    Modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '650px',
      backgroundColor: 'background.paper',
      borderRadius: '20px',
      boxShadow: '24',
    },
  };
});

export default styles;
