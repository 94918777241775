import {FC, memo, useEffect} from 'react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {FormLabel} from '@mui/material';
import {TextField} from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import {GenericModalProps} from 'types/genericTypes';
import {createMenuItems} from "../../../helpers/dropdown";
import {actions as reportParameterSlice} from "../../../store/slices/reportSlices/reportParameterSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
});

const defaultValues = {
    code: '',
    name: '',
    type: '',
    referenceTable: '',
    key: '',
    label: '',
};

const reportParamTypes = createMenuItems([
    {label: 'string', value: 'string'},
    {label: 'number', value: 'number'},
    {label: 'date', value: 'date'},
    {label: 'datetime', value: 'datetime'},
    {label: 'reference', value: 'reference'},
]);

type ReportParametersFormProps = {
    initialValues?: any;
    title: string;
    onSubmit: any
} & GenericModalProps;

const ReportParametersForm: FC<ReportParametersFormProps> = ({
                                                                 onClose,
                                                                 initialValues = defaultValues,
                                                                 title,
                                                                 onSubmit,
                                                             }) => {
    const dispatch = useDispatch();
    const columns = useSelector((state: RootState) => state.reportParameters.columns).map(
        (item: any) => ({
            label: item,
            value: item,
        }),
    );
    const tables = useSelector((state: RootState) => state.reportParameters.tables).map(
        (item: any) => ({
            label: item,
            value: item,
        }),
    );

    const {setValues, ...formik} = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        onSubmit: (values: any) => {
            onSubmit({
                ...values,
            });
        },
    });

    useEffect(() => {
        dispatch(reportParameterSlice.getReportParametersTables());
    }, [dispatch]);

    const showKeyLabelFields = formik.values.referenceTable && formik.values.referenceTable !== '';

    useEffect(() => {
        if(showKeyLabelFields){
            dispatch(reportParameterSlice.getReportParametersColumns({urlSuffix: formik.values.referenceTable}));
        }
    }, [dispatch, formik.values.referenceTable]);

    const showTablesDropDown = formik.values.type === 'reference';

    return (
        <SimpleFormModal
            onClose={onClose}
            onSubmit={formik.submitForm}
            title={title}>
            <FormLabel>Name</FormLabel>
            <TextField
                name="name"
                size="small"
                value={formik.values.name || ''}
                error={Boolean(formik.errors.name)}
                helperText={formik.errors.name}
                onChange={formik.handleChange}
            />
            <FormLabel>Type</FormLabel>
            <TextField
                select
                name="type"
                size="small"
                value={formik.values.type || ''}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.type)}
                helperText={formik.errors.type}>
                {reportParamTypes}
            </TextField>
            {
                showTablesDropDown ? (
                    <>
                        <FormLabel>Reference Table</FormLabel>
                        <TextField
                            select
                            name="referenceTable"
                            size="small"
                            value={formik.values.referenceTable || ''}
                            onChange={formik.handleChange}
                            error={Boolean(formik.errors.referenceTable)}
                            helperText={formik.errors.referenceTable}>
                            {createMenuItems(tables)}
                        </TextField>
                    </>
                ) : null
            }
            {showKeyLabelFields && showTablesDropDown ? (
                <>
                    <FormLabel>Key</FormLabel>
                    <TextField
                        select
                        name="key"
                        size="small"
                        value={formik.values.key || ''}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.key)}
                        helperText={formik.errors.key}>
                        {createMenuItems(columns)}
                    </TextField>
                    <FormLabel>Label</FormLabel>
                    <TextField
                        select
                        name="label"
                        size="small"
                        value={formik.values.label || ''}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.label)}
                        helperText={formik.errors.label}>
                        {createMenuItems(columns)}
                    </TextField>
                </>
            ) : null
            }
        </SimpleFormModal>
    );
};

export default memo(ReportParametersForm);
