import { FC, memo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Form } from 'components';
import { makeStyles } from '@mui/styles';
import { actions as orderLineStatusesActions } from 'store/slices/orderLineStatusesSlice';
import FormInfoRow from 'components/FormInfoRow/FormInfoRow';
import colors from 'styles/colors';
import { TextField, Box, FormLabel } from '@mui/material';
import { numbersDataDropdown } from 'config/utilsFunctions';
import { createMenuItems } from 'helpers/dropdown';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  formRoot: {
    '& .boxRow': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
      '& .MuiSvgIcon-root': {
        fill: '#02A7F0',
      },
      '& label.MuiFormLabel-root': {
        width: 300,
      },
      '& .MuiFormControl-root, & .MuiAutocomplete-root': {
        height: 'unset',
        width: '40%',
        minWidth: '350px',
        '&.number': {
          width: '20%',
          minWidth: '200px',
        },
      },
      '& .MuiAutocomplete-root .MuiFormControl-root': {
        width: '100%',
      },
    },
  },
}));

const defaultValues = {
  orderLineStatusUuid: '',
  statusDescription: '',
  editorGradeForWriter: 0,
};

interface EditorFormProps {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  initialValues?: any;
  orderLineDetails?: any;
}

const EditorForm: FC<EditorFormProps> = ({
  onCancel,
  initialValues = defaultValues,
  onSubmit,
  orderLineDetails,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const orderLineStatusesDropdown = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  ).map((entry: any) => ({ label: entry.name, value: entry.uuid }));

  const orderLineStatuses = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  );

  const [editDoneUuid, setEditDoneUuid] = useState<string>('');
  const [needDescription, setNeedDescription] = useState<boolean>(false);
  const [needDescriptionUuid, setNeedDescriptionUuid] = useState<string>('');

  const validationSchema = Yup.object().shape({
    orderLineStatusUuid: Yup.string().required('This field is required!'),
    editorComment: Yup.string().required('This field is required!'),
    statusDescription: Yup.string().when('orderLineStatusUuid', {
      is: (orderLineStatusUuid: string) =>
        orderLineStatusUuid === needDescriptionUuid,
      then: Yup.string().required('This field is required!').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    editorGradeForWriter: Yup.number().when('orderLineStatusUuid', {
      is: (orderLineStatusUuid: string) => orderLineStatusUuid === editDoneUuid,
      then: Yup.number().required('This field is required!').nullable(),
      otherwise: Yup.number().nullable(),
    }), // Writer Grade - Mandatory if Status = edit_done
  });

  const { setValues, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      const payload = { ...values };
      if (!needDescription) {
        delete payload.statusDescription;
      }

      onSubmit({
        ...payload,
      });
    },
  });

  useEffect(() => {
    const editDoneStatusValue = orderLineStatuses.find(
      (status: any) => status.code === 'edit_done',
    );

    const currentStatus = orderLineStatuses.find(
      (status: any) => status.uuid === formik?.values?.orderLineStatusUuid,
    );
    if (currentStatus?.needsDescription) {
      setNeedDescriptionUuid(currentStatus?.uuid);
    }
    setNeedDescription(currentStatus?.needsDescription);

    setEditDoneUuid(editDoneStatusValue?.uuid);
  }, [formik]);

  useEffect(() => {
    dispatch(orderLineStatusesActions.getOrderLineStatuses());
  }, [dispatch]);

  return (
    <Form
      onSubmit={formik.submitForm}
      onCancel={onCancel}
      btnCancelLabel="Cancel"
      btnSubmitLabel="Save"
      className={classes.formRoot}
      sx={{ padding: '32px', backgroundColor: '#fff' }}>
      <FormInfoRow
        rowTitle={'Writer Article Link'}
        rowValueText={orderLineDetails?.writerArticleLink}
        url={orderLineDetails?.writerArticleLink}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Client task Instruction link'}
        rowValueText={orderLineDetails?.clientInstructionsUrl}
        url={orderLineDetails?.clientInstructionsUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Client Additional Note'}
        rowValueText={orderLineDetails?.instructions}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Client General Notes Link'}
        rowValueText={orderLineDetails?.order?.contactPerson?.notes}
        url={orderLineDetails?.order?.contactPerson?.notes}
        color={colors.black}
      />
      {/* TODO 3 basic form */}
      <Box className="boxRow">
        <FormLabel>Status</FormLabel>
        <TextField
          select
          name="orderLineStatusUuid"
          size="small"
          value={formik.values.orderLineStatusUuid}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.orderLineStatusUuid)}
          helperText={formik.errors.orderLineStatusUuid}
          //pressEnter={formik.submitForm}
        >
          {createMenuItems(orderLineStatusesDropdown)}
        </TextField>
      </Box>
      {needDescription && (
        <Box className="boxRow">
          <FormLabel>Status Description</FormLabel>
          <TextField
            name="statusDescription"
            size="small"
            value={formik.values.statusDescription}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.statusDescription)}
            helperText={formik.errors.statusDescription}
            //pressEnter={formik.submitForm}
          />
        </Box>
      )}
      <Box className="boxRow">
        <FormLabel>Writer Grade</FormLabel>
        <TextField
          select
          name="editorGradeForWriter"
          size="small"
          className="number"
          value={formik.values.editorGradeForWriter}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.editorGradeForWriter)}
          helperText={formik.errors.editorGradeForWriter}
          //pressEnter={formik.submitForm}
        >
          {createMenuItems(numbersDataDropdown())}
        </TextField>
      </Box>
      <Box className="boxRow">
        <FormLabel>Editors Comment</FormLabel>
        <TextField
          required
          multiline
          minRows={5}
          maxRows={5}
          name="editorComment"
          size="small"
          inputProps={{ maxLength: 5000 }}
          value={formik.values.editorComment}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.editorComment)}
          helperText={formik.errors.editorComment}
        />
      </Box>
      <FormInfoRow
        rowTitle={'PM Revision Comments'}
        rowValueText={orderLineDetails?.pmRevisionComments}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Article Topic'}
        rowValueText={orderLineDetails?.articleTopic}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Article Title'}
        rowValueText={orderLineDetails?.articleTitle}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Keywords'}
        rowValueText={orderLineDetails?.keywords}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Expertise 1'}
        rowValueText={orderLineDetails?.expertise1?.name}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Expertise 2'}
        rowValueText={orderLineDetails?.expertise2?.name}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Expertise 3'}
        rowValueText={orderLineDetails?.expertise3?.name}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Speaker Perspective'}
        rowValueText={orderLineDetails?.speaker?.name}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Tone of Voice'}
        rowValueText={orderLineDetails?.toneOfVoice?.name}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Target Audience'}
        rowValueText={orderLineDetails?.targetAudience?.name}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Outline Link'}
        rowValueText={orderLineDetails?.outlineUrl}
        url={orderLineDetails?.outlineUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Example Articles Link'}
        rowValueText={orderLineDetails?.exampleArticlesUrl}
        url={orderLineDetails?.exampleArticlesUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Internal Links'}
        rowValueText={orderLineDetails?.internalUrl}
        url={orderLineDetails?.internalUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'External Links'}
        rowValueText={orderLineDetails?.externalUrl}
        url={orderLineDetails?.externalUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Anchor Text Link'}
        rowValueText={orderLineDetails?.anchorTextUrl}
        url={orderLineDetails?.anchorTextUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Anchor Text'}
        rowValueText={orderLineDetails?.anchorText}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Hosting Site Link'}
        rowValueText={orderLineDetails?.hostingSiteUrl}
        url={orderLineDetails?.hostingSiteUrl}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Meta title'}
        rowValueText={orderLineDetails?.hasMetaTitle}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Meta description'}
        // rowValueText={false}
        rowValueText={orderLineDetails?.hasMetaDescription}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Images'}
        rowValueText={orderLineDetails?.hasImages}
        color={colors.black}
      />
      <FormInfoRow
        rowTitle={'Images Link'}
        rowValueText={orderLineDetails?.imagesUrl}
        url={orderLineDetails?.imagesUrl}
        color={colors.black}
      />
    </Form>
  );
};

export default memo(EditorForm);
