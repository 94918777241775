import { FC, useCallback } from 'react';
import { useDispatch } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import { actions } from 'store/slices/common';
import { actions as projectManagerActions } from 'store/slices/projectManagerSlice';
import AssignAssistantPMForm from 'components/Forms/AssignAssistantPMForm';

//data: {urlSuffix, assistantPM} - parameters to accept in this component
const AssignAssistantPMModal: FC<GenericModalProps> = ({ data }) => {
  const dispatch = useDispatch();
  const closeHandler = useCallback(
    () => dispatch(actions.hideModal()),
    [dispatch],
  );

  const submitHandler = useCallback(
    (values: any) => {
      const dataForm = {
        ...values,
        urlSuffix: `${data.uuid}/employees-and-dates`,
      };

      dispatch(
        projectManagerActions.editProjectEMployeeDatesOrderLines({
          ...dataForm,
        }),
      );
    },
    [dispatch],
  );

  const initialData = {
    assistantProjectManagerUuid: data.assistantPM,
  };
  return (
    <AssignAssistantPMForm
      closeHandler={closeHandler}
      onSubmit={submitHandler}
      initialData={initialData}
    />
  );
};

export default AssignAssistantPMModal;
