import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Countries,
} from 'types/countriesType';

const initialState: Countries = {
  items: [],
  meta: {},
};

export const countries = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    getCountryList: (state) => state,
    setCountryList: (state, action: PayloadAction<Countries>) => action.payload,
    sendCountryFilters: (state) => state,
    addCountry: (state) => state,

    editCountry: (state) => state,

    deleteCountry: (state) => state,
  },
});

export const actions: any = countries.actions;
export default countries.reducer;
