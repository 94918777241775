import { FC, memo, useCallback, useEffect } from 'react';
import { actions as projectsActions } from 'store/slices/projectsSlice';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'hooks/redux';
import ProjectsAddForm from 'components/Forms/ProjectsAddForm';
import { RootState } from 'store';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';

const defaultValidation = Yup.object().shape({
  name: Yup.string().required('Required'),
  contactPersonUuid: Yup.string(),
});

const ProjectsEditModal: FC<GenericModalProps> = ({
  onClose,
  data,
  ...rest
}) => {
  const dispatch = useDispatch();
  const project = useSelector((state: RootState) =>
    data ? state.projects.items.find((item: any) => item.uuid === data) : null,
  );

  const contacts = useSelector(
    (state: RootState) => state.projects.contactPersons.items,
  )?.map(({ nick, uuid }: any) => ({ label: nick, value: uuid }));

  const submitHandler = useCallback(
    (values: any) => {
      dispatch(
        projectsActions.editProjects({
          data: { ...values },
          uuid: data,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(projectsActions.getContactPersons());
  }, [dispatch]);
  return (
    <ProjectsAddForm
      title={l('EDIT_PROJECT')}
      onSubmit={submitHandler}
      contactPersonDropdown={contacts}
      onClose={onClose}
      initialValues={project}
      validationSchema={defaultValidation}
      {...rest}
    />
  );
};

export default memo(ProjectsEditModal);
