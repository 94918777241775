import { FC, memo, useCallback } from 'react';
import { actions as userActions } from 'store/slices/user';
import { useDispatch } from 'hooks/redux';
import { Typography } from '@mui/material';
import { GenericModalProps } from 'types/genericTypes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

const ReplacementsDeleteModal: FC<GenericModalProps> = ({ data, onClose }) => {
  const dispatch = useDispatch();

  const submitHandler = useCallback(() => {
    dispatch(userActions.deleteUserLanguage(data.uuid));
  }, [dispatch, data.uuid]);

  return (
    <ConfirmationModal onConfirm={submitHandler} onCancel={onClose}>
      <Typography>
        Are you sure you want to delete <strong>{data.language.name}</strong>{' '}
        from your table of languages?
      </Typography>
    </ConfirmationModal>
  );
};

export default memo(ReplacementsDeleteModal);
