import { memo } from 'react';
import { PageLayout } from 'components';
import l from 'helpers/l';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import PMTableFilters from './PMTableFilters';
import PMTable from './PMTable';

const useStyles = makeStyles(() =>
  createStyles({
    mainWrapper: {
      '& .MuiBadge-dot': {
        display: 'none',
      },
      '& .hasUnreadMessages.MuiDataGrid-row': {
        '& .MuiBadge-dot': {
          display: 'block',
        },
      },
    },
    dropdownMenu: {
      maxHeight: '300px !important',
    },
    statusBox: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .MuiOutlinedInput': {
        width: '100%',
        '& #statusUuids': {
          width: '100%',
        },
      },
    },
  }),
);
const PMPage = () => {
  const classes = useStyles();
  return (
    <PageLayout
      title={l('PAGES.PROJECT_MANAGER_SCREEN')}
      fullWidth
      className={classes.mainWrapper}>
      <PMTableFilters />
      <PMTable />
    </PageLayout>
  );
};

export default memo(PMPage);
