import { Endpoint, Method } from 'enums/APIEndpointEnum';
import { QuestionEntry } from 'types/questionsTypes';
import apiCall from './apiCall';

const sendQuestionsFiltersApi = (params: string) =>
  apiCall({
    url: Endpoint.Questions,
    method: Method.Get,
    params,
  });

const getQuestionsApi = () =>
  apiCall({
    url: Endpoint.Questions,
    method: Method.Get,
  });

const addQuestionApi = (data: QuestionEntry) =>
  apiCall({
    url: Endpoint.Questions,
    method: Method.Post,
    data,
  });

const deleteQuestionApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.Questions,
    method: Method.Delete,
    urlSuffix,
  });

const editQuestionApi = (data: QuestionEntry) => {
  return apiCall({
    url: Endpoint.Questions,
    method: Method.Put,
    data,
  });
};

export {
  sendQuestionsFiltersApi,
  getQuestionsApi,
  addQuestionApi,
  editQuestionApi,
  deleteQuestionApi,
};
