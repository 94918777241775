import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

export const sendTimeZoneFilters = (params: string) =>
  apiCall({
    url: Endpoint.TimeZones,
    method: Method.Get,
    params,
  });

export const getTimeZones = () =>
  apiCall({
    url: Endpoint.TimeZones,
    method: Method.Get,
  });
