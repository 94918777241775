import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderTypesActionEntry } from 'types/orderTypes';
import { GenericIdNameType } from 'types/genericTypes';

export interface OrderTypes {
  orderTypes: GenericIdNameType[];
}

const initialState: OrderTypes = {
  orderTypes: [],
};

export const orderTypesSlice = createSlice({
  name: 'orderTypes',
  initialState,
  reducers: {
    getOrderTypes: (state) => state,
    setOrderTypes: (state, action: PayloadAction<GenericIdNameType[]>) => {
      state.orderTypes = action.payload;
    },
    addOrderTypes: (state) =>
      state,
    deleteOrderTypes: (state) =>
      state,
    editOrderTypes: (state) =>
      state,
  },
});
export const actions: any = orderTypesSlice.actions;
export default orderTypesSlice.reducer;
