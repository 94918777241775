import { FC, memo, useCallback } from 'react';
import { actions as orderTypesActions } from 'store/slices/orderTypesSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import EmployeeStatusesForm from 'components/Forms/EmployeeStatusesForm';
import { RootState } from 'store';
import { OrderTypesActionEntry } from 'types/orderTypes';

const OrderTypeEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  const entry = useSelector((state: RootState) =>
    data
      ? state.orderTypes.orderTypes.find((entry) => entry?.name === data)
      : null,
  );

  const submitHandler = useCallback(
    (values: OrderTypesActionEntry) =>
      dispatch(orderTypesActions.editOrderTypes({ ...values })),
    [dispatch, entry?.name],
  );

  return entry ? (
    <EmployeeStatusesForm
      title={l('EDIT_ORDER_TYPE')}
      onSubmit={submitHandler}
      initialValues={entry}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(OrderTypeEditModal);
