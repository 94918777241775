import { FC, memo, useCallback } from 'react';
import { actions as userActions } from 'store/slices/user';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import * as Yup from 'yup';
import EmployeePreferenceForm from 'components/Forms/EmployeePreferenceForm';

const defaultValues = {
  employeeRoleUuid: '',
  contactPersonUuid: '',
  level: 1,
  description: '',
};

const validationSchema = Yup.object().shape({
  employeeRoleUuid: Yup.string().required('Required'),
  contactPersonUuid: Yup.string().required('Required'),
  level: Yup.number().required('Required'),
  description: Yup.string().required('Required'),
});

const EmployeePreferenceModalAdd: FC<GenericModalProps> = ({
  data,
  ...rest
}) => {
  const dispatch = useDispatch();
  const name = `${data.firstName} ${data.lastName}`;
  const submitHandler = useCallback(
    (values: any) => {
      dispatch(
        userActions.addUserPreferences({
          id: data.uuid,
          data: { ...values },
        }),
      );
    },
    [dispatch],
  );

  return data ? (
    <EmployeePreferenceForm
      title={l('EMPLOYEE.PREFERENCES_ADD')}
      onSubmit={submitHandler}
      initialValues={defaultValues}
      validationSchema={validationSchema}
      employeeName={name}
      {...rest}
    />
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(EmployeePreferenceModalAdd);
