import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/orderLineStatusTransitionSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  getAssignedOrderLineStatusTransitionsApi,
  getNotAssignedOrderLineStatusTransitionsApi,
  deleteOrderLineStatusTransitionsApi,
  addOrderLineStatusTransitionsApi,
} from 'store/api/orderLineStatusTransitionAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { OrderLineStatusTransition } from 'interfaces/orderLineStatusTransitions';
import { GenericIdNameType } from 'types/genericTypes';
import { genericErrorMessageHelper } from '../../helpers/messageHelpers';
import { APIResult } from 'types/APITypes';

function* getAssignedOrderLineStatusTransitions(action: PayloadAction<string>) {
  yield put(commonActions.setLoading(true));
  const uuid = action.payload;
  const { result }: APIResult<OrderLineStatusTransition['assignedStatuses']> =
    yield call(getAssignedOrderLineStatusTransitionsApi, uuid);
  if (result?.data?.length) {
    yield put(actions.setAssignedStatusTransitions(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* getNotAssignedOrderLineStatusTransitions(
  action: PayloadAction<string>,
) {
  yield put(commonActions.setLoading(true));
  const uuid = action.payload;
  const {
    result,
  }: APIResult<OrderLineStatusTransition['notAssignedStatuses']> = yield call(
    getNotAssignedOrderLineStatusTransitionsApi,
    uuid,
  );
  if (result?.data?.length) {
    yield put(actions.setNotAssignedStatusTransitions(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addOrderLineStatusTransitions(action: PayloadAction<any>) {
  const { originUuid, targetUuid } = action.payload;
  const {
    result,
    error,
  }: APIResult<OrderLineStatusTransition['assignedStatuses']> = yield call(
    addOrderLineStatusTransitionsApi,
    { originUuid, targetUuid },
  );
  if (result?.status === 201) {
    const { result }: APIResult<OrderLineStatusTransition['assignedStatuses']> =
      yield call(getAssignedOrderLineStatusTransitionsApi, originUuid);
    if (result?.data?.length) {
      yield put(actions.setAssignedStatusTransitions(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added transition`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `Transition already exists`,
        severity: 'error',
      }),
    );
  }
}

function* deleteOrderLineStatusTransitions(action: PayloadAction<any>) {
  const { uuid, statusUuid, name } = action.payload;
  const { result, error }: APIResult<GenericIdNameType> = yield call(
    deleteOrderLineStatusTransitionsApi,
    uuid,
  );
  if (error?.status === 404) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Transition not found ',
        severity: 'error',
      }),
    );
  } else if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: genericErrorMessageHelper(error),
        severity: 'error',
      }),
    );
  }
  if (result?.status === 200) {
    yield put(commonActions.setLoading(true));
    const { result }: APIResult<OrderLineStatusTransition['assignedStatuses']> =
      yield call(getAssignedOrderLineStatusTransitionsApi, statusUuid);
    if (result?.data?.length) {
      yield put(actions.setAssignedStatusTransitions(result?.data));
    }
    yield put(commonActions.setLoading(false));
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
  }
  yield put(commonActions.hideModal());
}

export default function* OrderLineStatusTransitionWatcher() {
  yield takeLatest(
    actions.getAssignedStatusTransitions.type,
    getAssignedOrderLineStatusTransitions,
  );
  yield takeLatest(
    actions.getNotAssignedStatusTransitions.type,
    getNotAssignedOrderLineStatusTransitions,
  );
  yield takeLatest(
    actions.deleteStatusTransitions.type,
    deleteOrderLineStatusTransitions,
  );
  yield takeLatest(
    actions.addStatusTransitions.type,
    addOrderLineStatusTransitions,
  );
}
