import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationMeta } from 'types/genericTypes';

interface IAPEmployeeLanguageRate {
  items: any[];
  meta: PaginationMeta;
}
const initialState: IAPEmployeeLanguageRate = {
  items: [],
  meta: {},
};

export const apEmployeeLanguageRateSlice = createSlice({
  name: 'apEmployeeLanguageRate',
  initialState,
  reducers: {
    getAPEmployeeLanguageRate: (state) => state,
    setAPEmployeeLanguageRate: (state, action: PayloadAction<any>) => {
      state.items = action?.payload?.items;
      state.meta = action.payload.meta;
    },
    addAPEmployeeLanguageRate: (state) => state,
    editAPEmployeeLanguageRate: (state) => state,
    deleteAPEmployeeLanguageRate: (state) => state,
  },
});
export const actions: any = apEmployeeLanguageRateSlice.actions;
export default apEmployeeLanguageRateSlice.reducer;
