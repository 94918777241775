import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

interface GobackProps {
  text: string;
  location: string | number;
  callback?: any;
}

const Goback: FC<GobackProps> = ({ text, location, callback }) => {
  const navigate = useNavigate();

  return (
    <IconButton
      color="info"
      // @ts-ignore
      onClick={() => {
        if(typeof location === 'string' ){
          navigate(location, { state: { urlHistory: location || '' } })
        }else{
          navigate(location)
        }
        callback && callback()

      }}
      component="button">
      <ArrowBack fontSize="large" />
      <Typography>{text}</Typography>
    </IconButton>
  );
};
export default Goback;
