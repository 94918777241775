import { useEffect, useCallback, memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as commonActions } from 'store/slices/common';
import { actions as clientsActions } from 'store/slices/clientsSlice';
import {
  GridRowParams,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import l from 'helpers/l';
import { PageLayout, EditTable, Filters, TextField } from 'components';
import { FormLabel, Grid } from '@mui/material';
import { RootState } from 'store';
import { createMenuItems } from 'helpers/dropdown';
import { useFormik } from 'formik';
import { formatFilters, formatSortOrderBy } from 'helpers/filters';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { ModalType } from 'enums/ModalEnums';
import moment from 'moment';

const initialValues = {
  keyword: '',
  writerLevel: '',
  type: '',
};

const ClientsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state: RootState) => state.common.loading);
  const { items, meta } = useSelector((state: RootState) => state.clients);

  const [dropdownValues, setDropdownValues] = useState({});
  const [sort, setSort] = useState({});

  useSelector(
    (state: RootState) => state.employeeStatuses.employeeStatuses,
  ).map((status) => ({ label: status.name, value: status.id }));

  const { setValues, ...formik } = useFormik({
    initialValues,
    onSubmit: (values) => {
      const formatValues = formatFilters(values);
      setDropdownValues(() => {
        return { ...formatValues };
      });
      if (!isEmpty(formatValues)) {
        const params = { ...formatValues, ...sort };
        dispatch(clientsActions.sendClientsFilters(params));
      } else {
        dispatch(clientsActions.sendClientsFilters(sort));
      }
    },
  });

  const addEntryHandler = useCallback(() => {
    navigate('/add-client');
  }, [navigate]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      navigate(`/edit-client/${params.row.uuid}`);
    },
    [navigate],
  );

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.ClientsDelete,
          data: { employeeDetails: params.row } as any,
        }),
      );
    },
    [dispatch],
  );

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: formatSortOrderBy(item.field),
        order: item?.sort?.toUpperCase(),
      }))[0];
      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });
      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
        ...dropdownValues,
      };
      dispatch(clientsActions.sendClientsFilters(params));
    },
    [dispatch, dropdownValues],
  );

  const handlePage = useCallback(
    (page: number) => {
      const params = { ...dropdownValues, ...sort, page: page + 1 };
      dispatch(clientsActions.sendClientsFilters(params));
    },
    [dispatch, dropdownValues, sort],
  );

  const onReset = useCallback(() => {
    setValues(initialValues);
    setDropdownValues({});
    dispatch(clientsActions.sendClientsFilters(sort));
  }, [dispatch, setValues, sort]);

  useEffect(() => {
    dispatch(clientsActions.getClients());
  }, [dispatch]);

  const writerValues = createMenuItems([
    { label: 'High', value: 'High' },
    { label: 'Average', value: 'Average' },
  ]);

  const typeValues = createMenuItems([
    { label: 'Client', value: 'Client' },
    { label: 'Lead', value: 'Lead' },
  ]);
  return (
    <PageLayout title={l('PAGES.CLIENTS')} fullWidth={true}>
      <Filters handleFilters={formik.submitForm} onReset={onReset}>
        <Grid
          container
          columns={{ xs: 4, md: 15 }}
          spacing={2}
          alignItems="center">
          <Grid item xs={4} md={6}>
            <FormLabel>Keyword</FormLabel>
            <TextField
              autoHeight
              fullWidth
              name="keyword"
              size="small"
              value={formik?.values?.keyword}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.keyword)}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <FormLabel>Writer Level</FormLabel>
            <TextField
              select
              autoHeight
              fullWidth
              name="writerLevel"
              size="small"
              value={formik?.values?.writerLevel}
              onChange={formik.handleChange}>
              {writerValues}
            </TextField>
          </Grid>
          <Grid item xs={4} md={4}>
            <FormLabel>Type</FormLabel>
            <TextField
              select
              autoHeight
              fullWidth
              name="type"
              size="small"
              value={formik?.values?.type}
              onChange={formik.handleChange}>
              {typeValues}
            </TextField>
          </Grid>
        </Grid>
      </Filters>
      <EditTable
        rows={items}
        columns={[
          {
            field: 'id',
            headerName: 'Id',
            width: 50,
          },
          {
            field: 'name',
            headerName: 'Name',
            width: 200,
          },
          {
            field: 'internalName',
            headerName: 'Internal Name',
            width: 200,
          },
          {
            field: 'timeZone',
            headerName: 'Time Zone',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
              params.row?.timezone?.name,
          },
          {
            field: 'writerLevel',
            headerName: 'Writer Level',
            width: 150,
          },
          {
            field: 'type',
            headerName: 'Type',
            width: 100,
          },
          //  {
          //    field: 'source',
          //    headerName: 'Source',
          //    width: 200,
          //  },
          {
            field: 'createdAt',
            headerName: 'Created At',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row.createdAt).format('DD.MM.YYYY hh:mm:ss'),
          },
          {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
              moment(params.row.updatedAt).format('DD.MM.YYYY hh:mm:ss'),
          },
        ]}
        loading={loading}
        onAdd={addEntryHandler}
        onEdit={editEntryHandler}
        onDelete={deleteEntryHandler}
        // onView={viewEntityHandler}
        editColumnWidth={130}
        sortedFieldIndex={1}
        onSortModelChange={handleSort}
        pagination
        page={meta.currentPage! - 1 || 0}
        rowCount={meta?.totalItems}
        onPageChange={handlePage}
        pageSize={meta?.itemsPerPage}
        rowsPerPageOptions={[meta.itemsPerPage || 10]}
        paginationMode="server"
        sortingMode="server"
      />
    </PageLayout>
  );
};

export default memo(ClientsPage);
