import { FC, memo} from 'react';
import { actions as userActions } from 'store/slices/user';
import { useDispatch } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { Typography } from '@mui/material';

const EmployeesDeleteModal: FC<GenericModalProps> = ({ data, onClose }) => {
  const dispatch = useDispatch();
  const { uuid, role } = data;

  const confirmHandler = () => {
    dispatch(userActions.deleteUserRole({ urlSuffix: uuid, role: role.name }));
  };

  return data?.uuid ? (
    <ConfirmationModal onCancel={onClose} onConfirm={confirmHandler}>
      <Typography>
        Are you sure you want to delete
        <strong>{` ${role.name} `}</strong>
        role from the list ?
      </Typography>
    </ConfirmationModal>
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(EmployeesDeleteModal);
