import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GenericIdNameType } from 'types/genericTypes';

export interface Payment {
  payment: GenericIdNameType[];
}

const initialState: Payment = {
  payment: [],
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    getPayment: (state) => state,
    setPayment: (state, action: PayloadAction<GenericIdNameType[]>) => {
      state.payment = action.payload;
    },
    addPayment: (state) => state,
    deletePayment: (state) => state,
    editPayment: (state) => state,
  },
});
export const actions: any = paymentSlice.actions;
export default paymentSlice.reducer;
