import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from 'components';
import { useDispatch } from 'react-redux';
import { actions as ordersActions } from 'store/slices/orderEntriesSlice';
import l from 'helpers/l';
import OrderEntriesForm from 'components/Forms/OrderEntriesForm';

function OrderEntriesAddPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (values: any) => {
    const data = {
      ...values,
    };

    dispatch(ordersActions.addEntries({ ...data, navigate }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <PageLayout title={l('ADD_ORDER')} fullWidth={true}>
      <OrderEntriesForm onSubmit={handleSubmit} onCancel={handleCancel} />
    </PageLayout>
  );
}

export default memo(OrderEntriesAddPage);
