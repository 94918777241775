import { FC, memo, useCallback } from 'react';
import { actions as employeeStatusesActions } from 'store/slices/employeeStatusesSlice';
import { useDispatch, useSelector } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { Typography } from '@mui/material';

const EmployeeStatusesDeleteModal: FC<GenericModalProps> = ({
  data,
  onClose,
}) => {
  const dispatch = useDispatch();
  const entry = useSelector((state) =>
    data
      ? state.employeeStatuses.employeeStatuses.find(
          (entry) => entry?.name === data,
        )
      : null,
  );

  const confirmHandler = useCallback(() => {
    if (entry?.id) {
      dispatch(
        employeeStatusesActions.deleteEmployeeStatuses({
          id: entry?.id,
          name: entry?.name,
        }),
      );
    }
  }, [dispatch, entry]);

  return entry ? (
    <ConfirmationModal onCancel={onClose} onConfirm={confirmHandler}>
      <Typography>
        Are you sure you want to delete <strong>{entry.name} </strong>
        from the table ?
      </Typography>
    </ConfirmationModal>
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(EmployeeStatusesDeleteModal);
