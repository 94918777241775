import {FC, memo, useCallback} from 'react';
import {useDispatch} from 'hooks/redux';
import {GenericModalProps} from 'types/genericTypes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import {Typography} from '@mui/material';
import {actions as reportCategoriesSlice} from "../../../../store/slices/reportSlices/reportCategoriesSlice";

const ReportCategoryDeleteModal: FC<GenericModalProps> = ({data, onClose}) => {
    const dispatch = useDispatch();

    const submitHandler = useCallback(() => {
        const objToSend = {
            urlSuffix: data.row.uuid,
        }
        dispatch(reportCategoriesSlice.deleteReportCategory(objToSend));
    }, [dispatch, data.row.uuid]);

    return (
        <ConfirmationModal onConfirm={submitHandler} onCancel={onClose}>
            <Typography>
                Are you sure you want to delete <strong>{data.row.name}</strong>{' '}
                from your table of report categories?
            </Typography>
        </ConfirmationModal>
    );
};

export default memo(ReportCategoryDeleteModal);
