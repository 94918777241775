import { EditEmailTemplate } from 'store/slices/user';
import { Roles } from 'types/userRoleTypes';

import { Endpoint, Method } from 'enums/APIEndpointEnum';
import apiCall from './apiCall';

const getEmailTemplatesApi = () =>
  apiCall({
    url: Endpoint.EmailTemplates,
    method: Method.Get,
  });

const editEmailTemplateApi = (data: EditEmailTemplate) =>
  apiCall({
    url: Endpoint.EmailTemplates,
    method: Method.Put,
    data,
  });

const getPermissionsApi = () =>
  apiCall({
    url: Endpoint.Permissions,
    method: Method.Get,
  });

const putPermissionApi = (urlSuffix: string, data: any) =>
  apiCall({
    url: Endpoint.Permissions,
    method: Method.Put,
    urlSuffix,
    data,
  });

const getRolesApi = () =>
  apiCall({
    url: Endpoint.Roles,
    method: Method.Get,
  });

const editRoleApi = (urlSuffix: string, data: any) =>
  apiCall({
    url: Endpoint.Roles,
    method: Method.Put,
    urlSuffix,
    data,
  });

const addRoleApi = (data: Roles) =>
  apiCall({
    url: Endpoint.Roles,
    method: Method.Post,
    data,
  });

const deleteRoleApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.Roles,
    method: Method.Delete,
    urlSuffix,
  });

const putRolePermissionApi = (urlSuffix: string, data: any) =>
  apiCall({
    url: Endpoint.PermissionsAssign,
    method: Method.Put,
    urlSuffix,
    data,
  });

const deleteRolePermissionApi = (urlSuffix: string, data: any) =>
  apiCall({
    url: Endpoint.PermissionsRemove,
    method: Method.Delete,
    urlSuffix,
    data,
  });

const createUserApi = (data: any) =>
  apiCall({
    url: Endpoint.User,
    method: Method.Post,
    data,
  });

const inactivateUserApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.InactivateUser,
    method: Method.Patch,
    urlSuffix,
  });

const activateUserApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.ActivateUser,
    method: Method.Patch,
    urlSuffix,
  });

const unlockUserApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.UnlockUser,
    method: Method.Patch,
    urlSuffix,
  });

const getUserRolesApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.EmployeeAssignedRoles,
    method: Method.Get,
    urlSuffix,
  });

const editUserRoleApi = (urlSuffix: string, data: any) =>
  apiCall({
    url: Endpoint.EmployeeRoles,
    method: Method.Put,
    urlSuffix,
    data,
  });

const assignRoleApi = (data: any) =>
  apiCall({
    url: Endpoint.EmployeeAssignRoles,
    method: Method.Patch,
    data,
  });

const deleteUserRoleApi = (urlSuffix: string) =>
  apiCall({
    url: Endpoint.EmployeeUnassign,
    method: Method.Delete,
    urlSuffix,
  });

const getReplacementsApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.Replacements,
    method: Method.Get,
    urlSuffix,
  });
};

const deleteReplacementApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.Replacements,
    method: Method.Delete,
    urlSuffix,
  });
};

const addReplacementsApi = (data: any) => {
  return apiCall({
    url: Endpoint.AddReplacements,
    method: Method.Post,
    data,
  });
};

const getReplacementsByRolesApi = (urlSuffix: string, params: any) => {
  return apiCall({
    url: Endpoint.EmployeeReplacementsRoles,
    method: Method.Get,
    urlSuffix,
    params,
  });
};

const getUserLanguagesApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.UserLanguages,
    method: Method.Get,
    urlSuffix,
  });
};

const getUserUnassignedLanguagesApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.UserNotAssignedLanguages,
    method: Method.Get,
    urlSuffix,
  });
};

const addUserLanguageApi = (data: any) => {
  return apiCall({
    url: Endpoint.AddUserLanguage,
    method: Method.Post,
    data,
  });
};

const deleteUserLanguageApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.AddUserLanguage,
    method: Method.Delete,
    urlSuffix,
  });
};

const editUserLanguageApi = (id: string, data: any) => {
  return apiCall({
    url: Endpoint.EditUserLanguage,
    method: Method.Put,
    urlSuffix: id,
    data,
  });
};

const getUserExpertiseApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.UserExpertise,
    method: Method.Get,
    urlSuffix,
  });
};

const getUserUnassignedExpertiseApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.UserNotAssignedExpertise,
    method: Method.Get,
    urlSuffix,
  });
};

const addUserExpertiseApi = (data: any) => {
  return apiCall({
    url: Endpoint.AddUserExpertise,
    method: Method.Post,
    data,
  });
};

const deleteUserExpertiseApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.AddUserExpertise,
    method: Method.Delete,
    urlSuffix,
  });
};

const editUserExpertiseApi = (urlSuffix: string, data: any) => {
  return apiCall({
    url: Endpoint.AddUserExpertise,
    method: Method.Put,
    urlSuffix,
    data,
  });
};

const getUserPreferencesApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.PreferredEmployeeByEmployee,
    method: Method.Get,
    urlSuffix,
  });
};

const addUserPreferencesApi = (data: any) => {
  return apiCall({
    url: Endpoint.PreferredEmployee,
    method: Method.Post,
    data,
  });
};

const editUserPreferencesApi = (urlSuffix: string, data: any) => {
  return apiCall({
    url: Endpoint.PreferredEmployee,
    method: Method.Put,
    urlSuffix,
    data,
  });
};

const deleteUserPreferencesApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.PreferredEmployee,
    method: Method.Delete,
    urlSuffix,
  });
};

// Questions Api Calls

const getUserQuestionsApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.UserQuestions,
    method: Method.Get,
    urlSuffix,
  });
};

const getUserUnassignedQuestionsApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.UserNotAssignedQuestions,
    method: Method.Get,
    urlSuffix,
  });
};

const addUserQuestionsApi = (data: any) => {
  return apiCall({
    url: Endpoint.AddUserQuestion,
    method: Method.Post,
    data,
  });
};

const deleteUserQuestionsApi = (urlSuffix: string) => {
  return apiCall({
    url: Endpoint.AddUserQuestion,
    method: Method.Delete,
    urlSuffix,
  });
};

const editUserQuestionsApi = (urlSuffix: string, data: any) => {
  return apiCall({
    url: Endpoint.AddUserQuestion,
    method: Method.Put,
    urlSuffix,
    data,
  });
};

const editUserNameApi = (urlSuffix: string, data: string) => {
  return apiCall({
    url: Endpoint.SetUsername,
    method: Method.Patch,
    urlSuffix,
    data,
  });
};

export {
  getEmailTemplatesApi,
  editEmailTemplateApi,
  getPermissionsApi,
  putPermissionApi,
  getRolesApi,
  editRoleApi,
  addRoleApi,
  deleteRoleApi,
  putRolePermissionApi,
  deleteRolePermissionApi,
  createUserApi,
  inactivateUserApi,
  activateUserApi,
  unlockUserApi,
  getUserRolesApi,
  editUserRoleApi,
  assignRoleApi,
  deleteUserRoleApi,
  getReplacementsApi,
  deleteReplacementApi,
  addReplacementsApi,
  getReplacementsByRolesApi,
  getUserLanguagesApi,
  getUserUnassignedLanguagesApi,
  addUserLanguageApi,
  deleteUserLanguageApi,
  getUserExpertiseApi,
  getUserUnassignedExpertiseApi,
  addUserExpertiseApi,
  deleteUserExpertiseApi,
  editUserExpertiseApi,
  getUserQuestionsApi,
  getUserUnassignedQuestionsApi,
  getUserPreferencesApi,
  addUserPreferencesApi,
  editUserPreferencesApi,
  deleteUserPreferencesApi,
  addUserQuestionsApi,
  deleteUserQuestionsApi,
  editUserQuestionsApi,
  editUserNameApi,
  editUserLanguageApi,
};
