import {FC, memo, useCallback} from 'react';
import {useDispatch} from 'hooks/redux';
import {GenericModalProps} from 'types/genericTypes';
import ReportParametersForm from "../../Forms/reports/ReportParametersForm";
import {actions as reportParameterSlice} from "../../../store/slices/reportSlices/reportParameterSlice";

const ReportParametersModal: FC<GenericModalProps> = ({ data, ...rest }) => {
    const dispatch = useDispatch();

    const submitHandler = useCallback(
        (values: any) => {
            const codeString = values.name.toLowerCase().split(' ').join('_');
            const objToSend = {
                ...values,
                code: codeString,
            }
            dispatch(reportParameterSlice.postReportParameter(objToSend));
        },
        [dispatch],
    );

    return (
        <ReportParametersForm
            title="Report Parameters Add"
            onSubmit={submitHandler}
            {...rest}
        />
    );
};

export default memo(ReportParametersModal);
