import { FC, memo, useCallback } from 'react';
import { actions as articleTypesActions } from 'store/slices/articleTypesSlice';
import { useDispatch } from 'hooks/redux';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';
import EmployeeStatusesForm from 'components/Forms/EmployeeStatusesForm';
import { GenericIdNameType } from 'types/genericTypes';

const ArticleTypeAddModal: FC<GenericModalProps> = (props) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: GenericIdNameType) =>
      dispatch(articleTypesActions.addArticleTypes(values)),
    [dispatch],
  );

  return (
    <EmployeeStatusesForm
      title={l('ADD_NEW_ARTICLE_TYPE')}
      onSubmit={submitHandler}
      {...props}
    />
  );
};

export default memo(ArticleTypeAddModal);
