import { useEffect } from 'react';
import { actions as employeeListActions } from 'store/slices/employeeListSlice';
import { useDispatch } from 'hooks/redux';
import { FormLabel} from '@mui/material';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { SuggestedDropdown } from '../SuggestedDropdown/SuggestedDropdown';
import { Endpoint } from '../../enums/APIEndpointEnum';

interface AssignAssistantPMFormProps {
  closeHandler: () => void;
  onSubmit: (values: any) => void;
  initialData: any;
}

const validationSchema = Yup.object().shape({
  assistantProjectManagerUuid: Yup.string(),
});

const defaultValues: any = {
  assistantProjectManagerUuid: '',
};

function AssignAssistantPMForm({
  closeHandler,
  onSubmit,
  initialData = defaultValues,
}: AssignAssistantPMFormProps) {
  const dispatch = useDispatch();

  useSelector(
    (state: RootState) => state?.employees?.employeesByRole,
  ).map((role: any) => ({
    label: role.firstName + ' ' + role.lastName,
    value: role.uuid,
  }));

  const { setValues, ...formik } = useFormik({
    initialValues: initialData,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      onSubmit({
        ...values,
      });
    },
  });
  //  assistant_pm role suffix
  useEffect(() => {
    const params: any = { urlSuffix: 'assistant_pm' }
    dispatch(
      employeeListActions.getEmployeesByRole(params),
    );
  }, [dispatch]);

  return (
    <SimpleFormModal
      onSubmit={formik.submitForm}
      onClose={closeHandler}
      title="Assign Assistant Project Manager">
      <FormLabel>Name</FormLabel>
      <SuggestedDropdown
        url={Endpoint.EmployeeReplacementsRoles}
        urlSuffix="assistant_pm"
        inputId="assistantProjectManagerUuid"
        inputValue={formik?.values.assistantProjectManagerUuid}
        dropdownLabel={['firstName', 'lastName']}
        dropdownValue="uuid"
        dataLocation="resultDataItems"
        formikHook={formik}
        style={{ width: '100%', height: '55px' }}
      />
    </SimpleFormModal>
  );
}

export default AssignAssistantPMForm;
