import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, FormControl, IconButton, MenuItem } from '@mui/material';
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';
import EditTable from 'components/Table/EditTable';
import PageLayout from '../../components/PageLayout/PageLayout';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/slices/user';
import { PermissionEntry } from 'types/permissionTypes';
import { actions as commonActions } from 'store/slices/common';
import { RootState } from 'store';
import { GridColDef, GridRenderCellParams, GridRowHeightParams, GridRowParams } from '@mui/x-data-grid';
import * as Yup from 'yup';
import styles from './RolesStyles';
import l from 'helpers/l';
import { ModalType } from 'enums/ModalEnums';
import { Roles } from 'types/userRoleTypes';
import { TextField } from 'components';

const initialValues: Roles = {
  code: '',
  name: '',
  permissions: [],
};
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

const RolesPage: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const availablePermissions = useSelector(
    (state: RootState) => state.user.permissions,
  );
  const roles = useSelector((state: RootState) => state.user.roles);
  const classes = styles();
  const [selectedValue, setSelectedValue] = useState('');

  const onDropdownSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setSelectedValue(e.target.value);
  };

  const addEntryHandler = useCallback(() => {
    dispatch(commonActions.showModal({ type: ModalType.RoleAdd }));
  }, [dispatch]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.RoleEdit,
          data: params.id.toString(),
        }),
      );
    },
    [dispatch],
  );

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.RoleDelete,
          data: params.id.toString(),
        }),
      );
    },
    [dispatch],
  );

  const handleAssignPermission = useCallback(
    (params: GridRenderCellParams) => {
      dispatch(
        actions.editRolePermission({
          code: params.row.code,
          name: params.row.name,
          permissions: selectedValue,
        }),
      );
      dispatch(actions.getRoles());
    },
    [selectedValue],
  );

  const handleDeletePermission = useCallback(
    (params: GridRenderCellParams, permissionCode: string) => {
      if (params?.id != 'su') {
        dispatch(
          actions.deleteRolePermission({
            code: params.row.code,
            name: params.row.name,
            permissions: permissionCode,
          }),
        );
        dispatch(actions.getRoles());
      }
    },
    [],
  );

  useEffect(() => {
    setTimeout(() => {
      dispatch(actions.getPermissions());
      dispatch(actions.getRoles());
    });
  }, [dispatch, handleAssignPermission, handleDeletePermission]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'code',
        headerName: 'Code',
        width: 200,
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 200,
      },
      {
        field: 'isFixed',
        headerName: 'Fixed',
        width: 70,
        renderCell: (params: GridRenderCellParams) => {
          const isFixedRole = params.row.isFixed;
          return (
            <>
              {isFixedRole ? (
                <CheckIcon key={params.row.code} color="secondary" />
              ) : (
                ''
              )}
            </>
          );
        },
      },
      {
        headerName: 'Permissions',
        align: 'left',
        field: 'permissions',
        flex: 1,
        //rowHeight: 100,
        renderCell: (params: GridRenderCellParams) => {
          const assignedPermissions = params.row.permissions;

          const assignedPermissionCodes = assignedPermissions?.map(
            (el: PermissionEntry) => el.code,
          );
          const permissionOptions = availablePermissions?.filter(
            (option: PermissionEntry) =>
              !assignedPermissionCodes?.includes(option.code),
          );

          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {assignedPermissions?.map((el: any) => {
                return (
                  <Box
                    key={el.code}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    className={classes.PermissionBox}>
                    <Box>{`${el.name}`}</Box>
                    <IconButton
                      size="small"
                      onClick={() => handleDeletePermission(params, el.code)}>
                      <ClearIcon />
                    </IconButton>
                  </Box>
                );
              })}
              {permissionOptions.length ? (
                <FormControl className={classes.DropDown}>
                  <TextField
                    select
                    size="small"
                    name="dropdownSelect"
                    sx={{
                      width: '150px',
                    }}
                    className={classes.Test}
                    onChange={(e: any) => onDropdownSelectChange(e)}>
                    <MenuItem style={{ display: 'none' }}/>
                    {permissionOptions.map((el: PermissionEntry) => {
                      return (
                        <MenuItem key={el.code} value={el.code}>
                          {`${el.name}`}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => handleAssignPermission(params)}>
                      Assign
                    </Button>
                  </Box>
                </FormControl>
              ) : (
                ''
              )}
            </Box>
          );
        },
      },
    ],
    [editEntryHandler, roles, handleAssignPermission, handleDeletePermission],
  );
  const handleGetRowHeight = React.useCallback(
    ({ model }: GridRowHeightParams) => {
      if ((model.permissions?.length as number) > 1) {
        const availableWidth = window.innerWidth - 200 - 200 - 70;
        const averageItemWidth = 180;
        const lines = Math.ceil(
          ((model.permissions.length + 1) * averageItemWidth) / availableWidth,
        );
        return lines * 95;
      } else return 100;
    },
    [],
  );

  return (
    <PageLayout title={l('PAGES.ROLES')} fullWidth={true}>
      <EditTable
        rows={roles}
        columns={columns}
        loading={isLoading}
        onEdit={editEntryHandler}
        onAdd={addEntryHandler}
        onDelete={deleteEntryHandler}
        getRowHeight={handleGetRowHeight}
        getRowId={(row) => row.code}
        hideFooterPagination
        hideFooter
      />
    </PageLayout>
  );
};

export default memo(RolesPage);
