import { FC, memo, useCallback } from 'react';
import { actions as orderEntryLineActions } from 'store/slices/orderLinesSlice';
import { useDispatch } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { Typography } from '@mui/material';

const OrderEntryLineDeleteModal: FC<GenericModalProps> = ({ data, onClose }) => {
  const dispatch = useDispatch();

  const confirmHandler = useCallback(() => {
    dispatch(orderEntryLineActions.deleteLine(data));
  }, [dispatch]);

  return (
    <ConfirmationModal onCancel={onClose} onConfirm={confirmHandler}>
      <Typography>
        Are you sure you want to delete this order entry line from the table ?
      </Typography>
    </ConfirmationModal>
  );
};

export default memo(OrderEntryLineDeleteModal);
