import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { PageLayout } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { actions as clientsActions } from 'store/slices/clientsSlice';
import ClientsForm from 'components/Forms/ClientsForm';

const defaultValidation = Yup.object().shape({
  name: Yup.string(),
  internalName: Yup.string(),
  source: Yup.string(),
  writerLevel: Yup.string(),
  type: Yup.string(),
  timezoneUuid: Yup.string(),
});

const ClientsEditPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client } = useParams();

  // const emailTemplates = useSelector(
  //   (state: RootState) => state.user.emialTemplate,
  // );
  // const [exactEmailTemplate] = emailTemplates.filter(
  //   (template) => template.uuid === id,
  // );

  const clients = useSelector((state: RootState) => state.clients.items);
  const [exactClient] = clients.filter((c: any) => c?.uuid === client);

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const {
    createdAt,
    id,
    uuid,
    name,
    internalName,
    source,
    updatedAt,

  } = exactClient;

  const initialValues = {
    createdAt,
    id,
    uuid,
    name,
    internalName,
    source,
    timezone: capitalizeFirstLetter(exactClient?.timezone?.name),
    timezoneUuid: exactClient?.timezone?.uuid,
    type: capitalizeFirstLetter(exactClient.type),
    updatedAt,
    writerLevel: capitalizeFirstLetter(exactClient.writerLevel),
  };

  const handleSubmit = (values: any) => {
    const data = {
      ...values,
      navigate,
    };

    dispatch(clientsActions.editClients({ ...data, navigate }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <PageLayout title="Edit client" fullWidth={true}>
      <ClientsForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        initialValues={initialValues}
        validationSchema={defaultValidation}
      />
    </PageLayout>
  );
};
export default ClientsEditPage;
