import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => {
  return {
    PaperWrapper: {
      '& > .content-paper-wrapper': {
        '& > .employeeForm': {
          // display: 'flex',
          // flexWrap: 'wrap',
          // padding: 0,
        },
      },
    },
  };
});

export default styles;
