import {
  Box,
  Paper,
  Grid,
  Typography,
  IconButton,
  Button,
  Stack,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { FC, memo, PropsWithChildren } from 'react';
import styles from './SimpleFormModalStyles';

type SimpleFormModalType = {
  title: string;
  onClose: (event: {}) => void;
  onSubmit?: (event: {}) => void;
  btnCancelLabel?: string;
  btnSubmitLabel?: string;
  style?: any; // added for modal to add custom stilizations if that is needed
};

const SimpleFormModal: FC<PropsWithChildren<SimpleFormModalType>> = ({
  title,
  style,
  onClose,
  onSubmit,
  btnCancelLabel = 'Cancel',
  btnSubmitLabel = 'Save',
  children,
}) => {
  const classes = styles();

  return (
    <Box className={classes.Modal + ' modalBox'} style={style}>
      <Paper style={{ borderRadius: '20px', padding: '1rem' }}>
        <Grid
          padding="10px 10px 10px 20px"
          container
          justifyContent="space-between">
          <Grid item alignSelf="flex-end">
            <Typography
              gutterBottom
              color="primary"
              variant="h6"
              component="h2"
              style={{ fontWeight: 700 }}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color="primary" onClick={onClose} component="button">
              <CloseIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
        <Box padding="20px" className="formButtonsBox">
          <Box mb="20px" className="formBox" key={title}>
            {children}
          </Box>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            {onSubmit && (
              <Button variant="contained" onClick={onSubmit}>
                {btnSubmitLabel}
              </Button>
            )}
            <Button variant="outlined" color="primary" onClick={onClose}>
              {btnCancelLabel}
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
};

export default memo(SimpleFormModal);
