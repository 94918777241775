interface AuthHeaders {
  Authorization?: string | {};
}

export default function authHeaders(): AuthHeaders {
  const token = localStorage.getItem('login_token');

  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
}
