import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as commonActions } from 'store/slices/common';

import { actions as reportManagementSlice } from '../../../slices/reportSlices/reportManagementSlices/reportManagementSlice';
import { APIResult } from '../../../../types/APITypes';
import {
  editReportApi,
  getCategoryByUuid,
  getReportForEditApi,
  runTestQueryApi,
  saveReportApi,
} from '../../../api/reportManagementApi';
import { deleteReportCategoryApi } from '../../../api/reportsAPI';

function* getReportsFromCategoryByUuid(action: any) {
  yield put(commonActions.setLoading(true));
  const urlSuffix = action.payload.categoryUuid;
  const { result, error }: APIResult<any> = yield call(getCategoryByUuid, urlSuffix);
  if (result?.data) {
    yield put(reportManagementSlice.setReportsFromCategoryByUuid(result?.data));
  }
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  }
  yield put(commonActions.setLoading(false));
}

function* deleteReportFromCategoryByUuid(action: any) {
  yield put(commonActions.setLoading(true));
  const urlSuffix = action.payload.reportId;
  const { error }: APIResult<any> = yield call(deleteReportCategoryApi, urlSuffix);
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const urlSuffix = action.payload.categoryUuid;
    const { result, error }: APIResult<any> = yield call(getCategoryByUuid, urlSuffix);
    if (result?.data) {
      yield put(reportManagementSlice.setReportsFromCategoryByUuid(result?.data));
    }
    if (error) {
      yield put(
        commonActions.snackBar({
          open: true,
          message: error.message,
          severity: 'error',
        }),
      );
    }else{
      yield put(
        commonActions.snackBar({
          open: true,
          message: `You have successfully deleted the item from the list`,
          severity: 'info',
        }),
      );
    }
  }

  yield put(commonActions.setLoading(false));
}

function* runTestQuery(action: any) {
  yield put(commonActions.setLoading(true));
  const data = action.payload;
  const { result, error }: APIResult<any> = yield call(runTestQueryApi, data);
  if (result?.data) {
    yield put(reportManagementSlice.setRunTestQueryResult(result?.data));
  }
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'warning',
      }),
    );
    yield put(reportManagementSlice.setRunTestQueryResult(error.message));
  }
  yield put(commonActions.setLoading(false));
}

function* saveReport(action: any) {
  yield put(commonActions.setLoading(true));
  const data = { ...action.payload };
  delete data.navigate;
  const { result, error }: APIResult<any> = yield call(saveReportApi, data);
  if (result?.data) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully created report`,
        severity: 'success',
      }),
    );
    action.payload.navigate(-1);
  }
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  }

  yield put(commonActions.setLoading(false));
}

function* editReport(action: any) {
  yield put(commonActions.setLoading(true));
  const data = { ...action.payload.data };
  delete data.navigate;
  const urlSuffix = action.payload.urlSuffix;
  const { result, error }: APIResult<any> = yield call(editReportApi, urlSuffix, data);
  if (result?.data) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited report`,
        severity: 'success',
      }),
    );
    action.payload.data.navigate(-1);
  }
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  }

  yield put(commonActions.setLoading(false));
}

function* getReportForEdit(action: any) {
  yield put(commonActions.setLoading(true));
  const urlSuffix = action.payload.urlSuffix;
  const { result }: APIResult<any> = yield call(getReportForEditApi, urlSuffix);
  if (result?.data) {
    const report = result?.data;
    const objectWithInitialValues: any = {
      reportParametersDropdowns: result?.data.reportParameters,
    };
    Object.keys(report).map((key: any) => {
      if (key === 'reportParameters') {
        report[key].forEach((param: any) => {
          objectWithInitialValues[`reportParameter-param${param.index}`] = param.parameter.uuid;
          objectWithInitialValues[`${param.parameter.code}-param${param.index}`] = param.testValue;
        });
      } else if (key === 'folder') {
        objectWithInitialValues.categoryName = report[key].name;
        objectWithInitialValues.categoryUuid = report[key].uuid;
      } else {
        objectWithInitialValues[key] = report[key];
      }

    });

    yield put(reportManagementSlice.setReportForEdit(objectWithInitialValues));
  }
  yield put(commonActions.setLoading(false));
}

export default function* ReportManagementWatcher() {
  yield takeLatest(reportManagementSlice.getReportsFromCategoryByUuid.type, getReportsFromCategoryByUuid);
  yield takeLatest(reportManagementSlice.deleteReportFromCategoryByUuid.type, deleteReportFromCategoryByUuid);
  yield takeLatest(reportManagementSlice.runTestQuery.type, runTestQuery);
  yield takeLatest(reportManagementSlice.saveReport.type, saveReport);
  yield takeLatest(reportManagementSlice.getReportForEdit.type, getReportForEdit);
  yield takeLatest(reportManagementSlice.editReport.type, editReport);

}
