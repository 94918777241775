import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  FormLabel,
} from '@mui/material';
import { styles } from './EmailEditTemplateStyles';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useDispatch } from 'hooks/redux';
import { actions, EditEmailTemplate, EmailTemplate } from 'store/slices/user';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { PageTitle } from 'components';
import l from 'helpers/l';

const validationSchema = Yup.object().shape({
  name: Yup.string().min(1).required('Required'),
  subject: Yup.string().min(1).required('Required'),
  body: Yup.string().min(1).required('Required'),
});

const EmailEditTemplate: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const classes = styles();
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const emailTemplates = useSelector(
    (state: RootState) => state.user.emialTemplate,
  );
  const [exactEmailTemplate] = emailTemplates.filter(
    (template) => template.uuid === id,
  );
  const { key, name, subject, bcc, body, fields } = exactEmailTemplate;
  const initialValues: EditEmailTemplate = {
    uuid: id,
    key,
    name,
    subject,
    bcc,
    body,
    fields,
  };

  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: EmailTemplate) => {
      dispatch(
        actions.editEmailTemplate({
          uuid: id,
          key,
          name: values.name,
          subject: values.subject,
          bcc: values.bcc,
          body: values.body,
          fields,
        }),
      );
      if (!isLoading) {
        navigate('/email-list');
      }
    },
  });

  return (
    <Box padding="20px 50px 20px">
      <PageTitle title={l('PAGES.EDIT_EMAIL_TEMPLATE')} />
      {isLoading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <Box className={classes.EmailTemplateBox}>
          <Box className={classes.Lables}>
            <Box sx={{ cursor: 'default' }}>
              <FormLabel>Code</FormLabel>
              <Box className={classes.NonEditable}>{key}</Box>
            </Box>
            <Box maxWidth="50%">
              <FormLabel>Name</FormLabel>
              <TextField
                name="name"
                fullWidth
                size="small"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.name)}
                helperText={formik.errors.name}
              />
              <FormLabel>Subject</FormLabel>
              <TextField
                name="subject"
                fullWidth
                size="small"
                value={formik.values.subject}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.subject)}
                helperText={formik.errors.subject}
              />
              <FormLabel>Bcc</FormLabel>
              <TextField
                name="bcc"
                fullWidth
                size="small"
                value={formik.values.bcc}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.bcc)}
                helperText={formik.errors.bcc}
              />
            </Box>
          </Box>
          <Box className={classes.TextEditor}>
            <FormLabel>Body</FormLabel>
            <textarea
              name="body"
              className={classes.TextArea}
              style={{
                border: Boolean(formik.errors.body)
                  ? '1px solid #D32F2F'
                  : 'none',
              }}
              value={formik.values.body}
              onChange={formik.handleChange}
              minLength={1}
              placeholder={
                Boolean(formik.errors.body)
                  ? `Body is ${formik.errors.body}`
                  : ''
              }
            />
          </Box>
          <Box sx={{ cursor: 'default' }}>
            <FormLabel>Dynamic Fields</FormLabel>
            <Box className={classes.NonEditable}>
              {fields.map((curr) => `{{ ${curr} }} `)}
            </Box>
          </Box>
          <Box className={classes.ButtonBox}>
            <Button
              type="submit"
              variant="contained"
              onClick={formik.submitForm}>
              Save
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate('/email-list')}>
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EmailEditTemplate;
