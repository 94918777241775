import { FC, memo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, FormLabel, Stack } from '@mui/material';
import { TextField } from 'components';
import SimpleFormModal from 'components/SimpleFormModal/SimpleFormModal';
import { GenericModalProps } from 'types/genericTypes';
import { actions as orderLineStatusesActions } from '../../store/slices/orderLineStatusesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { createMenuItems } from '../../helpers/dropdown';
import l from '../../helpers/l';
import colors from '../../styles/colors';
import FormInfoRow from '../FormInfoRow/FormInfoRow';
import { numbersDataDropdown } from '../../config/utilsFunctions';

type OrderDeliveryFormProps = {
  initialValues?: any;
  data?: any;
  title: string;
  onSubmit: (values: any) => void;
} & GenericModalProps;

const OrderDeliveryForm: FC<OrderDeliveryFormProps> = ({
  onClose,
  initialValues,
  data,
  title,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const [sentToClientUuid, setSentToClientUuid] = useState<string>('');
  const [clientRevisionUuid, setClientRevisionUuid] = useState<string>('');

  const orderLineStatusesDropdown = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  ).map((entry: any) => ({ label: entry.name, value: entry.uuid }));

  const orderLineStatuses = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  );

  const validationSchema = Yup.object().shape({
    orderLineStatusUuid: Yup.string().required('This field is required!'), // mandatory
    clientCleanArticleLink: Yup.string().when('orderLineStatusUuid', {
      is: (orderLineStatusUuid: string) =>
        orderLineStatusUuid === sentToClientUuid,
      then: Yup.string().required('This field is required!').nullable(),
      otherwise: Yup.string().nullable(),
    }), // Client clean article link is mandatory if the new status is Sent to client
    clientRevisionUrl: Yup.string().when('orderLineStatusUuid', {
      is: (orderLineStatusUuid: string) =>
        orderLineStatusUuid === clientRevisionUuid,
      then: Yup.string().required('This field is required!').nullable(),
      otherwise: Yup.string().nullable(),
    }), // Client revision link is mandatory if status = client_revision
    statusDescription: Yup.string().when('orderLineStatusUuid', {
      is: (orderLineStatusUuid: string) =>
        orderLineStatusUuid === clientRevisionUuid,
      then: Yup.string().required('This field is required!').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    orderReceiverGradeForEditor: Yup.number()
      .min(0, 'Grade must be at least 0')
      .max(10, 'Grade cannot be over 10')
      .nullable(),
    orderReceiverGradeForPm: Yup.number()
      .min(0, 'Grade must be at least 0')
      .max(10, 'Grade cannot be over 10')
      .nullable(),
  });

  const { setValues, ...formik } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      const objToSend = {
        orderLineStatusUuid: values.orderLineStatusUuid || '',
        statusDescription: values.statusDescription || '',
        clientCleanArticleLink: values.clientCleanArticleLink || '',
        clientRevisionUrl: values.clientRevisionUrl || '',
        orderUuid: data?.uuid,
        orderReceiverGradeForEditor: values?.orderReceiverGradeForEditor,
        orderReceiverGradeForPm: values?.orderReceiverGradeForPm,
        editorGradeComments: values?.editorGradeComments,
        PMGradeComments: values?.PMGradeComments,
      };
      onSubmit(objToSend);
    },
  });

  useEffect(() => {
    const sentToClientValue = orderLineStatuses.find(
      (status: any) => status.code === 'sent_to_client',
    );
    const clientRevisionValue = orderLineStatuses.find(
      (status: any) => status.code === 'client_revision',
    );

    setSentToClientUuid(sentToClientValue?.uuid);
    setClientRevisionUuid(clientRevisionValue?.uuid);
  }, [formik]);

  useEffect(() => {
    dispatch(orderLineStatusesActions.getOrderLineStatuses());
  }, [dispatch]);

  return (
    <SimpleFormModal
      style={{ maxHeight: '90%', overflowY: 'auto' }}
      onSubmit={formik.submitForm}
      onClose={onClose}
      title={title}>
      <FormInfoRow
        rowTitle={'Client reference'}
        rowValueText={data?.clientReference || ''}
        color={colors.black}
        style={{ marginBottom: '25px' }}
      />
      <FormInfoRow
        rowTitle={'PM Clean Article Link'}
        rowValueText={data?.pmCleanArticleUrl || ''}
        url={data?.pmCleanArticleUrl || ''}
        color={colors.black}
        style={{ marginBottom: '25px' }}
      />
      <FormInfoRow
        rowTitle={'Writer Article Link'}
        rowValueText={data?.writerArticleLink || ''}
        url={data?.writerArticleLink || ''}
        color={colors.black}
      />
      <FormLabel sx={{ marginTop: '25px' }}>
        {l('ORDER_DELIVERY.CLIENT_CLEAN_ARTICLE_LINK')}
      </FormLabel>
      <TextField
        name="clientCleanArticleLink"
        size="small"
        value={formik.values.clientCleanArticleLink || ''}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.clientCleanArticleLink)}
        helperText={formik.errors.clientCleanArticleLink}
      />
      <FormLabel>{l('ORDER_DELIVERY.CLIENT_REVISION_URL')}</FormLabel>
      <TextField
        name="clientRevisionUrl"
        size="small"
        value={formik.values.clientRevisionUrl || ''}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.clientRevisionUrl)}
        helperText={formik.errors.clientRevisionUrl}
      />
      <FormLabel>Status</FormLabel>
      <TextField
        select
        name="orderLineStatusUuid"
        size="small"
        value={formik?.values?.orderLineStatusUuid}
        onChange={formik.handleChange}
        autoComplete="off"
        error={Boolean(formik.errors.orderLineStatusUuid)}
        helperText={formik.errors.orderLineStatusUuid}>
        {createMenuItems(orderLineStatusesDropdown)}
      </TextField>

      <>
        <FormLabel>Status Description</FormLabel>
        <TextField
          name="statusDescription"
          size="small"
          value={formik?.values?.statusDescription}
          onChange={formik.handleChange}
          autoComplete="off"
          error={Boolean(formik.errors.statusDescription)}
          helperText={formik.errors.statusDescription}
        />
      </>
      <Stack direction={'column'} gap={10} pb={10}>
        <Stack direction={'column'}>
          <Box>
            <FormLabel>Editor Grade</FormLabel>
            <TextField
              select
              name="orderReceiverGradeForEditor"
              size="small"
              className="number"
              value={formik?.values?.orderReceiverGradeForEditor}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.orderReceiverGradeForEditor)}
              helperText={formik.errors.orderReceiverGradeForEditor}>
              {createMenuItems(numbersDataDropdown())}
            </TextField>
          </Box>
          <Box>
            <FormLabel>Editor Grade Comments</FormLabel>
            <TextField
              required
              multiline
              minRows={5}
              maxRows={5}
              name="editorGradeComments"
              size="small"
              // inputProps={{ maxLength: 5000 }}
              value={formik.values.editorGradeComments}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.editorGradeComments)}
              helperText={formik.errors.editorGradeComments}
            />
          </Box>
        </Stack>
        <Stack direction={'column'}>
          <Box>
            <FormLabel>Pm Grade</FormLabel>
            <TextField
              select
              name="orderReceiverGradeForPm"
              size="small"
              className="number"
              value={formik?.values?.orderReceiverGradeForPm}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.orderReceiverGradeForPm)}
              helperText={formik.errors.orderReceiverGradeForPm}>
              {createMenuItems(numbersDataDropdown())}
            </TextField>
          </Box>
          <Box>
            <FormLabel>PM Grade Comments</FormLabel>
            <TextField
              required
              multiline
              minRows={5}
              maxRows={5}
              name="PMGradeComments"
              size="small"
              inputProps={{ maxLength: 5000 }}
              value={formik.values.PMGradeComments}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.PMGradeComments)}
              helperText={formik.errors.PMGradeComments}
            />
          </Box>
        </Stack>
      </Stack>
    </SimpleFormModal>
  );
};

export default memo(OrderDeliveryForm);
