import { FC, memo, useCallback} from 'react';
import { actions as userActions } from 'store/slices/user';
import { useDispatch} from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import UserExpertiseAddForm from 'components/Forms/UserExpertiseAddForm';
import { createMenuItems } from 'helpers/dropdown';

const UserExpertiseEditModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  let levelDropdown = [];

  for (let i = 1; i < 11; i++) {
    levelDropdown.push({ label: `${i}`, value: i });
  }

  const levelValues = createMenuItems(levelDropdown);

  const submitHandler = useCallback(
    (values: any) => {
      dispatch(
        userActions.editUserExpertise({
          ...values.values,
        }),
      );
    },
    [dispatch],
  );

  const initialValues = {
    expertiseId: data.data.expertise.id,
    ...data.data,
  };

  return (
    <UserExpertiseAddForm
      title="Employee Expertise Edit"
      employeeName={data.name}
      onSubmit={submitHandler}
      levelValues={levelValues}
      initialValues={initialValues}
      {...rest}
    />
  );
};

export default memo(UserExpertiseEditModal);
