import { FC, memo, useCallback } from 'react';
import { actions } from 'store/slices/checklistsRolesSlice';
import { useDispatch } from 'hooks/redux';
import ChecklistsRolesForm from 'components/Forms/ChecklistsRolesForm';
import l from 'helpers/l';
import { GenericModalProps } from 'types/genericTypes';

const ChecklistsRolesAddModal: FC<GenericModalProps> = ({ data, ...rest }) => {
  const dispatch = useDispatch();
  const submitHandler = useCallback(
    (values: any) => dispatch(actions.addChecklistsRoles(values)),
    [dispatch],
  );

  const initialValues = {
    articleTypeUuid: data.articleTypeUuid,
    roleCode: data.roleCode,
  };

  return (
    <ChecklistsRolesForm
      title={l('ADD_CHECKLIST_ROLES')}
      onSubmit={submitHandler}
      initialValues={initialValues}
      {...rest}
    />
  );
};

export default memo(ChecklistsRolesAddModal);
