import { GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import EditTable from 'components/Table/EditTable';
import { Box } from '@mui/material';
import { ModalType } from 'enums/ModalEnums';
import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { actions as commonActions } from 'store/slices/common';
import { actions as userActions } from 'store/slices/user';
import EmployeeRolesSwitch from './EmployeeRolesSwitch';
interface EmployeeViewRolesProps {
  employeeData: any;
}

const EmployeeViewRoles: FC<EmployeeViewRolesProps> = ({ employeeData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.common.loading);
  const { userRoles } = useSelector((state: RootState) => state.user);
  const { employeeDetails } = useSelector(
    (state: RootState) => state.employees,
  );
  const name = `${employeeDetails.firstName} ${employeeDetails.lastName}`;
  const addEntryHandler = useCallback(() => {
    dispatch(
      commonActions.showModal({
        type: ModalType.EmployeeRolesAdd,
        data: { id: employeeDetails.uuid, name } as any,
      }),
    );
  }, [dispatch, employeeDetails.uuid, name]);

  const editEntryHandler = useCallback(
    (params: GridRowParams) => {
      if (employeeDetails?.user?.username != 'admin') {
        dispatch(
          commonActions.showModal({
            type: ModalType.EmployeeRolesEdit,
            data: {
              data: params.row,
              name,
            } as any,
          }),
        );
      }
    },
    [dispatch, name],
  );

  useEffect(() => {
    dispatch(userActions.getUserRoles(employeeData.uuid));
  }, [dispatch, employeeData.uuid]);

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      if (employeeDetails?.user?.username != 'admin') {
        dispatch(
          commonActions.showModal({
            type: ModalType.EmployeeRolesDelete,
            data: params.row as any,
          }),
        );
      }
    },
    [dispatch],
  );
  return (
    <Box style={{ margin: '-1px -10px' }}>
      {!isLoading && (
        <EditTable
          loading={isLoading}
          rows={userRoles}
          sortedFieldIndex={0}
          columns={[
            {
              field: 'role',
              headerName: 'Role',
              width: 150,
              valueFormatter: (params: any) => params.value.name as string,
            },
            {
              field: 'wordsPerHour',
              headerName: 'Words per hour',
              width: 200,
            },
            {
              field: 'isActive',
              headerName: 'Is Active',
              width: 140,
              renderCell: (params: GridRenderCellParams) => (
                <EmployeeRolesSwitch data={params.row} />
              ),
            },
          ]}
          onAdd={addEntryHandler}
          onEdit={editEntryHandler}
          onDelete={deleteEntryHandler}
          hideFooterPagination
          hideFooter
        />
      )}
    </Box>
  );
};

export default EmployeeViewRoles;
