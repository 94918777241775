import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  items: [],
  meta: {},
};

export const checklistsSlice = createSlice({
  name: 'checklists',
  initialState,
  reducers: {
    getChecklists: (state) => state,
    setChecklists: (state, action: PayloadAction<any[]>) => action.payload,
    sendChecklistsFilters: (state) => state,
    addChecklists: (state) => state,
    deleteChecklists: (state) => state,
    editChecklists: (state) => state,
  },
});
export const actions: any = checklistsSlice.actions;
export default checklistsSlice.reducer;
