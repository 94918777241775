import { EditTable, Filters, PageLayout, TextField } from 'components';
import { memo, useCallback, useEffect, useState } from 'react';
import { actions as commonActions } from 'store/slices/common';
import { actions as contactPersonActions } from 'store/slices/contactPersonsSlice';
import { actions as clientActions } from 'store/slices/clientsSlice';
import { actions as employeeActions } from 'store/slices/employeeListSlice';
import { useDispatch, useSelector } from 'react-redux';
import l from 'helpers/l';
import { RootState } from 'store';
import { columns } from './utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridRowParams, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { ModalType } from 'enums/ModalEnums';
import { formatFilters, formatSortOrderBy } from 'helpers/filters';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { FormLabel, Grid } from '@mui/material';
import { createMenuItems } from 'helpers/dropdown';

const initialValues = {
  keyword: '',
  client: '',
  accountManager: '',
  contactPerson: '',
};

const ContactPersons = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sort, setSort] = useState({});
  const [dropdownValues, setDropdownValues] = useState({});
  const { items, meta } = useSelector(
    (state: RootState) => state.contactPersons,
  );
  const location = useLocation();

  const addEntryHandler = useCallback(() => {
    navigate('/contact-persons/add-contact-person');
  }, [navigate]);

  const editHandler = useCallback(
    (params: GridRowParams) => {
      navigate(`/edit-contact-persons/${params.row.uuid}`);
    },
    [navigate],
  );

  const deleteEntryHandler = useCallback(
    (params: GridRowParams) => {
      dispatch(
        commonActions.showModal({
          type: ModalType.ContactPersonDelete,
          data: params.row.nick.toString(),
        }),
      );
    },
    [dispatch],
  );

  const { setValues, ...formik } = useFormik({
    initialValues,
    onSubmit: (values) => {
      const formatValues = formatFilters(values);
      setDropdownValues(() => {
        return { ...formatValues };
      });
      if (!isEmpty(formatValues)) {
        const params = { ...formatValues, ...sort };
        dispatch(contactPersonActions.sendContactPersonsFilters(params));
      } else {
        dispatch(contactPersonActions.sendContactPersonsFilters(sort));
      }
    },
  });

  const handleSort = useCallback(
    (model: GridSortModel) => {
      const formatValues = model.map((item: GridSortItem) => ({
        orderBy: formatSortOrderBy(item.field),
        order: item?.sort?.toUpperCase(),
      }))[0];
      setSort({ orderBy: formatValues.orderBy, order: formatValues.order });
      const params = {
        orderBy: formatValues.orderBy,
        order: formatValues.order,
        ...dropdownValues,
      };
      dispatch(contactPersonActions.sendContactPersonsFilters(params));
    },
    [dispatch, dropdownValues],
  );

  const handlePage = useCallback(
    (page: number) => {
      const params = { ...dropdownValues, ...sort, page: page + 1 };
      dispatch(contactPersonActions.sendContactPersonsFilters(params));
    },
    [dispatch, dropdownValues, sort],
  );

  useEffect(() => {
    dispatch(contactPersonActions.getContactPersonsList());
  }, [dispatch, location?.pathname]);

  useEffect(() => {
    dispatch(clientActions.getClients());
    dispatch(employeeActions.getEmployeesByRole({ urlSuffix: 'am' }));
  }, [dispatch]);

  const onReset = useCallback(() => {
    setValues(initialValues);
    setDropdownValues({});
    dispatch(contactPersonActions.sendContactPersonsFilters(sort));
  }, [dispatch, setValues, sort]);

  const clientsDropdown = useSelector(
    (state: RootState) => state.clients.items,
  ).map((cli) => ({ label: cli.name, value: cli.uuid }));

  const accountManagerDropdown = useSelector(
    (state: RootState) => state.employees.employeesByRole,
  ).map((emp: any) => ({
    label: `${emp.firstName} ${emp.lastName}`,
    value: emp.uuid,
  }));

  return (
    <PageLayout title={l('PAGES.CONTACT_PERSONS')} fullWidth>
      <Filters handleFilters={formik.submitForm} onReset={onReset}>
        <Grid
          container
          spacing={2}
          columns={{ xs: 4, md: 20 }}
          alignItems="center">
          <Grid item xs={4} md={6}>
            <FormLabel>Keyword</FormLabel>
            <TextField
              autoHeight
              fullWidth
              name="keyword"
              size="small"
              value={formik.values.keyword}
              onChange={formik.handleChange}
              autoComplete="off"
              error={Boolean(formik.errors.keyword)}
            />
          </Grid>
          <Grid item xs={4} md={6}>
            <FormLabel>Client</FormLabel>
            <TextField
              select
              name="client"
              size="small"
              value={formik.values.client}
              onChange={formik.handleChange}
              autoComplete="off"
              pressEnter={formik.submitForm}>
              {createMenuItems(clientsDropdown)}
            </TextField>
          </Grid>
          <Grid item xs={4} md={6}>
            <FormLabel>Account Manager</FormLabel>
            <TextField
              select
              name="accountManager"
              size="small"
              value={formik.values.accountManager}
              onChange={formik.handleChange}
              autoComplete="off"
              pressEnter={formik.submitForm}>
              {createMenuItems(accountManagerDropdown)}
            </TextField>
          </Grid>
        </Grid>
      </Filters>
      <EditTable
        columns={columns}
        rows={items}
        onAdd={addEntryHandler}
        onEdit={editHandler}
        onDelete={deleteEntryHandler}
        editColumnWidth={130}
        sortedFieldIndex={1}
        onSortModelChange={handleSort}
        pagination
        page={meta?.currentPage ? meta?.currentPage - 1 : 0}
        rowCount={meta?.totalItems || 0}
        onPageChange={handlePage}
        pageSize={meta?.itemsPerPage}
        rowsPerPageOptions={[meta.itemsPerPage || 10]}
        paginationMode="server"
        sortingMode="server"
      />
    </PageLayout>
  );
};

export default memo(ContactPersons);
