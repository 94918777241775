import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slices/paymentSlice';
import { actions as commonActions } from 'store/slices/common';
import {
  addPaymentApi,
  getPaymentApi,
  editPaymentApi,
  deletePaymentApi,
} from 'store/api/paymentAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { GenericIdNameType } from 'types/genericTypes';
import { APIResult } from 'types/APITypes';

function* getPayment() {
  yield put(commonActions.setLoading(true));
  const { result }: APIResult<GenericIdNameType[]> = yield call(getPaymentApi);
  if (result?.data?.length) {
    yield put(actions.setPayment(result.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addPayment(action: PayloadAction<GenericIdNameType>) {
  const data = action.payload;
  const { result, error }: APIResult<GenericIdNameType> = yield call(
    addPaymentApi,
    data,
  );
  if (result?.status === 201) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getPaymentApi,
    );
    if (result?.data?.length) {
      yield put(actions.setPayment(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added ${data.name} to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: `${data.name} already exist!`,
        severity: 'error',
      }),
    );
  }
}

function* deletePayment(action: PayloadAction<GenericIdNameType>) {
  const { id, name } = action.payload;
  const { result }: APIResult<GenericIdNameType> = yield call(
    deletePaymentApi,
    id! as string,
  );
  if (result?.status === 200) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getPaymentApi,
    );
    if (result?.data?.length) {
      yield put(actions.setPayment(result.data));
    }
    yield put(commonActions.hideModal());
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted ${name} from the list`,
        severity: 'info',
      }),
    );
  }
}

function* editPayment(action: PayloadAction<GenericIdNameType>) {
  const name = action.payload;

  const { result, error }: APIResult<GenericIdNameType> = yield call(
    editPaymentApi,
    name,
  );
  if (result?.status === 200) {
    const { result }: APIResult<GenericIdNameType[]> = yield call(
      getPaymentApi,
    );
    if (result?.data?.length) {
      yield put(actions.setPayment(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited ${name.name}`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
  }
  if (error?.status === 400) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: 'Payment method name is taken!',
        severity: 'error',
      }),
    );
  }
}

export default function* PaymentWatcher() {
  yield takeLatest(actions.getPayment.type, getPayment);
  yield takeLatest(actions.addPayment.type, addPayment);
  yield takeLatest(actions.deletePayment.type, deletePayment);
  yield takeLatest(actions.editPayment.type, editPayment);
}
