import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoleDeleteEntry, Roles } from 'types/userRoleTypes';
import { PermissionEntry } from 'types/permissionTypes';
import { replaceItem } from 'helpers/arrayHelpers';

export type EmailTemplate = {
  uuid: string;
  key: string;
  name: string;
  subject: string;
  body: string;
  bcc: string;
  fields: string[];
};
export type EditEmailTemplate = {
  uuid: any;
  key: string;
  name: string;
  subject: string;
  body: string;
  bcc: string;
  fields: string[];
};
export type ReplacementsByRole = {
  items: [];
  meta: {};
};
export interface User {
  emialTemplate: EmailTemplate[];
  permissions: PermissionEntry[];
  roles: Roles[];
  userRoles: any;
  replacements: [];
  replacementsByRole: ReplacementsByRole[];
  userLanguages: [];
  userUnassignedLanguages: [];
  userExpertise: [];
  userUnassignedExpertise: [];
  userQuestions: [];
  userUnassignedQuestions: [];
  userPreferences: any[];
}

const initialState: User = {
  emialTemplate: [
    {
      uuid: '',
      key: '',
      name: '',
      subject: '',
      bcc: '',
      body: '',
      fields: [''],
    },
  ],
  permissions: [{ code: '', name: '' }],
  roles: [
    {
      code: '',
      name: '',
      permissions: [{ code: '', name: '' }],
    },
  ],
  userRoles: [],
  replacements: [],
  replacementsByRole: [],
  userLanguages: [],
  userUnassignedLanguages: [],
  userExpertise: [],
  userUnassignedExpertise: [],
  userQuestions: [],
  userUnassignedQuestions: [],
  userPreferences: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getEmailTemplates: (state) => state,
    getExactEmailTemplate: (state) => state,
    setEmailTemplates: (state, action: PayloadAction<EmailTemplate[]>) => {
      state.emialTemplate = action.payload;
    },
    editEmailTemplate: (state) => state,
    editEmailTemplateSuccess: (state, action: PayloadAction<EmailTemplate>) => {
      const id = action.payload.uuid;
      const findIndex = state.emialTemplate.findIndex(
        (template) => template.uuid === id,
      );
      const copyState = state.emialTemplate.slice();
      copyState.splice(findIndex, 1, action.payload);
      state.emialTemplate = copyState;
    },
    getPermissions: (state) => state,
    setPermissions: (state, action: PayloadAction<PermissionEntry[]>) => {
      state.permissions = action.payload;
    },
    editPermission: (state) => state,
    editPermissionSuccess: (state, action: PayloadAction<PermissionEntry>) => {
      const id = action.payload.code;
      const findIndex = state.permissions.findIndex((el) => el.code === id);
      const copyState = state.permissions.slice();
      copyState.splice(findIndex, 1, action.payload);
      state.permissions = copyState;
    },
    getRoles: (state) => state,
    setRoles: (state, action: PayloadAction<Roles[]>) => {
      state.roles = action.payload;
    },
    editRole: (state) => state,
    addRole: (state) => state,
    deleteRole: (state) => state,
    editRolePermission: (state) => state,
    editRolePermissionSuccess: (state, action: PayloadAction<Roles>) => {
      const id = action.payload.code;
      const findIndex = state.roles.findIndex((el) => el.code === id);
      const copyState = state.roles.slice();
      copyState.splice(findIndex, 1, action.payload);
      state.roles = copyState;
    },
    deleteRolePermission: (state) => state,
    createUser: (state) => state,
    inactivateUser: (state) => state,
    activateUser: (state) => state,
    unlockUser: (state) => state,
    getUserRoles: (state) => state,
    setUserRoles: (state, action) => {
      return { ...state, userRoles: action.payload };
    },
    editUserRole: (state) => state,
    editUserRoleSuccess: (state, action) => {
      const editedRoles = replaceItem<any>(
        state.userRoles,
        (entry) => entry.uuid === action.payload.uuid,
        action.payload,
      );
      return { ...state, userRoles: editedRoles };
    },
    assignRole: (state) => state,
    assignRoleSuccess: (state, action: any) => {
      return { ...state, userRoles: [...state.userRoles, action.payload] };
    },
    deleteUserRole: (state) => state,
    deleteUserRoleSuccess: (state, action: any) => {
      return {
        ...state,
        userRoles: state.userRoles.filter(
          (user: any) => user.uuid !== action.payload,
        ),
      };
    },
    getReplacements: (state) => state,
    setReplacements: (state, action: any) => {
      state.replacements = action.payload;
    },
    addReplacements: (state) => state,
    deleteReplacement: (state) => state,
    getReplacementsByRoles: (state) => state,
    setReplacementsByRoles: (
      state,
      action: PayloadAction<ReplacementsByRole[]>,
    ) => {
      state.replacementsByRole = action.payload;
    },
    getUserLanguages: (state) => state,
    setUserLanguages: (state, action: any) => {
      state.userLanguages = action.payload;
    },
    getUserUnassignedLanguages: (state) => state,
    setUserUnassignedLanguages: (state, action: any) => {
      state.userUnassignedLanguages = action.payload;
    },
    addUserLanguage: (state) => state,
    deleteUserLanguage: (state) => state,
    editUserLanguage: (state) => state,
    getUserExpertise: (state) => state,
    setUserExpertise: (state, action: any) => {
      state.userExpertise = action.payload;
    },
    getUserUnassignedExpertise: (state) => state,
    setUserUnassignedExpertise: (state, action: any) => {
      state.userUnassignedExpertise = action.payload;
    },
    addUserExpertise: (state) => state,
    deleteUserExpertise: (state) => state,
    editUserExpertise: (state) => state,
    getUserQuestions: (state) => state,
    setUserQuestions: (state, action: any) => {
      state.userQuestions = action.payload;
    },
    getUserUnassignedQuestions: (state) => state,
    setUserUnassignedQuestions: (state, action: any) => {
      state.userUnassignedQuestions = action.payload;
    },
    addUserQuestions: (state) => state,
    deleteUserQuestions: (state) => state,
    editUserQuestions: (state) => state,
    getUserPreferences: (state: any) => state,
    setUserPreferences: (
      state,
      action: PayloadAction<User['userPreferences']>,
    ) => {
      state.userPreferences = action.payload;
    },
    addUserPreferences: (state) => state,
    editUserPreferences: (state) => state,
    deleteUserPreferences: (state) => state,
    editEmployeeUsername: (state) => state,
  },
});
export const actions: any = userSlice.actions;
export default userSlice.reducer;
