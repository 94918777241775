import { FC, memo} from 'react';
import { actions as employeeActions } from 'store/slices/employeeListSlice';
import { useDispatch } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { Typography } from '@mui/material';

const EmployeesDeleteModal: FC<GenericModalProps> = ({ data, onClose }) => {
  const dispatch = useDispatch();
  const { employeeDetails, navigate = null } = data;

  const confirmHandler = () => {
    dispatch(
      employeeActions.deleteEmployee({
        id: employeeDetails?.uuid,
        ...(navigate && { navigate }),
      }),
    );
  };

  return employeeDetails?.uuid ? (
    <ConfirmationModal onCancel={onClose} onConfirm={confirmHandler}>
      <Typography>
        Are you sure you want to delete
        <strong>
          {` ${employeeDetails.firstName} ${employeeDetails.lastName}`}{' '}
        </strong>
        from the list ?
      </Typography>
    </ConfirmationModal>
  ) : (
    <div>Error: Item not found!</div>
  );
};

export default memo(EmployeesDeleteModal);
