import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState: any = {
    category: {},
    runTestReportResult: {},
    reportForEdit: {},
};

export const reportManagementSlice = createSlice({
    name: 'reportManagement',
    initialState,
    reducers: {
        getReportsFromCategoryByUuid: (state) => state,
        setReportsFromCategoryByUuid: (state, action: PayloadAction<any>) => {
            state.category = action.payload;
        },
        deleteReportFromCategoryByUuid: (state) => state,
        runTestQuery:  (state) => state,
        setRunTestQueryResult:  (state, action: PayloadAction<any>) => {
            state.runTestReportResult = action.payload;
        },
        saveReport: (state) => state,
        editReport: (state) => state,
        getReportForEdit: (state) => state,
        setReportForEdit: (state, action: PayloadAction<any>) => {
            state.reportForEdit = action.payload;
        },
    },
});
export const actions: any = reportManagementSlice.actions;
export default reportManagementSlice.reducer;
